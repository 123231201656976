import gql from 'graphql-tag';
import { useEffect } from 'react';
import { iRegionModel } from 'shared/deployment';
import { useAuthedQuery } from '../../utils/qlAuth';
import { JobList } from './jobs/JobList';
import { Skeleton, Space } from 'antd';
import { spaceWidth } from 'utils/styles';

export interface iClusterJobsProps {
  region: iRegionModel;
}

export const ClusterJobs = (props: iClusterJobsProps) => {
  const query = useAuthedQuery(
    gql`
      query JobsController_jobsList($regionId: Int!) {
        JobsController_jobsList(regionId: $regionId) {
          id
          status
          region
          createdAt
          updatedAt
          variables
          finishTime
          createdAt
          tfMode
          name
          lastError
        }
      }
    `,
    { skip: !Number(props?.region?.id), variables: { regionId: Number(props?.region?.id) }, pollInterval: 5000 },
  );
  query.startPolling(5000);

  useEffect(() => {
    return query.stopPolling;
  }, []);

  if (query.loading) return <Skeleton active={true} loading={true} style={{ width: '200px' }} />;

  return (
    <Space direction="vertical" style={spaceWidth}>
      <JobList query={query} refetch={query.refetch} region={props.region} clusterAreReady={true} />
    </Space>
  );
};
