import { Typography, Form, AutoComplete, Input, Space, Button } from 'antd';
import Password from 'antd/es/input/Password';
import { regionsService } from 'services/regions.service';
import { iRegionModel } from 'shared/deployment';
import { buttonBorder } from 'utils/styles';
import { awsRegionNames } from '../settings/TerraformJobsConfig';

const { Title, Text } = Typography;

export const ChangeAWSkeys = (props: { region: iRegionModel; onSave?: any }) => {
  const handleOnClick_RegionConfigurations = async value => {
    if (props.onSave) {
      props.onSave();
    }
    await regionsService.updateOwnRegion(region.id, value);
  };

  const region = props.region;

  const titles = { fontWeight: 'bold', marginTop: '10px' };
  const texts = { marginTop: '10px', marginBottom: '10px' };
  return (
    <Form onFinish={handleOnClick_RegionConfigurations} style={{ ...texts, maxWidth: 480 }} labelCol={{ flex: '200px' }} labelWrap={true}>
      <Title level={5} style={titles}>
        Region configurations
      </Title>

      <Form.Item key={`regionName`} name={`regionName`} label={`AWS region`} initialValue={region.regionName || ''}>
        <AutoComplete
          options={awsRegionNames.map(region => ({ value: region }))}
          filterOption={(input, option) => option?.value.toLowerCase().includes(input.toLowerCase())}
        >
          <Input placeholder="Select AWS region here" />
        </AutoComplete>
      </Form.Item>

      <Form.Item
        key={`TF_AWS_ACCESS_KEY_ID`}
        name={`TF_AWS_ACCESS_KEY_ID`}
        label={`AWS_ACCESS_KEY_ID`}
        initialValue={region.TF_AWS_ACCESS_KEY_ID || ''}
      >
        <Password placeholder="Enter AWS access key ID here" />
      </Form.Item>

      <Form.Item
        key={`TF_AWS_SECRET_ACCESS_KEY`}
        name={`TF_AWS_SECRET_ACCESS_KEY`}
        label={`AWS_SECRET_ACCESS_KEY`}
        initialValue={region.TF_AWS_SECRET_ACCESS_KEY || ''}
      >
        <Password placeholder="EEnter AWS secret access key here" />
      </Form.Item>
      <Space direction="horizontal">
        <Button type="primary" htmlType="submit" style={buttonBorder}>
          Save AWS access configuration
        </Button>
      </Space>
    </Form>
  );
};
