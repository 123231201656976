import './Authentication.scss';
import { Fragment, useState } from 'react';
import { authService } from '../../services/auth.service';
import { handleInputChange } from '../../utils/common';
import { Typography, Button, Input, notification, Space, Modal, Alert } from 'antd';
import { formInput, formButton } from 'utils/styles';
import { PROJECT_CODE_NAME, PROJECT_NAME } from 'interface/common';
import { AuthenticationForm, ImageC, LoginLink, SignupHeading, TextSignup } from 'components/SharedComponents/AuthComp/AuthComp';
import { ServerVars } from 'utils/ServerVars';
import { error } from 'console';
import { isVariableSubDomainName } from 'shared/user';

const { Text } = Typography;

const onFormSubmit = async (content: { email: string; organization: string; firstName: string; lastName: string; cloudURL: string }) => {
  notification.info({ key: 'RequestAccess', description: 'Please wait a second...', message: '' });
  try {
    const { status, data } = await authService.requestAccess(content);
    if (status === 201) {
      notification.success({ key: 'RequestAccess', message: 'Success' });
      Modal.info({
        centered: true,
        onOk: () => {
          debugger;
          window.location.replace(
            `https://${content.cloudURL}.${window.location.host.replace('auth.', '')}/#/login/email/${encodeURIComponent(content.email)}`,
          );
        },
        title: `You have successfully signed up`,
        content: (
          <>
            <p> Thank you for your interest in the {PROJECT_NAME} service. </p>
            <p>
              We sent an email to <b> {content.email} </b> with further instructions. Please check your email and follow the instructions.
            </p>
          </>
        ),
      });
    } else {
      notification.error({ key: 'RequestAccess', description: data.message, message: '' });
    }
  } catch (error) {
    notification.error({ key: 'RequestAccess', description: 'An error occurred', message: '' });
  }
};

export default function RequestAccess() {
  const [login, setLogin] = useState('');
  const [organization, setOrganization] = useState('');
  const [cloudURL, setCloudURL] = useState('');
  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');

  const defaultURL = String(cloudURL || organization || '')
    .trim()
    .replace(/[^A-z0-9\-]/gim, '-')
    .replace(/-+/gim, '-')
    .toLocaleLowerCase()
    .replace(/^-/gim, '')
    .replace(/-$/gim, '');

  const handleForm = () => onFormSubmit({ email: login, organization, firstName: fName, lastName: lName, cloudURL: defaultURL });

  const fields: any = [
    { label: 'First Name', placeholder: 'Enter first name here', setter: setFName, status: '', error: '' },
    { label: 'Last Name', placeholder: 'Enter last name here', setter: setLName, status: '', error: '' },
    { label: 'Organization name', placeholder: 'Enter organization name here', setter: setOrganization, status: '', error: '' },
    {
      label: `URL of your ${PROJECT_NAME} cloud`,
      placeholder: 'Enter DNS alias here',
      setter: setCloudURL,
      suffix: `.${PROJECT_CODE_NAME}`,
      value: defaultURL,
      status: !defaultURL || isVariableSubDomainName(defaultURL) ? '' : 'error',
      error: '',
    },
    { label: 'Email ID', placeholder: 'Enter email ID here', setter: setLogin, status: '', error: '' },
  ];

  if (!ServerVars.ALLOW_signup) {
    return (
      <>
        <ImageC />
        <LoginLink />
        <AuthenticationForm>
          <Alert type="error" message={`Signup is disabled.`} description={`Please contact your Administrator.`} />
        </AuthenticationForm>
        <TextSignup />
      </>
    );
  }

  return (
    <>
      <ImageC />
      <LoginLink />
      <AuthenticationForm>
        <SignupHeading />
        <form onSubmit={handleForm}>
          <Space direction="vertical" style={formInput}>
            {fields.map(({ label, placeholder, setter, value, suffix, status, error }) => (
              <Fragment key={1}>
                <Text strong> {label} </Text>
                <Input status={status} placeholder={placeholder} onChange={handleInputChange(setter)} value={value} suffix={suffix} />
                {error && <Text type="danger"> {error} </Text>}
              </Fragment>
            ))}
            <Text />
            <Button type="primary" onClick={handleForm} style={formButton}>
              Sign up
            </Button>
          </Space>
        </form>
      </AuthenticationForm>
      <TextSignup />
    </>
  );
}
