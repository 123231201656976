import { useMutation, useQuery } from '@apollo/client';
import { notification } from 'antd';

interface DeleteResult {
  status: string;
  answer: any;
}

/**
 * Like useQuery but will refresh the page if the response is 401.
 * @link https://medium.com/@sourabhbagrecha/how-to-handle-error-401-unauthorized-request-in-react-query-732297f24285
 * @param options
 * @returns
 */

export const useAuthedQuery = (...options) => {
  // @ts-ignore
  const query = useQuery(...options);
  // @ts-ignore
  if (query?.error?.response?.status === 401) {
    // Insert custom access-token refresh logic here. For now, we are just refreshing the page here, so as to redirect them to the login page since their token is now expired.
    debugger;
    window.location.reload();
  }
  if (query?.error?.message) notification.error({ message: `${query?.error?.message}` });
  return query;
};

/**
 * Like useMutation but will refresh the page if the response is 401.
 * @link https://medium.com/@sourabhbagrecha/how-to-handle-error-401-unauthorized-request-in-react-query-732297f24285
 * @param options
 * @returns
 */

export const useAuthedMutation = (...options) => {
  // @ts-ignore
  const mutation = useMutation(...options);
  // @ts-ignore
  if (mutation?.error?.response?.status === 401) {
    // Insert custom access-token refresh logic here. For now, we are just refreshing the page here, so as to redirect them to the login page since their token is now expired.
    debugger;
    window.location.reload();
  }
  return mutation;
};

/**
 * Call useAuthedMutation - and show notification on error or success
 * @param options
 * @returns
 */

export const useAuthedMutationWithNotification = (...options) => {
  // @ts-ignore
  const mutation = useAuthedMutation(...options);
  const [mutateFunction, saveRes] = mutation;
  const newMutateFunction = async (...args) => {
    try {
      await mutateFunction(...args);
    } catch (e) {
      notification.error({ message: `${e.message}` });
      throw e;
    }
    if (saveRes.error) {
      notification.error({ message: `Error - ${saveRes.error}` });
    } else {
      notification.success({ message: `Ready` });
    }
    return saveRes;
  };
  mutation[0] = newMutateFunction;
  return mutation;
};
