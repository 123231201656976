import { authService } from '../../services/auth.service';
import { Button, Form, Input, message, Space } from 'antd';
import { AuthenticationForm, CreatePassword, FormFooter, ImageB, LoginLink, TextPassword } from 'components/SharedComponents/AuthComp/AuthComp';
import { useHistory } from 'react-router-dom';
import { buttonWidth, formInput } from 'utils/styles';

const { Item } = Form;
const { Password } = Input;

export default function CreateUserPassword() {
  const [form] = Form.useForm();
  const history = useHistory();

  const createPassword = async ({ password, rePassword }) => {
    if (password.length < 8 || rePassword.length < 8) {
      message.error('Password is not valid');
      return;
    }
    if (password !== password.replace(/[^\x00-\x7F]+/gi, '')) {
      message.error('Password is not valid');
      return;
    }
    if (rePassword !== rePassword) {
      message.error('Password mismatch');
      return;
    }
    try {
      await authService.createPassword(password);
      message.success('Success');
      history.push('/');
    } catch (e) {
      message.error('Error ' + e?.message);
      console.error(e);
    }
  };

  return (
    <>
      <ImageB />
      <LoginLink />
      <AuthenticationForm>
        <Space direction="vertical">
          <CreatePassword />
          <Form form={form} onFinish={createPassword}>
            <Item key="password" name="password">
              <Password type="password" placeholder="Enter the password here" style={formInput} />
            </Item>
            <Item key="rePassword" name="rePassword">
              <Password type="password" placeholder="Re-enter the password here" style={formInput} />
            </Item>
            <FormFooter />
            <Item>
              <Button type="primary" htmlType="submit" style={buttonWidth}>
                Create Password
              </Button>
            </Item>
          </Form>
        </Space>
      </AuthenticationForm>
      <TextPassword />
    </>
  );
}
