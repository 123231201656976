let _cache_userData: any = null;
import { iProject } from 'interface/project';
import _ from 'lodash';
import { iProjectModel } from 'shared/deployment';

/**
 * Return user data from github api
 * @returns
 */
export const getGitHubUserData = async (): Promise<any> => {
  if (_cache_userData) {
    return _cache_userData;
  }

  const githubAccessToken = window.sessionStorage.getItem('githubAccessToken');
  const userData = await fetch(`https://api.github.com/user`, {
    method: 'GET',
    headers: {
      Accept: 'application/vnd.github.v3+json',
      Authorization: 'token ' + githubAccessToken,
    },
  });
  _cache_userData = await userData.json();
  return _cache_userData;
};

let _cache_getGitHubOrganizations_url: any = null;
export const getGitHubOrganizations_url = async (): Promise<any> => {
  if (_cache_getGitHubOrganizations_url) {
    return _cache_getGitHubOrganizations_url;
  }
  const githubAccessToken = window.sessionStorage.getItem('githubAccessToken');
  const userData = await getGitHubUserData();

  const organizations = await fetch(userData.organizations_url, {
    method: 'GET',
    headers: {
      Accept: 'application/vnd.github.v3+json',
      Authorization: 'token ' + githubAccessToken,
    },
  });
  _cache_getGitHubOrganizations_url = await organizations.json();
  return _cache_getGitHubOrganizations_url;
};

let _cache_getGitHubAllRepos: any = null;
export const getGitHubAllRepos = async (): Promise<any> => {
  if (_cache_getGitHubAllRepos) {
    return _cache_getGitHubAllRepos;
  }
  const userData = await getGitHubUserData();
  const orgs = await getGitHubOrganizations_url();

  const repos = await fetch(userData.repos_url, {
    method: 'GET',
    headers: {
      Accept: 'application/vnd.github.v3+json',
      Authorization: 'token ' + window.sessionStorage.getItem('githubAccessToken'),
    },
  });
  const orgReposArr = await repos.json();

  for (let i = 0; i < orgs.length; i++) {
    const repos = await fetch(orgs[i].repos_url, {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.github.v3+json',
        Authorization: 'token ' + window.sessionStorage.getItem('githubAccessToken'),
      },
    });
    orgReposArr.push(await repos.json());
  }

  const newArr = _.flatten(orgReposArr);

  _cache_getGitHubAllRepos = newArr;
  return _cache_getGitHubAllRepos;
};

/**
 * Check if github token is valid
 * @returns
 */
export const isValidGithubApiToken = (): boolean => {
  const githubAccessToken = window.sessionStorage.getItem('githubAccessToken');
  const githubAccessToken_created = window.sessionStorage.getItem('githubAccessToken_created');
  return githubAccessToken && githubAccessToken_created && new Date().getTime() - Number(githubAccessToken_created) < 3600000;
};

export const getGitHubRepoBranches = async (repo: any): Promise<any> => {
  const githubAccessToken = window.sessionStorage.getItem('githubAccessToken');
  const userData = await fetch(`${repo.url}/branches`, {
    method: 'GET',
    headers: {
      Accept: 'application/vnd.github.v3+json',
      Authorization: 'token ' + githubAccessToken,
    },
  });
  return await userData.json();
};

export const createGitHubRepoForProject = async (project: iProjectModel): Promise<any> => {
  const userData = await getGitHubUserData();

  const repos = await fetch(userData.repos_url, {
    method: 'GET',
    headers: {
      Accept: 'application/vnd.github.v3+json',
      Authorization: 'token ' + window.sessionStorage.getItem('githubAccessToken'),
    },
  });
  const orgReposArr = await repos.json();

  const repoName = `unifie-${project.id}-${project.name}`.replaceAll(' ', '-').replaceAll(/[^0-9A-z\-]/gim, '');

  const repo = (orgReposArr || []).find((repo: any) => repo.name === repoName);
  if (repo) {
    return repo;
  }
  try {
    const newRepo = await fetch(`https://api.github.com/user/repos`, {
      method: 'POST',
      headers: {
        Accept: 'application/vnd.github.v3+json',
        'X-GitHub-Api-Version': `2022-11-28`,
        Authorization: 'token ' + window.sessionStorage.getItem('githubAccessToken'),
      },
      body: JSON.stringify({
        name: repoName,
        description: `${project.name} - ${project.description}`,
        homepage: 'https://unifie.cloud',
        private: true,
        is_template: false,
        has_issues: false,
        has_projects: false,
        has_wiki: false,
        has_discussions: false,
        auto_init: true,
        has_downloads: false,
      }),
    });

    const res = await newRepo.json();

    if (Number(res.status) === 422) {
      // repo.errors.find(r => r.message === "name already exists on this account")
      return {
        clone_url: `${userData.html_url}/${repoName}`,
        default_branch: `main`,
      };
    }
    return res;
  } catch (e) {
    return {
      clone_url: `${userData.html_url}/${repoName}`,
      default_branch: `main`,
    };
  }
};

export const getGitHubToken = (): string => {
  return String(window.sessionStorage.getItem('githubAccessToken'));
};

/**
 * Download a repository archive (tar)
 * https://docs.github.com/en/rest/repos/contents?apiVersion=2022-11-28#download-a-repository-archive-tar
 */
