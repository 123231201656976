import { useHistory, useParams } from 'react-router-dom';
import { useGqlDeploymentById } from '../../../services/deployment.service';
import { iDeployment } from 'shared/deployment';
import { DeploymentWidgetsRow } from '../DeploymentWidgetsRow';
import { TitleUIRow } from 'layout/TitleUI';
import { Button, Result, Skeleton, Tabs } from 'antd';
import { buttonBorder, topMargined } from 'utils/styles';
import { MonitoringOfServices } from '../monitoring/MonitoringOfServices/MonitoringOfServices';
import { AWSPriceForDeployment } from '../monitoring/PriceCharts/AWSPriceForDeployment';
import { OpenCostPriceForDeployment } from '../monitoring/PriceCharts/OpenCostPriceForDeployment';
import { OverViewInInit } from '../OverView/OverviewInInit';
import { userHasPermission, UserPermissions } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { SpecsRevisions } from '../SpecsRevisions/SpecsRevisions';
import OverViewV2 from '../OverView/OverViewV2';

const { TabPane } = Tabs;

export default function DeploymentStatusPage() {
  const history = useHistory();
  // @ts-ignore
  const { id, tabName } = useParams();

  const user = useUser();
  const dpQuery = useGqlDeploymentById(id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};
  const { error, loading } = dpQuery;

  const inInitStage_NoNamespace = !deployment?.NamespaceModel?.name;
  const inInitStage_NoSync = !(deployment?.NamespaceModel?.lastSyncDelay < 1707802250);
  const inInitStage = inInitStage_NoNamespace || inInitStage_NoSync;

  const { isReady, id: ID, name, useManualApply } = deployment;

  if (!tabName) history.push(`/app/${id}/status/overview`);

  if (error) {
    return (
      <Skeleton active={false} loading={false}>
        {JSON.stringify(error)}
      </Skeleton>
    );
  }

  if (loading || !deployment) return <Skeleton active={true} loading={true} />;
  if (!isReady) {
    const finishButton = () => (
      <Button type="primary" onClick={() => history.push(`/app/${ID}/configuration/settings/general`)} style={buttonBorder}>
        Finish configuration
      </Button>
    );
    return <Result status="warning" title="Application not ready" subTitle="Sorry, the page you visited does not exist." extra={finishButton()} />;
  }

  if (inInitStage) {
    return (
      <>
        <TitleUIRow title={`Application: ${name}`} />
        <OverViewInInit deployment={deployment} />
      </>
    );
  }

  const { Viewer, DeploymentHistory } = UserPermissions;

  const overviewTabs = [
    { tab: 'Overview', key: 'overview', component: <OverViewV2 deployment={deployment} />, permission: Viewer },
    { tab: 'Monitoring', key: 'services', component: <MonitoringOfServices deployment={deployment} /> },
    { tab: 'OpenCost', key: 'price-opencost', component: <OpenCostPriceForDeployment deployment={deployment} /> },
    { tab: 'AWS', key: 'price-aws', component: <AWSPriceForDeployment deployment={deployment} /> },
    {
      tab: 'Deploy',
      key: 'deploy',
      component: <SpecsRevisions deployment={deployment} />,
      condition: useManualApply && userHasPermission(user, DeploymentHistory),
    },
  ];

  return (
    <>
      <TitleUIRow title={`Application: ${name}`} />
      <DeploymentWidgetsRow deployment={deployment} />
      <Tabs
        style={topMargined}
        defaultActiveKey={tabName || `overview`}
        onChange={(activeKey: string) => history.push(`/app/${id}/status/${activeKey}`)}
      >
        {overviewTabs.map(({ tab, key, component, permission, condition }) =>
          (permission && !userHasPermission(user, permission)) || (condition && !condition) ? null : (
            <TabPane tab={tab} key={key}>
              {component}
            </TabPane>
          ),
        )}
      </Tabs>
    </>
  );
}
