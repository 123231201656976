export const form_ports_schema_ui = { 'ui:options': { orderable: false } };
export const form_ports_schema = {
  type: 'array',
  description: `Ports configuration`,
  items: {
    type: 'object',
    properties: {
      name: { type: 'string', title: 'Name', default: 'http' },
      containerPort: { type: 'number', title: 'Port', default: 80 },
      protocol: { type: 'string', title: 'Protocol', enum: ['TCP', 'UDP'], default: 'TCP' },
    },
  },
};
