import './Overview.scss';
import gql from 'graphql-tag';
import Markdown from 'react-markdown';
import DeploymentUptimeComponent from 'components/ChartRenderer/DeploymentUptimeComponent';
import { Link, useHistory, useParams } from 'react-router-dom';
import { uptimeToString } from '../../../utils/common';
import { useGqlDeploymentById, useGqlDeploymentRoutes } from '../../../services/deployment.service';
import { MonitoringStatus } from '../../Deployments/MonitoringStatus/MonitoringStatus';
import { InstallCommands } from '../InstallCommands/InstallCommands';
import { ChartDashboardMap } from 'components/ChartRenderer/ChartDashboard';
import { iDeployment } from 'shared/deployment';
import { TitleUIRow } from 'layout/TitleUI';
import { DeploymentWidgetsRow } from '../DeploymentWidgetsRow';
import { DeploymentTag } from '../DeploymentTag';
import { DeploymentNotes } from '../DeploymentNotes';
import { useAuthedQuery } from 'utils/qlAuth';
import { ResourcesCard } from '../ResourcesStates/ResourcesData';
import { Popover, Row, Col, Skeleton, Tag, Space, Card, Button, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { bottomMargin, buttonWidth, floatingRight } from 'utils/styles';
import { OverViewInInit } from './OverviewInInit';
import { DeploymentUptime } from './deploymentUptime';

const { Title, Text } = Typography;

export default function OverView() {
  // @ts-ignore
  let { id } = useParams();
  const history = useHistory();
  const dpQuery = useGqlDeploymentById(id);
  const dpQueryRoutes = useGqlDeploymentRoutes(id);

  const projectDocsQl = useAuthedQuery(
    gql`
      query DeploymentsController_getProjectDocs($deploymentId: Int!) {
        DeploymentsController_getProjectDocs(deploymentId: $deploymentId) {
          docs
        }
      }
    `,
    { variables: { deploymentId: Number(id) } },
  );

  const projectDocs = projectDocsQl.data?.DeploymentsController_getProjectDocs?.docs || '';
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};
  const error = dpQuery.error || dpQueryRoutes.error;
  const loading = dpQuery.loading || dpQueryRoutes.loading;
  const hasRoutes = dpQueryRoutes.data?.DeploymentsController_getRoutes?.routes?.length > 0;
  const inInitStage_NoNamespace = !deployment?.NamespaceModel?.name;
  const inInitStage_NoSync = !(deployment?.NamespaceModel?.lastSyncDelay < 1707802250);
  const inInitStage = inInitStage_NoNamespace || inInitStage_NoSync;

  if (inInitStage) {
    dpQuery.startPolling(10000);
  } else {
    dpQuery.stopPolling();
  }

  const OverView_stats = [
    {
      id: 0,
      span: `12`,
      height: 400,
      name: 'Availability for deployments (30 minutes)',
      vizState: {
        chartType: 'line',
        query: {
          measures: ['Monitoring.okRate'],
          timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'minute', dateRange: 'Last 30 minute' }],
          dimensions: ['Monitoring.serviceName'],
          filters: [{ member: 'Monitoring.deploymentId', operator: 'equals', values: [`${id}`] }],
        },
      },
    },
    {
      id: 0,
      span: `12`,
      height: 400,
      name: 'Response time (30 minutes)',
      vizState: {
        chartType: 'line',
        query: {
          measures: ['Monitoring.timeResponse'],
          timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'minute', dateRange: 'Last 30 minute' }],
          dimensions: ['Monitoring.serviceName'],
          filters: [{ member: 'Monitoring.deploymentId', operator: 'equals', values: [`${id}`] }],
        },
      },
    },
  ];

  const OverView_usage = [
    {
      id: 0,
      span: `12`,
      name: 'CPU usage avg (60 minutes)',
      height: 400,
      vizState: {
        chartType: 'line',
        query: {
          measures: ['PodsMonitoring.cpuUsageAverage'],
          timeDimensions: [{ dimension: 'PodsMonitoring.createdAt', granularity: 'minute', dateRange: 'Last 60 minute' }],
          dimensions: ['PodsMonitoring.uiName'],
          filters: [{ member: 'PodsMonitoring.deploymentId', operator: 'equals', values: [`${id}`] }],
          // order: { 'PodsMonitoring.createdAt': 'desc', },
        },
      },
    },
    {
      id: 0,
      span: `12`,
      name: 'Mem usage avg (60 minutes)',
      height: 400,
      vizState: {
        chartType: 'line',
        query: {
          measures: ['PodsMonitoring.memUsageAverage'],
          timeDimensions: [{ dimension: 'PodsMonitoring.createdAt', granularity: 'minute', dateRange: 'Last 60 minute' }],
          dimensions: ['PodsMonitoring.uiName'],
          filters: [{ member: 'PodsMonitoring.deploymentId', operator: 'equals', values: [`${id}`] }],
          // order: { 'PodsMonitoring.createdAt': 'desc', },
        },
      },
    },
  ];

  const loadingSkeleton = () => <Skeleton active={true} loading={true} />;

  if (error) {
    history.push('/');
    return loadingSkeleton();
  }

  if (loading) return loadingSkeleton();

  if (!deployment.isReady) {
    history.push(`/app/${deployment.id}/configuration/settings/general`);
    return loadingSkeleton();
  }

  const isOnPremise = deployment.isOnpremise;

  const popoverContent = (
    <Space direction="vertical">
      <li> This Application do not have any Routes defined. </li>
      <li>
        If you want to make it Public, you can add Routes in the Project service settings on
        <Text strong> Routes </Text>
        tab.
      </li>
      <Link to={`/project/${deployment.projectId}/settings/services`}>
        <Button type="primary" style={buttonWidth}>
          Add Routes
        </Button>
      </Link>
    </Space>
  );

  const minutesData = [
    { minutes: 60, title: '60 min uptime' },
    { minutes: 15, title: '15 min uptime' },
    { minutes: 5, title: '5 min uptime' },
    { minutes: 3, title: '3 min uptime' },
  ];

  const deploymentPromise = () => (
    <Row gutter={24}>
      {inInitStage || isOnPremise ? null : (
        <Col span={13}>
          {hasRoutes && (
            <>
              <Space size="large">
                {minutesData.map(({ minutes, title }) => (
                  <Card key={minutes} bordered={false}>
                    <DeploymentUptimeComponent deploymentId={deployment.id} minutes={minutes} title={title} size="big" />
                  </Card>
                ))}
              </Space>
              <br /> <br />
            </>
          )}
          <ResourcesCard deploymentId={deployment.id} />
          <br />
        </Col>
      )}
      <Col span={isOnPremise ? 24 : 11}>
        {isOnPremise ? (
          <>
            <Card style={bottomMargin}>
              <InstallCommands deployment={deployment} />
            </Card>
            <br />
          </>
        ) : (
          !inInitStage && <MonitoringStatus id={id} />
        )}
        <DeploymentNotes deployment={deployment} />
        {projectDocs && (
          <Card style={bottomMargin}>
            <Markdown>{projectDocs}</Markdown>
          </Card>
        )}
      </Col>
    </Row>
  );

  if (inInitStage) {
    return (
      <>
        <TitleUIRow title={`Application: ${deployment.name}`} />
        <OverViewInInit deployment={deployment} />
      </>
    );
  }

  return (
    <>
      <TitleUIRow title={`Application: ${deployment.name}`} />

      <DeploymentWidgetsRow deployment={deployment} />
      <DeploymentUptime deployment={deployment} hasRoutes={hasRoutes} popoverContent={popoverContent} />
      {deploymentPromise()}
      {!isOnPremise && hasRoutes && !inInitStage && <ChartDashboardMap stats={OverView_stats} />}
      {!isOnPremise && !inInitStage && <ChartDashboardMap stats={OverView_usage} />}
    </>
  );
}
