import { useUser } from './common';
import { PROJECT_NAME } from 'interface/common';

export const formatUserName = (email: string) => {
  try {
    const [username] = email.split('@');
    const usernameMap = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);
    return username.toLowerCase() === 'admin' ? 'Admin' : username.split(/[.-]/).map(usernameMap).join(' ');
  } catch (e) {
    return `${PROJECT_NAME} User`;
  }
};

export const avatarUserName = (email: string) => {
  try {
    const [username] = email.split('@');
    const [firstName, ...rest] = username.split(/[.-]/).map(word => word.charAt(0).toUpperCase() + word.slice(1));
    const lastName = rest.length > 0 ? rest.join('') : '';
    const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
    return username.toLowerCase() === 'admin' ? 'A' : (() => initials)();
  } catch (e) {
    return `${PROJECT_NAME} User`;
  }
};

export const avatarLogo = {
  backgroundColor: `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')}`,
};

export const capitalLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

export const UserNameFormat = () => {
  const user = useUser();
  return user && user.email ? `${user.email.split('.')[0].charAt(0).toUpperCase() + user.email.split('.')[0].slice(1)}!` : '';
};
