import { Alert, Space } from 'antd';
import { CommonFormUI, CommonUI_JsonFormModalUI, CommonUI_select, CommonUI_text } from './CommonFormUI';
import { useContext } from 'react';
import { ProjectDeploymentSyncVarsContext } from '../ProjectDeploymentContext';
import { form_schema_Service_ports } from './schema/ServicePorts';
import { spaceWidth } from 'utils/styles';

export interface iServiceMainOptionsUIProps {
  yamlObj: any;
  onChange: (key: string, newObj: any, action?: string) => void;
}

export const form_schema_Labels = { schema: { type: 'object', additionalProperties: { type: 'string' } }, ui: {} };

export const ServiceMainOptionsUI = (props: iServiceMainOptionsUIProps) => {
  let volumeHelpUI = null;

  const formData = () => {
    const syncVarsContext = useContext(ProjectDeploymentSyncVarsContext);

    const childrenName = () => (
      <CommonUI_text value={props.yamlObj?.metadata?.name} onChange={props.onChange} path={`.metadata`} propertyName={`name`} />
    );

    const childrenTypeOptions = [
      { value: 'ClusterIP', label: 'ClusterIP' },
      { value: 'NodePort', label: 'NodePort' },
      { value: 'LoadBalancer', label: 'LoadBalancer' },
      { value: 'ExternalName', label: 'ExternalName' },
    ];

    const childrenType = () => (
      <CommonUI_select
        value={props.yamlObj?.spec?.type}
        onChange={props.onChange}
        path={`.spec`}
        propertyName={`type`}
        options={childrenTypeOptions}
      />
    );

    const childrenSelector = () => (
      <CommonUI_JsonFormModalUI
        formData={props.yamlObj?.spec?.selector || {}}
        formConfigs={form_schema_Labels}
        SyncVarsContext={syncVarsContext}
        title={`Match labels`}
        onChange={(v: any) => props.onChange(`.spec.selector`, v)}
      />
    );

    const childrenMetadataLabels = () => (
      <CommonUI_JsonFormModalUI
        formData={props.yamlObj?.metadata?.labels || {}}
        formConfigs={form_schema_Labels}
        SyncVarsContext={syncVarsContext}
        title={`Metadata labels`}
        onChange={(v: any) => props.onChange(`.metadata.labels`, v)}
      />
    );

    const childrenPorts = () => (
      <CommonUI_JsonFormModalUI
        formData={props.yamlObj?.spec?.ports || []}
        formConfigs={form_schema_Service_ports}
        SyncVarsContext={syncVarsContext}
        title={`Ports`}
        onChange={(v: any) => props.onChange(`.spec.ports`, v)}
      />
    );

    const form = [
      { name: 'Name', children: childrenName() },
      { name: 'Type', children: childrenType() },
      { name: 'Selector', children: childrenSelector() },
      { name: 'Metadata labels', children: childrenMetadataLabels() },
      { name: 'Ports', children: childrenPorts() },
    ];
    return <CommonFormUI form={form} />;
  };

  const alertData = () => {
    const alertDescription =
      'In Kubernetes, a Service is a method for exposing a network application that is running as one or more Pods in your cluster';
    const volumeHelpUIalertAction = <a href={'https://kubernetes.io/docs/concepts/services-networking/service/'}> Learn more </a>;
    return <Alert type="info" description={alertDescription} action={volumeHelpUIalertAction} />;
  };

  volumeHelpUI = alertData();

  return (
    <Space direction="vertical" style={spaceWidth}>
      {formData()}
      {volumeHelpUI}
    </Space>
  );
};
