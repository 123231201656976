import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';

const resultExtra = (
  <Link to="/">
    <Button type="primary"> Back </Button>
  </Link>
);

export const PaymentError = () => <Result status="error" title="Somthing goes wrong!" subTitle="Please ask support team." extra={resultExtra} />;
