import gql from 'graphql-tag';
import CountUp from 'react-countup';
import { useEffect } from 'react';
import { useAuthedQuery } from 'utils/qlAuth';
import { iRegionModel } from 'shared/deployment';
import { Console } from 'console-feed';
import { Alert, Col, Skeleton, Statistic, Typography } from 'antd';
import { bottomMargin, logsStyle, logsText } from 'utils/styles';

const { Text } = Typography;

interface iApiAgentStatusProps {
  region: iRegionModel;
}

export function ApiAgentStatus(props: iApiAgentStatusProps) {
  const { loading, error, data, stopPolling, startPolling } = useAuthedQuery(
    gql`
      query ApiAgentController_getStatus($regionId: Int!) {
        ApiAgentController_getStatus(regionId: $regionId) {
          status
          message
          logs
          noSyncTime
        }
      }
    `,
    { variables: { regionId: props.region.id }, pollInterval: 25000 },
  );
  startPolling(25000);

  useEffect(() => {
    return stopPolling;
  }, []);

  let apiAgentStatus = data?.ApiAgentController_getStatus;

  const formatter = (value: number) => {
    if (value === 0 || value > 3600 * 24 * 365)
      return (
        <Text strong style={{ color: '#FF7777' }}>
          Never synced
        </Text>
      );
    return <CountUp end={value} separator="," />;
  };
  return (
    <Col className="gutter-row " span={24}>
      <Skeleton active={true} loading={loading && !error && !apiAgentStatus}>
        {apiAgentStatus && (
          <>
            <Alert
              message={apiAgentStatus.message}
              description={<Statistic title="Last sync time" value={apiAgentStatus.noSyncTime} formatter={formatter} />}
              type={apiAgentStatus.status}
              style={bottomMargin}
            />
            {apiAgentStatus.logs ? (
              <div style={logsStyle}>
                <Console logs={apiAgentStatus.logs} variant="light" styles={logsText} />
              </div>
            ) : null}
          </>
        )}
      </Skeleton>
    </Col>
  );
}
