import gql from 'graphql-tag';
import TabPane from 'antd/lib/tabs/TabPane';
import { ReactElement, useEffect, useState } from 'react';
import { Skeleton, Switch, Tabs } from 'antd';
import { projectService } from 'services/project.service';
import { useHistory, useParams } from 'react-router-dom';
import { NewService } from './NewService';
import { useLazyQuery } from '@apollo/client';
import { ProjectDeploymentSyncVarsContext } from 'components/Projects/ProjectDeploymentContext';
import { ProjectOneServiceSettingsPostProcessing } from 'components/Projects/Settings/ProjectService/PostProcessingTab';
import { ProjectOneServiceCustomYAML } from 'components/Projects/Settings/ProjectService/ProjectOneServiceCustomYAML';
import { ProjectOneServiceSettingsFormRoutes } from 'components/Projects/Settings/ProjectService/ProjectOneServiceSettingsFormRoutes';
import { ProjectOneServiceVariables } from 'components/Projects/Settings/ProjectService/ProjectOneServiceVariables';
import { useApiQuery, useUser } from 'utils/common';
import { iDeployment, iDeploymentServicesConfig, iProjectModel } from 'shared/deployment';
import { ProjectOneServiceSettingsHelmTab } from 'components/Projects/Settings/ProjectService/HelmTab';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';
import { DockerWizard } from 'components/Projects/YamlEditor/wizard/DockerWizard';
import { PROJECT_CODE_NAME } from 'interface/common';
import { YamlEditor } from 'components/Projects/YamlEditor/YamlEditor';
import { StarFilled } from '@ant-design/icons';
import { useGqlAllServicesConfigurationUI } from 'services/deployment.service';
import { DeploymentOneServiceSettings } from 'components/Deployments/Setting/General/DeploymentOneServiceSettings';
import { isInAdvancedMode, setAdvancedMode } from 'utils/util';
import { userHasPermission, UserPermissions } from 'shared/UserRolesPermission';
import { OneServiceMetrics } from './metrics/OneServiceMetrics';

const { Provider } = ProjectDeploymentSyncVarsContext;

/** http://localhost:3000/#/app/43/configuration/services
 * @param props
 * @returns */
export const ConfigurationServices = (props: { deployment: iDeployment; serviceName: string }): ReactElement => {
  const history = useHistory();
  const deployment = props.deployment;
  const project: iProjectModel = deployment?.ProjectModel;

  const params: any = useParams();
  const { serviceName, activeTabType } = params;
  const [isAdvancedMode, setIsAdvancedMode] = useState(isInAdvancedMode(`dp`, deployment.id));

  const user = useUser();

  const deploymentId = deployment?.id;
  const [getSyncVars, { loading, error, data }] = useLazyQuery(
    gql`
      query DeploymentsController_debugUI_deploymentSyncVars($deploymentId: Int!) {
        DeploymentsController_debugUI_deploymentSyncVars(deploymentId: $deploymentId) {
          syncVars
        }
      }
    `,
  );

  const qlQuery = useGqlAllServicesConfigurationUI(Number(deployment.id));
  const servicesConfig: iDeploymentServicesConfig[] = qlQuery.data?.DeploymentsController_AllServicesConfigurationUI || [];

  const serviceConfig = servicesConfig.find(service => service.serviceName === props.serviceName);

  const [serviceData, serviceError, serviceLoader] = useApiQuery(
    () => projectService.getProjectService(project?.id, serviceName),
    [project?.id, serviceName],
  );
  const service: iCloudProjectServiceYamlSpecs = serviceData;

  useEffect(() => {
    if (deploymentId) getSyncVars({ variables: { deploymentId: deploymentId } });
  }, [deploymentId]);

  if (loading || serviceLoader || qlQuery.loading) {
    return <Skeleton active={true} loading={true} />;
  }
  if (serviceName === 'new') {
    return <NewService deployment={deployment} />;
  }

  const uiType = service?.uiType || null;
  let defaultOpenKey = 'metrics';
  if (deployment.isReady !== true) {
    if (serviceConfig) {
      defaultOpenKey = 'favorit';
    } else if (uiType === `helm`) {
      defaultOpenKey = 'helm';
    } else if (uiType === `docker`) {
      defaultOpenKey = 'deployment-configuration';
    } else if (uiType === `k8s`) {
      defaultOpenKey = 'custom-yaml';
    }
  }

  const selectedTabType = activeTabType || defaultOpenKey;
  const projectFunctions = { service: service, project: project, serviceName: serviceName, tabType: selectedTabType };

  if (!deploymentId || !user) {
    return <Skeleton active={true} loading={true} />;
  }

  const handleTabClick = (key: any) => history.push(`/app/${deploymentId}/configuration/services/${serviceName}/${key}`);

  const handleOnClick = val => {
    setIsAdvancedMode(val);
    setAdvancedMode(`dp`, deployment.id, val);
  };

  let showAdvancedModeSwitch = false;
  const gitTabs = [
    deployment.isReady === true
      ? {
          uiTypes: [`docker`, `helm`, `k8s`, null],
          permission: userHasPermission(user, UserPermissions.ProjectsMange),
          forAdvancedMode: false,
          key: 'metrics',
          tab: 'Metrics',
          component: <OneServiceMetrics service={service} serviceName={props.serviceName} deployment={deployment} />,
        }
      : null,
    {
      forAdvancedMode: false,
      key: 'favorit',
      permission: userHasPermission(user, UserPermissions.DeploymentMange),
      tab: (
        <>
          <StarFilled /> Favorite settings
        </>
      ),
      component: <DeploymentOneServiceSettings serviceName={props.serviceName} deployment={deployment} schema={serviceConfig} />,
    },
    {
      uiTypes: [`docker`, null],
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forAdvancedMode: false,
      key: 'deployment-configuration',
      tab: 'Wizard',
      component: (
        <DockerWizard
          key="wizard"
          serviceName={serviceName}
          fileName={`${PROJECT_CODE_NAME}/services/${serviceName}/template.yaml`}
          project={project}
          deployment={deployment}
        />
      ),
    },
    {
      uiTypes: [`docker`, null],
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forAdvancedMode: false,
      key: 'deployment-yaml',
      tab: 'YAML advanced configuration',
      component: <YamlEditor key={`yaml-editor`} fileName={`${PROJECT_CODE_NAME}/services/${serviceName}/template.yaml`} deployment={deployment} />,
    },
    {
      uiTypes: [`helm`, null],
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forAdvancedMode: false,
      key: 'helm',
      tab: 'HELM',
      component: <ProjectOneServiceSettingsHelmTab {...projectFunctions} deployment={deployment} />,
    },
    {
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forAdvancedMode: false,
      key: 'variables',
      tab: 'Variables',
      component: <ProjectOneServiceVariables deployment={deployment} {...projectFunctions} />,
    },
    {
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forAdvancedMode: false,
      key: 'route',
      tab: 'Routes',
      component: <ProjectOneServiceSettingsFormRoutes deployment={deployment} {...projectFunctions} />,
    },
    {
      uiTypes: [`helm`, null],
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forAdvancedMode: true,
      key: 'post-processing-rules',
      tab: 'Post Processing',
      component: <ProjectOneServiceSettingsPostProcessing deployment={deployment} {...projectFunctions} />,
    },
    {
      uiTypes: [`helm`, `k8s`, null],
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forAdvancedMode: uiType !== `k8s`,
      key: 'custom-yaml',
      tab: 'Custom YAML Template',
      component: <ProjectOneServiceCustomYAML deployment={deployment} {...projectFunctions} />,
    },
  ];

  const gitTabsFiltered = gitTabs
    .filter(Boolean)
    .filter(v => !v.uiTypes || v.uiTypes.includes(uiType))
    .filter(v => v.permission !== false)
    .filter(v => {
      const res = v.forAdvancedMode === isAdvancedMode || v.forAdvancedMode === false;
      if (v.forAdvancedMode === true) {
        showAdvancedModeSwitch = true;
      }
      return res;
    });

  const extraTab = showAdvancedModeSwitch && userHasPermission(user, UserPermissions.ProjectsMange) && (
    <Switch checkedChildren="Advanced Mode" unCheckedChildren="Basic mode" onClick={handleOnClick} checked={isAdvancedMode} />
  );

  return (
    <Provider value={{ serviceName: serviceName, syncVars: data?.DeploymentsController_debugUI_deploymentSyncVars?.syncVars }}>
      <Tabs defaultActiveKey={selectedTabType} onChange={handleTabClick} tabBarExtraContent={extraTab}>
        {gitTabsFiltered.map(({ key, tab, component }) => (
          <TabPane key={key} tab={tab}>
            {component}
          </TabPane>
        ))}
      </Tabs>
    </Provider>
  );
};
