import { useState, ReactNode, FC } from 'react';
import { Space, Splitter } from 'antd';
import { spaceWidth } from 'utils/styles';

interface iSplitter {
  left: ReactNode;
  right: ReactNode;
}

interface iSplitChild {
  children: ReactNode;
}

export const SplitterComp: FC<iSplitter> = ({ left, right }) => {
  const [sizes, setSizes] = useState<(number | string)[]>(['50%', '50%']);
  const [enabled] = useState(true);
  const Resizer: FC<iSplitChild> = ({ children }) => <> {children} </>;
  const panels = [{ position: left }, { position: right }];
  const { Panel } = Splitter;

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Splitter onResize={setSizes}>
        {panels.map(({ position }, index) => (
          <Panel collapsible key={index} resizable={enabled} size={sizes[index]} style={{ marginLeft: 10 }}>
            <Resizer> {position} </Resizer>
          </Panel>
        ))}
      </Splitter>
    </Space>
  );
};
