import { useAuthedMutationWithNotification, useAuthedQuery } from '../utils/qlAuth';
import gql from 'graphql-tag';
import { authService } from './auth.service';

export const projectVersionsList = async (projectId: number, filter: object, params: object) => {
  return await authService.getApolloClient().query({
    query: gql`
      query Versions_getVersionsList($projectId: Int!, $filter: JSON!, $sort: JSON!) {
        Versions_getVersionsList(projectId: $projectId, filter: $filter, sort: $sort) {
          total
          result {
            id
            channel
            name
            build
            title
            description
            isAvailable
            images
            updatedAt
            createdAt
          }
        }
      }
    `,
    variables: { projectId: projectId, filter: filter, sort: params },
  });
};

export const createNewVersion = async (projectId: number, data: object) => {
  return await authService.getApolloClient().query({
    query: gql`
      mutation Versions_addVersion($projectId: Int!, $data: JSON!) {
        Versions_addVersion(projectId: $projectId, data: $data) {
          result
        }
      }
    `,
    variables: { projectId: projectId, data: data },
  });
};

export const updateVersion = async (Id: number, data: object) => {
  return await authService.getApolloClient().query({
    query: gql`
      mutation Versions_updateVersion($id: Int!, $data: JSON!) {
        Versions_updateVersion(id: $id, data: $data) {
          result
        }
      }
    `,
    variables: { id: Id, data: data },
  });
};

export const deleteVersion = async (Id: number) => {
  return await authService.getApolloClient().query({
    query: gql`
      mutation Versions_deleteVersion($id: Int!) {
        Versions_deleteVersion(id: $id) {
          result
        }
      }
    `,
    variables: { id: Id },
  });
};
