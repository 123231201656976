import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { Button, Col, Flex, Row, Select, Skeleton, Space, Typography } from 'antd';
import { iDeployment } from 'shared/deployment';
import { ServiceLogs } from '../monitoring/logs';
import { useAuthedQuery } from 'utils/qlAuth';
import { floatingRight, spaceWidth } from 'utils/styles';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { TipTopLeft } from 'components/SharedComponents/Tooltip/Tooltip';

const { Text } = Typography;
const { Option } = Select;

export const PodsLogs = (props: { deployment: iDeployment; tabName: string }) => {
  const history = useHistory();
  const tabName = props.tabName;
  const deployment = props.deployment;

  /**
   * Container name for logs in cubernetes pod
   * @type {string}
   * @todo get container name from the pod describe command ( https://nanoheal.atlassian.net/browse/DP-565 )
   * can be empty string - in this case logs will be taken only if in pod only one container
   */
  let container: string = '';

  const podsTabsRegex = /^pods_(.*)/;

  let serviceName = '';
  let podsTabRegex = /^pods_(.*?)_container_/;
  const containerRegex = /container_(.*)/;
  if (tabName) {
    // let serviceName = tabName.replace(podsTabRegex, '$1');
    if (tabName && podsTabRegex.test(tabName)) {
      serviceName = tabName.match(podsTabRegex)[1];
      if (containerRegex.test(tabName)) {
        container = tabName.match(containerRegex)[1];
      } else {
        container = '';
      }
    } else {
      podsTabRegex = /^pods_(.*)/;
      if (podsTabRegex.test(tabName)) {
        serviceName = tabName.match(podsTabRegex)[1];
      }
    }
  }

  const podsQuery = useAuthedQuery(
    gql`
      query DeploymentsController_getPodsMetrics($deploymentId: Int!) {
        DeploymentsController_getPodsStatus(deploymentId: $deploymentId) {
          error
          status
        }
      }
    `,
    { skip: !Number(deployment.id), variables: { deploymentId: Number(deployment.id) } },
  );

  const loadingStatus = podsQuery?.loading;
  const errorStatus = podsQuery?.error || podsQuery?.data?.DeploymentsController_getPodsStatus?.status?.error;
  const podsList = podsQuery?.data?.DeploymentsController_getPodsStatus?.status || [];

  if (!serviceName && podsList.length > 0) {
    serviceName = podsList[0].name;
  }

  const query = useAuthedQuery(
    gql`
      query DeploymentsController_getPodsDescribe($deploymentId: Int!, $podName: String) {
        DeploymentsController_getPodsDescribe(deploymentId: $deploymentId, podName: $podName) {
          error
          status
          yaml
        }
      }
    `,
    { skip: !Number(deployment.id) || !serviceName || serviceName === '', variables: { deploymentId: Number(deployment.id), podName: serviceName } },
  );

  if (loadingStatus) return <Skeleton active={true} loading={true} />;

  if (errorStatus || query.error) {
    return (
      <Skeleton active={false} loading={false}>
        Error: {errorStatus?.message || JSON.stringify(query.error)}
      </Skeleton>
    );
  }

  const podContainerList = [];
  let desctibeContiner = {};
  let yamlPod = query.data?.DeploymentsController_getPodsDescribe?.yaml || '';
  if (query.data?.DeploymentsController_getPodsDescribe?.status) {
    const allContainers = query.data?.DeploymentsController_getPodsDescribe?.status[0]?.spec?.containers;
    if (allContainers) {
      allContainers.map(item => {
        podContainerList.push(item.name);
        if (item.name == container) {
          desctibeContiner = item;
        }
      });
      if (container == '') {
        container = podContainerList[0];
        desctibeContiner = allContainers[0];
      } else {
        const position = podContainerList.indexOf(container);
        container = podContainerList[position];
        desctibeContiner = allContainers[position];
      }
    }
  }

  if (!container && podContainerList.length > 0) {
    container = podContainerList[0];
  }

  const changeContainer = (elem: any) => history.push(`/app/${deployment.id}/logs/pods_${serviceName}_container_${elem}`);
  const handleChange = (value: string) => history.push(`/app/${deployment.id}/logs/pods_${value}`);

  const podSelection = () => (
    <Col span={12}>
      <Space direction="horizontal">
        <Text strong> Select Pod </Text>
        <TipTopLeft tip="Select the pod to see the logs">
          <QuestionCircleTwoTone twoToneColor="#00A58E" />
        </TipTopLeft>
        {podsList && (
          <Select defaultValue={serviceName} onChange={handleChange} style={{ width: '20vw' }}>
            {(podsList || []).map(item => (
              <Option value={item.name}> {item.name} </Option>
            ))}
          </Select>
        )}
      </Space>
    </Col>
  );

  const podContainerSelection = () => (
    <Col span={12}>
      <Flex justify="flex-end" style={floatingRight}>
        <Space direction="horizontal">
          <Text strong> Select Pod container </Text>
          {podContainerList.map(item => (
            <Button type={container === item ? `primary` : `default`} onClick={() => changeContainer(item)}>
              {item.charAt(0).toUpperCase() + item.slice(1)}
            </Button>
          ))}
        </Space>
      </Flex>
    </Col>
  );

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Row>
        {podSelection()}
        {podContainerSelection()}
      </Row>
      <Text />
      <ServiceLogs serviceName={serviceName} deployment={deployment} container={container} describe={desctibeContiner} yamlPod={yamlPod} />
    </Space>
  );
};
