import { iUserModel } from 'shared/user';
import { apiQuery, iBaseAjaxAnswer } from '../utils/common';

class UsersService {
  async newUser(user: iUserModel): Promise<iBaseAjaxAnswer<iUserModel>> {
    const res = await apiQuery('post', `/api/users/new`, user);
    return res;
  }

  async getUsers(): Promise<iBaseAjaxAnswer<iUserModel[]>> {
    const res = await apiQuery('GET', `/api/users`);
    return res;
  }

  async getUser(userId: number): Promise<iBaseAjaxAnswer<iUserModel>> {
    const res = await apiQuery('GET', `/api/users/${userId}`);
    return res;
  }

  async deleteUser(userId: number): Promise<iBaseAjaxAnswer<any>> {
    const res = await apiQuery('delete', `/api/users/${userId}`);
    return res;
  }

  async updateUser(userId: number, user: iUserModel): Promise<iBaseAjaxAnswer<any>> {
    const res = await apiQuery('post', `/api/users/${userId}`, user);
    return res;
  }
}

const usersService = new UsersService();
export { usersService, UsersService };
