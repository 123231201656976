import './Authentication.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { authService } from '../../services/auth.service';
import { handleInputChange, useApiQuery } from '../../utils/common';
import { iProject } from 'interface/project';
import { projectService } from 'services/project.service';
import { Typography, Button, Input, notification, Space } from 'antd';
import { formButton } from 'utils/styles';
import { capitalLetter } from 'utils/nameFormat';
import { PROJECT_NAME } from 'interface/common';
import { AuthenticationForm, ImageA, ImageD, SignupLink, TextFind } from 'components/SharedComponents/AuthComp/AuthComp';

const { Title, Text } = Typography;

const BigDeployButton = (props: { projectId: number }) => {
  const handleDeployClick = () => window.location.replace(`/#/new/${props.projectId}`);
  return (
    <Button type="primary" onClick={handleDeployClick} style={formButton}>
      Deploy
    </Button>
  );
};

const FindTenantFormForProjectDeploy = (props: { projectId: number }) => {
  const [email, setEmail] = useState('');
  const [lastEmail, setLastEmail] = useState('');
  const [tenants, setTenants] = useState([]);

  const onTenantsRequest = async email => {
    const { error, status, data } = await authService.tenants(email);
    return error
      ? (notification.error({ key: 'Login', message: `status ${status}`, description: data.message }), null)
      : // Set the email in local storage to remember the last used email
        (window.localStorage.setItem('LastTenant_email', email),
        setLastEmail(email),
        data.length === 0
          ? // @ts-ignore
            notification.warn({ key: 'Login', description: `Your email was not found. Would you like to sign up? to ${PROJECT_NAME}` })
          : null,
        setTenants(data));
  };

  useEffect(() => {
    const lastUsedEmail = window.localStorage.getItem('LastTenant_email') || undefined;
    lastUsedEmail ? onTenantsRequest(lastUsedEmail) : null;
  }, []);

  const handleFormSubmit = () => onTenantsRequest({ email });

  const loginButton = () => {
    const emailButtonDeploy = () => (
      <Button type="primary" disabled={lastEmail === email} onClick={() => onTenantsRequest(email)} style={formButton}>
        Login
      </Button>
    );
    return email && (lastEmail !== email || tenants.length == 0) ? emailButtonDeploy() : null;
  };

  const deployButton = () =>
    lastEmail !== email && !!email
      ? null
      : tenants.map(tenant => {
          const handleDeploy = () =>
            window.location.replace(`https://${tenant}.${window.location.host.replace('auth.', '')}/#/new/${props.projectId}`);
          return (
            <Button type="primary" onClick={handleDeploy} style={formButton}>
              Deploy in account: {capitalLetter(tenant)}
            </Button>
          );
        });

  return (
    <form onSubmit={handleFormSubmit}>
      <Space direction="vertical">
        <Text strong> Email </Text>
        <Input placeholder="Enter email here" onChange={handleInputChange(setEmail)} value={email} />
        {loginButton()}
        {deployButton()}
      </Space>
    </form>
  );
};

export default function DeployPublicProjectPage() {
  const params: any = useParams();
  const projectUUid = params?.projectUUID || '';
  const [projectData, fError, fLoader] = useApiQuery(() => projectService.getPublicProjectByUUID(projectUUid), [projectUUid]);
  const project: iProject = projectData;
  const needToFindTenant = [`www`, `api`, `auth`, `sso`].includes(window.location.host.split('.')[0]);

  if (projectData?.error) {
    return (
      <>
        <ImageA />
        <SignupLink />
        <AuthenticationForm>
          <Title level={3}> Error </Title>
          <Text> {projectData?.error} </Text>
        </AuthenticationForm>
        <TextFind />
      </>
    );
  }

  const projectDetails = () => {
    const details = [
      { label: 'title', value: project.title },
      { label: 'description', value: project.description },
      { label: 'logo', value: project.logo },
      { label: 'tags', value: project.tags },
      { label: 'hardcodedNameSpace', value: project.hardcodedNameSpace },
      { label: 'oneDeploymentPerCluster', value: project.oneDeploymentPerCluster },
      { label: 'gitBranch', value: project.gitBranch },
    ];
    return details.map(({ label, value }) => <li key={label}> {`${label} ${value}`} </li>);
  };

  const projectTags = () => (
    <AuthenticationForm>
      {project && (
        <>
          {projectDetails()}
          <Title level={2}> You can deploy this project in your kubernetes cluster </Title>
          {needToFindTenant ? <FindTenantFormForProjectDeploy projectId={project.id} /> : <BigDeployButton projectId={project.id} />}
        </>
      )}
    </AuthenticationForm>
  );

  return (
    <>
      <ImageD />
      <SignupLink />
      {projectTags()}
      <TextFind />
    </>
  );
}
