import { projectService } from 'services/project.service';
import { Alert, Button, Form, Input, Space, Switch, Typography, Modal, notification } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { buttonBorder, buttonSize, orangeColor, spaceWidth } from 'utils/styles';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { useState } from 'react';
import { CodeLine } from 'components/SharedComponents/CodeLine/CodeLine';
import { iProjectModel } from 'shared/deployment';

interface iShareProjectProps {
  project: iProjectModel;
  onSave?: () => void;
}

const { Text } = Typography;
const { Item } = Form;

const ReadmeSharedContent = (props: { uuid: string }) => {
  const alertData = (
    <Alert
      showIcon
      type="warning"
      message="Warning"
      description={
        <Text>
          Making a template public will allow other users to see and use it.&nbsp;
          <Text strong> Please be sure if you publish you template to the public github repository. </Text>
        </Text>
      }
      icon={<WarningFilled style={orangeColor} />}
    />
  );

  return (
    <Space direction="vertical" style={spaceWidth}>
      {alertData}
      <Text />
      <Text>
        Please add this code to your <Text strong> README.md </Text> file to allow other users to deploy your template with one click:
      </Text>
      <CodeLine>
        [![deploy with unifie](https://api.unifie.cloud/deploy-btn/button_unifie-one-click-deploy.gif)](
        {`https://unifie.cloud/kubernetes/${props.uuid}`})
      </CodeLine>
    </Space>
  );
};

export const ShareProject = (props: iShareProjectProps) => {
  const project = props.project;
  const [form] = Form.useForm();
  const [isPublic, setIsPublic] = useState(project.isPublic);

  const init = {
    title: project.title,
    description: project?.description,
    tags: (project.tags || []).join(`, `),
    isPublic: project.isPublic,
  };

  const handleSubmit = async data => {
    const config = form.getFieldsValue();
    if (config.tags) {
      config.tags = config.tags.split(`,`).map((tag: string) =>
        tag
          .trim()
          .replace(/ /gim, `-`)
          .replace(/[^A-z0-9]/gim, ``),
      );
    }

    if (config.isPublic && project.gitInitialized === false) {
      Modal.error({
        title: `Error`,
        content: (
          <>
            Configure git repository first. <br />
            <Text type="secondary">
              You need to configure git repository first to make the project public. Please be sure if you publish you template to the public github
              repository.
            </Text>
          </>
        ),
      });
      return;
    }

    const res = await projectService.setProjectSettings(project.id, config);
    if (res.error) {
      notification.error({ message: `Error - ${res.error}` });
    } else {
      notification.success({ message: `Ready` });
    }
    if (props.onSave) {
      props.onSave();
    }
  };

  return (
    <>
      <Alert
        message={<>You and your team can use same template for several applications. </>}
        type="info"
        showIcon
        style={{ marginBottom: '20px' }}
      />

      <Form form={form} layout="vertical" validateTrigger="onSubmit" initialValues={init} onFinish={async values => handleSubmit(values)}>
        <Form.Item
          label="Template name"
          name="title"
          rules={[
            {
              required: true,
              message: 'Please input template name!',
            },
          ]}
        >
          <Input placeholder="Enter name of the template here" />
        </Form.Item>
        <Form.Item
          label="Template Description"
          name="description"
          rules={[
            {
              required: true,
              message: 'Please input template description!',
            },
          ]}
        >
          <Input.TextArea placeholder="Enter template description here" />
        </Form.Item>

        <Form.Item label="Template tags" name="tags">
          <Input placeholder="Comma separated tags" />
        </Form.Item>

        <Item label={`Template visibility`} help="Mark it if you want to share your template with whole world" name="isPublic">
          <Switch checked={isPublic} checkedChildren="Project is public" unCheckedChildren="Project is private" onChange={e => setIsPublic(e)} />
        </Item>
        {isPublic && <ReadmeSharedContent uuid={project.uuid} />}

        <BottomButtons>
          <Button type="primary" htmlType="submit" style={buttonBorder}>
            Save
          </Button>
          {props.onSave && (
            <Button type="primary" onClick={props.onSave} style={buttonBorder}>
              Cancel
            </Button>
          )}
        </BottomButtons>
      </Form>
    </>
  );
};

export const ShareProjectModalBtn = (props: iShareProjectProps) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button style={buttonSize} type="default" onClick={() => setVisible(true)}>
        Private settings
      </Button>
      <Modal width={`80vw`} title="Share project" open={visible} onCancel={() => setVisible(false)} footer={null}>
        <ShareProject project={props.project} onSave={() => setVisible(false)} />
      </Modal>
    </>
  );
};
