import * as yaml from 'js-yaml';
import gql from 'graphql-tag';
import CountUp from 'react-countup';
import Editor from '@monaco-editor/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { iDeployment } from 'shared/deployment';
import { useObjectsForApply } from 'services/apply.service';
import { uptimeToString } from 'utils/common';
import { useAuthedMutationWithNotification } from 'utils/qlAuth';
import { Alert, Button, Col, Collapse, Result, Row, Skeleton, Space, Statistic, Tag, Typography } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { bottomMargin, bottomPadding, buttonWidth, floatingRight, spaceWidth, topMargin } from 'utils/styles';
import { FullScreenButton, FullScreenEditor } from 'components/SharedComponents/FullScreenView/FullScreenView';

export interface iDeploymentSyncVarsProps {
  deployment: iDeployment;
}

const { Text } = Typography;
const { Panel } = Collapse;

const ReApply = (props: { deploymentId: number; kind: String; name: String }) => {
  const [inSync, setInSync] = useState(false);
  const [doReApply, resyncRes] = useAuthedMutationWithNotification(gql`
    mutation ApplyController_reApply($deploymentId: Int!, $objName: String!, $objKind: String!) {
      ApplyController_reApply(deploymentId: $deploymentId, objName: $objName, objKind: $objKind)
    }
  `);

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setInSync(true);
    try {
      await doReApply({ variables: { deploymentId: props.deploymentId, objName: props.name, objKind: props.kind } });
    } catch (error) {
    } finally {
      setInSync(false);
    }
  };

  return (
    <Tag title="Re apply object to cluster" icon={<SyncOutlined spin={inSync} />} onClick={handleClick}>
      Re-sync
    </Tag>
  );
};

export const DeploymentNamespace = (props: iDeploymentSyncVarsProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const applyed = useObjectsForApply(props.deployment.id);

  if (applyed.loading) return <Skeleton active />;

  if (!props.deployment.NamespaceModel) return <Result style={bottomMargin} status="404" title="Not ready" subTitle={`Namespace not creted yet`} />;

  const formatter = (value: number) => <CountUp end={Math.floor(value)} separator="," />;
  const ns = props.deployment.NamespaceModel;

  const statusColorMap = {
    ok: 'green',
    removed: 'purple',
    'remove-error': 'red',
    'api-error': 'orange',
    'remove-sent-error': 'orange',
    'apply-error': 'red',
    'sent-error': 'red',
  };

  const applyedAnswer = () => (
    <Collapse size="small">
      {applyed.answer.map((item, index) => {
        const statusColor = statusColorMap[item.status] || 'magenta';

        const panelHeader = (
          <>
            <Tag color="default"> {item.kind} </Tag>
            {item.name}
          </>
        );

        const panelExtra = (
          <Space style={floatingRight}>
            {item.lastSyncTime > 0 ? (
              <Tag color="default" title={`Last sync time ${new Date(item.lastSyncTime)}`}>
                Last sync {uptimeToString(item.lastSyncDelay * 1000)}
              </Tag>
            ) : null}
            <Tag color={statusColor}> {item.status} </Tag>
            <ReApply deploymentId={props.deployment.id} kind={item.kind} name={item.name} />
          </Space>
        );

        return (
          <Panel header={panelHeader} key={index} extra={panelExtra}>
            {item.errordata ? <Alert message={JSON.stringify(item.errordata)} type="warning" /> : null}
            <Space direction="vertical" style={spaceWidth}>
              <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
              <Text />
              <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
                <Editor
                  height={isFullscreen ? '100vh' : '300px'}
                  width={`100%`}
                  language={`yaml`}
                  value={yaml.dump(item.spec) || ''}
                  theme={`GitHub`}
                  defaultValue=""
                />
              </FullScreenEditor>
            </Space>
          </Panel>
        );
      })}
    </Collapse>
  );

  const alertAction = (
    <Link to={`/app/${props.deployment.id}/settings/delete`}>
      <Button type="primary" style={buttonWidth}>
        Enable
      </Button>
    </Link>
  );

  return (
    <Space direction="vertical" style={spaceWidth}>
      {props.deployment.isEnabled === false ? (
        <Alert showIcon type="warning" message="Application is Disabled. You can enable it in Settings." key={'warning'} action={alertAction} />
      ) : null}
      <Row gutter={24}>
        <Col span={3}>
          <Statistic title="Namespace" value={ns.name} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Last sync"
            value={ns.lastSyncDelay < 1707802250 ? ns.lastSyncDelay : 'No sync yet'}
            formatter={ns.lastSyncDelay < 1707802250 ? formatter : undefined}
          />
        </Col>
      </Row>
      <Text /> <Text /> <Text />
      <Row gutter={24}>
        <Col span={24} style={{ ...topMargin, ...bottomPadding }}>
          <Alert
            key={'info'}
            showIcon
            type="info"
            message={`Here you can see Kubernetes entities applied with api-agent service. It is read only.`}
            style={bottomMargin}
          />
          {applyed?.answer && applyed?.answer?.length > 0 ? applyedAnswer() : null}
        </Col>
      </Row>
    </Space>
  );
};
