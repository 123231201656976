export const stats_aws_ce = [
  {
    id: 0,
    lg: 24,
    span: `24`,
    name: 'Price for 30 days',
    vizState: {
      chartType: 'area',
      pivotConfig: { x: ['CostHistory.timeStart.day'], y: ['CostHistory.uiName'], fillMissingDates: true, joinDateRange: false },
      query: {
        limit: 500,
        dimensions: ['CostHistory.uiName'],
        timeDimensions: [{ dimension: 'CostHistory.timeStart', granularity: 'day', dateRange: 'Last 30 days' }],
        measures: ['CostHistory.sum'],
        order: { 'CostHistory.sum': 'desc' },
        filters: [
          { member: 'CostHistory.timeInterval', operator: 'equals', values: ['day'] },
          { member: 'CostHistory.costProvider', operator: 'equals', values: ['aws'] },
          { member: 'CostHistory.deploymentId', operator: 'gt', values: [`0`] },
        ],
      },
    },
  },
  {
    id: 0,
    lg: 24,
    span: `24`,
    name: 'Price for 30 days',
    vizState: {
      chartType: 'table',
      query: {
        limit: 1000,
        order: { 'CostHistory.sum': 'desc' },
        dimensions: ['Deployments.name', 'CostHistory.uiName'],
        timeDimensions: [{ dimension: 'CostHistory.timeStart', dateRange: 'Last 30 days' }],
        measures: ['CostHistory.sum'],
        filters: [
          { member: 'CostHistory.timeInterval', operator: 'equals', values: ['day'] },
          { member: 'CostHistory.costProvider', operator: 'equals', values: ['aws'] },
          { member: 'CostHistory.deploymentId', operator: 'gt', values: [`0`] },
        ],
      },
    },
  },
];
