import { combineReducers } from 'redux';

export interface IPageStore {
  title: string;
  widgets: any;
}

export interface IMainStore {
  actions: {
    setTitleAction: (title: string | any) => void;
    setWidgetsAction: (widgets: any) => void;
  };
  store: IMainStoreProps;
}

export interface IMainStoreProps {
  page: IPageStore;
}

const initialPageState: IPageStore = {
  title: '',
  widgets: null,
};

function pageReducer(state = initialPageState, action) {
  switch (action.type) {
    case 'SET_title':
      return { ...state, title: action.payload };
    case 'SET_Widgets':
      return { ...state, widgets: action.payload };
    default:
      return state;
  }
}

export const rootReducer = combineReducers({
  page: pageReducer,
});

export const mapStateToProps = (store: IMainStoreProps) => {
  return {
    store,
  };
};
