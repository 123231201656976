import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Space, Alert, Typography } from 'antd';
import { HelpDrawerTpl } from 'components/Help/HelpDrawerTpl';

const { Title, Text } = Typography;
export const SecretsTabHelAlert = () => {
  const alertMessage = `This tab allows you configure integration with container storage services and create image pull secrets. It needed if you use Private Containers Registry.`;

  const alertAction = () => {
    const alertActionButton = <QuestionCircleTwoTone twoToneColor="#00A58E" />;

    const links = [
      {
        href: 'https://kubernetes.io/docs/tasks/configure-pod-container/pull-image-private-registry/',
        text: 'Pull an Image from a Private Registry',
      },
      {
        href: 'https://docs.aws.amazon.com/AmazonECR/latest/userguide/docker-pull-ecr-image.html',
        text: 'Pulling an image from an Amazon ECR private repository',
      },
    ];

    return (
      <HelpDrawerTpl title="Help" btnText={alertActionButton}>
        <Space direction="vertical">
          <Text> This page allows you to create image pull secret from credentials. It needed if you use Private Containers Registry </Text>
          <Text /> <Text />
          <Title level={4}> Troubleshooting: </Title>
          <Text>
            Be sure to add your secret to <Text strong> "imagePullSecrets" </Text> fields in Pod configuration.
          </Text>
          <Text /> <Text />
          <Title level={4}> Additional information: </Title>
          <ul>
            {links.map((link, index) => (
              <li key={index}>
                <Text strong>
                  <a href={link.href} target="_blank">
                    {link.text}
                  </a>
                </Text>
              </li>
            ))}
          </ul>
        </Space>
      </HelpDrawerTpl>
    );
  };

  return <Alert showIcon type="info" message={alertMessage} action={alertAction()} />;
};
