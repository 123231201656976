import * as yaml from 'js-yaml';
import _ from 'lodash';

export interface iCodeObjectBasic {
  obj: any;
  toRemove?: boolean;
}

/**
 * Function to change any property in the current yaml object
 * @param key - path in the object
 * @param newObj - object to set or undefined if we want to unset
 * @param action - push, splice or undefined (set or unset)
 */
export function patchYamlCodeArray<T>(
  yamlCodeObjectArray: (T & iCodeObjectBasic)[],
  condition: (item: T, index: number) => boolean,
  key: string,
  newObj: any,
  action?: PatchYamlAction,
): string {
  const newYamlCodeObjectArray = yamlCodeObjectArray.map((item: T & iCodeObjectBasic, index): T & iCodeObjectBasic => {
    if (!condition(item, index)) {
      return item;
    }
    return patchYamlCode<T & iCodeObjectBasic>(item, key, newObj, action);
  });

  return newYamlCodeObjectArray.map(yamlObj => yaml.dump(yamlObj.obj)).join('\n---\n');
}

export type PatchYamlAction = 'push' | 'splice' | 'set' | 'unset' | undefined;

/**
 * Function to change any property in the yaml object
 * @param key - path in the object
 * @param newObj - object to set or undefined if we want to unset
 * @param action - push, splice or undefined (set or unset)
 */
export function patchYamlCode<T>(yamlCodeObject: T & iCodeObjectBasic, key: string, newObj: any, action?: PatchYamlAction): T {
  const keyPath = key.replace('.', '');
  console.log('Changed: ', keyPath, newObj);

  if (action === 'push') {
    let arr = _.get(yamlCodeObject.obj, keyPath);
    if (!Array.isArray(arr)) {
      arr = [];
    }
    arr.push(newObj);
    _.set(yamlCodeObject.obj, keyPath, arr);
  } else if (action === 'splice') {
    let arr = _.get(yamlCodeObject.obj, keyPath);
    if (!Array.isArray(arr)) {
      arr = [];
    }
    arr.splice(newObj, 1);
    _.set(yamlCodeObject.obj, keyPath, arr);
  } else {
    // set | unset | undefined
    if (newObj === undefined || newObj === 'undefined') {
      _.unset(yamlCodeObject.obj, keyPath);
    } else {
      _.set(yamlCodeObject.obj, keyPath, newObj);
    }
  }
  return yamlCodeObject;
}
