import { Tooltip, Typography } from 'antd';
import { HelpDrawerTpl } from 'components/Help/HelpDrawerTpl';
import { blackText } from 'utils/styles';

const { Title, Text } = Typography;

export const ProjectGitConfigurationHelp = (props: { btnText?: any }) => {
  return (
    <HelpDrawerTpl title={`Help`} btnText={props.btnText}>
      <Text style={blackText}>You can use Github and Gitlab Repositories. </Text>
      <Text style={blackText}>Unifie will need write and read access. </Text>
      <Title level={4}> Github </Title>
      <Text style={blackText}>
        Personal access tokens are an alternative to using passwords for authentication to GitHub when using the GitHub API or the command line.
      </Text>
      <ul>
        <li>
          <a
            href={'https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens'}
            target="_blank"
          >
            Managing your personal access tokens
          </a>
        </li>
        <li>
          <Tooltip color="#115EA3" title="Click here to login through your GitHub Account">
            <Text strong style={blackText}>
              <a href={'https://github.com/settings/tokens?type=beta'} target="_blank">
                Personal access tokens in your GitHub Account
              </a>
            </Text>
          </Tooltip>
        </li>
      </ul>
      <Title level={4}> GitLab </Title>

      <Text style={blackText}>
        Project access tokens are similar to passwords, except you can limit access to resources, select a limited role, and provide an expiry date.
      </Text>
      <ul>
        <li>
          <Tooltip color="#115EA3" title="Click here to login through your GitLab Account">
            <Text strong style={blackText}>
              <a href={'https://docs.gitlab.com/ee/user/project/settings/project_access_tokens.html#create-a-project-access-token'} target="_blank">
                &nbsp; GitLab &nbsp;
              </a>
            </Text>
          </Tooltip>
        </li>
      </ul>
    </HelpDrawerTpl>
  );
};
