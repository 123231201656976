import { Col, Row, Space } from 'antd';

interface iBottomButtonsProps {
  extra?: any;
  children?: any;
}

export const BottomButtons = (props: iBottomButtonsProps) => {
  let extraBtn = props.extra ? props.extra : null;

  return (
    <div className="bottomButtons">
      {/* <Row wrap={false}>
      <Col flex="auto"> */}
      <Space direction="horizontal">
        {props.children} {extraBtn}
      </Space>
      {/* </Col>
      {props.extra && (
        <Col flex="none">
          <Space direction="horizontal">{props.extra}</Space>
        </Col>
      )}
    </Row> */}
    </div>
  );
};
