import ChartDashboard from 'components/ChartRenderer/ChartDashboard';
import DashboardItem from 'components/ChartRenderer/DashboardItem';
import { ChartRenderer } from 'components/ChartRenderer/ChartRenderer';
import { iDeployment } from 'shared/deployment';
import { Col } from 'antd';

export const OpenCostPriceForDeployment = (props: { deployment: iDeployment }) => {
  const deployment = props.deployment;

  const stats = [
    {
      id: 0,
      lg: 24,
      span: `24`,
      height: '500px',
      name: 'Price for week',
      vizState: {
        query: {
          limit: 50,
          order: { 'CostHistory.sum': 'desc' },
          filters: [
            { member: 'CostHistory.timeInterval', operator: 'equals', values: ['hour'] },
            { member: 'CostHistory.type', operator: 'equals', values: ['pod'] },
            { member: 'CostHistory.costProvider', operator: 'equals', values: ['opencost'] },
            { member: 'CostHistory.deploymentId', operator: 'equals', values: [`${deployment.id}`] },
          ],
          dimensions: ['CostHistory.uiName'],
          timeDimensions: [{ dimension: 'CostHistory.timeStart', granularity: 'day', dateRange: 'Last 7 days' }],
          measures: ['CostHistory.sum'],
        },
        chartType: 'area',
      },
    },
    {
      id: 0,
      lg: 12,
      span: `24`,
      height: '100%',
      name: 'Price per pods for 7 days',
      vizState: {
        query: {
          limit: 50,
          order: { 'CostHistory.sum': 'desc' },
          filters: [
            { member: 'CostHistory.timeInterval', operator: 'equals', values: ['hour'] },
            { member: 'CostHistory.type', operator: 'equals', values: ['pod'] },
            { member: 'CostHistory.costProvider', operator: 'equals', values: ['opencost'] },
            { member: 'CostHistory.deploymentId', operator: 'equals', values: [`${deployment.id}`] },
          ],
          dimensions: ['CostHistory.uiName'],
          timeDimensions: [{ dimension: 'CostHistory.timeStart', dateRange: 'Last 7 days' }],
          measures: ['CostHistory.sum'],
        },
        chartType: 'table',
      },
    },
    {
      id: 0,
      lg: 12,
      span: `24`,
      height: '500px',
      name: 'Price per pods for 7 day ',
      vizState: {
        query: {
          limit: 50,
          order: {
            'CostHistory.sum': 'desc',
          },
          filters: [
            { member: 'CostHistory.timeInterval', operator: 'equals', values: ['hour'] },
            { member: 'CostHistory.type', operator: 'equals', values: ['pod'] },
            { member: 'CostHistory.costProvider', operator: 'equals', values: ['opencost'] },
            { member: 'CostHistory.deploymentId', operator: 'equals', values: [`${deployment.id}`] },
          ],
          dimensions: ['CostHistory.uiName'],
          timeDimensions: [{ dimension: 'CostHistory.timeStart', dateRange: 'Last 7 days' }],
          measures: ['CostHistory.sum'],
        },
        chartType: 'pie',
      },
    },
  ];

  return (
    <ChartDashboard>
      {stats.map((stat, index) => {
        return (
          <Col span={stat.span || 24} lg={stat.lg || 24} key={stat.id || index} style={{ marginBottom: '24px', height: stat.height }}>
            <DashboardItem title={stat.name}>
              <ChartRenderer vizState={stat.vizState} />
            </DashboardItem>
          </Col>
        );
      })}
    </ChartDashboard>
  );
};
