import gql from 'graphql-tag';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { useAuthedQuery } from 'utils/qlAuth';
import { ProjectGitBtn_Clone, ProjectGitBtn_Pull, ProjectGitBtn_push } from './GitButtons';
import { Alert, Skeleton, Space, Tag, Timeline, Typography } from 'antd';
import { ReactElement, ReactNode, ReactPortal } from 'react';
import { iProjectModel } from 'shared/deployment';

const { Text } = Typography;

interface iGitLogsProps {
  project: iProjectModel;
  noPush?: boolean;
}

interface iEventToUI {
  date: string | number | Date;
  author_name: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
  author_email: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
  refs: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
  message: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
  body: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
  commit: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
}

export const GitLogs = (props: iGitLogsProps) => {
  const { loading, error, data } = useAuthedQuery(
    gql`
      query ProjectGitController_gitLog($projectId: Int!) {
        ProjectGitController_gitLog(projectId: $projectId) {
          error
          log
        }
        ProjectGitController_gitStatus(projectId: $projectId) {
          error
          status
        }
      }
    `,
    { variables: { projectId: Number(props.project.id) } },
  );

  if (loading) return <Skeleton active={true} loading={true} />;

  const log = data?.ProjectGitController_gitLog?.log;
  const gitError = data?.ProjectGitController_gitLog?.error;
  const gitStatus = data?.ProjectGitController_gitStatus?.status;

  if (gitError) return <Alert message={String(gitError)} type="error" />;

  const eventToUI = (event: iEventToUI, color: string = 'gray') => {
    const colorChildren = () => {
      const tagsData = [
        { color: 'blue', title: 'author', text: event.author_name },
        { color: 'cyan', title: 'email', text: event.author_email },
      ];
      return (
        <Space direction="vertical">
          {String(new Date(event.date))}
          <div>
            {tagsData.map(({ color, title, text }, index) => (
              <Tag key={index} color={color} title={title}>
                {text}
              </Tag>
            ))}
          </div>
          <div>
            {event.refs} {event.message} {event.body}
          </div>
          {event.commit}
        </Space>
      );
    };
    return { color: color, children: colorChildren() };
  };

  const logs = (log?.all || []).map(i => eventToUI(i, 'gray'));
  if (log?.latest) logs.push(eventToUI(log?.latest, `green`));

  if (gitStatus?.files?.length > 0) {
    const filesList =
      gitStatus?.files.length < 10 ? (
        <>
          <Text strong> You have {gitStatus?.files.length} uncommitted files: </Text>
          <ul>
            {gitStatus?.files.map((file: { path: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal }) => (
              <li> {file.path} </li>
            ))}
          </ul>
        </>
      ) : (
        <Text> You have {gitStatus?.files.length} uncommitted files. </Text>
      );

    const colourChildren = (
      <Space direction="vertical">
        <Text> You have modified files in the project. Please push your changes to the repository. </Text>
        {filesList}
        <ProjectGitBtn_push dstBranch={props.project.gitBranch} projectId={Number(props.project.id)} />
      </Space>
    );

    logs.push({ color: `blue`, children: colourChildren });
  }

  return (
    <>
      <Timeline mode={'left'} items={logs} reverse={true} />
      {props.noPush !== true && (
        <BottomButtons extra={<ProjectGitBtn_Clone projectId={Number(props.project.id)} />}>
          <ProjectGitBtn_push dstBranch={props.project.gitBranch} projectId={Number(props.project.id)} />
          <ProjectGitBtn_Pull projectId={Number(props.project.id)} />
        </BottomButtons>
      )}
    </>
  );
};
