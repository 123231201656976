import { Divider, Select, Typography } from 'antd';
import { HelpDrawerTpl } from 'components/Help/HelpDrawerTpl';
import { PROJECT_NAME } from 'interface/common';
import { spaceWidth } from 'utils/styles';

const { Title, Text } = Typography;

export function MetacontrollerOptions(props: { defaultValue: boolean; onChange: any }) {
  return (
    <>
      <Text strong>
        Do you have metacontroller service in your Cluster?
        <HelpDrawerTpl>
          <Title level={5}> {PROJECT_NAME} </Title>
          <ul>
            <li> It uses metacontroller to manage the cluster. </li>
            <li> If you do not have metacontroller, {PROJECT_NAME} will install it </li>
            <li> If you have metacontroller, {PROJECT_NAME} will use your version instead of install own version. </li>
          </ul>
          <Divider />
          <Title level={5}> Additional information: </Title>
          <Text strong>
            <a href="https://metacontroller.github.io/metacontroller/intro.html" target="_blank">
              What is a Metacontroller ?
            </a>
          </Text>
          <ul>
            <li> Metacontroller is an add-on for Kubernetes that makes it easy to write and deploy custom controllers. </li>
            <li> Although the open-source project was started at Google, the add-on works the same in any Kubernetes Cluster. </li>
          </ul>
        </HelpDrawerTpl>
      </Text>
      <Select placeholder="Select Your Choice Here" onChange={props.onChange} defaultValue={props.defaultValue} style={spaceWidth}>
        <Select.Option value={true} key={'yes'}>
          Yes, I have Metacontroller
        </Select.Option>

        <Select.Option value={false} key={'no'}>
          No, I do not have Metacontroller
        </Select.Option>
      </Select>
    </>
  );
}
