import { Link } from 'react-router-dom';
import { UserPermissions } from 'shared/UserRolesPermission';
import { TenantPermissions, canIusePermission } from 'shared/permissions';
import { useTenantPermissions } from 'utils/common';
import { Button, Popover, Result } from 'antd';

interface iPermissionCheckProps {
  permission: TenantPermissions;
  allow: any;
  no: any;
}

/**
 * Check if tenant has permission
 * @param props
 * @returns
 */

export const PermissionCheck = (props: iPermissionCheckProps) => {
  const tenantPermissions = useTenantPermissions();
  if (!tenantPermissions) return null;
  const popoverContent = (
    <Popover content="This function not available on your tariff plan" title="Not available">
      {props?.no}
    </Popover>
  );
  return canIusePermission(props.permission, tenantPermissions) ? props?.allow : popoverContent;
};

export const UserNoPermissions = (props: { permission: UserPermissions }) => {
  const resultExtra = (
    <Link to={'/'}>
      <Button type="primary"> Back Home </Button>
    </Link>
  );
  return <Result status="403" title="403" subTitle={`Sorry, you do not have permission ${props.permission}.`} extra={resultExtra} />;
};
