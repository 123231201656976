export const stats_availability_hour_time = [
  {
    id: 0,
    span: `24`,
    name: 'Availability for deployments (1 hour)',
    vizState: {
      chartType: 'line',
      query: {
        measures: ['Monitoring.okRate'],
        timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'minute', dateRange: 'Last 60 minute' }],
        dimensions: ['Monitoring.deploymentId', 'Deployments.name'],
        filters: [],
      },
    },
  },
  {
    id: 0,
    span: `24`,
    name: 'Availability for services (1 hour)',
    vizState: {
      chartType: 'line',
      query: {
        measures: ['Monitoring.okRate'],
        timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'minute', dateRange: 'Last 60 minute' }],
        dimensions: ['Monitoring.deploymentId', 'Deployments.name', 'Monitoring.serviceName'],
        filters: [],
      },
    },
  },
];

export const stats_availability_day_time = [
  {
    id: 0,
    span: `24`,
    name: 'Availability for deployments (2 day)',
    vizState: {
      chartType: 'line',
      query: {
        measures: ['Monitoring.okRate'],
        timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'hour', dateRange: 'Last 48 hours' }],
        dimensions: ['Monitoring.deploymentId', 'Deployments.name'],
        filters: [],
      },
    },
  },
  {
    id: 0,
    span: `24`,
    name: 'Availability for services (2 day)',
    vizState: {
      chartType: 'line',
      query: {
        measures: ['Monitoring.okRate'],
        timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'hour', dateRange: 'Last 48 hours' }],
        dimensions: ['Monitoring.deploymentId', 'Deployments.name', 'Monitoring.serviceName'],
        filters: [],
      },
    },
  },
];

export const stats_availability_long_time = [
  {
    id: 0,
    span: `24`,
    name: 'Availability for deployments (7 day)',
    vizState: {
      chartType: 'line',
      query: {
        measures: ['Monitoring.okRate'],
        timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'hour', dateRange: 'Last 168 hours' }],
        dimensions: ['Monitoring.deploymentId', 'Deployments.name'],
        filters: [],
      },
    },
  },
  {
    id: 0,
    span: `24`,
    name: 'Availability for services (7 day)',
    vizState: {
      chartType: 'line',
      query: {
        measures: ['Monitoring.okRate'],
        timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'hour', dateRange: 'Last 168 hours' }],
        dimensions: ['Monitoring.deploymentId', 'Deployments.name', 'Monitoring.serviceName'],
        filters: [],
      },
    },
  },
  {
    id: 0,
    span: `24`,
    name: 'Availability for deployments (30 days)',
    vizState: {
      chartType: 'line',
      query: {
        measures: ['Monitoring.okRate'],
        timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'day', dateRange: 'Last 30 days' }],
        dimensions: ['Monitoring.deploymentId', 'Deployments.name'],
        filters: [],
      },
    },
  },
  {
    id: 0,
    span: `24`,
    name: 'Availability for services (30 days)',
    vizState: {
      chartType: 'line',
      query: {
        measures: ['Monitoring.okRate'],
        timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'day', dateRange: 'Last 30 days' }],
        dimensions: ['Monitoring.deploymentId', 'Deployments.name', 'Monitoring.serviceName'],
        filters: [],
      },
    },
  },
];
