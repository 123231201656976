import { apiQuery } from '../utils/common';

class MonitoringService {
  getMonitoring(id: number) {
    return apiQuery('GET', `/api/monitoring/${id}`);
  }
}

const monitoringService = new MonitoringService();
export { monitoringService, MonitoringService };
