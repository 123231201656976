import { Alert } from 'antd';
import { iJobModel } from 'shared/deployment';

export const JobErrorMessage = (props: { job: iJobModel }) => {
  const job = props.job;

  const ErrorMessage = (
    <pre>
      {String(job?.lastError || '')
        .split('\\n')
        .map(line => {
          return (
            <>
              {line}
              <br />
            </>
          );
        })}
    </pre>
  );

  if (job?.status === 'canceled') {
    return (
      <Alert message="Job canceled" description="Cluster creation has been canceled, please run new job instead of canceled" type="error" showIcon />
    );
  }
  if (job?.status === 'render-error') {
    return (
      <Alert
        message="Job creation error"
        description={
          <>
            Can not create job, please check the configuration and try again. <br />
            {ErrorMessage}
          </>
        }
        type="error"
        showIcon
      />
    );
  }
  if (job?.status === 'finished-error') {
    return <Alert message="Job finished with error" description={ErrorMessage} type="error" showIcon />;
  }

  if (job?.status === 'error') {
    return <Alert message="Job error" description={ErrorMessage} type="error" showIcon />;
  }

  return null;
};
