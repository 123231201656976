export function isUnifie(): boolean {
  return window.location.host.indexOf('unifie.') !== -1;
}

export function isDevLocalhost(): boolean {
  return window.location.host === 'localhost:3000';
}

export const isInAdvancedMode = (type: string, id: number): boolean => {
  return window.localStorage.getItem(`AdvancedMode-${type}-${id}`) === 'true';
};

export const setAdvancedMode = (type: string, id: number, status: boolean): void => {
  window.localStorage.setItem(`AdvancedMode-${type}-${id}`, status ? 'true' : 'false');
};
