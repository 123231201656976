import { useHistory, useParams } from 'react-router-dom';
import { Skeleton } from 'antd';

/**
 * Intermediary component for Github API redirect. Do not contain any UI. Only loader and redirect to final page.
 * @returns Skeleton
 */
export const GithubApiUI = () => {
  let url: any = useParams();
  const history = useHistory();
  const { params, accessToken } = url;
  const paramsArr = String(params).split('-');
  window.sessionStorage.setItem('githubAccessToken', accessToken);
  window.sessionStorage.setItem('githubAccessToken_created', String(new Date().getTime()));

  if (paramsArr[0] === `gitconfig`) {
    // Only loader and redirect to final page.
    history.push(`/project/${Number(paramsArr[1])}/settings/git`);
    return <Skeleton active={true} loading={true} />;
  }
  if (paramsArr[0] === `gitAutoCreate`) {
    // Only loader and redirect to final page.
    history.push(`/app/${Number(paramsArr[1])}/configuration/services`);
    return <Skeleton active={true} loading={true} />;
  }
  // Somthing went wrong. Redirect to home page.
  debugger;
  console.error('GithubApiUI: Something went wrong. Redirect to home page.');
  history.push('/');
  return <Skeleton active={true} loading={true} />;
};
