import { useState } from 'react';
import { AutoComplete } from 'antd';

export interface iClusterCreaionStep2Props {
  cloudProvider: string;
  value: string;
}

export const ClusterRegionNames = ({ cloudProvider, value }: iClusterCreaionStep2Props) => {
  const [filterDropdown, setFilterDropdown] = useState([]);

  const awsRegionNames = [
    ['ec2.ap-south-2.amazonaws.com', 'ap-south-2', 'not-opted-in'],
    ['ec2.ap-south-1.amazonaws.com', 'ap-south-1', 'opt-in-not-required'],
    ['ec2.eu-south-1.amazonaws.com', 'eu-south-1', 'not-opted-in'],
    ['ec2.eu-south-2.amazonaws.com', 'eu-south-2', 'not-opted-in'],
    ['ec2.me-central-1.amazonaws.com', 'me-central-1', 'not-opted-in'],
    ['ec2.il-central-1.amazonaws.com', 'il-central-1', 'not-opted-in'],
    ['ec2.ca-central-1.amazonaws.com', 'ca-central-1', 'opt-in-not-required'],
    ['ec2.eu-central-1.amazonaws.com', 'eu-central-1', 'opt-in-not-required'],
    ['ec2.eu-central-2.amazonaws.com', 'eu-central-2', 'not-opted-in'],
    ['ec2.us-west-1.amazonaws.com', 'us-west-1', 'opt-in-not-required'],
    ['ec2.us-west-2.amazonaws.com', 'us-west-2', 'opt-in-not-required'],
    ['ec2.af-south-1.amazonaws.com', 'af-south-1', 'not-opted-in'],
    ['ec2.eu-north-1.amazonaws.com', 'eu-north-1', 'opt-in-not-required'],
    ['ec2.eu-west-3.amazonaws.com', 'eu-west-3', 'opt-in-not-required'],
    ['ec2.eu-west-2.amazonaws.com', 'eu-west-2', 'opt-in-not-required'],
    ['ec2.eu-west-1.amazonaws.com', 'eu-west-1', 'opt-in-not-required'],
    ['ec2.ap-northeast-3.amazonaws.com', 'ap-northeast-3', 'opt-in-not-required'],
    ['ec2.ap-northeast-2.amazonaws.com', 'ap-northeast-2', 'opt-in-not-required'],
    ['ec2.me-south-1.amazonaws.com', 'me-south-1', 'not-opted-in'],
    ['ec2.ap-northeast-1.amazonaws.com', 'ap-northeast-1', 'opt-in-not-required'],
    ['ec2.sa-east-1.amazonaws.com', 'sa-east-1', 'opt-in-not-required'],
    ['ec2.ap-east-1.amazonaws.com', 'ap-east-1', 'not-opted-in'],
    ['ec2.ca-west-1.amazonaws.com', 'ca-west-1', 'not-opted-in'],
    ['ec2.ap-southeast-1.amazonaws.com', 'ap-southeast-1', 'opt-in-not-required'],
    ['ec2.ap-southeast-2.amazonaws.com', 'ap-southeast-2', 'opt-in-not-required'],
    ['ec2.ap-southeast-3.amazonaws.com', 'ap-southeast-3', 'not-opted-in'],
    ['ec2.ap-southeast-4.amazonaws.com', 'ap-southeast-4', 'not-opted-in'],
    ['ec2.us-east-1.amazonaws.com', 'us-east-1', 'opt-in-not-required'],
    ['ec2.us-east-2.amazonaws.com', 'us-east-2', 'opt-in-not-required'],
  ];

  const azureRegionNames = [
    ['East US', 'eastus'],
    ['East US 2', 'eastus2'],
    ['South Central US', 'southcentralus'],
    ['West US 2', 'westus2'],
    ['West US 3', 'westus3'],
    ['Australia East', 'australiaeast'],
    ['Southeast Asia', 'southeastasia'],
    ['Sweden Central', 'swedencentral'],
    ['UK South', 'uksouth'],
    ['West Europe', 'westeurope'],
    ['Central US', 'centralus'],
    ['South Africa North', 'southafricanorth'],
    ['Central India', 'centralindia'],
    ['East Asia', 'eastasia'],
    ['Japan East', 'japaneast'],
    ['Korea Central', 'koreacentral'],
    ['Canada Central', 'canadacentral'],
    ['France Central', 'francecentral'],
    ['Germany West Central', 'germanywestcentral'],
    ['Norway East', 'norwayeast'],
    ['Poland Central', 'polandcentral'],
    ['Switzerland North', 'switzerlandnorth'],
    ['UAE North', 'uaenorth'],
    ['Brazil South', 'brazilsouth'],
    ['Qatar Central', 'qatarcentral'],
    ['North Central US', 'northcentralus'],
    ['West US', 'westus'],
    ['Asia', 'asia'],
    ['Asia Pacific', 'asiapacific'],
    ['Australia', 'australia'],
    ['Brazil', 'brazil'],
    ['Canada', 'canada'],
    ['Europe', 'europe'],
    ['France', 'france'],
    ['Germany', 'germany'],
    ['Global', 'global'],
    ['India', 'india'],
    ['Japan', 'japan'],
    ['Korea', 'korea'],
    ['Norway', 'norway'],
    ['Singapore', 'singapore'],
    ['South Africa', 'southafrica'],
    ['Switzerland', 'switzerland'],
    ['United Arab Emirates', 'uae'],
    ['United Kingdom', 'uk'],
    ['United States', 'unitedstates'],
    ['Brazil US', 'brazilus'],
    ['Jio India West', 'jioindiawest'],
    ['West Central US', 'westcentralus'],
    ['South Africa West', 'southafricawest'],
    ['Australia Central', 'australiacentral'],
    ['Australia Central 2', 'australiacentral2'],
    ['Australia Southeast', 'australiasoutheast'],
    ['Japan West', 'japanwest'],
    ['Jio India Central', 'jioindiacentral'],
    ['Korea South', 'koreasouth'],
    ['South India', 'southindia'],
    ['West India', 'westindia'],
    ['Canada East', 'canadaeast'],
    ['France South', 'francesouth'],
    ['Germany North', 'germanynorth'],
    ['Norway West', 'norwaywest'],
    ['Switzerland West', 'switzerlandwest'],
    ['UK West', 'ukwest'],
    ['UAE Central', 'uaecentral'],
    ['Brazil Southeast', 'brazilsoutheast'],
  ];

  const gcpRegionNames = [
    ['Oregon', 'us-west1'],
    ['Los Angeles', 'us-west2'],
    ['Salt Lake City', 'us-west3'],
    ['Las Vegas', 'us-west4'],
    ['Iowa', 'us-central1'],
    ['South Carolina', 'us-east1'],
    ['N. Virginia', 'us-east4'],
    ['Columbus', 'us-east5'],
    ['Dallas', 'us-south1'],
    ['Montréal', 'northamerica-northeast1'],
    ['Toronto', 'northamerica-northeast2'],
    ['Santiago', 'southamerica-west1'],
    ['São Paulo', 'southamerica-east1'],
    ['London', 'europe-west2'],
    ['Belgium', 'europe-west1'],
    ['Netherlands', 'europe-west4'],
    ['Zurich', 'europe-west6'],
    ['Frankfurt', 'europe-west3'],
    ['Finland', 'europe-north1'],
    ['Warsaw', 'europe-central2'],
    ['Milan', 'europe-west8'],
    ['Madrid', 'europe-southwest1'],
    ['Paris', 'europe-west9'],
    ['Turin', 'europe-west12'],
    ['Berlin', 'europe-west10'],
    ['Mumbai', 'asia-south1'],
    ['Delhi', 'asia-south2'],
    ['Singapore', 'asia-southeast1'],
    ['Jakarta', 'asia-southeast2'],
    ['Hong Kong', 'asia-east2'],
    ['Taiwan', 'asia-east1'],
    ['Tokyo', 'asia-northeast1'],
    ['Osaka', 'asia-northeast2'],
    ['Sydney', 'australia-southeast1'],
    ['Melbourne', 'australia-southeast2'],
    ['Seoul', 'asia-northeast3'],
    ['Tel Aviv', 'me-west1'],
    ['Doha', 'me-central1'],
    ['Dammam', 'me-central2'],
    ['Johannesburg', 'africa-south1'],
  ];

  // Add more regions (cloudProvider(s)) if needed here both above and below

  const awsRegion = awsRegionNames.map(([Endpoint, RegionName, OptInStatus]) => ({ Endpoint, RegionName, OptInStatus }));
  const azureRegion = azureRegionNames.map(([Endpoint, RegionName]) => ({ Endpoint, RegionName }));
  const gcpRegion = gcpRegionNames.map(([Endpoint, RegionName]) => ({ Endpoint, RegionName }));

  const handleDropdownSearch = (regions, value) => {
    const selectedRegions = regions.filter(region => region.RegionName.toLowerCase().includes(value.toLowerCase()));
    const sortedRegions = selectedRegions.map(region => region.RegionName).sort((primary, secondary) => primary.localeCompare(secondary));
    const filterDropdown = sortedRegions.map(name => ({ value: name }));
    setFilterDropdown(filterDropdown);
  };

  const cloudRegionsData = { AWS: awsRegion, AZURE: azureRegion, GCP: gcpRegion };
  const awsOption = cloudProvider === 'aws';
  const azureOption = cloudProvider === 'azure';
  const gcpOption = cloudProvider === 'gcp';
  const awsData = cloudRegionsData.AWS;
  const azureData = cloudRegionsData.AZURE;
  const gcpData = cloudRegionsData.GCP;

  const handleRegionSearch = value => {
    // Add more regions (cloudProvider(s)) if needed here below
    const cloudRegions = awsOption ? awsData : azureOption ? azureData : gcpOption ? gcpData : [];
    handleDropdownSearch(cloudRegions, value);
  };

  const clusterRegionName = () => (
    <AutoComplete defaultValue={value} placeholder="Enter Region Name Here" options={filterDropdown} onSearch={handleRegionSearch} />
  );

  return clusterRegionName();
};
