// @ts-ignore
import Form from 'antd/lib/form';
import { FieldTemplateProps, getTemplate, getUiOptions, GenericObjectType } from '@rjsf/utils';

const { Item } = Form;
const VERTICAL_LABEL_COL = { span: 24 };
const VERTICAL_WRAPPER_COL = { span: 24 };

/** The `FieldTemplate` component is the template used by `SchemaField` to render any field. It renders the field content, (label, description, children, errors and help) inside of a `WrapIfAdditional` component.
 * @param props - The `FieldTemplateProps` for this component
 */

export function CustomFieldTemplate(props: FieldTemplateProps) {
  const {
    children,
    classNames,
    style,
    description,
    disabled,
    displayLabel,
    errors,
    formContext,
    help,
    hidden,
    id,
    label,
    onDropPropertyClick,
    onKeyChange,
    rawErrors,
    rawDescription,
    rawHelp,
    readonly,
    registry,
    required,
    schema,
    uiSchema,
  } = props;
  const { colon, labelCol = VERTICAL_LABEL_COL, wrapperCol = VERTICAL_WRAPPER_COL, wrapperStyle, descriptionLocation = 'below' } = formContext;

  const uiOptions = getUiOptions(uiSchema);
  const WrapIfAdditionalTemplate = getTemplate('WrapIfAdditionalTemplate', registry, uiOptions);

  if (hidden) return <div className="field-hidden">{children}</div>;

  // to check if there is rawDescription(string) before using description(ReactNode) to prevent showing a blank description area
  const descriptionNode = rawDescription ? description : undefined;
  const descriptionProps: GenericObjectType = {};
  switch (descriptionLocation) {
    case 'tooltip':
      descriptionProps.tooltip = descriptionNode;
      break;
    case 'below':
    default:
      descriptionProps.extra = descriptionNode;
      break;
  }

  return (
    <WrapIfAdditionalTemplate
      classNames={classNames}
      style={style}
      disabled={disabled}
      id={id}
      label={label}
      onDropPropertyClick={onDropPropertyClick}
      onKeyChange={onKeyChange}
      readonly={readonly}
      required={required}
      schema={schema}
      uiSchema={uiSchema}
      registry={registry}
    >
      <Item
        colon={colon}
        hasFeedback={schema.type !== 'array' && schema.type !== 'object'}
        help={(!!rawHelp && help) || (rawErrors?.length ? errors : undefined)}
        htmlFor={id}
        label={displayLabel && label}
        labelCol={labelCol}
        required={required}
        validateStatus={rawErrors?.length ? 'error' : undefined}
        wrapperCol={wrapperCol}
        {...descriptionProps}
        style={wrapperStyle}
      >
        {children}
      </Item>
    </WrapIfAdditionalTemplate>
  );
}

export function x_CustomFieldTemplate(props: FieldTemplateProps) {
  const { id, classNames, style, label, help, required, description, errors, children } = props;
  return (
    <div className={classNames} style={style}>
      <label htmlFor={id}>
        {label}
        {required ? '*' : null}
      </label>
      {description}
      {children}
      {errors}
      {help}
    </div>
  );
}

// return (
//   <Row gutter={48}>
//     <Col flex="250px">
//       <label htmlFor={id}>
//         {label}
//         {required ? '*' : null}
//         {errors}
//         {help}
//       </label>
//     </Col>
//     <Col flex="auto">
//       <div className="_CustomFieldTemplate_children">
//         {children}
//         {props.description ? (
//           <div>
//             <Text italic style={{ color: '#00A58E' }}>{props.description}</Text>
//           </div>
//         ) : null}
//       </div>
//     </Col>
//   </Row>
// );
