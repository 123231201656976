import { Link } from 'react-router-dom';
import { monitoringService } from '../../../services/monitoring.servise';
import { useApiQuery } from '../../../utils/common';
import { Card, Flex, Skeleton, Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { bottomMargin, redColor } from 'utils/styles';

export const MonitoringStatus = ({ id }: any) => {
  const [monitoring, monitoringError, Monitoringloading] = useApiQuery(() => monitoringService.getMonitoring(id));

  if (Monitoringloading) return <Skeleton active={true} loading={true} />;

  if (monitoringError || !monitoring) return null;
  // debugger;
  const isLink = monitoring.filter((e: any) => e.data !== 'skip').length;
  if (isLink === 0) return null;

  return (
    <Card title="Status" style={bottomMargin}>
      <Flex wrap="wrap" gap="small">
        {monitoring.map((e: any, index: any) => {
          if (e.data === 'skip') return null;
          const checkError = e.data.status <= 400 ? 'Running' : 'Error';
          const tagIcon = checkError === 'Running' ? <CheckCircleOutlined style={{ color: '#00FF00' }} /> : <CloseCircleOutlined style={redColor} />;
          return (
            <Tag className="d-flex pl-0" key={index} icon={tagIcon}>
              <span className="pt-1 overviewRightSideTitle" style={{ minWidth: '90px' }}>
                {e.name}
              </span>
            </Tag>
          );
        })}
      </Flex>
      <br />
      <Link to={`/app/${id}/monitoring`}> Monitoring of service </Link>
    </Card>
  );
};
