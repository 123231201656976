import { PROJECT_NAME } from 'interface/common';
import { Image, Result, Space, Typography } from 'antd';

const { Title, Text } = Typography;

export const Landing = () => {
  const resultIcon = (
    <Space direction="horizontal">
      <Image src="branding/loginProjectImage.svg" alt={`${PROJECT_NAME} Logo`} preview={false} />
      <Text strong> {PROJECT_NAME} </Text>
    </Space>
  );

  const resultTitle = <Title level={4}> One Portal to Manage Them All </Title>;

  const resultSubTitle = (
    <Text>
      Embark on a digital odyssey with {PROJECT_NAME}, harnessing the power to seamlessly navigate clouds, clusters, and deployments alike. With just
      one click, our portal becomes your gateway to unified control, ensuring your IT infrastructure is as harmonious as a well orchestrated symphony.
    </Text>
  );

  return <Result icon={resultIcon} title={resultTitle} subTitle={resultSubTitle} />;
};
