import { iPostProcessingRule } from 'shared/project.interface';
import { VarNameComponent } from './VarNameComponent';
import { getK8sDocs } from './k8sDocs';
import { Alert, Button, Flex, Input, Select, Space, Typography } from 'antd';
import { spaceWidth } from 'utils/styles';
import { TipTop } from 'components/SharedComponents/Tooltip/Tooltip';

export interface iRulesListComponentProps {
  rulesList: iPostProcessingRule[];
  syncVars: any;
  editRule: (rule: iPostProcessingRule) => void;
  removeRule: (rule: iPostProcessingRule) => void;
}

const { Title, Text } = Typography;

export const RulesListComponent = (props: iRulesListComponentProps) => {
  if (!props.rulesList) {
    return null;
  }

  return (
    <>
      {(props.rulesList || []).map((rule: iPostProcessingRule, index) => {
        let type: any = 'info';
        let description = null;

        const docs = getK8sDocs(rule.mainKind, rule.path);

        const codedvalue = [
          { value: 'hardcoded', label: 'Value Hardcoded Here' },
          { value: 'variable', label: 'Value from Variable' },
        ];

        const dataType = [
          { value: 'string', label: 'String' },
          { value: 'object', label: 'Object' },
          { value: 'number', label: 'Number' },
          { value: 'boolean', label: 'Boolean' },
          { value: 'array', label: 'Array' },
        ];

        const addEditDescription = (
          <Space direction="vertical" style={spaceWidth}>
            {docs ? docs.description : null}
            <Text />
            {rule.action === 'add' ? (
              <Input
                style={spaceWidth}
                type="text"
                placeholder="Key name"
                defaultValue={rule.addPropertyName}
                onChange={e => props.editRule({ ...rule, addPropertyName: e.target.value })}
              />
            ) : null}
            <Text />
            <Select
              style={spaceWidth}
              defaultValue={rule.varValueSrcType || 'hardcoded'}
              options={codedvalue}
              onChange={e => props.editRule({ ...rule, varValueSrcType: e })}
            />
            <Text />
            <Select
              style={spaceWidth}
              defaultValue={rule.varValueType || 'string'}
              options={dataType}
              onChange={e => props.editRule({ ...rule, varValueType: e })}
            />
            <Text />
            <VarNameComponent
              onChange={value => props.editRule({ ...rule, value: value })}
              syncVars={props.syncVars}
              value={rule.value}
              AutoCompleteStyles={spaceWidth}
            />
          </Space>
        );

        if (rule.action === 'removeFullRecord') type = 'error';
        if (rule.action === 'remove') type = 'warning';
        if (rule.action === 'edit' || rule.action === 'add') (type = 'info'), (description = addEditDescription);

        const alertMessage = <Title level={5}> {`${rule.action} - ${rule.mainKind} ${rule.mainMetadataName} (${rule.path})`} </Title>;

        const alertAction = (
          <TipTop tip="Click here to Undo it">
            <Button data-qa="RulesListComponent-UNDO" size="small" type="primary" onClick={() => props.removeRule(rule)}>
              Undo
            </Button>
          </TipTop>
        );

        return (
          <Flex justify={'flex-end'} style={spaceWidth}>
            <Space direction="vertical" style={spaceWidth}>
              <Alert
                style={spaceWidth}
                data-qa="RulesListComponent"
                message={alertMessage}
                key={index}
                type={type}
                description={description}
                closable={false}
                action={alertAction}
              />
              <Text />
            </Space>
          </Flex>
        );
        // { JSON.stringify(rule) }
      })}
    </>
  );
};
