import { EditOutlined } from '@ant-design/icons';
import { Button, Space, Tag } from 'antd';
import { iDeployment } from 'shared/deployment';
import { JsonFormModalUI } from '../JsonFormModalUI';
import { iProjectDeploymentSyncVarsContext } from 'components/Projects/ProjectDeploymentContext';
import { buttonSize } from 'utils/styles';

interface IImagePullSecrets {
  deployment: iDeployment;
  imagePullSecrets: { name: string; namespace: string }[];
  syncVarsContext: iProjectDeploymentSyncVarsContext;
  onChange: (secrets: { name: string; namespace: string }[]) => void;
}

/**
 * UI for managing image pull secrets in deployment
 * @param props
 * @returns
 */
export const ImagePullSecrets = (props: IImagePullSecrets) => {
  const handleOnClick = () => {
    JsonFormModalUI({
      title: `Image pull secrets`,
      formData: props.imagePullSecrets || [],
      formConfigs: { schema: { type: 'array', items: { type: 'string' } }, ui: { 'ui:options': { orderable: false } } },
      SyncVarsContext: props.syncVarsContext,
      onChange: (v: any) => props?.onChange(v),
    });
  };

  const tags = props.imagePullSecrets?.length;

  return (
    <Space direction="horizontal">
      <Button icon={<EditOutlined />} onClick={handleOnClick} style={buttonSize}>
        Manage secrets
      </Button>
      <Tag color={tags ? 'green' : 'red'}> {tags ? 'Configured' : 'Not configured'} </Tag>
    </Space>
  );
};
