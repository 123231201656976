import { Space } from 'antd';
import { CommonFormUI, CommonUI_DeploymentVarWidget, CommonUI_text } from './CommonFormUI';
import { spaceWidth } from 'utils/styles';

export interface iScaledObjectMainOptionsUIProps {
  yamlObj: any;
  onChange: (key: string, newObj: any, action?: string) => void;
}

export const ScaledObjectMainOptionsUI = (props: iScaledObjectMainOptionsUIProps) => {
  const form = [
    {
      name: 'Name',
      children: <CommonUI_text value={props.yamlObj?.metadata?.name} onChange={props.onChange} path={`.metadata`} propertyName={`name`} />,
    },
    {
      name: 'scaleTargetRef kind',
      children: (
        <CommonUI_text
          value={props.yamlObj?.spec?.scaleTargetRef?.kind}
          onChange={props.onChange}
          path={`.spec.scaleTargetRef`}
          propertyName={`kind`}
        />
      ),
    },
    {
      name: 'Min replica count',
      children: (
        <CommonUI_DeploymentVarWidget
          dstType={'number'}
          value={props.yamlObj?.spec?.minReplicaCount}
          onChange={props.onChange}
          path={`.spec`}
          propertyName={`minReplicaCount`}
          validator={val => {
            if (val < 1) {
              return 'Must be more then 0';
            }
            return null;
          }}
        />
      ),
    },
    {
      name: 'Target CPU usage',
      children: (
        <CommonUI_DeploymentVarWidget
          dstType={'number'}
          value={(props.yamlObj?.spec?.triggers || [])[0]?.metadata?.value}
          onChange={props.onChange}
          path={`.spec.triggers[0].metadata`}
          propertyName={`value`}
          validator={val => {
            if (val < 1) {
              return 'Must be more then 0';
            }
            return null;
          }}
        />
      ),
    },
    {
      name: 'Max replica count',
      children: (
        <CommonUI_DeploymentVarWidget
          dstType={'number'}
          value={props.yamlObj?.spec?.maxReplicaCount}
          onChange={props.onChange}
          path={`.spec`}
          propertyName={`maxReplicaCount`}
          validator={val => {
            if (val < 1) {
              return 'Must be more then 0';
            }
            return null;
          }}
        />
      ),
    },
    {
      name: 'Fallback replicas count',
      children: (
        <CommonUI_DeploymentVarWidget
          dstType={'number'}
          value={props.yamlObj?.spec?.fallback?.replicas}
          onChange={props.onChange}
          path={`.spec.fallback`}
          propertyName={`replicas`}
          validator={val => {
            if (val < 1) {
              return 'Must be more then 0';
            }
            return null;
          }}
        />
      ),
    },
    {
      name: 'Failure threshold',
      children: (
        <CommonUI_DeploymentVarWidget
          dstType={'number'}
          value={props.yamlObj?.spec?.fallback?.failureThreshold}
          onChange={props.onChange}
          path={`.spec.fallback`}
          propertyName={`failureThreshold`}
          validator={val => {
            if (val < 1) {
              return 'Must be more then 0';
            }
            return null;
          }}
        />
      ),
    },
  ];

  return (
    <Space direction="vertical" style={spaceWidth}>
      <CommonFormUI form={form} />
    </Space>
  );
};
