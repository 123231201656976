import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { authService } from 'services/auth.service';
import { usersService } from 'services/users.service';
import { useApiQuery } from 'utils/common';
import { useAuthedMutation } from 'utils/qlAuth';
import { bannerStyle } from 'utils/styles';
import { CodeLine } from 'components/SharedComponents/CodeLine/CodeLine';
import { Alert, Button, Modal, Skeleton, Space, Switch, Typography } from 'antd';
import { KeyOutlined } from '@ant-design/icons';

const { Text } = Typography;

export const UserApiKeys = (props: { userId: number }) => {
  const [saveAllowApi] = useAuthedMutation(gql`
    mutation UsersController_allowApi($userId: Int!, $allowApi: Boolean!) {
      UsersController_allowApi(userId: $userId, allowApi: $allowApi)
    }
  `);
  const [userData, err, load] = useApiQuery(() => usersService.getUser(props.userId), [props.userId]);
  const [allowApi, setAllowApi] = useState(userData?.allowApi);
  useEffect(() => {
    setAllowApi(userData?.allowApi);
  }, [userData]);

  const switchData = () => {
    const handleOnChange = v => {
      setAllowApi(v);
      saveAllowApi({ variables: { userId: Number(props.userId), allowApi: v } });
    };
    return (
      <Space size="large" direction="horizontal">
        <Text> Allow API </Text>
        <Switch onChange={handleOnChange} checkedChildren="On" unCheckedChildren="OFF" defaultChecked={allowApi} />
      </Space>
    );
  };

  const apiButton = () => {
    const handleOnClick = () => {
      Modal.confirm({
        title: 'Confirm?',
        content: `Do you want to get new api key and remove the old one?`,
        okText: 'Ok',
        cancelText: 'Cancel',
        onOk: async () => {
          const query = await authService.apolloQuery({
            query: gql`
              mutation UsersController_getApiKey($userId: Int!) {
                UsersController_getApiKey(userId: $userId)
              }
            `,
            variables: { userId: Number(props.userId) },
          });
          const resPromis = query.data?.UsersController_getApiKey;
          Modal.success({
            title: 'Api key',
            icon: <KeyOutlined />,
            closable: true,
            content: (
              <>
                <Text> Getting API Key </Text>
                <CodeLine>{resPromis}</CodeLine>
                You can pass this key to `x-auth-token`
              </>
            ),
          });
        },
      });
    };
    return (
      <Button disabled={!allowApi} onClick={handleOnClick}>
        Get API Key
      </Button>
    );
  };

  if (load) return <Skeleton active={true} loading={true} />;
  return <Alert message={switchData()} action={apiButton()} style={bannerStyle} />;
};
