import ChartDashboard from 'components/ChartRenderer/ChartDashboard';
import DashboardItem from 'components/ChartRenderer/DashboardItem';
import { ChartRenderer } from 'components/ChartRenderer/ChartRenderer';
import { iDeployment } from 'shared/deployment';
import { Col } from 'antd';

export const AWSPriceForDeployment = (props: { deployment: iDeployment }) => {
  // podsOnNodes
  const deployment = props.deployment;

  const stats = [
    {
      id: 0,
      lg: 24,
      span: `24`,
      name: 'Price for 30 days',
      vizState: {
        query: {
          limit: 500,
          filters: [
            { member: 'CostHistory.timeInterval', operator: 'equals', values: ['day'] },
            { member: 'CostHistory.costProvider', operator: 'equals', values: ['aws'] },
            { member: 'CostHistory.deploymentId', operator: 'equals', values: [`${deployment.id}`] },
          ],
          dimensions: ['CostHistory.uiName'],
          timeDimensions: [{ dimension: 'CostHistory.timeStart', granularity: 'day', dateRange: 'Last 30 days' }],
          measures: ['CostHistory.sum'],
          order: { 'CostHistory.sum': 'desc' },
        },
        chartType: 'area',
        pivotConfig: {
          x: ['CostHistory.timeStart.day'],
          y: ['CostHistory.uiName'],
          fillMissingDates: true,
          joinDateRange: false,
        },
      },
    },
    {
      id: 0,
      lg: 24,
      span: `24`,
      name: 'Price for 30 days',
      vizState: {
        query: {
          limit: 1000,
          order: { 'CostHistory.sum': 'desc' },
          filters: [
            { member: 'CostHistory.timeInterval', operator: 'equals', values: ['day'] },
            { member: 'CostHistory.costProvider', operator: 'equals', values: ['aws'] },
            { member: 'CostHistory.deploymentId', operator: 'equals', values: [`${deployment.id}`] },
          ],
          dimensions: ['CostHistory.uiName'],
          timeDimensions: [{ dimension: 'CostHistory.timeStart', dateRange: 'Last 30 days' }],
          measures: ['CostHistory.sum'],
        },
        chartType: 'table',
      },
    },
  ];

  return (
    <ChartDashboard>
      {stats.map((stat, index) => {
        return (
          <Col span={stat.span || 24} lg={stat.lg || 24} key={stat.id || index} style={{ marginBottom: '24px' }}>
            <DashboardItem title={stat.name}>
              <ChartRenderer vizState={stat.vizState} />
            </DashboardItem>
          </Col>
        );
      })}
    </ChartDashboard>
  );
};
