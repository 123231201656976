import { gql } from '@apollo/client';

// Define mutation
export const setContainerStorageConfig = gql`
  mutation setContainerStorageConfig($projectId: Int!, $config: InputContainerStorageConfig!) {
    setContainerStorageConfig(projectId: $projectId, config: $config)
  }
`;

export const ProjectApiKeys_newApiKey = gql`
  mutation ProjectApiKeys_newApiKey($projectId: Int!, $key: String!) {
    ProjectApiKeys_newApiKey(projectId: $projectId, key: $key)
  }
`;

export const getHistoryChangesCluster = gql`
  query getHistoryChangesCluster($clusterId: Int!) {
    getHistoryChangesCluster(clusterId: $clusterId) {
      value
    }
  }
`;

export const ProjectController_removeService = gql`
  mutation ProjectController_removeService($projectId: Int!, $serviceName: String!) {
    ProjectController_removeService(projectId: $projectId, serviceName: $serviceName)
  }
`;

export const ProjectFilesController_removeFile = gql`
  mutation ProjectFilesController_removeFile($projectId: Int!, $fileName: String!) {
    ProjectFilesController_removeFile(projectId: $projectId, fileName: $fileName)
  }
`;

export const UsersController_setThemeName = gql`
  mutation UsersController_setThemeName($themeName: String!) {
    UsersController_setThemeName(themeName: $themeName)
  }
`;

export const UsersController_setThemeConfig = gql`
  mutation UsersController_setThemeConfig($config: InputUserThemeConfig!) {
    UsersController_setThemeConfig(config: $config)
  }
`;

export const UsersController_passTour = gql`
  mutation UsersController_passTour($tourName: String!) {
    UsersController_passTour(tourName: $tourName)
  }
`;
