import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { usersService } from 'services/users.service';
import { CustomWidgets } from 'form-components';

export interface iNewUserPageProps {}

const NewUserSchemaFormUI = {};
const NewUserSchemaForm: any = {
  required: ['name', 'email'],
  title: '',
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name' },
    email: { type: 'string', title: 'Email' },
    password: { type: 'string', title: 'Password' },
  },
};

export const NewUserPage = (props: iNewUserPageProps) => {
  const history = useHistory();

  const handleSubmitAddNewUser = async (e: any) => {
    const data = e.formData;
    const res = await usersService.newUser(data);
    const notificationMessage = res.error ? `Error - ${res.error}` : 'New User Created!';
    notification[res.error ? 'error' : 'success']({ message: notificationMessage });
    debugger;
    history.push(res.error ? '/error' : `/users/${res.data.id}`);
  };

  return (
    <Form
      widgets={CustomWidgets}
      formData={{}}
      schema={NewUserSchemaForm}
      uiSchema={NewUserSchemaFormUI}
      validator={validator}
      onSubmit={handleSubmitAddNewUser}
      onError={e => console.log('Error from submit: ', e)}
    />
  );
};
