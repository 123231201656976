/**
 * Array of tunnel errors codes.
 */
export const TunnelErrorsArray = ['TUNNEL_TARGET_CON_RESET', 'TUNNEL_NOT_CONNECTED', 'TUNNEL_TARGET_CON_FAILED'];

export function isTunnelError(error: string) {
  if (!error) {
    return false;
  }
  if (TunnelErrorsArray.includes(error)) {
    return true;
  }

  if (error.indexOf('TUNNEL_') === 0) {
    return true;
  }
  return false;
}
