// import { isMainDomain } from '../utils/common.js';
import ChangePassword from '../components/Authentication/changePassword';
import ForgotPassword from '../components/Authentication/ForgotPassword';
import Login, { FindTenantForm } from '../components/Authentication/Login';
import RequestAccess from '../components/Authentication/RequestAccess';
import Dashboard from '../components/Dashboard/Dashboard';
import AdminLayout from '../layout/AdminLayout';
import { Projects } from 'components/Projects/Projects';
import { isLandingDomain, isMainDomain } from 'utils/common';
import { TenantCharts } from 'components/Tenant/TenantCharts';
import { ClustersList } from 'components/Clusters/ClustersList';
import { UsersList } from 'components/Users/UsersList';
import { TenantConfiguration } from 'components/Tenant/TenantConfiguration';
import { IntegrationsList } from 'components/Integrations/integrations';
import { UserPage } from 'components/Users/UserPage';
import { AuditLogs } from 'components/AuditLogs/AuditLogs';
import { NewUserPage } from 'components/Users/NewUserPage';
import { NewClusterPage } from 'components/Clusters/new-cluster/NewClusterPage';
import OverView from 'components/Deployments/OverView/OverView';
import DeploymentSettingPage from 'components/Deployments/Setting/DeploymentSettingPage';
import SettingDetails from 'components/Deployments/Setting/SettingDetails';
import DeploymentKeyDropDown from 'components/Deployments/New/DeploymentKeyDropDown';
import { ClusterMainPage } from 'components/Clusters/ClusterMainPage';
import DeploymentStoragePage from 'components/Deployments/Setting/DeploymentStorage';
import DeploymentMonitoringPage from 'components/Deployments/Setting/DeploymentMonitoringPage';
import { NewProjectForm } from 'components/Projects/NewProjectForm';
import FindTenantPage from 'components/Authentication/FindTenantPage';
import ErrorPage from 'components/Errors/ErrorPage';
import ErrorPage_github_link_error from 'components/Errors/ErrorPage_github_link_error';
import { GithubApiUI } from 'components/Authentication/GithubApiUI';
import { ClusterCreaionStep2 } from 'components/Clusters/new-cluster/ClusterCreaionStep2';
import DeploymentSpecsPage from 'components/Deployments/Setting/DeploymentSpecsPage';
import { ClusterCreaionStep4 } from 'components/Clusters/new-cluster/ClusterCreaionStep4';
import { ClusterCreaionStep3 } from 'components/Clusters/new-cluster/ClusterCreaionStep3';
import { Landing } from 'landing/Landing';
import DeployPublicProjectPage from 'components/Authentication/DeployPublicProjectPage';
import { PaymentOk } from 'components/Payments/PaymentOk';
import { PaymentError } from 'components/Payments/PaymentError';
import { TariffsPage } from 'components/Payments/TariffsPage';
import { NewDeployment } from 'components/Deployments/New/NewDeployment';
import { Configuration } from 'components/Projects/v2-templates/configuration/Configuration';
import DeploymentStatusPage from 'components/Deployments/Setting/DeploymentStatusPage';
import { DeploymentWebsshPage } from 'components/Deployments/DeploymentWebssh/DeploymentWebssh';
import DeploymentActivityPage from 'components/Deployments/Setting/DeploymentActivityPage';
import { DeploymentLogsPage } from 'components/Deployments/Setting/DeploymentLogsPage';
import CreateUserPassword from '../components/Authentication/CreateUserPassword';
import { SelectDeployWay } from 'components/Deployments/New/SelectDeployWay';
import { ClusterCreaionV2 } from 'components/Clusters/new-cluster-v2/NewClusterPageV2';
import { NoApplication } from 'components/SharedComponents/NoPage/NoPages';
import { ApiAgentYamlInstallation } from 'components/Clusters/new-cluster-v2/ApiAgentYamlInstallation';
import { Tf_AwsEksClusterCreate } from 'components/Clusters/new-cluster-v2/Tf_AwsEksClusterCreate';
import { PreFillNewDeploymentSettings } from 'components/Deployments/New/PreFillNewDeploymentSettings';

let adminRoutes = [];

const isAuth_layout = { isAuth: true, layout: AdminLayout };

if (!isMainDomain()) {
  // if window.location.host have only one dot symbol then this main site like nanoheal.site
  // if window.location.host has 2 dot symbols then this organization site like tesla.nanoheal.site
  adminRoutes = [
    {
      ...isAuth_layout,
      path: '/',
      component: Dashboard,
    },
    {
      ...isAuth_layout,
      path: '/new-intro',
      component: NewDeployment,
    },
    {
      ...isAuth_layout,
      path: '/new-application',
      component: SelectDeployWay,
    },
    {
      ...isAuth_layout,
      path: '/new-application/:way',
      component: PreFillNewDeploymentSettings,
    },
    {
      ...isAuth_layout,
      path: '/new',
      component: DeploymentKeyDropDown,
    },
    {
      ...isAuth_layout,
      path: '/new/:projectId',
      component: DeploymentKeyDropDown,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/setting',
      component: SettingDetails,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/setting/:tabName',
      component: SettingDetails,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/storage/:tabName',
      component: DeploymentStoragePage,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/specs/:tabName',
      component: DeploymentSpecsPage,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/status/:tabName',
      component: DeploymentStatusPage,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/specs',
      component: DeploymentSpecsPage,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/logs/:tabName',
      component: DeploymentLogsPage,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/activity',
      component: DeploymentActivityPage,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/logs',
      component: DeploymentLogsPage,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/logs/:tabName/:podName',
      component: DeploymentLogsPage,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/settings/:tabName',
      component: DeploymentSettingPage,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/webssh',
      component: DeploymentWebsshPage,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/monitoring/:tabName',
      component: DeploymentMonitoringPage,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/monitoring',
      component: DeploymentMonitoringPage,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/overview',
      component: OverView,
    },
    {
      ...isAuth_layout,
      path: '/projects/new',
      component: NewProjectForm,
    },
    {
      ...isAuth_layout,
      path: '/projects',
      component: Projects,
    },
    {
      ...isAuth_layout,
      path: '/clusters',
      component: ClustersList,
    },
    {
      ...isAuth_layout,
      path: '/cluster/new',
      component: NewClusterPage,
    },
    {
      ...isAuth_layout,
      path: '/cluster/create/:provider',
      component: ClusterCreaionV2,
    },
    {
      ...isAuth_layout,
      path: '/cluster/install/:regionId/agent',
      component: ApiAgentYamlInstallation,
    },
    {
      ...isAuth_layout,
      path: '/cluster/install/:regionId/tf-aws',
      component: Tf_AwsEksClusterCreate,
    },
    {
      ...isAuth_layout,
      path: '/cluster/install/:regionId/integrations',
      component: ClusterCreaionStep4,
    },
    {
      ...isAuth_layout,
      path: '/first-step',
      component: NoApplication,
    },
    {
      ...isAuth_layout,
      path: '/cluster/new/:regionId',
      component: ClusterCreaionStep2,
    },
    {
      ...isAuth_layout,
      path: '/cluster/new/:regionId/basic',
      component: ClusterCreaionStep2,
    },
    {
      ...isAuth_layout,
      path: '/cluster/new/:regionId/step3',
      component: ClusterCreaionStep3,
    },
    {
      ...isAuth_layout,
      path: '/cluster/new/:regionId/integrations',
      component: ClusterCreaionStep4,
    },
    {
      ...isAuth_layout,
      path: '/clusters/:regionId/:tabName',
      component: ClusterMainPage,
    },
    {
      ...isAuth_layout,
      path: '/clusters/:regionId/:tabName/:subTabName',
      component: ClusterMainPage,
    },
    {
      ...isAuth_layout,
      path: '/clusters/:regionId',
      component: ClusterMainPage,
    },
    {
      ...isAuth_layout,
      path: '/users',
      component: UsersList,
    },
    {
      ...isAuth_layout,
      path: '/users/new',
      component: NewUserPage,
    },
    {
      ...isAuth_layout,
      path: '/users/:userId',
      component: UserPage,
    },
    {
      ...isAuth_layout,
      path: '/integrations',
      component: IntegrationsList,
    },
    {
      ...isAuth_layout,
      path: '/configuration',
      component: TenantConfiguration,
    },
    {
      ...isAuth_layout,
      path: '/charts/:tabName',
      component: TenantCharts,
    },
    {
      ...isAuth_layout,
      path: '/charts',
      component: TenantCharts,
    },
    {
      ...isAuth_layout,
      path: '/audit-logs',
      component: AuditLogs,
    },
    {
      ...isAuth_layout,
      path: '/app/:deploymentId',
      component: Configuration,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/overview',
      component: OverView,
    },
    {
      ...isAuth_layout,
      path: '/app/:id/webssh',
      component: DeploymentWebsshPage,
    },
    {
      ...isAuth_layout,
      path: '/app/:deploymentId/configuration',
      component: Configuration,
    },
    {
      ...isAuth_layout,
      path: '/app/:deploymentId/configuration/:tabName',
      component: Configuration,
    },
    {
      ...isAuth_layout,
      path: '/app/:deploymentId/configuration/:tabName/:serviceName',
      component: Configuration,
    },
    {
      ...isAuth_layout,
      path: '/app/:deploymentId/configuration/:tabName/:serviceName/:activeTabType',
      component: Configuration,
    },
    {
      ...isAuth_layout,
      path: '/github-api-ui/:params/:accessToken',
      component: GithubApiUI,
    },
    {
      ...isAuth_layout,
      path: '/payment/ok',
      component: PaymentOk,
    },
    {
      ...isAuth_layout,
      path: '/payment/error',
      component: PaymentError,
    },
    {
      ...isAuth_layout,
      path: '/tariffs',
      component: TariffsPage,
    },
  ];
} else adminRoutes = [{ path: '/', component: Login }];

export let publicRoutes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/login/r/:oldPath',
    component: Login,
  },
  {
    path: '/signup',
    component: RequestAccess,
  },
  {
    path: '/login/email/:email',
    component: Login,
  },
  {
    path: '/requestaccess',
    component: RequestAccess,
  },
  {
    path: '/find-tenant',
    component: FindTenantPage,
  },
  {
    path: '/deploy/:projectUUID',
    component: DeployPublicProjectPage,
  },
  {
    path: '/reset-password',
    component: ForgotPassword,
  },
  {
    path: '/changePassword',
    component: ChangePassword,
  },
  {
    path: '/error-page/:message',
    component: ErrorPage,
  },
  {
    path: '/github-link-error',
    component: ErrorPage_github_link_error,
  },
  {
    path: '/password-reset',
    component: FindTenantForm,
  },
  {
    path: '/password-create',
    component: CreateUserPassword,
  },
];

if (isLandingDomain()) {
  adminRoutes = [];
  publicRoutes = [{ path: '/', component: Landing }];
}

export const routeConfigAdminLayout = [...publicRoutes, ...adminRoutes];
