import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { UserNoPermissions } from 'components/Permissions/Permissions';
import { Skeleton, Space } from 'antd';
import { useGqlDeploymentsList } from 'services/deployment.service';
import { BigOptions, Center, InfoApplication } from 'components/SharedComponents/BigOptions/BigOptions';

export function SelectDeployWay() {
  const user = useUser();
  const gqlQuery = useGqlDeploymentsList();

  if (gqlQuery?.loading) {
    return <Skeleton active={true} loading={true} />;
  }

  const data = gqlQuery.data?.DeploymentsController_deployments || [];
  const hasDeployments = data?.length > 0;

  if (!userHasPermission(user, UserPermissions.DeploymentCreate)) {
    return <UserNoPermissions permission={UserPermissions.DeploymentCreate} />;
  }

  const copy = 'Copy existing application';
  const existing = 'Deploy from public template';
  const image = 'branding/newCluster.svg';

  const copyCard = hasDeployments
    ? {
        name: copy,
        tip: copy,
        img: image,
        path: '/new-application/from-own-template',
        secondary: 'Create a new application by copying an existing one',
      }
    : null;

  const existingCard = {
    name: existing,
    tip: existing,
    img: image,
    path: '/new-application/from-public-template',
    secondary: 'Choose from a list of public preconfigured templates',
  };

  const newCard = {
    name: `Deploy from scratch`,
    tip: `Deploy from scratch`,
    img: image,
    path: '/new-application/from-scratch',
    secondary: 'Create a new template and deploy your application',
    // customUI: <NewProjectForm openBtnUI={<BigOption name={scratch} tip={scratch} secondary="Create a new template and deploy your application" />} />,
  };

  return (
    <Center>
      <Space direction="vertical">
        <InfoApplication />

        <BigOptions options={[copyCard, existingCard, newCard].filter(v => !!v)} />
      </Space>
    </Center>
  );
}
