import { Steps } from 'antd';
import { LoadingOutlined, CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { iJobModel } from 'shared/deployment';

export const JobStatusUI = (props: { job: iJobModel }) => {
  const job = props.job;

  if (!job) {
    return null;
  }

  let InQueueIcon = <ClockCircleOutlined />;
  let ProcessingIcon = <LoadingOutlined />;
  let DoneIcon = <CheckCircleOutlined />;
  let current = 0;
  let status: any = 'wait'; // wait | process | finish | error

  if ([`finished`].includes(job?.status)) {
    current = 3;
    status = 'finish';
    ProcessingIcon = <CheckCircleOutlined />;
    InQueueIcon = <CheckCircleOutlined />;
  }

  if ([`pre-init`, `pre-run`].includes(job?.status)) {
    // UI if in progress
    current = 0;
    status = 'wait';
  }

  if ([`applyd`, `load-logs`].includes(job?.status)) {
    // UI if in progress
    current = 2;
    status = 'process';
    InQueueIcon = <CheckCircleOutlined />;
  }

  if ([`render-error`, `error`, `canceled`].includes(job?.status)) {
    // UI if errors
    current = 2;
    status = 'error';
    ProcessingIcon = <CloseCircleOutlined />;
    InQueueIcon = <CheckCircleOutlined />;
  }
  if ([`finished-error`].includes(job?.status)) {
    // UI if errors
    current = 3;
    status = 'error';
    DoneIcon = <CloseCircleOutlined />;
    ProcessingIcon = <CheckCircleOutlined />;
  }

  return (
    <>
      <Steps
        status={status}
        current={current}
        items={[
          {
            // If you see the task - than it already configured
            title: 'Configure',
            icon: <CheckCircleOutlined />,
          },
          {
            title: 'In queue',
            icon: InQueueIcon,
          },
          {
            title: 'Processing',
            icon: ProcessingIcon,
          },
          {
            title: 'Done',
            icon: DoneIcon,
          },
        ]}
      />
    </>
  );
};
