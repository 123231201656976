import { ReactElement, useState } from 'react';
import { Button, Form, Input, Space, Typography } from 'antd';
import { CopyServiceButton } from './CopyService';
import { iDeployment } from 'shared/deployment';
import { PlusOutlined } from '@ant-design/icons';
import { TipTopLeft } from 'components/SharedComponents/Tooltip/Tooltip';
import { NewServicePreFill_helm } from './NewServicePreFill/NewServicePreFill_helm';
import { NewServicePreFill_docker } from './NewServicePreFill/NewServicePreFill_docker';
import { NewServicePreFill_k8s } from './NewServicePreFill/NewServicePreFill_k8s';
import { isServiceNameValid } from 'shared/services';
import { dockerWidth } from 'utils/styles';

const { Text, Title } = Typography;
interface iNewService {
  deployment: iDeployment;
}

export const NewService = (props: iNewService): ReactElement => {
  const [serviceType, setServiceType] = useState(null);
  const [serviceName, setServiceName] = useState('');
  const [serviceNameErrors, setServiceNameErrors] = useState<string>('');

  const disableService = serviceNameErrors !== '' || serviceName === '';

  const serviceButtons = [
    {
      key: 'docker',
      label: 'Docker image',
      text: 'Service will be created from docker image, it is a good choice if you did not find ready made helm chart',
    },
    { key: 'helm', label: 'Helm chart', text: 'Use if you already have a Helm chart' },
    { key: 'k8s', label: 'Kubernetes yaml file', text: 'Kubernetes yaml file is a good choice for advanced users who want to have full control.' },
  ];

  return (
    <Space direction="vertical">
      <Title level={5}> Add a Service </Title>
      <Text />
      <Form.Item help={serviceNameErrors} label={<Text strong> Service name </Text>}>
        <Input
          style={{ width: '360px' }}
          status={disableService ? 'error' : null}
          placeholder="Enter service name here"
          value={serviceName}
          onChange={e => {
            const name = e.target.value;
            if (isServiceNameValid(name)) {
              setServiceNameErrors('');
            } else {
              if (name === '') {
                setServiceNameErrors(`Name should not be empty`);
              }
              if (name === 'new') {
                setServiceNameErrors(`Please use another service name`);
              }

              setServiceNameErrors('Do not use spaces');
            }
            setServiceName(name);
          }}
        />
      </Form.Item>

      <Text> Select Service Type to add or Use Prebuilt Template </Text>
      <Text />
      <Space direction="horizontal">
        {serviceButtons.map(({ key, label, text }) => (
          <TipTopLeft tip={text}>
            <Button
              disabled={disableService}
              key={key}
              type={serviceType === key ? 'primary' : 'default'}
              icon={<PlusOutlined />}
              onClick={() => setServiceType(key)}
            >
              {label}
            </Button>
          </TipTopLeft>
        ))}
        <CopyServiceButton disabled={disableService} project={props?.deployment?.ProjectModel} />
      </Space>
      <Text />
      <Text />
      {serviceType === 'docker' && <NewServicePreFill_docker disabled={disableService} serviceName={serviceName} deployment={props.deployment} />}
      {serviceType === 'helm' && <NewServicePreFill_helm disabled={disableService} serviceName={serviceName} deployment={props.deployment} />}
      {serviceType === 'k8s' && <NewServicePreFill_k8s disabled={disableService} serviceName={serviceName} deployment={props.deployment} />}
    </Space>
  );
};
