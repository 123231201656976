import { useHistory } from 'react-router-dom';
import { deploymentService, useGqlDeploymentById } from 'services/deployment.service';
import { iDeployment } from 'shared/deployment';
import { Button, Result, notification, Alert, Space, Typography, Modal } from 'antd';
import { buttonWidth, spaceWidth } from 'utils/styles';
import { DeploymentStep4 } from 'components/SharedComponents/CreateSteps/CreateSteps';
import { useState } from 'react';
import { DeploymentCustomisePage } from './DeploymentCustomisePage';
import { NewRevisionForm } from 'components/Deployments/SpecsRevisions/SpecsRevisions';

const { Text } = Typography;

interface iDeployButton {
  deployment: iDeployment;
  beforeDeploy?: (iDeployment) => boolean | Promise<boolean>;
  title?: string;
  disabled?: boolean;
}

const makeReadyToDeploy = async (deploymentId: number) => {
  const res = await deploymentService.updateDeployment(deploymentId, { isReady: true, isEnabled: true });
  if (res.error || res.data.error) {
    notification.error({ message: res.error || res.data.error });
    return false;
  }
  notification.success({ message: `Saved` });
};

export const DeployButton = (props: iDeployButton) => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (props.deployment.isReady) {
    return (
      <NewRevisionForm
        disabled={props.disabled || false}
        beforeDeploy={props.beforeDeploy}
        deployment={props.deployment}
        btnText={props.title || `Save & Deploy`}
        onNewRevision={(revision: any) => {
          console.log('revision', revision);
        }}
      />
    );
  }

  if (!props?.deployment?.region) {
    return (
      <Button
        type="default"
        disabled={props.disabled || false}
        onClick={async () => {
          if (props.beforeDeploy) {
            if (!(await props.beforeDeploy(props.deployment))) {
              return;
            }
          }

          Modal.confirm({
            title: 'Please select a cluster to deploy.',
            content: 'Do you want to select a kubernetes cluster now?',
            onOk: () => history.push(`/app/${props.deployment.id}/configuration/settings/general`),
          });
        }}
      >
        {props.title || `Save & Deploy`}
      </Button>
    );
  }

  const onDone = async () => {
    if (await makeReadyToDeploy(props.deployment.id)) {
      history.push(`/app/${props.deployment.id}/configuration/settings/general`);
    }

    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        disabled={props.disabled || false}
        type="primary"
        onClick={async () => {
          if (props.beforeDeploy) {
            if (!(await props.beforeDeploy(props.deployment))) {
              return;
            }
          }
          setIsModalOpen(true);
        }}
      >
        {props.title || `Save & Deploy`}
      </Button>
      <Modal
        width={`80vw`}
        title="Application is ready to deploy, finalise the settings"
        open={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        <DeploymentCustomisePage inPopup={true} deployment={props.deployment} onCancel={() => setIsModalOpen(false)} afterSave={onDone} />
      </Modal>
    </>
  );
};

export const DeployPage = (props: { deploymentId: number }) => {
  const history = useHistory();
  const dpQuery = useGqlDeploymentById(props.deploymentId);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};

  if (!deployment.region && 0) {
    return (
      <Result
        status="warning"
        title="Please Select a Region to Deploy."
        extra={
          <Button style={buttonWidth} type="primary" onClick={async () => history.push(`/app/${props.deploymentId}/configuration/settings/general`)}>
            Select Region
          </Button>
        }
      />
    );
  }

  return (
    <Result
      status="success"
      title="Application is Ready to Deploy."
      extra={
        <Button
          style={buttonWidth}
          type="primary"
          onClick={async e => {
            if (await makeReadyToDeploy(props.deploymentId)) {
              history.push(`/app/${props.deploymentId}/configuration/settings/general`);
            }
          }}
        >
          Confirm and Deploy
        </Button>
      }
    />
  );
};
