import gql from 'graphql-tag';
import { iJobModel } from 'shared/deployment';
import { useAuthedMutationWithNotification } from 'utils/qlAuth';
import { Popconfirm, Button, Typography } from 'antd';
import { redColor } from 'utils/styles';

interface iDestroyJobButtonProps {
  job: iJobModel;
  onDestroy?: (jobId: number) => void;
}

const { Title } = Typography;

export const DestroyJobButton = (props: iDestroyJobButtonProps) => {
  const [runDestroyJobFunction, runJobRes] = useAuthedMutationWithNotification(gql`
    mutation JobsController_clusterDestroy($jobId: Int!) {
      JobsController_clusterDestroy(jobId: $jobId) {
        id
      }
    }
  `);

  if (runJobRes?.data?.JobsController_clusterDestroy?.id && props.onDestroy) props.onDestroy(runJobRes?.data?.JobsController_clusterDestroy?.id);

  const popData = () => {
    const listItems = [
      'This can take 20 - 30 minutes, please be patient.',
      'You can see the progress in the logs in realtime.',
      'If you will stop Destroy process, you will need to destroy the cluster manually.',
      'AWS EKS cluster is paid.',
      'AWS will bill you for everything (nodes, traffic, IP v4, volumes and so on ...).',
      'Price will depend from your usage and cluster size.',
      'Price will be more than 180 USD per month.',
      'You can destroy cluster at any time later.',
    ];

    const popDescription = (
      <>
        <Title level={3} style={redColor}>
          It will increase your bills in your account in AWS
        </Title>
        <ul>
          {listItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </>
    );

    const popOnConfirm = async e => {
      try {
        await runDestroyJobFunction({ variables: { jobId: props.job.id } });
      } catch (e) {
        console.error(`Error in DestroyJobButton`, e);
      }
    };

    return (
      <Popconfirm
        title="Do you want to start cluster creation in your cloud?"
        description={popDescription}
        onConfirm={popOnConfirm}
        okText="Yes, create a cluster for me!"
        cancelText="Cancel"
      >
        <Button danger disabled={runJobRes?.data?.JobsController_clusterDestroy?.id}>
          Destroy
        </Button>
      </Popconfirm>
    );
  };

  return popData();
};
