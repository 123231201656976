import { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { authService } from '../../services/auth.service';
import { goToLogin, handleInputChange } from '../../utils/common';
import { Typography, Button, Input, notification, Space } from 'antd';
import { formInput } from 'utils/styles';
import { AuthenticationForm, ForgotPasswordHeading, ImageD } from 'components/SharedComponents/AuthComp/AuthComp';
import { LoginLink, PasswordSentHeading, TextForgot } from 'components/SharedComponents/AuthComp/AuthComp';

const { Text } = Typography;

const onFormSubmit = async ({ email }: { email: string }) => {
  notification.info({ key: 'RequestAccess', description: 'Please wait a second...', message: '' });
  try {
    const { status, data } = await authService.resetPassword({ email });
    return status === 201
      ? (notification.success({ key: 'RequestAccess', message: 'Success' }), true)
      : (notification.error({ key: 'RequestAccess', description: data.message, message: '' }), false);
  } catch (error) {
    notification.error({ key: 'RequestAccess', description: 'An error occurred', message: '' });
    return false;
  }
};

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [showForm, setShowForm] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const timeoutId = !showForm ? setTimeout(() => goToLogin(), 10000) : null;
    return () => timeoutId && clearTimeout(timeoutId);
  }, [showForm, history]);

  useEffect(() => {
    const timeoutId = !showForm && showSuccessMessage ? setTimeout(() => {}, 5000) : null;
    return () => timeoutId && clearTimeout(timeoutId);
  }, [showForm, showSuccessMessage]);

  const handleSendEmailClick = async () => {
    setShowForm(false);
    try {
      if (await onFormSubmit({ email })) {
        setShowSuccessMessage(true);
      } else {
        setShowForm(true);
      }
    } catch (e) {
      console.error(e);
      setShowForm(true);
    }
  };

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email) {
      notification.error({ key: 'RequestAccess', description: 'Please enter your email', message: '' });
      return;
    }

    await handleSendEmailClick();
  };

  const formForgotPassword = (
    <Space direction="vertical">
      <ForgotPasswordHeading />
      <form onSubmit={handleFormSubmit}>
        <Space direction="vertical">
          <Text strong> Email </Text>
          <Input placeholder="Enter email here" onChange={handleInputChange(setEmail)} style={formInput} />
          <Text />
          <Button type="primary" htmlType="submit" disabled={email === ''}>
            Send Password Reset Email
          </Button>
        </Space>
      </form>
    </Space>
  );

  const successText = showSuccessMessage && <PasswordSentHeading email={email} />;

  return (
    <>
      <ImageD />
      <LoginLink />
      <AuthenticationForm> {showForm ? formForgotPassword : successText} </AuthenticationForm>
      <TextForgot />
    </>
  );
}
