import { Select } from 'antd';
import { spaceWidth } from 'utils/styles';

const k8sVersionsList = [/*'1.25', '1.26', '1.27', '1.28',*/ '1.29', '1.30'];

export function K8sVersions(props: { defaultValue: string; onChange: any }) {
  return (
    <Select placeholder="Select Kubernate version Here" defaultValue={props.defaultValue} style={spaceWidth} onChange={props.onChange}>
      {k8sVersionsList.map(version => (
        <Select.Option value={version} key={version}>
          {version}
        </Select.Option>
      ))}
    </Select>
  );
}
