import { Link, useHistory } from 'react-router-dom';
import { ReactElement } from 'react';
import { projectService } from 'services/project.service';
import { useApiQueryLoop } from 'utils/common';
import { Tag, Tooltip } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { TipTop } from 'components/SharedComponents/Tooltip/Tooltip';

export interface GitStatusComponentProps {
  projectId: number;
  deploymentId?: number;
}

export const GitStatusComponent = (props: GitStatusComponentProps): ReactElement => {
  const [gitStatus, gitError, gitLoader] = useApiQueryLoop(() => projectService.gitStatusProject(props.projectId), 60000);

  let basedPath = `/project/${props.projectId}/settings`;
  if (props?.deploymentId) {
    basedPath = `/app/${props?.deploymentId}/configuration/settings`;
  }

  const fileStatus = () => {
    return (
      <TipTop tip="In order not to lose the changes, do a git push" data-qa="GitStatusComponent">
        <Link to={`${basedPath}/git`} target="_blank">
          <Tag color={'volcano'}> Template files is modified </Tag>
        </Link>
      </TipTop>
    );
  };

  const errorStatus = () => (
    <TipTop tip="Error in git config">
      <Tag color={'error'}> {gitStatus.error || ''} </Tag>
    </TipTop>
  );

  const loadingStatus = () => (
    <Link to={`${basedPath}/git`} data-qa="GitStatusComponent">
      <Tag icon={<SyncOutlined spin />} color={'default'}>
        Loading
      </Tag>
    </Link>
  );

  const changeStatus = () => (
    <TipTop tip="Git status" data-qa="GitStatusComponent">
      <Link to={`${basedPath}/git`}>
        <Tag color={'green'}> No local changes </Tag>
      </Link>
    </TipTop>
  );

  return gitLoader || gitError
    ? null
    : gitStatus?.status?.files.length
    ? fileStatus()
    : gitError
    ? errorStatus()
    : gitLoader || !gitStatus
    ? loadingStatus()
    : gitStatus?.status === null
    ? null
    : changeStatus();
};
