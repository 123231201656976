import { iMyUserData, iUserModel } from './user';

/**
 * All existing user permissions
 */
export enum UserPermissions {
  /**
   * Allow to set api keys for other users
   */
  UsersApiKeysMange = 'Permission_UsersApiKeysMange',
  /**
   * Allow to see the list of other users, edit permissions, block, unblock, delete users
   */
  UsersMange = 'Permission_UsersMange',
  /**
   * Allow to see the AllAuditLogs ( http://localhost:3000/#/audit-logs )
   */
  AuditLogs = 'Permission_AllAuditLogs',

  /**
   * Allow to create the Deployment
   */
  DeploymentCreate = 'Permission_DeploymentCreate',
  /**
   * Allow to see the Deployment env var and specs
   */
  DeploymentSpecs = 'Permission_DeploymentSpecs',
  /**
   * Allow to see the DeploymentHistory ( http://localhost:3000/#/deployment/65/logs/audit )
   */
  DeploymentHistory = 'Permission_DeploymentHistory',
  DeploymentWebSSH = 'Permission_DeploymentWebSSH',
  DeploymentDelete = 'Permission_DeploymentDelete',
  DeploymentMange = 'Permission_DeploymentMange',
  DeploymentLogs = 'Permission_DeploymentLogs',
  /**
   * Allow to see the Projects list and edit project settings ( http://localhost:3000/#/projects )
   */
  ProjectsMange = 'Permission_ProjectsMange',
  /**
   * Allow to do all that is allowed to the Viewer
   */
  Viewer = 'Permission_Viewer',
  /**
   * Allow to work with own clusters ( http://localhost:3000/#/clusters )
   */
  ClusterMange = 'Permission_ClusterMange',

  /**
   * Allow to work with payments and tariffs
   */
  Tariffs = 'Permission_Tariffs',
}

/**
 * All existing user roles
 */
export const UserRolesList = ['Admin', 'DevOps', 'Developer' /*, 'Viewer'*/]; // 'Viewer' is basic role, it has basic permissions for all users

interface iUsersRoleMap {
  [key: string]: UserPermissions[];
}
const Role_Viewer = [UserPermissions.Viewer, UserPermissions.Tariffs];
const Role_Developer = [
  ...Role_Viewer,
  UserPermissions.AuditLogs,
  UserPermissions.DeploymentWebSSH, // ok
  UserPermissions.DeploymentHistory, // ok
  UserPermissions.DeploymentCreate, // ok
  UserPermissions.DeploymentSpecs, //  ok
  UserPermissions.DeploymentDelete, // ok
  UserPermissions.DeploymentMange, //  ok
  UserPermissions.DeploymentLogs, //   ok
];
const Role_DevOps = [
  ...Role_Developer,
  UserPermissions.ClusterMange, // ok
  UserPermissions.ProjectsMange, // ok
  UserPermissions.AuditLogs, // ok
];
const Role_Admin = [
  ...Role_DevOps,
  UserPermissions.UsersApiKeysMange, // ok
  UserPermissions.UsersMange, // ok
];

/**
 * All existing user roles and their permissions
 */
const UsersRoleMap: iUsersRoleMap = {
  Admin: Role_Admin,
  DevOps: Role_DevOps,
  Developer: Role_Developer,
  Viewer: Role_Viewer,
};

/**
 * Check if user has permission
 * @param me
 * @param permission
 * @returns
 */
export const userHasPermission = (me: iUserModel | iMyUserData, permission: UserPermissions): boolean => {
  if (!me) {
    return false;
  }
  if (UserPermissions.Viewer === permission) {
    // Viewer is basic role, it has basic permissions for all users
    // It is not necessary to check the Viewer role
    return true;
  }
  const answer = !!me?.roles?.find(role => (UsersRoleMap[role] || []).includes(permission));
  if (!answer) {
    console.warn('No permission', me, permission, answer);
  }
  return answer;
};

/**
 * Check if user has permission
 * @param me
 * @param permission
 * @returns
 */
export const userHasPermissionOrDie = (me: iUserModel | iMyUserData, permission: UserPermissions): boolean => {
  if (!me) {
    throw `Forbidden: Permission ${permission} is required`;
  }

  if (UserPermissions.Viewer === permission) {
    // Viewer is basic role, it has basic permissions for all users
    // It is not necessary to check the Viewer role
    return true;
  }
  return !!me?.roles?.find(role => (UsersRoleMap[role] || []).includes(permission));
};
