import * as jForm from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { useState } from 'react';
import { useApiQuery } from 'utils/common';
import { projectService } from 'services/project.service';
import { schemaFormService } from 'services/schemaForm.service';
import { CustomFieldTemplate } from 'form-components/ObjectFieldTemplate';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Alert, Button, Form, Skeleton, Switch, Tabs, notification, Space, Typography, Modal } from 'antd';
import { ApartmentOutlined, AppstoreOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { buttonBorder, spaceWidth } from 'utils/styles';
import { iProjectModel } from 'shared/deployment';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';

const { Text } = Typography;
const { TabPane } = Tabs;
const { Item } = Form;

interface iProjectGeneralProps {
  project: iProjectModel;
  onDone?: () => void;
}

/**
 * ToDo: Replace to andt.d form and use one form and one Save button
 * https://nanoheal.atlassian.net/browse/DP-736
 * @param props
 * @returns
 */
export const ProjectEnvSettings = (props: iProjectGeneralProps) => {
  const project = props.project;
  const [schema, schemaError, schemaLoader] = useApiQuery(() => schemaFormService.getEnv(project.id));
  const [env, envError, envLoader] = useApiQuery(() => projectService.getProjectEnv(project.id));
  const [showEnvForm, setShowForm] = useState(false);

  if (schemaError || schemaLoader || envError || envLoader) {
    return <Skeleton active={true} loading={true} />;
  }
  if (!schema || !env) {
    return <Skeleton active={true} loading={true} />;
  }

  const handleSubmit = async e => {
    const res = await projectService.setProjectEnv(project.id, e.formData);
    if (res.error) {
      notification.error({ message: `Error - ${res.error}` });
    } else {
      notification.success({ message: `Ready` });
    }
    if (props.onDone) {
      props.onDone();
    }
  };

  const handleSaveAndContinue = async values => {
    const res = await projectService.setProjectSettings(project.id, values);
    if (res.error) {
      notification.error({ message: `Error - ${res.error}` });
    } else {
      notification.success({ message: `Ready` });
    }
    setShowForm(values.showEnvVarsTab || values.autoAddEnvVarsToDeployments);
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Form onFinish={handleSaveAndContinue}>
        {/* <Item
        key={`showEnvVarsTab`}
        name={`showEnvVarsTab`}
        label={`Add the Environment variables configuration tab in the Application UI`}
        initialValue={project.showEnvVarsTab}
      >
        <Switch defaultChecked={project.showEnvVarsTab} />
      </Item> */}
        <Item
          key={`autoAddEnvVarsToDeployments`}
          name={`autoAddEnvVarsToDeployments`}
          label={
            <>
              Add environment variables to all Deployments in this template automatically{' '}
              <TipLeft tip="Click here to know more...">
                <a href="https://www.unifie.cloud/post/variables" target="_blank">
                  <Button type="text">
                    <QuestionCircleTwoTone twoToneColor="#00A58E" />
                  </Button>
                </a>
              </TipLeft>
            </>
          }
          initialValue={project.autoAddEnvVarsToDeployments}
        >
          <Switch defaultChecked={project.autoAddEnvVarsToDeployments} />
        </Item>

        <BottomButtons>
          <Button type="primary" htmlType="submit" style={buttonBorder}>
            Save
          </Button>
        </BottomButtons>
      </Form>

      <jForm.Form
        formData={env}
        schema={schema.envSettingSchema}
        uiSchema={schema.ui}
        validator={validator}
        onSubmit={handleSubmit}
        templates={{ FieldTemplate: CustomFieldTemplate }}
        onError={e => e}
      >
        <BottomButtons>
          <Button type="primary" htmlType="submit" style={buttonBorder}>
            Save
          </Button>
          {props.onDone && (
            <Button
              type="default"
              style={buttonBorder}
              onClick={() => {
                props.onDone();
              }}
            >
              Cancel
            </Button>
          )}
        </BottomButtons>
      </jForm.Form>
    </Space>
  );
};

export const Modal_ProjectEnvSettings = (props: iProjectGeneralProps) => {
  if (props.project.name !== 'nanoheal') {
    // For now, this functionality is only for nanoheal ( https://nanoheal.atlassian.net/browse/DP-736 )
    return null;
  }

  const [open, setOpen] = useState(false);
  return (
    <>
      <Button type="default" onClick={() => setOpen(true)}>
        Environment settings
      </Button>
      <Modal width={`80vw`} title="Environment settings" open={open} onCancel={() => setOpen(false)} footer={null}>
        <ProjectEnvSettings project={props.project} onDone={() => setOpen(false)} />
      </Modal>
    </>
  );
};
