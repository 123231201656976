import { parse } from '@ctrl/golang-template';
/**
 * Typescript library that handles basic functions of the golang template syntax.
 *
 * @examples
 *  {{ .keywords }}
 *  {{ if .keywords }}{{ .keywords }}!!{{else}}nothing{{end}}
 *  {{ .foo }}
 *  {{ range .categories }}{{.}};{{end}}
 *
 * @param template string
 * @param templateVars  any vars
 * @returns string
 * @link https://www.npmjs.com/package/@ctrl/golang-template
 * @link https://github.com/scttcper/golang-template#readme
 * @license MIT
 *
 * @description
 *  isolation note:
 *  This function do noe execute any code, it just renders the template with regexp and other string operations.
 *
 */
export function renderGolangTemplate(template: string, templateVars: any): string {
  return parse(template, templateVars);
}

/**
 * @param template
 * @returns true if template is not empty and not only comments
 */
export function notEmptyYamlString(template: string): boolean {
  if (!template) {
    return false;
  }
  return (
    template.split('\n').find(line => {
      if (line.trim().startsWith('#')) {
        return false;
      }
      return true;
    }) !== undefined
  );
}
