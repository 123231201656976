import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { projectService } from 'services/project.service';
import { useUser } from 'utils/common';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { Button, Form, Input, Modal, notification, Select, Skeleton, Tag } from 'antd';
import { buttonSize, buttonWidth, floatingRight } from 'utils/styles';
import { LoadingOutlined } from '@ant-design/icons';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { TitleUIRow } from 'layout/TitleUI';
import DeploymentFromTemplate from './DeploymentFromTemplate';
import { useAuthedQuery } from 'utils/qlAuth';
import gql from 'graphql-tag';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';
import { iDeployment, iRegionModel } from 'shared/deployment';
import { NewClusterCreation } from 'components/Clusters/ClustersList';
import { updateDeployment } from '../Setting/common';
import { DeleteDeployment } from '../Setting/General/DeleteDeployment';
import { DeployButton } from '../Setting/new-deployment/DeployPage';

export function OverviewForNotReadyDeployment(props: { deployment: iDeployment }) {
  const params: any = useParams();
  /**
   * Way of creating a new project
   * `from-public-template` or `from-own-template` or `from-scratch`
   */
  const way = String(params?.way);

  const user = useUser();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const qTags = useAuthedQuery(
    gql`
      query TagsController_tagsList {
        TagsController_tagsList {
          id
          name
          color
        }
        RegionsController_regionsForNewDeployment {
          id
          tenant
          name
          title
          kubernetesVersion
          cloudProvider
          isPublic
        }
      }
    `,
    {},
  );
  const regions: iRegionModel[] = qTags.data?.RegionsController_regionsForNewDeployment || [];

  if (qTags.loading) {
    return <Skeleton active={true} loading={true} />;
  }

  const Save = async () => {
    setLoading(true);
    try {
      const config = form.getFieldsValue();
      await updateDeployment(props.deployment.id, {
        name: config.name,
        tags: config.tags,
        region: config.region,
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleSave = async () => {
    await Save();
    if (!props.deployment.isReady) {
      history.push(`/app/${props.deployment.id}/configuration/new-service/add`);
    }
  };

  // Not authorized to access this page
  if (!userHasPermission(user, UserPermissions.ProjectsMange)) {
    return null;
  }

  return (
    <>
      <TitleUIRow title={`Create a new application`} />
      <Form
        form={form}
        onFinish={handleSave}
        layout="vertical"
        initialValues={{
          name: props.deployment.name,
          // description: props.deployment.ProjectModel?.description,
          tags: props.deployment.tags,
          region: props.deployment.region,
        }}
        validateTrigger="onSubmit"
        validateMessages={{
          required: '${label} is required!',
        }}
      >
        <Form.Item
          label="Application Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input Application Name!',
            },
          ]}
        >
          <Input placeholder="Enter Name of the Project Here" />
        </Form.Item>

        <Form.Item name="tags" label="Application tags">
          <Select mode="multiple" placeholder="Tags">
            {qTags.data.TagsController_tagsList.map(elem => (
              <Select.Option value={elem.id} key={elem.id}>
                <Tag color={elem.color}>{elem.name}</Tag>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="region" label="Kubernetes cluster">
          <Select placeholder="Choose Cluster Here">
            <Select.Option value={-1} key={0}>
              Connect cluster later
            </Select.Option>
            {regions
              .sort((a, b) => (b.id - a.id !== 0 ? b.id - a.id : Number(a.isPublic) - Number(b.isPublic)))
              .map((v: iRegionModel) => (
                <Select.Option value={v.id} key={v.id} disabled={v.allowToAddDeployments}>
                  <Tag color={v.isPublic ? 'grey' : 'green'} title="Kubernetes version">
                    {v.isPublic ? 'Shared' : 'Private'}
                  </Tag>
                  <Tag color={v.kubernetesVersion ? 'green' : 'red'} title="Kubernetes version" style={{ ...floatingRight, marginTop: 5 }}>
                    Kubernetes {v.kubernetesVersion || 'N/A'}
                  </Tag>
                  {v.title}
                </Select.Option>
              ))}
            <Select.Option key={'New_Cluster'}>
              <TipLeft tip="Select this to create a new Cluster">
                <NewClusterCreation />
              </TipLeft>
            </Select.Option>
          </Select>
        </Form.Item>
        <BottomButtons extra={<DeleteDeployment id={props.deployment.id} />}>
          <Button disabled={loading} type="primary" htmlType="submit" style={buttonWidth}>
            {props.deployment.isReady ? `Save` : `Save > Add a service`} {loading ? <LoadingOutlined /> : null}
          </Button>
          <DeployButton
            deployment={props.deployment}
            beforeDeploy={async e => {
              await Save();
              return true;
            }}
          />
        </BottomButtons>
      </Form>
    </>
  );
}
