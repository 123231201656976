import { ConfigMapMainOptionsUI } from './ConfigMapMainOptionsUI';
import { ContainersUI } from './ContainersUI';
import { DeploymentMainOptionsUI } from './DeploymentMainOptionsUI';
import { PersistentVolumeClaimMainOptionsUI } from './PersistentVolumeClaimMainOptionsUI';
import { ScaledObjectMainOptionsUI } from './ScaledObjectMainOptionsUI';
import { SecretsMainOptionsUI } from './SecretsMainOptionsUI';
import { ServiceMainOptionsUI } from './ServiceMainOptionsUI';
import { VolumesUI } from './VolumesUI';

/**
 * Here we will create a custom tabs for each object depending on kind
 *
 * @param kind
 * @param yamlObj
 * @param onChangeCurrentProperty
 * @returns  - array of tabs
 */

export const OneKubeItemTabUI = (kind: string, yamlObj: any, onChangeCurrentProperty: (key: string, newObj: any, action?: string) => void): any[] => {
  const tabsArr = [];
  tabsArr.push(
    ['scaledobject'].includes(kind)
      ? { label: 'Main', key: 'Main', children: <ScaledObjectMainOptionsUI yamlObj={yamlObj} onChange={onChangeCurrentProperty} /> }
      : null,
    ['secret'].includes(kind)
      ? { label: 'Main', key: 'Main', children: <SecretsMainOptionsUI yamlObj={yamlObj} onChange={onChangeCurrentProperty} /> }
      : null,
    ['configmap'].includes(kind)
      ? { label: 'Main', key: 'Main', children: <ConfigMapMainOptionsUI yamlObj={yamlObj} onChange={onChangeCurrentProperty} /> }
      : null,
    [`persistentvolumeclaim`].includes(kind)
      ? { label: 'Main', key: 'Main', children: <PersistentVolumeClaimMainOptionsUI yamlObj={yamlObj} onChange={onChangeCurrentProperty} /> }
      : null,
    ['deployment', 'statefulset', 'daemonset', 'cronjob'].includes(kind)
      ? [
          { label: 'Main', key: 'Main', children: <DeploymentMainOptionsUI yamlObj={yamlObj} onChange={onChangeCurrentProperty} /> }, // Custom UI for the kind===deployment
          { label: 'Volumes', key: 'Volumes', children: <VolumesUI yamlObj={yamlObj} onChange={onChangeCurrentProperty} /> },
          { label: 'Containers', key: 'Containers', children: <ContainersUI yamlObj={yamlObj} onChange={onChangeCurrentProperty} /> }, // tabsArr.push({ label: 'Affinity', key: 'Affinity', children: <Text> Affinity (not ready) </Text> });
        ]
      : null,
    kind === 'service'
      ? { label: 'Main', key: 'Main', children: <ServiceMainOptionsUI yamlObj={yamlObj} onChange={onChangeCurrentProperty} /> } // tabsArr.push({ label: 'Routes', key: 'Routes', children: <Text> Routes (not ready) </Text> });
      : null,
  );
  // Add common tabs for all objects types
  // tabsArr.push({
  //   label: 'Specs',
  //   key: 'Specs',
  //   children: <TreeHelpUI kindLib={KindLib[String(yamlObj.kind).toLowerCase()]} yamlObj={yamlObj} onChange={onChangeCurrentProperty} />,
  // });
  return tabsArr.flat().filter(Boolean);
};
