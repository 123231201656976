import { Space, Typography } from 'antd';

const { Text } = Typography;

/**
 * help text about go templates concluded in a list tags (li)
 * @returns
 */

export const HelpNoteAboutGoTemplates = () => (
  <Space direction="vertical">
    <Text> Variables are defined on Variables tab. </Text>
    <Text>
      You can use these variables &nbsp;
      <Text strong>
        For example:
        {`{{ .Values.VARIABLE_NAME }}`}, {`{{ .Release.Namespace }}`}, {`{{ .UnifieVar.image.my-service }}`}
      </Text>
      . Code will be parsed with,&nbsp;
      <Text strong>
        <a href="https://www.npmjs.com/package/@ctrl/golang-template" target="_blank">
          GoLang-Template Library
        </a>
      </Text>
    </Text>
  </Space>
);

/**
 * help text about go templates concluded in a list tags (li)
 * @returns
 * */

export const HelpNoteAboutHelmTemplates = () => (
  <Space direction="vertical">
    <Text>
      You can use variables here. For example: <code>{`{{ .Values.ANY_NAME }}`}</code>
    </Text>
    <Text>
      Code will be parsed with
      <Text strong>
        <a href="https://helm.sh/docs/helm/helm_template/" target="_blank">
          Helm Template Command
        </a>
      </Text>
    </Text>
    <Text> You can add new variables on `Variables` tab. </Text>
  </Space>
);

/**
 * help text about variables concluded in a list tags (li)
 * @returns
 */

export const HelpNoteAboutVars = () => <Text> You can use variables here. You can add new variables on "Variables" tab. </Text>;
