import { CrudBodyFilters } from 'interface/common';
import { apiQuery } from '../utils/common';

class AuditLogsService {
  async getDeploymentLogsHistory(deploymentsId: number) {
    return apiQuery('GET', `/api/auditLogs/${deploymentsId}/history`);
  }

  async getLogs(filters: CrudBodyFilters | any) {
    const str = Object.keys(filters)
      .map(key => {
        return `${key}=${encodeURIComponent(filters[key])}`;
      })
      .join('&');

    return apiQuery('GET', `/api/auditLogs/logs?${str}`);
  }
}

const auditLogsService = new AuditLogsService();
export { auditLogsService, AuditLogsService };
