import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { buttonBorder } from 'utils/styles';

export const NewApplicationButton = () => {
  return (
    <Link to="/new-application">
      <Button type="primary" style={buttonBorder}>
        New Application
      </Button>
    </Link>
  );
};
