import { useState } from 'react';
import { projectService } from 'services/project.service';
import { Button, Result, notification } from 'antd';
import { CloudDownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { buttonWidth } from 'utils/styles';

export const ProjectClonePage = (props: { projectId: number; onClone: () => Promise<void> }) => {
  const [gitCloning, setGitCloning] = useState<boolean>(false);

  const resultData = () => {
    const resultExtra = () => {
      const handleButtonClick = async () => {
        setGitCloning(true);
        const res = await projectService.gitCloneProject(props.projectId);
        res.error ? notification.error({ message: `Error - ${res.error || res?.data?.results?.error}` }) : await props.onClone(); // await dpQuery.refetch();
        setGitCloning(false);
      };

      return (
        <Button type="primary" key="console" onClick={handleButtonClick} disabled={gitCloning} style={buttonWidth}>
          Continue
        </Button>
      );
    };

    return (
      <Result
        title="To continue, we need to clone the Project Template Settings from the Git Repository."
        icon={gitCloning ? <LoadingOutlined /> : <CloudDownloadOutlined />}
        extra={resultExtra()}
      />
    );
  };

  return resultData();
};
