import { Flex } from 'antd';
import { connect } from 'react-redux';
import { mapDispatchToProps } from 'services/actions';
import { IMainStoreProps, mapStateToProps } from 'services/reducer';

const connector = connect(mapStateToProps, mapDispatchToProps);

export const WidgetArea = connector((props: any) => {
  const store: IMainStoreProps = props?.store;
  return (
    <Flex data-qa={'WidgetArea'} justify={'flex-end'} style={{ marginTop: -40 }}>
      {store?.page?.widgets}
    </Flex>
  );
});
