import { Link } from 'react-router-dom';
import { useTenantInfo, useUser } from 'utils/common';
import { PROJECT_NAME } from 'interface/common';
import { Button, Result, Skeleton } from 'antd';
import { SmileTwoTone } from '@ant-design/icons';
import { buttonWidth } from 'utils/styles';

export const TariffsPage = () => {
  const tenantInfo = useTenantInfo();
  const user = useUser();
  const email = encodeURIComponent(user?.email || '');
  const user_id = encodeURIComponent(`${user?.tenant || ''}-${user?.id || ''}-99-r99`);
  const loader = !tenantInfo;
  const payment = tenantInfo && tenantInfo.roles.includes('paid');
  const resultIcon = <SmileTwoTone twoToneColor="#00A58E" />;

  const stripeLink = `https://buy.stripe.com/test_4gwaGUcQ30Z51c48ww?prefilled_email=${email}&client_reference_id=${user_id}&utm_term=${user_id}&utm_campaign=${user_id}`;

  const payButton = (
    <a href={stripeLink} target="_blank">
      <Button type="primary" style={buttonWidth}>
        Pay
      </Button>
    </a>
  );

  const startButton = (
    <Link to={'/'}>
      <Button type="primary" style={buttonWidth}>
        Get Started with {PROJECT_NAME}
      </Button>
    </Link>
  );

  const paymentOptions = {
    paid: { icon: resultIcon, title: 'You already paid for the service!', subTitle: `Thank you for your payment`, extra: startButton },
    unpaid: { icon: resultIcon, title: "Let's pay for the service!", subTitle: 'Please click below to pay.', extra: payButton },
  };

  const tariffsData = () =>
    loader ? <Skeleton active={true} loading={true} /> : payment ? <Result {...paymentOptions.paid} /> : <Result {...paymentOptions.unpaid} />;

  return tariffsData();
};
