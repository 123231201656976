import { Col, List, Row, Space, Typography } from 'antd';
import { redColor, spaceWidth } from 'utils/styles';

const { Title } = Typography;
const { Item } = List;
const { Meta } = Item;

export const DeploymentVersionInfo = ({ images, alterImages }) => {
  let hasChanges = false;
  const changedData = alterImages
    ? Object.keys(alterImages).map(elem => {
        const style = alterImages[elem] != images[elem] ? ((hasChanges = true), redColor) : { color: 'grey' };
        return { title: elem, style };
      })
    : null;

  const imageData = item => (
    <Item>
      <Meta title={item.title} description={images[item.title]} />
    </Item>
  );

  const alterImage = item => (
    <Item>
      <Meta title={item.title} description={<span style={item.style}> {alterImages[item.title]} </span>} />
    </Item>
  );

  return (
    <Row>
      <Col span={12}>
        <Space direction="vertical" style={spaceWidth}>
          <Title level={4}> Current Images </Title>
          <List itemLayout="horizontal" dataSource={Object.keys(images).map(elem => ({ title: elem }))} renderItem={imageData} />
        </Space>
      </Col>
      <Col span={12}>
        <Space direction="vertical" style={spaceWidth}>
          {hasChanges && alterImages && (
            <>
              <Title level={4}> Changed Images </Title>
              <List itemLayout="horizontal" dataSource={changedData} renderItem={alterImage} />
            </>
          )}
        </Space>
      </Col>
    </Row>
  );
};
