/**
 * Create a dynamic schema for volume mounts based on the current container and deployment yaml
 * @param deploymentYaml
 * @param currentContainer
 * @returns
 */
export const containerVolumeMountsDynamicSchema = (deploymentYaml: any, currentContainer: any) => {
  return {
    schema: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
            title: 'Name',
            description: `Volume name, you can add new volumes on the Volumes tab.`,
            enum: (deploymentYaml?.spec?.template?.spec?.volumes || []).map((v: any) => v.name),
          },
          readOnly: { type: 'boolean', title: 'Read only' },
          path: { type: 'string', title: 'Path' },
          subPath: { type: 'string', title: 'Sub path', description: `Specifies a sub-path inside the referenced volume instead of its root` },
        },
      },
    },
    ui: { 'ui:options': { orderable: false } },
  };
};
