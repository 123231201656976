import _ from 'lodash';
import gql from 'graphql-tag';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TitleUIRow } from 'layout/TitleUI';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { regionsService, useRegionsController_getRegionById } from 'services/regions.service';
import { ClusterDeleteButton } from '../ClusterDangerZone';
import { useAuthedMutationWithNotification } from 'utils/qlAuth';
import { layout } from 'components/Deployments/Setting/common';
import { PROJECT_NAME } from 'interface/common';
import { Alert, Button, Collapse, Form, List, Modal, Skeleton, Space, Switch, Tag, Typography, notification } from 'antd';
import { bottomMargin, buttonBorder, buttonWidth, spaceWidth } from 'utils/styles';
import { TipTop } from 'components/SharedComponents/Tooltip/Tooltip';

const { Title, Text } = Typography;
const { Item } = Form;

const createLearnMoreLink = (url: string, text: string | number | boolean | ReactElement<string> | Iterable<ReactNode>) => (
  <Text strong>
    <a href={url} target="_blank">
      {text}
    </a>
  </Text>
);

export const ClusterCreaionStep4 = () => {
  const history = useHistory();
  const params: any = useParams();
  const { loading, region, stopPolling, startPolling, refetch } = useRegionsController_getRegionById(params.regionId);
  const [selectedAddons, setSelectedAddons] = useState({});
  const [installIntegrations, installResults] = useAuthedMutationWithNotification(gql`
    mutation RegionsController_installIntegrations($regionId: Int!) {
      RegionsController_installIntegrations(regionId: $regionId) {
        error
        status
      }
    }
  `);
  const isSelectedAddons = Object.keys(selectedAddons).filter(k => selectedAddons[k]).length > 0;

  useEffect(() => {
    if (region) {
      const addons = {};
      region.selectedIntegrations.forEach(integration => (addons[integration] = true));
      setSelectedAddons(addons);
    }
  }, [region]);
  console.log('isSelectedAddons', isSelectedAddons, selectedAddons);
  if (installResults.data) {
    if (installResults.data.RegionsController_installIntegrations.error) {
      notification.error({ message: `Error - ${installResults.data.RegionsController_installIntegrations.error}` });
    }
    if (installResults.data.RegionsController_installIntegrations.status) {
      history.push(`/clusters/${region.id}/overview`);
      return <Skeleton active={true} loading={true} />;
    }
  }

  if (loading) {
    return <Skeleton active={true} loading={true} />;
  }

  const clusterPreSaveConfig = _.debounce(async (formData: any = {}) => {
    const selectedIntegrations = { ...selectedAddons, ...formData };
    const newValues: any = {
      selectedIntegrations: Object.keys(selectedIntegrations).filter(k => selectedIntegrations[k]),
    };
    await regionsService.updateOwnRegion(region.id, newValues);
  }, 1000);

  const clusterSaveConfig = async formData => {
    const selectedIntegrations = { ...formData };
    const newValues: any = { selectedIntegrations: Object.keys(selectedIntegrations).filter(k => selectedIntegrations[k]) };
    const res = await regionsService.updateOwnRegion(region.id, newValues);
    if (res.error) {
      notification.error({ message: `Error - ${res.error}` });
      return;
    }

    notification.success({ message: `Ready` });
    await installIntegrations({ variables: { regionId: region.id } });
  };

  const handleConfigurationClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    Modal.confirm({
      title: 'Cluster not ready to use',
      content: `Cluster not ready to use. It seems ${PROJECT_NAME} api agent was not installed to this cluster. Please install it first.`,
      onOk: () => history.push(`/cluster/install/${region.id}/agent`),
    });
  };

  const addonsArray = [
    {
      name: 'Monitoring tools',
      key: 'Prometheus',
      description:
        'Prometheus and Grafana and OpenCost are monitoring tools. They are used to monitor the cluster and the services running on it. OpenCost is used to monitor the cost of the Cluster. Be careful, Prometheus and Grafana can consume a lot of resources.',
    },
    {
      key: 'KEDA',
      name: 'KEDA',
      description: 'Keda is a Kubernetes-based Event Driven Autoscaler. It provides event driven scale for any container running in Kubernetes.',
    },
    {
      key: 'Checkov',
      name: 'Checkov',
      description: 'Checkov scans cloud infrastructure configurations to find misconfigurations before they are deployed.',
    },
    {
      key: 'Ingress',
      name: 'Ingress',
      description: 'Ingress NGINX Controller for Kubernetes using NGINX as a reverse proxy and load balancer.',
      Action: createLearnMoreLink('https://github.com/kubernetes/ingress-nginx?tab=readme-ov-file#ingress-nginx-controller', 'Learn more'),
    },
    {
      key: 'metrics-server',
      name: 'Metrics Server',
      description: 'Metrics Server is a scalable, efficient source of container resource metrics for Kubernetes built-in autoscaling pipelines.',
      Action: createLearnMoreLink('https://github.com/kubernetes-sigs/metrics-server', 'Learn more'),
    },
    {
      key: 'VPA',
      name: 'Vertical pod autoscaler',
      description: `Vertical Pod Autoscaler (VPA) frees users from the necessity of setting up-to-date resource limits and requests for the containers in their pods. When configured, it will set the requests automatically based on usage and thus allow proper scheduling onto nodes so that appropriate resource amount is available for each pod. It will also maintain ratios between limits and requests that were specified in initial containers configuration.`,
      Action: createLearnMoreLink('https://github.com/kubernetes/autoscaler/tree/master/vertical-pod-autoscaler', 'Learn more'),
    },
  ];

  return (
    <Space direction="vertical" style={spaceWidth}>
      <TitleUIRow title={`Cluster is Ready`} />

      <Text>
        <Tag color="purple">
          <i>Optional step</i>
        </Tag>
        You can install additional Software to the Cluster. Please mark software that you want to install.{' '}
      </Text>
      <Form initialValues={region} onFinish={clusterSaveConfig}>
        <List>
          {addonsArray.map(addon => {
            const handleSwitchClick = (e: any) => {
              const addons = { ...selectedAddons };
              addons[addon.key] = e;
              setSelectedAddons(addons);
              clusterPreSaveConfig(addons);
            };
            return (
              <List.Item key={addon.key} actions={[addon.Action]}>
                <List.Item.Meta
                  avatar={
                    <Item name={addon.key}>
                      <Switch checked={!!selectedAddons[addon.key]} onChange={handleSwitchClick} />
                    </Item>
                  }
                  title={addon.name}
                  description={addon.description}
                />
              </List.Item>
            );
          })}
        </List>
        <BottomButtons>
          <Space>
            {region?.lastSyncTime === 0 && (
              <TipTop
                tip={`Cluster not ready to use. It seems ${PROJECT_NAME} API agent was not installed to this cluster. Please install it first.`}
              >
                <Button type={`primary`} data-qa="ClusterCreaionStep4.tsx-not-ready" onClick={handleConfigurationClick} style={buttonBorder}>
                  {isSelectedAddons ? `Install seleced addons and continue` : `Skip`}
                </Button>
              </TipTop>
            )}
            {region?.lastSyncTime > 0 && (
              <Button type={`primary`} htmlType="submit" disabled={!(region?.lastSyncTime > 0)} data-qa="ClusterCreaionStep4.tsx" style={buttonWidth}>
                {isSelectedAddons ? `Install seleced addons and continue` : `Skip`}
              </Button>
            )}
            <ClusterDeleteButton region={region} />
          </Space>
        </BottomButtons>
      </Form>
    </Space>
  );
};
