import { Card } from 'antd';
import { flexDisplay, spaceHeight, spaceWidth } from 'utils/styles';

const DashboardItem = ({ children, title }) => (
  <Card title={title} style={{ ...spaceHeight, ...spaceWidth, ...flexDisplay, flexDirection: 'column' }} bodyStyle={spaceHeight}>
    {children}
  </Card>
);

export default DashboardItem;
