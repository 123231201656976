import gql from 'graphql-tag';
import moment from 'moment';
import { Editor } from '@monaco-editor/react';
import { SetStateAction, useEffect, useState } from 'react';
import { iDeployment } from 'shared/deployment';
import { iSpecsRevision } from 'shared/SpecsRevisions';
import { arrToYamlString } from 'shared/yaml';
import { buttonBorder, spaceWidth } from 'utils/styles';
import { useAuthedMutation, useAuthedMutationWithNotification, useAuthedQuery } from 'utils/qlAuth';
import { SplitterComp } from 'components/SharedComponents/Splitter/SplitterComp';
import { FullScreenButton, FullScreenEditor } from 'components/SharedComponents/FullScreenView/FullScreenView';
import { Alert, Button, Card, Flex, Input, Modal, Popconfirm, Skeleton, Space, Timeline, Typography } from 'antd';
import { authService } from 'services/auth.service';

interface iNewRevisionForm {
  deployment: iDeployment;
  onNewRevision: (revision: any) => void;
  beforeDeploy?: (iDeployButton) => boolean | Promise<boolean>;
  btnText?: string;
  applyLater?: boolean;
  disabled?: boolean;
}

interface iNewRevision {
  target: { value: SetStateAction<string> };
}

interface iSpecsRevisions {
  deployment: iDeployment;
}

const { Text } = Typography;
const { TextArea } = Input;

export const NewRevisionForm = (props: iNewRevisionForm) => {
  const [revisionName, setRevisionName] = useState('');
  const [revisionDescription, setRevisionDescription] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleOnOk = async () => {
    if (props.beforeDeploy) {
      if (!(await props.beforeDeploy(props.deployment))) {
        return;
      }
    }

    const res = await authService.getApolloClient().query({
      query: gql`
        mutation SpecsRevisionController_createRevision($applicationId: Int!, $name: String, $description: String, $applyNow: Boolean) {
          SpecsRevisionController_createRevision(applicationId: $applicationId, name: $name, description: $description, applyNow: $applyNow) {
            id
          }
        }
      `,
      variables: { applicationId: props.deployment.id, name: revisionName, description: revisionDescription, applyNow: !props.applyLater },
    });
    setShowModal(false);
  };

  const handleOnCancel = () => setShowModal(false);
  const handleOnChangeInput = (e: iNewRevision) => setRevisionName(e.target.value);
  const handleOnChangeTextArea = (e: iNewRevision) => setRevisionDescription(e.target.value);

  return (
    <>
      <Button
        disabled={props.disabled || false}
        type="default"
        onClick={async () => {
          setShowModal(true);
        }}
      >
        {props.btnText || `Create new revision`}
      </Button>
      <Modal title="Create new revision" open={showModal} onOk={handleOnOk} onCancel={handleOnCancel}>
        <Space direction="vertical" style={spaceWidth}>
          <Text> Please provide revision name and description below: </Text>
          <Input placeholder="Enter Revision name here" value={revisionName} onChange={handleOnChangeInput} />
          <TextArea placeholder="Enter Revision description here" value={revisionDescription} onChange={handleOnChangeTextArea} rows={5} />
        </Space>
      </Modal>
    </>
  );
};

export const SpecsRevisions = ({ deployment }: iSpecsRevisions) => {
  const { lastRevisionId } = deployment;
  const [revisionId, setRevisionId] = useState(lastRevisionId);
  const [showLastApplied, setShowLastApplied] = useState(false);
  const [revisionData, setRevisionData] = useState<{ specs: any; vars: any; services: any }[]>([]);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    setRevisionId(lastRevisionId);
  }, [lastRevisionId]);

  const specsRevisions = useAuthedQuery(
    gql`
      query SpecsRevisionController_getList($applicationId: Int!) {
        SpecsRevisionController_getList(applicationId: $applicationId) {
          id
          createdAt
          name
          userId
          description
        }
      }
    `,
    { skip: !Number(deployment?.id), variables: { applicationId: Number(deployment?.id) } },
  );

  const specsRevision = useAuthedQuery(
    gql`
      query SpecsRevisionController_getOne($applicationId: Int!, $revisionId: Int!) {
        SpecsRevisionController_getOne(applicationId: $applicationId, revisionId: $revisionId) {
          id
          createdAt
          name
          userId
          description
          specs
        }
      }
    `,
    { skip: !Number(revisionId), variables: { revisionId: Number(revisionId), applicationId: Number(deployment?.id) } },
  );

  const [SpecsRevisionController_applyRevision] = useAuthedMutation(gql`
    mutation SpecsRevisionController_applyRevision($applicationId: Int!, $revisionId: Int!) {
      SpecsRevisionController_applyRevision(applicationId: $applicationId, revisionId: $revisionId)
    }
  `);

  const { data: revSpec } = specsRevisions;
  const revisions: iSpecsRevision[] = revSpec?.SpecsRevisionController_getList;

  useEffect(() => {
    specsRevision.data ? setRevisionData(prev => [...prev, specsRevision.data.SpecsRevisionController_getOne.specs]) : null;
  }, [specsRevision.data]);

  const comparisionData = () => {
    const renderEditor = (title: string, yamlString: string) => (
      <Card size="small" type="inner" bordered={false} title={<Text strong> {title} comparision </Text>}>
        <Editor height={isFullscreen ? '95vh' : '300px'} language="yaml" value={yamlString} theme="GitHub" options={{ readOnly: true }} />
      </Card>
    );
    const beforeEditor = () => renderEditor('Before', showLastApplied ? arrToYamlString(revisionData[0]?.specs || []) : '');
    const afterEditor = () => renderEditor('After', showLastApplied ? arrToYamlString(revisionData[1]?.specs || []) : '');
    return (
      showLastApplied && (
        <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
          <SplitterComp left={beforeEditor()} right={afterEditor()} />
        </FullScreenEditor>
      )
    );
  };

  const alertData = (
    <Alert
      showIcon
      type="info"
      key="info"
      message={
        <>
          Revision: A snapshot of your application configuration, create a new revision anytime and apply it to test configurations or roll back to a
          previous one.
        </>
      }
      action={
        <Space direction="horizontal">
          {showLastApplied && <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />}
          <Button type="primary" onClick={() => setShowLastApplied(!showLastApplied)}>
            {showLastApplied ? 'View timeline' : 'View editor'}
          </Button>
        </Space>
      }
    />
  );

  const timelineData = () => {
    const newRevision = () => <NewRevisionForm applyLater={true} deployment={deployment} onNewRevision={() => specsRevisions.refetch()} />;

    const timelineContent = () => {
      const itemsData = [
        ...revisions.map(({ id, name, createdAt, description }: iSpecsRevision) => {
          const revisionStatus = () => (
            <Text>
              Revision {name} is created at: {moment(Number(createdAt)).format('DD:MM:YYYY ~ HH:mm:ss A')}
            </Text>
          );

          const revisionButton = () => {
            const popTitle = `Apply revision ${name || 'without name'}?`;
            const popDescription = description || 'No description';
            const handleOnConfirm = () => {
              setRevisionId(id);
              SpecsRevisionController_applyRevision({ variables: { applicationId: deployment.id, revisionId: id } });
            };
            return (
              <Popconfirm title={popTitle} description={popDescription} onConfirm={handleOnConfirm}>
                <Button disabled={revisionId === id} style={buttonBorder}>
                  {revisionId === id ? 'Applied' : 'Apply'}
                </Button>
              </Popconfirm>
            );
          };

          return {
            color: revisionId === id ? 'green' : 'grey',
            children: (
              <Flex gap="middle" justify="space-between">
                {revisionStatus()}
                {revisionButton()}
              </Flex>
            ),
          };
        }),
        { color: 'blue', children: newRevision() },
      ];
      return <Timeline mode="left" reverse={true} items={itemsData} />;
    };
    return (
      !showLastApplied && (
        <>
          <Text /> {revisions?.length ? timelineContent() : <Text> No revisions yet: {newRevision()} </Text>}
        </>
      )
    );
  };

  if (!revSpec) return <Skeleton active loading />;
  return (
    <Space direction="vertical" style={spaceWidth}>
      {alertData}
      {timelineData()}
      {comparisionData()}
    </Space>
  );
};
