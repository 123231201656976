export const form_schema_Service_ports = {
  schema: {
    type: 'array',
    description: `Service ports configuration`,
    items: {
      type: 'object',
      properties: {
        name: { type: 'string', title: 'Name', default: 'http' },
        port: { type: 'number', title: 'Port', default: 80 },
        targetPort: { type: 'number', title: 'Target port', default: 80 },
        protocol: { type: 'string', title: 'Protocol', enum: ['TCP', 'UDP'], default: 'TCP' },
      },
    },
  },
  ui: { 'ui:options': { orderable: false } },
};
