import { useContext, useEffect, useState } from 'react';
import { projectService, useProjectGetProjectService } from 'services/project.service';
import { deploymentService } from 'services/deployment.service';
import { YamlSpecCustomizationComponent } from 'components/Projects/YamlSpecCustomization/YamlSpecCustomization';
import { ProjectDeploymentContext } from 'components/Projects/ProjectDeploymentContext';
import { SpecsRenderResult } from 'shared/yaml';
import { TitleUIRow } from 'layout/TitleUI';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Button, Skeleton, notification } from 'antd';
import { buttonBorder } from 'utils/styles';
import { iDeployment, iProjectModel } from 'shared/deployment';
import { DeployButton } from 'components/Deployments/Setting/new-deployment/DeployPage';

export interface iProjectOneServiceSettingsPostProcessingProps {
  deployment: iDeployment;
  serviceName: string;
  tabType: string;
}

export const ProjectOneServiceSettingsPostProcessing = (props: iProjectOneServiceSettingsPostProcessingProps) => {
  const [specs, setSpecs] = useState<SpecsRenderResult>(null);
  const [vars, setVars] = useState({});
  const [currentRules, setRules] = useState(null);

  const projectId = props.deployment.projectId;
  const serviceName = props.serviceName;
  const service = useProjectGetProjectService(projectId, serviceName, [projectId, serviceName, props.tabType]);
  const deploymentContext = useContext(ProjectDeploymentContext);

  useEffect(() => {
    deploymentContext?.deployment?.id
      ? deploymentService.debugUI_serviceYAML(deploymentContext.deployment.id, serviceName).then(res => {
          setSpecs(res.data.specs);
          setVars(res.data.vars);
        })
      : null;
  }, [deploymentContext, serviceName]);

  if (service.loading) return <Skeleton active={true} />;

  const rules = currentRules !== null ? currentRules : service?.answer?.postProcessingRules;

  const handleOnConfirm = async (): Promise<boolean> => {
    const serviceObj = { tabType: props.tabType, name: props.serviceName, data: { postProcessingRules: rules } };
    const res = await projectService.setProjectServices(projectId, serviceObj);
    if (res.error) {
      notification.error({ message: `Error - ${res.error}` });
      return false;
    }

    notification.success({ message: `Ready` });
    return true;
  };

  return (
    <>
      <TitleUIRow title={`Post Processing configuration for ${serviceName}`} />
      <YamlSpecCustomizationComponent
        rules={rules}
        specs={specs}
        syncVars={vars}
        onChange={(rules: any[]) => {
          debugger;
          setRules(rules);
        }}
      />
      <BottomButtons>
        <Button type="primary" onClick={handleOnConfirm} style={buttonBorder}>
          Save
        </Button>

        <DeployButton deployment={props.deployment} beforeDeploy={handleOnConfirm} />
      </BottomButtons>
    </>
  );
};
