import { Avatar } from 'antd';
import { logoAvatar, shadowStyle } from 'utils/styles';
import { AppstoreAddOutlined, AppstoreOutlined, BulbOutlined, BellOutlined, DashboardOutlined } from '@ant-design/icons';

const iconComponents: Record<string, JSX.Element | null> = {
  AppstoreAddOutlined: <AppstoreAddOutlined />,
  AppstoreOutlined: <AppstoreOutlined />,
  BulbOutlined: <BulbOutlined />,
  BellOutlined: <BellOutlined />,
  DashboardOutlined: <DashboardOutlined />,
};

const AvatarComponent = ({ children }) => (
  <Avatar size={20} style={{ ...logoAvatar, ...shadowStyle }}>
    {children}
  </Avatar>
);

export const LogoAvatar = (props: { logo: string; name: string }) => {
  const name = props.name;
  const logo = props.logo;
  const primaryAvatar = () => <AvatarComponent> {name} </AvatarComponent>;
  const secondaryAvatar = () => <AvatarComponent> {iconComponents[logo]} </AvatarComponent>;
  const tertiaryAvatar = () => <Avatar crossOrigin="anonymous" size={15} src={logo} />;
  return !logo ? primaryAvatar() : logo.startsWith('http') ? tertiaryAvatar() : secondaryAvatar();
};
