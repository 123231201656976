import { apiQuery, iBaseAjaxAnswer, iUseDpApi, useDpApi } from '../utils/common';

export interface iApplyedObjectsModel {
  id: number;
  projectId: number;
  deploymentId: number;
  tenant: number;
  spec: any;
  answer: any;
  errordata: any;
  status: string;
  kind: string;
  scope: string;
  namespaceId: number;
  regionId: number;
  name: string;
  lastSyncTime: number;
  lastSyncDelay: number;
}

export const useObjectsForApply = (deploymentId: number, deps: any[] = []): iUseDpApi<iApplyedObjectsModel[]> => {
  return useDpApi(() => applyService.getObjectsForApply(deploymentId), deps);
};

class ApplyService {
  async getObjectsForApply(deploymentsId: number): Promise<iBaseAjaxAnswer<iApplyedObjectsModel[]>> {
    return apiQuery('GET', `/api/apply/specs/${deploymentsId}`);
  }
}

const applyService = new ApplyService();
export { applyService, ApplyService };
