import DashboardItem from './DashboardItem';
import { Col, Row } from 'antd';
import { ChartRenderer } from './ChartRenderer';

const ChartDashboard = ({ children }) => (
  <Row justify="space-around" align="top" gutter={24}>
    {children}
  </Row>
);

export const ChartDashboardMap = ({ stats }) => (
  <ChartDashboard>
    {stats.map((stat, index) => (
      <Col span={stat.span || 24} key={stat.id || index} style={{ marginBottom: '24px' }}>
        <DashboardItem title={stat.name}>
          <ChartRenderer vizState={stat.vizState} height={stat?.height} />
        </DashboardItem>
      </Col>
    ))}
  </ChartDashboard>
);

export default ChartDashboard;
