// @ts-ignore
const WebSocket = typeof window !== 'undefined' ? window.WebSocket : require('ws');

let wsApiSocket: any = null;
let wsApiSocket_connected = false;
let wsApiSocket_started = false;
let wsApiSocket_authStatus = null;

export interface WsApiAuthTokenData {
  type: string;
  id?: string | number;
  tenantId?: string | number;
  permissions?: string[];
}

type wsMessageType = 'connected' | 'auth' | 'event' | 'stats' | 'answer';
type wsMessagePayload = {
  status: 'success' | 'error';
  target?: any;
  type: wsMessageType;
  data: any;
  name?: string;
  queryId?: string;
  sender?: WsApiAuthTokenData;
};

export interface WsApiAuthData {
  token: string;
}

function getConnectionIdFromToken(tokenData: WsApiAuthTokenData): string {
  let id = `${tokenData.type}`;
  if (tokenData?.tenantId) {
    id += `-${tokenData?.tenantId}`;
  }
  if (tokenData?.id) {
    id += `-${tokenData?.id}`;
  }
  return id;
}

function wsSendMessage(payload: wsMessagePayload, reTry: number = 0): Promise<boolean | any> {
  if (wsApiSocket_connected) {
    return new Promise((resolve, reject) => {
      try {
        wsApiSocket.send(JSON.stringify(payload));
        resolve(true);
      } catch (e) {
        console.error(`WsApiClient: error(1) `, e);
        reject(e);
      }
    });
  }

  if (reTry > 20) {
    return Promise.reject(`Max retries reached (${reTry})`);
  }

  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        await wsSendMessage(payload, reTry + 1);
        resolve(true);
      } catch (e) {
        console.error(`WsApiClient: error(2)  `, e);
        reject(e);
      }
    }, (reTry + 1) * 500);
  });
}

const apiEventCallbacks: { [key: string]: { cb: (data: any, queryId: string) => Promise<any>; id: string }[] } = {};

const answerCallBacks = {};

export class WsApiClient {
  addEventCallback(name: string, callback: (data: any, queryId: string) => Promise<any>): string {
    if (!apiEventCallbacks[name]) {
      apiEventCallbacks[name] = [];
    }

    const uid = `uid${Math.random()}`.replace('.', '');
    apiEventCallbacks[name].push({
      cb: callback,
      id: uid,
    });

    return uid;
  }
  removeEventCallback(name: string, uid: string) {
    if (!apiEventCallbacks[name]) {
      return;
    }
    apiEventCallbacks[name] = apiEventCallbacks[name].filter(e => e.id !== uid);
  }

  async _callEventCallback(name: string, data: any, queryId: string, sender: WsApiAuthTokenData) {
    if (!apiEventCallbacks[name]) {
      return;
    }
    for (const e of apiEventCallbacks[name]) {
      try {
        const res = await e.cb(data, queryId);
        if (res !== undefined && res !== null) {
          await wsSendMessage({ status: `success`, type: 'answer', data: res, queryId: queryId, target: getConnectionIdFromToken(sender) });
        }
      } catch (e) {
        console.error(`WsApiClient:callEventCallback:error:`, e);
      }
    }
  }

  async auth(wsApiData: WsApiAuthData) {
    if (!wsApiSocket_authStatus) {
      try {
        return await wsSendMessage({ status: `success`, type: 'auth', data: { token: wsApiData.token } });
      } catch (e) {
        console.error(`WsApiClient:auth:error:`, e);
      }
    }
  }

  async stats() {
    try {
      return await wsSendMessage({ status: `success`, type: 'stats', data: {} });
    } catch (e) {
      console.error(`WsApiClient:auth:error:`, e);
    }
  }

  _onAnswer(queryId: string, data: any) {
    try {
      if (answerCallBacks[queryId]) {
        answerCallBacks[queryId](data);
        delete answerCallBacks[queryId];
      }
    } catch (e) {
      console.error(`WsApiClient:onAnswer:error:`, e);
    }
  }

  start(host: string, useWss: boolean = true) {
    if (wsApiSocket_started) {
      return;
    }
    wsApiSocket_started = true;

    const connectionURL = `${useWss ? 'wss' : 'ws'}://${host}/ws-api`;
    console.log(`WsApiClient: start ws-api connection to ${connectionURL}`);
    wsApiSocket = new WebSocket(connectionURL);
    wsApiSocket.onopen = e => {
      console.log('[ws-api] Connected');

      // socket.send('Hi');
      wsApiSocket_connected = true;
    };

    wsApiSocket.onmessage = async event => {
      console.log(`[ws-api::income-message]: ${event.data}`);
      try {
        const data: wsMessagePayload = JSON.parse(event.data);
        if (data.type === 'event') {
          await this._callEventCallback(data.name, data.data, data?.queryId, data?.sender);
        } else if (data.type === 'auth') {
          console.log(`[ws-api::income-message]: auth `, data?.data?.auth);
          wsApiSocket_authStatus = data?.data?.auth === 'accepted';
        } else if (data.type === 'answer') {
          console.log(`[ws-api::income-answer]: auth `, data?.data?.auth);
          await this._onAnswer(data.queryId, data.data);
        } else if (data.type === 'connected') {
          console.log(`[ws-api::income-message]: connected build `, data?.data?.build);
        } else {
          console.log(`[ws-api::income-message]: unknown message type`, data);
        }
      } catch (e) {
        console.error(`[ws-api::income-message]: error`, e);
      }
    };

    wsApiSocket.onclose = event => {
      wsApiSocket_connected = false;
      wsApiSocket_authStatus = null;
      if (event.wasClean) {
        console.log(`[ws-api::close] code=${event.code} reason=${event.reason}`);
      } else {
        console.warn(`[ws-api::close] with error code=${event.code}`);
      }
      setTimeout(() => {
        wsApiSocket_started = false;
        wsApiSocket_authStatus = null;
        this.start(host, useWss);
      }, 1000);
    };

    wsApiSocket.onerror = error => {
      console.log(`[ws-api::error]`, error?.message || error);
    };
  }

  /**
   * Send event to server
   * @param eventName
   * @param payload
   */
  async sendEvent(target: string, eventName: string, payload: any, answerCallBack?: (data: any) => void) {
    let qId = null;

    if (answerCallBack) {
      qId = `qId${Math.random()}`.replace('.', '');
      answerCallBacks[qId] = answerCallBack;
    }

    await wsSendMessage({ status: `success`, target: target, type: 'event', data: payload, name: eventName, queryId: qId });
  }

  /**
   * Send event to server
   * @param eventName
   * @param payload
   *
   * @example:
   *  const serverWs = new WsServerApi();
   *  serverWs.serverConnect(`backend`);
   *  const answer = await serverWs.client().remoteApiCall(`backend`, 'Test_status', { me: '??' });
   */
  remoteApiCall(target: string, eventName: string, payload: any, timeout: number = 50000): Promise<any> {
    let qId = `qId${Math.random()}`.replace('.', '');
    return new Promise(async (resolve, reject) => {
      await this.sendEvent(target, eventName, payload, resolve);
      if (timeout > 0) {
        setTimeout(() => {
          delete answerCallBacks[qId];
          reject(`Timeout reached (${timeout})`);
        }, timeout);
      }
    });
  }
}
