import { Alert, Space } from 'antd';
import { CommonFormUI, CommonUI_JsonFormModalUI, CommonUI_select, CommonUI_text } from './CommonFormUI';
import { useContext } from 'react';
import { ProjectDeploymentSyncVarsContext } from '../ProjectDeploymentContext';

export interface iSecretsMainOptionsUIProps {
  yamlObj: any;
  onChange: (key: string, newObj: any, action?: string) => void;
  lockName?: boolean;
}

export const form_schema_Labels = {
  schema: {
    type: 'object',
    additionalProperties: {
      type: 'string',
    },
  },
  ui: {},
};

export const SecretsMainOptionsUI = (props: iSecretsMainOptionsUIProps) => {
  let volumeHelpUI = null;
  const syncVarsContext = useContext(ProjectDeploymentSyncVarsContext);

  const form = [
    props.lockName !== true
      ? {
          name: 'Name',
          children: <CommonUI_text value={props.yamlObj?.metadata?.name} onChange={props.onChange} path={`.metadata`} propertyName={`name`} />,
        }
      : null,

    {
      name: 'Metadata labels',
      children: (
        <CommonUI_JsonFormModalUI
          formData={props.yamlObj?.metadata?.labels || {}}
          formConfigs={form_schema_Labels}
          SyncVarsContext={syncVarsContext}
          title={`Metadata labels`}
          onChange={(v: any) => {
            props.onChange(`.metadata.labels`, { ...v });
          }}
        />
      ),
    },
    {
      name: 'Type',
      children: (
        <CommonUI_select
          value={props.yamlObj?.type}
          onChange={(v: any) => {
            props.onChange(`.metadata.labels`, { ...v });
          }}
          path={`.`}
          propertyName={`type`}
          options={[
            {
              label: 'Opaque',
              value: 'Opaque',
            },
            // {
            //   label: 'Docker-registry',
            //   value: 'kubernetes.io/dockerconfigjson',
            // },
            // {
            //   label: 'Service-account-token',
            //   value: 'kubernetes.io/service-account-token',
            // },
            // {
            //   label: 'TLS',
            //   value: 'kubernetes.io/tls',
            // },
          ]}
        />
      ),
    },
    {
      name: 'String data',
      children: (
        <CommonUI_JsonFormModalUI
          formData={props.yamlObj?.stringData || {}}
          formConfigs={form_schema_Labels}
          SyncVarsContext={syncVarsContext}
          title={`String data`}
          onChange={(v: any) => {
            props.onChange(`.stringData`, { ...v });
          }}
        />
      ),
    },
  ].filter(Boolean);

  volumeHelpUI = (
    <Alert
      type="info"
      description={<>A Secret is an object that contains a small amount of sensitive data such as a password, a token, or a key. </>}
      action={
        <a href={'https://kubernetes.io/docs/concepts/configuration/secret/'} target="_blank">
          Learn more
        </a>
      }
    />
  );

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <CommonFormUI form={form} />
      {volumeHelpUI}
    </Space>
  );
};
