import { ApolloClient, InMemoryCache } from '@apollo/client';
import { apiQuery } from '../utils/common';
import cubejs from '@cubejs-client/core';
import { WsApiClient } from 'shared/WsApi.client';
import { iMyUserData } from 'shared/user';

let selfInfo: any = undefined;
let selfInfo_data: iMyUserData | null = undefined;
let tenantInfo: any = undefined;
let tenantPermissionsInfo: any = undefined;

let cubejsClient: any = undefined;
let apolloClient: any = undefined;

let userThemeToken_callback: any = undefined;
const wsApi = new WsApiClient();
const WsApiServerHost = window.location.host === `localhost:3000` ? `localhost.nanoheal.work` : window.location.host;

class AuthService {
  /**
   * @param func Callback to set user theme config to Context
   */
  setThemeTokenCallback(func: any) {
    // Callback to set user theme config to Context
    userThemeToken_callback = func;
  }

  async apolloQuery(params: any) {
    return await this.getApolloClient().query(params);
  }

  getApolloClient() {
    apolloClient = new ApolloClient({
      cache: new InMemoryCache({
        addTypename: false,
      }),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      },
      uri: `${window.location.origin}/api/graphql`,
    });
    return apolloClient;
  }

  async getTenantInfo() {
    if (tenantInfo) {
      return tenantInfo;
    }
    return new Promise(async (resolve, reject) => {
      try {
        await this.getSelfInfo();
        resolve(tenantInfo);
      } catch (error) {
        console.error(error);
        resolve({ errorText: `${error}`, error: 401, status: 401 });
      }
    });
  }

  async getTenantPermissionsInfo() {
    if (tenantPermissionsInfo) {
      return tenantPermissionsInfo;
    }
    return new Promise(async (resolve, reject) => {
      try {
        await this.getSelfInfo();
        resolve(tenantPermissionsInfo);
      } catch (error) {
        console.error(error);
        resolve({ errorText: `${error}`, error: 401, status: 401 });
      }
    });
  }

  getSelfInfoNow(): iMyUserData | null {
    return selfInfo_data || null;
  }

  async getSelfInfo() {
    if (selfInfo) {
      return selfInfo;
    }

    selfInfo = new Promise(async (resolve, reject) => {
      try {
        const res = await fetch(`/api/auth/me`, {
          redirect: 'follow',
          credentials: 'include',
        });
        setTimeout(() => {
          selfInfo = false;
        }, 5000);

        const answer = await res.json();
        const result = {
          data: answer.user,
          status: res.status,
          error: !(res.status >= 200 && res.status < 300),
          cubejsClient,
        };

        tenantInfo = answer?.tenant;
        tenantPermissionsInfo = answer?.permissions;

        if (answer?.cube?.token) {
          cubejsClient = cubejs(answer.cube.token, {
            apiUrl: answer.cube.url,
          });
        }
        if (answer?.user?.antThemeToken) {
          if (userThemeToken_callback) {
            // Set user theme config to Context
            userThemeToken_callback({ antThemeToken: answer.user.antThemeToken, antThemeName: answer.user.antThemeName });
          }
        }
        result.cubejsClient = cubejsClient;

        if (answer?.wsApiAuth?.token) {
          try {
            wsApi.start(WsApiServerHost);
            wsApi.auth(answer?.wsApiAuth);
          } catch (e) {
            console.error(`wsApi:error`, e);
          }
        }
        selfInfo_data = {
          allowApi: result?.data.allowApi,
          antThemeName: result?.data.antThemeName,
          antThemeToken: result?.data.antThemeToken,
          avatar: result?.data.avatar,
          email: result?.data.email,
          githubLogin: result?.data.githubLogin,
          id: result?.data.id,
          name: result?.data.name,
          roles: result?.data.roles,
          tenant: result?.data.tenant,
          isConfirmed: true,
          createdAt: null,
          updatedAt: null,
        };
        resolve(result);
      } catch (error) {
        console.error(error);
        selfInfo_data = null;
        resolve({ errorText: `${error}`, error: 401, status: 401 });
      }
    });

    return selfInfo;
  }

  async checkAuth() {
    // @ts-ignore
    if (window.notAuth) {
      return false;
    }
    try {
      const res = await fetch(`/api/auth/me`, {
        redirect: 'follow',
        credentials: 'include',
      });

      return res.status >= 200 && res.status < 300;
    } catch (error) {
      return false;
    }
  }

  async login({ email, password }: any) {
    const res = await fetch(`/api/auth/login`, {
      redirect: 'follow',
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email?.toLowerCase().replace(/[ ]/i, ''), password }),
    });

    const loginData = await res.json();

    if (loginData?.tours) {
      window.localStorage.setItem(`tours`, JSON.stringify({ tours: loginData?.tours }));
    }

    return {
      data: loginData,
      status: res.status,
      error: !(res.status >= 200 && res.status < 300),
    };
  }

  logout() {
    window.sessionStorage.removeItem('githubAccessToken');
    return apiQuery('POST', `/api/auth/logout`, {});
  }

  authTypeInfo() {
    return apiQuery('GET', `/api/auth/authTypeInfo`);
  }

  tenants(email: any) {
    return apiQuery('POST', `/api/auth/tenants`, { email: email?.toLowerCase().replace(/[ ]/i, '') });
  }

  async requestAccess(data: { email: string; organization: string; firstName: string; lastName: string; cloudURL: string }) {
    const res = await fetch(`/api/auth/signup`, {
      redirect: 'follow',
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: data.email?.toLowerCase().replace(/[ ]/i, ''),
        firstName: data.firstName,
        lastName: data.lastName,
        organization: data.organization,
        cloudURL: data.cloudURL,
      }),
    });

    return {
      data: await res.json(),
      status: res.status,
      error: !(res.status >= 200 && res.status < 300),
    };
  }

  async resetPassword({ email }: any) {
    const res = await fetch(`/api/auth/reset-password`, {
      redirect: 'follow',
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email?.toLowerCase().replace(/[ ]/i, '') }),
    });

    return {
      data: await res.json(),
      status: res.status,
      error: !(res.status >= 200 && res.status < 300),
    };
  }

  async signup({ email, password }: any) {
    const res = await fetch(`/api/auth/signup`, {
      redirect: 'follow',
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email?.toLowerCase().replace(/[ ]/i, ''), password }),
    });

    return {
      data: await res.json(),
      status: res.status,
      error: !(res.status >= 200 && res.status < 300),
    };
  }

  async setNewPassword(newPassword: string, oldPassword: string) {
    return apiQuery('POST', `/api/auth/new-password`, { newPassword, oldPassword });
  }

  async createPassword(password: string) {
    return apiQuery('POST', `/api/auth/password-create-finish`, { password });
  }
}

const authService = new AuthService();
export { authService, AuthService };
