import './Authentication.scss';
import { useState } from 'react';
import { authService } from '../../services/auth.service';
import { handleInputChange } from '../../utils/common';
import { Typography, Button, Input, notification, Space } from 'antd';
import { buttonBorder, formButton } from 'utils/styles';
import { capitalLetter } from 'utils/nameFormat';
import { AuthenticationForm, ImageB, LoginHeading, SignupLink, TextLogin } from 'components/SharedComponents/AuthComp/AuthComp';

const { Text } = Typography;

const FindTenantForm = () => {
  const lasetUsedEmail = window.localStorage.getItem('LastTenant_email') || undefined;
  const [email, setEmail] = useState('');
  const [lastEmail, setLastEmail] = useState(lasetUsedEmail);
  const [tenants, setTenants] = useState([]);

  const onTenantsRequest = async email => {
    debugger;
    const { error, status, data } = await authService.tenants(email);
    return error
      ? notification.error({ key: 'Login', message: `status ${status}`, description: data.message })
      : // Set the email in local storage to remember the last used email
        (window.localStorage.setItem('LastTenant_email', email),
        setLastEmail(email),
        data.length === 1
          ? window.location.replace(`https://${data[0]}.${window.location.host}/#/login/email/${encodeURIComponent(email)}`)
          : data.length === 0
          ? // @ts-ignore
            notification.warn({ key: 'Login', description: 'Your email not found. Do you want sign up?' })
          : null,
        setTenants(data));
  };

  const openTenantButton = () => {
    const emailButtonFind = () => (
      <Button type="primary" disabled={lastEmail === email} onClick={() => onTenantsRequest(email)} style={formButton}>
        Login
      </Button>
    );
    return lastEmail !== email || tenants.length === 0
      ? emailButtonFind()
      : tenants.map(tenant => {
          const handleOpenTenant = () =>
            window.location.replace(`https://${tenant}.${window.location.host.replace('auth.', '')}/#/login/email/${encodeURIComponent(email)}`);
          return (
            <Button key={tenant} type="primary" onClick={handleOpenTenant} style={buttonBorder}>
              Open Tenant {capitalLetter(tenant)}
            </Button>
          );
        });
  };

  return (
    <form onSubmit={() => onTenantsRequest({ email })}>
      <Space direction="vertical">
        <Text strong> Email </Text>
        <Input placeholder="Enter email here" onChange={handleInputChange(setEmail)} value={email} />
        {openTenantButton()}
      </Space>
    </form>
  );
};

export default function FindTenantPage() {
  return (
    <>
      <ImageB />
      <SignupLink />
      <AuthenticationForm>
        <LoginHeading />
        <FindTenantForm />
      </AuthenticationForm>
      <TextLogin />
    </>
  );
}
