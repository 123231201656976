import gql from 'graphql-tag';
import Meta from 'antd/es/card/Meta';
import { useState } from 'react';
import { iRegionModel } from 'shared/deployment';
import { useUser } from 'utils/common';
import { useHistory } from 'react-router-dom';
import { LogoAvatar } from 'components/SharedComponents/LogoAvatar/LogoAvatar';
import { iMyUserData } from 'shared/user';
import { useAuthedQuery } from 'utils/qlAuth';
import { Col, Skeleton, Card, Tag, Button, Flex, Input, Space, Row, Result, Typography } from 'antd';
import { UnorderedListOutlined, AppstoreOutlined, CaretLeftFilled } from '@ant-design/icons';
import { integrationCardHeight, spaceWidth, topMargin } from 'utils/styles';
import { getDescription } from 'components/Projects/Projects';
import { TipTop } from 'components/SharedComponents/Tooltip/Tooltip';

export interface iClusterIntegrationsProps {
  region: iRegionModel;
}

const { Text } = Typography;
const { Search } = Input;

export default function ClusterIntegrations(props: iClusterIntegrationsProps) {
  const [isCompactView, setIsCompactView] = useState(false);
  const [activeButton, setActiveButton] = useState<'list' | 'card'>('list');
  const [searchQuery, setSearchQuery] = useState('');

  const user: iMyUserData = useUser();
  const history = useHistory();

  const { loading, data } = useAuthedQuery(
    gql`
      query PluginsController_getPluginsForCuster($regionId: Int!) {
        PluginsController_getPluginsForCuster(regionId: $regionId) {
          id
          name
          title
          description
          logo
          tags
          fullDescription
          preconfiguredProjectIds
          preconfiguredProjectUUID
          installTypeDeployment
          installTypeManual
        }
      }
    `,
    { variables: { regionId: Number(props.region.id) } },
  );

  const region = props.region;

  if (loading || !user || !region || !data) return <Skeleton active={true} loading={true} />;

  const filteredData = data.PluginsController_getPluginsForCuster.filter((elem: { title: string }) =>
    elem.title.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const toggleView = (view: 'list' | 'card') => {
    setIsCompactView(view === 'card');
    setActiveButton(view);
  };

  const renderCards = () => {
    if (filteredData.length === 0) {
      const noResults = (
        <Button type="primary" onClick={() => setSearchQuery('')} icon={<CaretLeftFilled />}>
          Back to Cluster Integrations
        </Button>
      );
      const noResultsSubTitle = 'This Cluster Integration does not exist... Please search for appropriate Cluster Integration';
      return (
        <Col span={24}>
          <Result status="404" subTitle={noResultsSubTitle} extra={noResults} style={topMargin} />
        </Col>
      );
    }

    const cardExtra = (elem: { tags: string[] }) =>
      elem.tags && (
        <Flex justify={'flex-end'}>
          {elem.tags.map((tag: string, index: number) => (
            <Tag color="green" key={index}>
              {tag}
            </Tag>
          ))}
        </Flex>
      );

    const clustersData = () =>
      filteredData.map(elem => {
        const handleOnClickPush = () => history.push(`/clusters/${region.id}/integrations/${elem.id}`);
        return (
          <Col span={isCompactView ? 8 : 24} key={elem.id}>
            <Space direction="vertical" style={spaceWidth}>
              <Card
                size="small"
                bordered={false}
                title={elem.title}
                key={elem.id}
                onClick={handleOnClickPush}
                extra={cardExtra(elem)}
                style={isCompactView ? integrationCardHeight : ''}
              >
                <Space direction="horizontal">
                  <Meta avatar={elem.logo && <LogoAvatar logo={elem.logo} name={elem.title} />} />
                  {getDescription(elem.description, isCompactView)}
                </Space>
              </Card>
            </Space>
          </Col>
        );
      });

    return clustersData();
  };

  const searchViews = () => (
    <Row gutter={[16, 16]}>
      <Col span={10}>
        <Search placeholder="Search" enterButton allowClear onChange={e => setSearchQuery(e.target.value)} />
      </Col>
      <Col span={14}>
        <Flex justify={'flex-end'}>
          <Space direction="horizontal">
            <TipTop tip="List View">
              <Button icon={<UnorderedListOutlined />} onClick={() => toggleView('list')} type={activeButton === 'list' ? 'primary' : 'default'} />
            </TipTop>
            <TipTop tip="Grid View">
              <Button icon={<AppstoreOutlined />} onClick={() => toggleView('card')} type={activeButton === 'card' ? 'primary' : 'default'} />
            </TipTop>
          </Space>
        </Flex>
      </Col>
    </Row>
  );

  const clusterIntegrationData = () => (
    <Space direction="vertical" style={spaceWidth}>
      {searchViews()}
      <Row gutter={[16, 16]}> {renderCards()} </Row>
      <Text />
    </Space>
  );

  return clusterIntegrationData();
}
