import gql from 'graphql-tag';
import { ReactElement, ReactNode, ReactPortal, SetStateAction, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthedMutationWithNotification, useAuthedQuery } from 'utils/qlAuth';
import { LogoAvatar } from 'components/SharedComponents/LogoAvatar/LogoAvatar';
import { TitleUIRow } from 'layout/TitleUI';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { UserNoPermissions } from 'components/Permissions/Permissions';
import { NewProjectForm } from 'components/Projects/NewProjectForm';
import { DeploymentStep1 } from 'components/SharedComponents/CreateSteps/CreateSteps';
import { authService } from 'services/auth.service';
import { Row, Col, Typography, Button, Select, Modal, Skeleton, Tag, Card, Alert, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { buttonWidth, spaceWidth, templateMargin } from 'utils/styles';
import { TipLeft, TipLeftTop } from 'components/SharedComponents/Tooltip/Tooltip';

interface iExistingContent {
  id: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
  name: any;
  title: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
}

const { Text } = Typography;
const { Option } = Select;
const { Meta } = Card;

/**
 * Create application from (/new route)
 * @returns
 */

export default function DeploymentKeyDropDown() {
  const params: any = useParams();
  const preSelectedProject = Number(params?.projectId) || null;

  const [activeProject, setProject] = useState(preSelectedProject || '');
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const history = useHistory();
  const user = useUser();

  const { loading, error, data } = useAuthedQuery(
    gql`
      query ProjectController_getProjectList {
        ProjectController_getProjectList {
          id
          name
          title
          description
          logo
          tags
        }
        PreconfiguredProjectsController_getPreconfiguredProjects {
          id
          name
          title
          description
          logo
          tags
        }
        TagsController_tagsList {
          id
          name
          color
        }
      }
    `,
    {},
  );

  const [createDeployment, newDeployment] = useAuthedMutationWithNotification(gql`
    mutation DeploymentsController_createDeployment($projectId: Int!, $name: String!, $dpDeployFlow: String!, $tags: [Int]) {
      DeploymentsController_createDeployment(projectId: $projectId, dpDeployFlow: $dpDeployFlow, deploymentName: $name, deploymentTags: $tags) {
        id
      }
    }
  `);

  if (error) {
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }

  if (loading) return <Skeleton active={true} loading={true} />;

  if (!userHasPermission(user, UserPermissions.DeploymentCreate)) return <UserNoPermissions permission={UserPermissions.DeploymentCreate} />;

  const handleProjectSelect = (e: SetStateAction<string | number>) => setProject(e);

  const handleInputChange = (inputValue: string) => {
    const filtered = (data?.ProjectController_getProjectList || []).filter((project: { title: string }) =>
      project.title.toLowerCase().includes(inputValue.toLowerCase()),
    );
    setFilteredProjects(filtered);
  };

  const onCreateDeployment = async () => {
    const projectId = data?.ProjectController_getProjectList.find((project: { title: string | number }) => project.title === activeProject)?.id;
    return activeProject || projectId
      ? await createDeployment({
          variables: { projectId, name: `New application proj-${activeProject}`, tags: selectedTags, dpDeployFlow: `from-copy` },
        })
      : undefined;
  };

  const newDeploymentId = newDeployment?.data?.DeploymentsController_createDeployment?.id;
  if (newDeploymentId) {
    history.push(`/app/${newDeploymentId}/configuration/settings/general`);
    return <Skeleton active={true} loading={true} />;
  }

  const tags = data?.TagsController_tagsList || [];

  const projectsFilter = () => {
    return (filteredProjects.length > 0 ? filteredProjects : data?.ProjectController_getProjectList || []).map(elem => (
      <Option key={elem.id} value={elem.title}>
        <Space>
          {elem.logo && <LogoAvatar logo={elem.logo} name={elem.title} />}
          {elem.title}
          <Text italic style={{ color: '#CCCCCC' }}>
            {elem.description}
          </Text>
          {elem.tags &&
            elem.tags.map(tag => (
              <Tag color="green" key={tag}>
                {tag}
              </Tag>
            ))}
        </Space>
      </Option>
    ));
  };

  const createNewProject = () => {
    const createButton = (
      <TipLeft tip="You can create your own first project here.">
        <Button size="large" type="primary">
          Create a New Project
        </Button>
      </TipLeft>
    );
    return <NewProjectForm openBtnUI={createButton} />;
  };

  const nullAlert = () => (
    <>
      {userHasPermission(user, UserPermissions.ProjectsMange) ? (
        <Alert
          type="info"
          message={`You don't have any own projects yet. You can create one or use one of Pre-configured projects.`}
          action={createNewProject()}
        />
      ) : null}
    </>
  );

  const chooseProjects = () => (
    <Row>
      <Col span={12}>
        <Text strong> Choose template </Text>
      </Col>
      <Col span={12}>
        <Select
          showSearch
          placeholder="Choose template here"
          style={spaceWidth}
          onChange={handleProjectSelect}
          onSearch={handleInputChange}
          filterOption={false}
        >
          {projectsFilter()}
        </Select>
      </Col>
    </Row>
  );

  const chooseTags = () => (
    <>
      {tags.length !== 0 ? (
        <Row>
          <Col span={12}>
            <Text strong> Tags </Text>
          </Col>
          <Col span={12}>
            <Select placeholder="Choose Tags Here" mode="multiple" onChange={v => setSelectedTags(v)} style={spaceWidth}>
              {tags.map(elem => (
                <Option value={elem.id} key={elem.id}>
                  <Tag color={elem.color}> {elem.name} </Tag>
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      ) : null}
    </>
  );

  const dropdownData = () => (
    <>
      {chooseProjects()}
      {chooseTags()}
    </>
  );

  const continueButton = () => (
    <>
      {data.ProjectController_getProjectList.length !== 0 ? (
        <Row>
          <Col span={24}>
            <Button type="primary" onClick={onCreateDeployment} disabled={!activeProject} style={buttonWidth}>
              Continue
            </Button>
          </Col>
        </Row>
      ) : null}
    </>
  );

  const createDeploymentStep1 = () => (
    <Space direction="vertical" style={spaceWidth}>
      <TitleUIRow title="Create a new application based on template" />
      <DeploymentStep1 />
      {data.ProjectController_getProjectList.length === 0 ? nullAlert() : dropdownData()}
      <Text />
      {continueButton()}
    </Space>
  );

  return createDeploymentStep1();
}
