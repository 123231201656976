import { Space, Typography } from 'antd';
import { spaceWidth } from 'utils/styles';

const { Text } = Typography;

export const NullSpaces = () => (
  <Space direction="vertical" style={spaceWidth} data-qa="NullSpaces-element">
    <Text /> <Text /> <Text /> <Text /> <Text /> <Text /> <Text /> <Text /> <Text /> <Text />
  </Space>
);
