import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Modal, Checkbox, List, Typography, Space, message } from 'antd';
import { ResourcesStates, Pod } from '../../Deployments/ResourcesStates/ResourcesStates';
import { redColor, spaceWidth } from 'utils/styles';
import { DeleteFilled } from '@ant-design/icons';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';

interface ResourcesCardProps {
  deploymentId: number;
}

const { Text } = Typography;
const { Item } = List;

export const ResourcesCard: React.FC<ResourcesCardProps> = ({ deploymentId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPods, setSelectedPods] = useState<string[]>([]);
  const [pods, setPods] = useState<Pod[]>([]);
  const [messageApi, contextHolder] = message.useMessage();

  const showModal = () => setIsModalVisible(true);

  const commonModal = () => {
    setIsModalVisible(false);
    setSelectedPods([]);
  };

  const handleOk = () => {
    commonModal();
    const podsToDelete = selectedPods.length;
    messageApi[podsToDelete === 0 ? 'warning' : 'success'](
      podsToDelete === 0 ? 'No pods selected for deletion.' : `${podsToDelete} pod${podsToDelete > 1 ? 's' : ''} deleted successfully!`,
    );
    // Call the delete pods mutation here. ( DeploymentsController_deletePod )
    // data is the response from the delete pods mutation.
    // If the response is successful and error, show the success and error messages respectively.
  };

  const handleCancel = () => commonModal;

  const handleSelectPod = (podId: string) => {
    setSelectedPods(prevSelectedPods =>
      prevSelectedPods.includes(podId) ? prevSelectedPods.filter(id => id !== podId) : [...prevSelectedPods, podId],
    );
  };

  const deleteResources = (containers: any[]) => {
    return containers.map(container => {
      const { requests = {}, limits = {} } = container.resources || {};
      const { cpu: cpuR, memory: memoryR } = requests;
      const { cpu: cpuL, memory: memoryL } = limits;
      const { name } = container;
      return (
        <Space direction="vertical" key={name}>
          <Text>
            {name}: {cpuR || 'N/A'} CPU / {memoryR || 'N/A'} Memory
          </Text>
          <Text> {cpuL || memoryL ? ` (Max: ${cpuL || 'N/A'} CPU / ${memoryL || 'N/A'} Memory)` : ''} </Text>
        </Space>
      );
    });
  };

  const deleteItems = ({ id, name, containers }: Pod) => (
    <Item key={id}>
      <Checkbox checked={selectedPods.includes(id)} onChange={() => handleSelectPod(id)}>
        <Space direction="vertical">
          {name}
          {containers.length > 0 ? deleteResources(containers) : <Text type="secondary"> No containers defined. </Text>}
        </Space>
      </Checkbox>
    </Item>
  );

  const deleteButton = (
    <TipLeft tip="Click here to delete the pods">
      <DeleteFilled style={redColor} onClick={showModal} />
    </TipLeft>
  );

  const resourcesData = () => (
    <Space direction="vertical" style={spaceWidth}>
      <ResourcesStates deploymentId={deploymentId} onPodsFetched={setPods} />
      <Text />
      <Text strong>
        <Link to={`/app/${deploymentId}/logs/pods`}> View cloud monitoring dashboard </Link>
      </Text>
    </Space>
  );

  const resourcesModal = () => (
    <Modal title="Select Pods to Delete" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText="Delete" cancelText="Cancel">
      <Space direction="vertical" style={spaceWidth}>
        <Text strong> Are you sure you want to delete them? </Text>
        <Text> These pods will be deleted forever in this application. </Text>
        <List dataSource={pods} renderItem={deleteItems} />
      </Space>
    </Modal>
  );

  return (
    <>
      {/*<Card title="Resources" bordered={false} extra={deleteButton}>*/}
      <Card title="Resources" bordered={false}>
        {resourcesData()}
        {/*{resourcesModal()}*/}
      </Card>
      {contextHolder}
    </>
  );
};
