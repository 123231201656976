import Editor from '@monaco-editor/react';
import { useState } from 'react';
import { deploymentService } from '../../../../services/deployment.service';
import { useApiQuery } from '../../../../utils/common';
import { Alert, Result, Skeleton, Space, Typography } from 'antd';
import { bottomMargin, spaceWidth } from 'utils/styles';
import { FullScreenButton, FullScreenEditor } from 'components/SharedComponents/FullScreenView/FullScreenView';

const { Text } = Typography;

export const DeploymentSyncVars = (props: { deployment: { id: any }; serviseNames?: string[] }) => {
  const [syncVars, error, loading] = useApiQuery(() => deploymentService.debugUI_deploymentSyncVars(Number(props.deployment.id)));
  const [isFullscreen, setIsFullscreen] = useState(false);

  if (loading || !syncVars) return <Skeleton active={true} loading={true} />;
  if (error) return <Alert showIcon type="error" message={`Can not load data: ${error}`} style={bottomMargin} />;
  if (syncVars.error) return <Result status="404" title="Not ready" subTitle={syncVars.error} style={bottomMargin} />;
  return (
    <Space direction="vertical" style={spaceWidth}>
      <Alert type="info" showIcon message={`Here you can see variables available in template.`} />
      <Text /> <Text /> <Text /> <Text />
      {Object.keys(syncVars.services)
        .filter(service => !(!props?.serviseNames || props.serviseNames.includes(service)))
        .map(service => (
          <>
            <Space direction="horizontal"> Service: {service} </Space>
            <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
            <Text />
            <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
              <Editor
                width={`100%`}
                theme={`GitHub`}
                language={`json`}
                height={isFullscreen ? '100vh' : 'calc(100vh - 400px)'}
                value={JSON.stringify(syncVars.services[service], null, 2)}
              />
            </FullScreenEditor>
          </>
        ))}
    </Space>
  );
};
