import { Link, useParams } from 'react-router-dom';
import { Button, Typography, Space } from 'antd';
import { formInput, formButton } from 'utils/styles';
import { AuthenticationForm, ImageC, LoginLink, TextLogin } from 'components/SharedComponents/AuthComp/AuthComp';

const { Title, Text } = Typography;

export default function ErrorPage() {
  // @ts-ignore
  const { message } = useParams();

  return (
    <>
      <ImageC />
      <LoginLink />
      <AuthenticationForm>
        <Title level={2}> Error </Title>
        <Space direction="vertical" style={formInput}>
          <Text strong> {message} </Text>
          <Space direction="horizontal">
            <Link to="/">
              <Button style={formButton} type="primary" data-cy="main-page-link">
                Go to Main page
              </Button>
            </Link>
          </Space>
        </Space>
      </AuthenticationForm>
      <TextLogin />
    </>
  );
}
