import gql from 'graphql-tag';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthedMutationWithNotification, useAuthedQuery } from 'utils/qlAuth';
import { LogoAvatar } from 'components/SharedComponents/LogoAvatar/LogoAvatar';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { getUrlHashParams, useUser } from 'utils/common';
import { UserNoPermissions } from 'components/Permissions/Permissions';
import { authService } from 'services/auth.service';
import { InfoCircleOutlined } from '@ant-design/icons';
import { absolutely, spaceWidth, templateMargin } from 'utils/styles';
import { iProjectModel } from 'shared/deployment';
import { TipLeftTop } from 'components/SharedComponents/Tooltip/Tooltip';
import { Row, Col, Typography, Button, Modal, Skeleton, Tag, Card, Space, Menu, Result } from 'antd';
import type { MenuProps } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

interface iExistingContent {
  name: any;
  id: string;
  title: string;
}

interface iTemplateSelectionList {
  preSelectedProjectId?: number;
  onSelect: any;
  selectBtnName: string;
  selectBtnTooltip: string;
  category: string;
}

interface iTemplateDataElem {
  tags: any[];
  id: any;
  title: any;
  logo: string;
  description: string;
}

const { Text } = Typography;

function getExtraConfings() {
  let conf = getUrlHashParams();
  if (conf) {
    try {
      conf = JSON.parse(decodeURIComponent(conf.conf));
    } catch (e) {
      console.log(`TemplateSelectionList error:`, e);
      return {};
    }
  }
  console.log(`conf`, conf);
  return conf;
}

export function TemplateSelectionList(props: iTemplateSelectionList) {
  const { selectBtnTooltip, onSelect, selectBtnName } = props;
  const [selectedMenuKey, setSelectedMenuKey] = useState(props.category);

  const history = useHistory();
  const user = useUser();

  const { loading, error, data } = useAuthedQuery(
    gql`
      query ProjectController_getProjectList {
        PreconfiguredProjectsController_getPreconfiguredProjects {
          id
          name
          title
          description
          logo
          tags
        }
        ProjectController_getProjectList {
          id
          name
          title
          description
          logo
          tags
        }
      }
    `,
    {},
  );
  if (!userHasPermission(user, UserPermissions.DeploymentCreate)) {
    return <UserNoPermissions permission={UserPermissions.DeploymentCreate} />;
  }
  if (error) {
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }
  if (loading) {
    return <Skeleton active={true} loading={true} />;
  }

  let projectList = [];
  if (selectedMenuKey == 'own') {
    projectList = data?.ProjectController_getProjectList;
  } else {
    projectList = data?.PreconfiguredProjectsController_getPreconfiguredProjects;
  }

  const templateData = (
    <Space direction="vertical" style={spaceWidth} data-qa="DeploymentFromTemplate.tsx">
      <Row gutter={[16, 16]}>
        {/*{data?.PreconfiguredProjectsController_getPreconfiguredProjects.filter(p => {*/}
        {projectList.filter(p => {
          if (selectedMenuKey == 'all' || selectedMenuKey == 'own') {
            return true;
          }
          if (p.tags) {
            return p.tags.includes(selectedMenuKey);
          }
          return false;
        }).length > 0 ? (
          projectList
            .filter(p => {
              if (selectedMenuKey == 'all' || selectedMenuKey == 'own') {
                return true;
              }
              if (p.tags) {
                return p.tags.includes(selectedMenuKey);
              }
              return false;
            })
            .map((elem: iTemplateDataElem) => {
              const deployButton = (
                <TipLeftTop tip={selectBtnTooltip}>
                  <Button type="primary" data-qa="PreconfiguredProjects-Deploy-btn" onClick={() => onSelect(elem)}>
                    {selectBtnName}
                  </Button>
                </TipLeftTop>
              );

              const deployTags = (
                <Space direction="horizontal">
                  {elem.tags &&
                    elem.tags.map(tag => (
                      <Tag color="green" key={tag}>
                        {tag}
                      </Tag>
                    ))}
                </Space>
              );

              return (
                <Col key={elem.id} xs={24} sm={12} md={8}>
                  <Card size="small" type="inner" bordered={false} title={elem.title} extra={deployButton} style={templateMargin}>
                    <Space direction="vertical">
                      <Space direction="horizontal">
                        <LogoAvatar logo={elem.logo} name={elem.title} />
                        <Text type="secondary">{elem.description}</Text>
                      </Space>
                      <Text /> <Text /> <Text />
                      <Space direction="horizontal">
                        <Space style={absolutely}>{deployTags}</Space>
                      </Space>
                    </Space>
                  </Card>
                </Col>
              );
            })
        ) : (
          <Result style={{ margin: 'auto' }} title="There are no projects in this category" />
        )}
      </Row>
    </Space>
  );

  const items: MenuItem[] = [
    { key: 'own', icon: <InfoCircleOutlined />, label: 'Own' },
    { key: 'all', icon: <InfoCircleOutlined />, label: 'All' },
    { key: 'personal', icon: <InfoCircleOutlined />, label: 'Personal' },
    { key: 'management', icon: <InfoCircleOutlined />, label: 'Content Management' },
    { key: 'devops', icon: <InfoCircleOutlined />, label: 'DevOps Tools' },
    { key: 'database', icon: <InfoCircleOutlined />, label: 'Database Management' },
    { key: 'monitoring', icon: <InfoCircleOutlined />, label: 'Monitoring & Logging' },
    { key: 'media', icon: <InfoCircleOutlined />, label: 'Media & Entertainment' },
    { key: 'security', icon: <InfoCircleOutlined />, label: 'Security' },
  ];
  const onClick: MenuProps['onClick'] = e => setSelectedMenuKey(e.key);
  // const menuStyle: any = { width: 300, position: 'relative', left: -24, marginTop: -300, borderRight: '1px solid transparent' };
  const menuStyle: any = { width: 300, position: 'relative', left: -24, borderRight: '1px solid transparent' };

  return (
    <>
      <Row>
        <Col flex="300px">
          <Menu
            mode="inline"
            onClick={onClick}
            defaultSelectedKeys={[props.category]}
            defaultOpenKeys={[props.category]}
            items={items}
            style={menuStyle}
          />
        </Col>
        <Col flex="calc(100% - 300px)">{templateData} </Col>
      </Row>
    </>
  );
}

interface iDeploymentFromTemplate {
  projectId?: number;
  category?: string;
  preFillValues?: {
    description: string;
    name: string;
    tags: any[];
    regionId: number;
  };
}

/** @returns Create deployment from (/new route) */
export default function DeploymentFromTemplate(props: iDeploymentFromTemplate) {
  // const params: any = useParams();
  const history = useHistory();
  const preSelectedProject = Number(props?.projectId) || null;

  const [createDeployment, newDeployment] = useAuthedMutationWithNotification(gql`
    mutation DeploymentsController_createDeployment($projectId: Int!, $dpDeployFlow: String!, $name: String!, $tags: [Int]) {
      DeploymentsController_createDeployment(projectId: $projectId, dpDeployFlow: $dpDeployFlow, deploymentName: $name, deploymentTags: $tags) {
        id
      }
    }
  `);

  const handleCreateFromTemplate = async (project: iProjectModel) => {
    const { id: pid, name: pName } = project;

    /** const similarProjects = (data?.ProjectController_getProjectList || []).filter(project => pid === projectId);
     * @todo: Add call to ProjectController_findSimilarProjects(projectId)
     * Can solve: https://nanoheal.atlassian.net/browse/DP-635
     * And if it returns a project, ask user if they want to use that project instead of creating a new one.
     * It needed for avoinding duplicating projects. */

    const result = await authService.getApolloClient().query({
      query: gql`
        query ProjectController_findSimilarProjects($projectId: Int!) {
          ProjectController_findSimilarProjects(projectId: $projectId) {
            id
            name
            title
            description
            logo
            tags
          }
        }
      `,
      variables: { projectId: pid },
    });

    const similarProjects = result?.data?.ProjectController_findSimilarProjects || [];
    if (similarProjects.length === 0) {
      Modal.confirm({
        title: 'Use Existing Project',
        icon: <InfoCircleOutlined />,
        content: (
          <>
            A similar project already exists. Do you want to use the existing project or create a new copy?
            {similarProjects.map(({ id, name, title }: iExistingContent) => {
              return (
                <Button
                  onClick={async e => {
                    await createDeployment({
                      variables: {
                        projectId: id,
                        dpDeployFlow: 'from-copy',
                        regionId: props.preFillValues?.regionId || null,
                        description: props.preFillValues?.description || ``,
                        name: props.preFillValues?.name || `New application ${name}`,
                        tags: props.preFillValues?.tags || [],
                      },
                    });
                  }}
                >
                  Copy from ({id}) {title}
                </Button>
              );
            })}
          </>
        ),
        cancelText: 'Cancel',
        okText: 'Create a new copy',
        async onOk() {
          await createDeployment({
            variables: {
              projectId: pid,
              dpDeployFlow: 'from-copy',
              regionId: props.preFillValues?.regionId || null,
              description: props.preFillValues?.description || ``,
              name: props.preFillValues?.name || `New application ${pName}`,
              tags: props.preFillValues?.tags || [],
            },
          });
        },
      });
    } else {
      Modal.confirm({
        title: 'Creating a application from a project template',
        icon: <InfoCircleOutlined />,
        content: (
          <Space direction="vertical">
            <Text> The project template will be automatically copied from the general template library and added to your own project list. </Text>
            <Text> You can find it on the Projects tab and edit it if you wish. </Text>
          </Space>
        ),
        async onOk() {
          await createDeployment({
            variables: {
              projectId: pid,
              dpDeployFlow: 'from-template',
              regionId: props.preFillValues?.regionId || null,
              description: props.preFillValues?.description || ``,
              name: props.preFillValues?.name || `New application ${pName}`,
              tags: props.preFillValues?.tags || [],
            },
          });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  };

  const newDeploymentId = newDeployment?.data?.DeploymentsController_createDeployment?.id;
  if (newDeploymentId) {
    history.push(`/app/${newDeploymentId}/configuration/settings/general`);
    return <Skeleton active={true} loading={true} />;
  }

  return (
    <TemplateSelectionList
      preSelectedProjectId={preSelectedProject}
      category={props.category}
      onSelect={handleCreateFromTemplate}
      selectBtnName="Deploy"
      selectBtnTooltip="You can deploy preconfigured ready-made project. It allow to deploy any software in your cluster in several minutes."
    />
  );
}
