import gql from 'graphql-tag';
import { ReactElement, ReactNode, ReactPortal, useEffect } from 'react';
import { useAuthedQuery } from 'utils/qlAuth';
import { iRegionModel } from 'shared/deployment';
import { iPvcItem } from 'shared/pvc';
import { Alert, Skeleton, Space, Table } from 'antd';
import { bottomMargin, spaceWidth } from 'utils/styles';

interface iClusterPvcListProps {
  region: iRegionModel;
}

interface iLabelData {
  [x: string]: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
}

export function ClusterPvcList(props: iClusterPvcListProps) {
  const { loading, error, data, stopPolling, startPolling } = useAuthedQuery(
    gql`
      query ApiAgentController_listPersistentVolumeClaimForAllNamespaces($regionId: Int!) {
        ApiAgentController_listPersistentVolumeClaimForAllNamespaces(regionId: $regionId) {
          status
          message
          PvcList
        }
      }
    `,
    { variables: { regionId: props.region.id }, pollInterval: 25000 },
  );
  startPolling(25000);

  useEffect(() => {
    return stopPolling;
  }, []);

  let ClusterPvcList: iPvcItem[] = data?.ApiAgentController_listPersistentVolumeClaimForAllNamespaces?.PvcList;

  const labelData = (text: iLabelData) => (
    <table>
      {Object.keys(text).map(key => (
        <tr>
          <td> {key} </td>
          <td> {text[key]} </td>
        </tr>
      ))}
    </table>
  );

  const columnsData = [
    { title: 'Namespace', dataIndex: 'metadata_namespace', key: 'metadata_namespace' },
    { title: 'Name', dataIndex: 'metadata_name', key: 'metadata_name' },
    { title: 'Phase', dataIndex: 'status_phase', key: 'status_phase' },
    { title: 'Storage class name', dataIndex: 'spec_storageClassName', key: 'spec_storageClassName' },
    { title: 'Volume mode', dataIndex: 'spec_volumeMode', key: 'spec_volumeMode' },
    { title: 'Access modes', dataIndex: 'spec_accessModes', key: 'spec_accessModes' },
    { title: 'Capacity', dataIndex: 'status_capacity', key: 'status_capacity' },
    { title: 'Created', dataIndex: 'metadata_creationTimestamp', key: 'metadata_creationTimestamp' },
    { title: 'Labels', dataIndex: 'metadata_labels', key: 'metadata_labels', render: labelData },
  ];

  return (
    <Skeleton active={true} loading={loading && !error && !ClusterPvcList}>
      <Space direction="vertical" style={spaceWidth}>
        <Alert type="info" showIcon message={`Here you can see a list of PersistentVolumeClaim (PVC) in the Cluster.`} />
        {(error || (!loading && !ClusterPvcList)) && (
          <Alert showIcon message={error?.message || `Something went wrong.`} type="error" style={bottomMargin} />
        )}
        {ClusterPvcList && ClusterPvcList ? <Table columns={columnsData} dataSource={ClusterPvcList} /> : null}
      </Space>
    </Skeleton>
  );
}
