import { AutoComplete, Input, Space, Typography } from 'antd';
import { floatingRight } from 'utils/styles';

export interface iVarNameComponentProps {
  onChange: (value: string) => void;
  syncVars: any;
  value: string;
  addonBefore?: any;
  AutoCompleteStyles?: any;
}

const { Text } = Typography;
const { Compact } = Space;
const { Search } = Input;

const getSyncVarsList = (syncVars: { [x: string]: any }, path = [], level = 0) => {
  let res = [];
  Object.keys(syncVars || {}).forEach(key => {
    res.push({ value: [...path, key].join('.'), varValue: JSON.stringify(syncVars[key]).substring(0, 100), level });
    res =
      typeof syncVars[key] === 'object' && level < 5 && syncVars[key] !== null
        ? res.concat(getSyncVarsList(syncVars[key], [...path, key], level + 1))
        : res;
  });
  if (!syncVars) return [];
  return res;
};

export const VarNameComponent = (props: iVarNameComponentProps) => {
  const options = getSyncVarsList(props.syncVars).map(v => {
    const getSyncVarsListLabel = (
      <span>
        {v.value}
        <Text italic style={floatingRight}>
          {v.varValue}
        </Text>
      </span>
    );
    return { value: String(v.value), label: getSyncVarsListLabel };
  });

  return (
    <Compact block>
      {props.addonBefore}
      <AutoComplete
        className="VarNameComponent-AutoComplete"
        style={props.AutoCompleteStyles || {}}
        filterOption={(inputValue, option) => option.value && option.value.indexOf(inputValue) !== -1}
        options={options}
        value={props.value}
        onSelect={value => props.onChange(value)}
      >
        <Search className="VarNameComponent-Search" placeholder="Enter Input Here" onChange={e => props.onChange(e.target.value)} />
      </AutoComplete>
    </Compact>
  );
};
