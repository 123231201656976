import moment from 'moment';
import ItemUpdateComponent from './ItemUpdateComponent';
import { getHistoryChangesCluster } from 'queries/queries';
import { useAuthedQuery } from '../../utils/qlAuth';
import { iRegionModel } from 'shared/deployment';
import { useUser } from 'utils/common';
import { iMyUserData } from 'shared/user';
import { Skeleton, Collapse, Alert, Space, Typography } from 'antd';
import { autoOverflow, spaceWidth } from 'utils/styles';

const { Text } = Typography;
const { Panel } = Collapse;

export interface iClusterAuditLogsProps {
  region: iRegionModel;
}

export default function ClusterAuditLogs(props: iClusterAuditLogsProps) {
  const regionId = props.region.id;
  const user: iMyUserData = useUser();
  const { loading, error, data } = useAuthedQuery(getHistoryChangesCluster, { variables: { clusterId: Number(regionId) } });
  const region = props.region;

  const showHistory = () => {
    if (data) {
      const formatDate = (date: string) => moment(date).format('YYYY-MM-DD ~ hh:mm:ss A');
      const historyValue = data.getHistoryChangesCluster.value;
      let item = 0;
      return Object.keys(historyValue).map(key => {
        const panelHeader = <Text> {historyValue[key].action} </Text>;
        item++;
        return (
          <Panel extra={formatDate(historyValue[key].createdAt)} header={panelHeader} key={item} style={{ ...autoOverflow, wordBreak: 'break-all' }}>
            <ItemUpdateComponent values={historyValue[key]} />
          </Panel>
        );
      });
    }
  };

  if (!region || !user) return <Skeleton active={true} loading={true} />;
  if (region.tenant !== user.tenant) return <Alert type="info" message="This cluster is public. You can not change settings." />;

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Skeleton active={true} loading={loading}>
        <Collapse size="small">{showHistory()}</Collapse>
      </Skeleton>
    </Space>
  );
}
