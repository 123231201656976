import history from '../../../../history';
import { deploymentService, useGqlDeploymentById } from '../../../../services/deployment.service';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { Button, Modal, notification, Space, Typography } from 'antd';
import { buttonBorder, buttonSize } from 'utils/styles';
import { iDeployment } from 'shared/deployment';

interface iDeleteDeployment {
  id: number;
  confirmDelete?: any;
}

const { Text } = Typography;

export const DeleteDeployment = (props: iDeleteDeployment) => {
  const user = useUser();

  if (!userHasPermission(user, UserPermissions.DeploymentDelete)) {
    return <>null</>;
  }

  const deleteDeployment = (id: any) => {
    return new Promise(resolve => {
      const modalContent = (
        <Text>
          Do you want to Delete this Application? <Text strong> {props.id} </Text>
        </Text>
      );
      const handleOnOk = async () => {
        (await deploymentService.deleteDeployment(id)).status === 401
          ? window.location.reload()
          : (notification.success({ description: 'Saved', message: '' }), resolve(true));
      };
      const handleOnCancel = () => resolve(false);
      Modal.confirm({ title: 'Confirm', content: modalContent, okText: 'Ok', cancelText: 'Cancel', onOk: handleOnOk, onCancel: handleOnCancel });
    });
  };

  const handleDeleteClick = async () => ((await deleteDeployment(props.id)) ? history.push('/') : null);
  return (
    <Button disabled={props?.confirmDelete} style={buttonSize} type="primary" danger onClick={handleDeleteClick}>
      Delete
    </Button>
  );
};
