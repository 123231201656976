import { useState } from 'react';
import { projectService } from 'services/project.service';
import { Button, Input, Modal, Popconfirm, Spin, Typography, notification } from 'antd';
import { GitlabOutlined } from '@ant-design/icons';
import { gitButtonModalStyle } from 'utils/styles';

interface iProjectGitBtns {
  projectId: number;
  disabled?: boolean;
  onClick?: () => void;
}

interface iProjectGitBtn_push extends iProjectGitBtns {
  dstBranch: string;
}

const { Text } = Typography;

async function showModalWithGitCommandResult(title: string, resPromis: Promise<any>, onClose?: (isOk: boolean) => void) {
  Modal.destroyAll();
  Modal.success({
    title: 'Loading...',
    content: <Spin size="large" />,
    icon: <GitlabOutlined />,
    closable: false,
    onOk: () => resPromis,
  });

  const res = await resPromis;

  const modalContent = () => (
    <pre style={gitButtonModalStyle}>
      {res?.data?.results?.step1?.stdout}
      {res?.data?.results?.step1?.stderr}
      {res?.data?.results?.step2?.stdout}
      {res?.data?.results?.step2?.stderr}
    </pre>
  );

  Modal.destroyAll();
  if (res.error) {
    notification.error({
      message: `Error - ${res.error || res?.data?.results?.error}`,
      onClose: () => {
        onClose ? onClose(false) : null;
      },
    });
  } else {
    Modal.success({
      title: title,
      content: modalContent(),
      width: '800px',
      onOk: () => {
        onClose ? onClose(true) : null;
      },
    });
  }

  return res;
}

export const projectGitPull = async (projectId: number, onClose?: (isOk: boolean) => void) => {
  await showModalWithGitCommandResult(`Pull`, projectService.gitPullProject(projectId), onClose);
};

const cloneBtn = async (projectId: number) => await showModalWithGitCommandResult(`Clone`, projectService.gitCloneProject(projectId));

const pushBtn = async (dstBranch: string, projectId: number) =>
  await showModalWithGitCommandResult(`Push`, projectService.gitPushProject(projectId, dstBranch));

export const ProjectGitBtn_push = (props: iProjectGitBtn_push) => {
  const [dstBranch, setDstBranch] = useState(null);
  const resdstBranch = dstBranch || props.dstBranch;

  const handlePushClick = async () => {
    await pushBtn(resdstBranch, props.projectId);
    props.onClick ? props.onClick() : null;
  };

  const popDescription = (
    <>
      <Text> Are you sure you want to push changes to git? </Text>
      <Text> Target Branch </Text>
      <Input placeholder="Branch name" value={resdstBranch} onChange={e => setDstBranch(e.target.value)} />
    </>
  );

  return (
    <Popconfirm
      title="Push changes to git?"
      description={popDescription}
      okText={`Push to ${resdstBranch}`}
      cancelText="No"
      key={`push`}
      onConfirm={handlePushClick}
    >
      <Button type="primary" disabled={props.disabled}>
        Git Push
      </Button>
    </Popconfirm>
  );
};

export const ProjectGitBtn_Pull = (props: iProjectGitBtns) => {
  const handlePullClick = async () => {
    await projectGitPull(props.projectId);
    if (props.onClick) {
      props.onClick();
    }
  };
  const pullFile = () => (
    <Button type="primary" disabled={props.disabled} key={`pull`} onClick={handlePullClick}>
      Git Pull
    </Button>
  );
  return pullFile();
};

export const ProjectGitBtn_Clone = (props: iProjectGitBtns) => {
  const handleCloneClick = async () => {
    await cloneBtn(props.projectId);
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <Popconfirm
      key={`clone`}
      title="Remove all local files and Git Clone?"
      description={`You will lose all local changes. Are you sure you want to clone the project?`}
      okText={`Clone`}
      cancelText="No"
      onConfirm={handleCloneClick}
    >
      <Button type="dashed" danger disabled={props.disabled} key={`clone`}>
        Git Clone
      </Button>
    </Popconfirm>
  );
};
