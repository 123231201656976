import { Col } from 'antd';
import ChartDashboard from 'components/ChartRenderer/ChartDashboard';
import { ChartRenderer } from 'components/ChartRenderer/ChartRenderer';
import DashboardItem from 'components/ChartRenderer/DashboardItem';
import { iDeployment } from 'shared/deployment';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';

interface iOneServiceMetricsProps {
  service: iCloudProjectServiceYamlSpecs;
  serviceName: string;
  deployment: iDeployment;
}

export const OneServiceMetrics = (props: iOneServiceMetricsProps) => {
  const id = props.deployment.id;

  const serviceNameFilter = { member: 'Monitoring.serviceName', operator: 'equals', values: [`${props.serviceName}`] };
  const stats = [
    {
      id: 0,
      span: `12`,
      name: 'Availability for deployments (30 minutes)',
      vizState: {
        query: {
          measures: ['Monitoring.okRate'],
          timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'minute', dateRange: 'Last 30 minute' }],
          dimensions: ['Monitoring.serviceName'],
          filters: [serviceNameFilter, { member: 'Monitoring.deploymentId', operator: 'equals', values: [`${id}`] }],
        },
        chartType: 'line',
      },
    },
    {
      id: 0,
      span: `12`,
      name: 'Response time (30 minutes)',
      vizState: {
        query: {
          measures: ['Monitoring.timeResponse'],
          timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'minute', dateRange: 'Last 30 minute' }],
          dimensions: ['Monitoring.serviceName'],
          filters: [serviceNameFilter, { member: 'Monitoring.deploymentId', operator: 'equals', values: [`${id}`] }],
        },
        chartType: 'line',
      },
    },
    {
      id: 0,
      span: `24`,
      name: 'Availability for deployments (2 hours)',
      vizState: {
        query: {
          measures: ['Monitoring.okRate'],
          timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'minute', dateRange: 'Last 120 minute' }],
          dimensions: ['Monitoring.serviceName'],
          filters: [serviceNameFilter, { member: 'Monitoring.deploymentId', operator: 'equals', values: [`${id}`] }],
        },
        chartType: 'line',
      },
    },
    {
      id: 0,
      span: `24`,
      name: 'Availability for deployments (24 hours)',
      vizState: {
        query: {
          measures: ['Monitoring.okRate'],
          timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'hour', dateRange: 'Last 24 hours' }],
          dimensions: ['Monitoring.serviceName'],
          filters: [serviceNameFilter, { member: 'Monitoring.deploymentId', operator: 'equals', values: [`${id}`] }],
        },
        chartType: 'line',
      },
    },
    {
      id: 0,
      span: `24`,
      name: 'Availability for deployments (7 days)',
      vizState: {
        query: {
          measures: ['Monitoring.okRate'],
          timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'hour', dateRange: 'Last 7 days' }],
          dimensions: ['Monitoring.serviceName'],
          filters: [serviceNameFilter, { member: 'Monitoring.deploymentId', operator: 'equals', values: [`${id}`] }],
        },
        chartType: 'line',
      },
    },
  ];

  return (
    <ChartDashboard>
      {stats.map((stat, index) => {
        return (
          <Col span={stat.span || 24} key={stat.id || index} style={{ marginBottom: '24px' }}>
            <DashboardItem title={stat.name}>
              <ChartRenderer vizState={stat.vizState} />
            </DashboardItem>
          </Col>
        );
      })}
    </ChartDashboard>
  );
};
