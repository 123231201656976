import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { iRegionModel } from 'shared/deployment';
import { regionsService } from 'services/regions.service';
import { CustomWidgets } from 'form-components';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Button, notification } from 'antd';
import { buttonBorder } from 'utils/styles';

export interface iKubeCostProps {
  region: iRegionModel;
}

const EditRegionSchemaForm: any = {
  type: 'object',
  properties: {
    usePluginOpencost: {
      type: 'boolean',
      title: 'Use Plugin OpenCost integration',
    },
    pluginOpencostHost: {
      type: 'string',
      title: 'Plugin OpenCost Host',
      description: `The host of the OpenCost plugin. It is used to get cost data from the OpenCost api.`,
    },
  },
};
const EdisRegionSchemaFormUI = {};

export const KubeCost = (props: iKubeCostProps) => {
  const handleSubmit = async (e: any) => {
    const data = e.formData;
    const res = await regionsService.updateOwnRegion(props.region.id, data);
    if (res.error) notification.error({ message: `Error - ${res.error}` });
    else notification.success({ message: `Ready` });
  };

  return (
    <Form
      widgets={CustomWidgets}
      formData={props.region}
      schema={EditRegionSchemaForm}
      uiSchema={EdisRegionSchemaFormUI}
      validator={validator}
      onSubmit={handleSubmit}
      onError={e => console.log('Error from submit: ', e)}
    >
      <BottomButtons>
        <Button type="primary" htmlType="submit" style={buttonBorder}>
          Save
        </Button>
      </BottomButtons>
    </Form>
  );
};
