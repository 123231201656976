import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { isTunnelError } from 'shared/TunnelErrors';
import { useAuthedMutationWithNotification, useAuthedQuery } from 'utils/qlAuth';
import { Popover, Skeleton, Space, Tag, Typography } from 'antd';
import { CheckCircleOutlined, ThunderboltOutlined, PoweroffOutlined, RocketOutlined, DeleteOutlined } from '@ant-design/icons';
import { WarningOutlined, SyncOutlined, FieldTimeOutlined, FireOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { blueColor, breadcrumbColor, buttonColor, redColor } from 'utils/styles';
import { TipLeft } from '../../SharedComponents/Tooltip/Tooltip';
import { DeleteFilled } from '@ant-design/icons/lib';

const { Text } = Typography;

export interface Pod {
  name: string;
  id: string;
  containers?: any;
}

interface iResourcesStatesProps {
  deploymentId: number;
  onPodsFetched: (pods: Pod[]) => void;
}

export interface iOneMetric {
  name: string;
  labels: any;
  memory: number;
  cpu: number;
  nodeName: string;
}

export const ResourcesStates = (props: iResourcesStatesProps) => {
  const { deploymentId, onPodsFetched } = props;

  const podsQuery = useAuthedQuery(
    gql`
      query DeploymentsController_getPodsMetrics($deploymentId: Int!) {
        DeploymentsController_getPodsMetrics(deploymentId: $deploymentId) {
          error
          metrics
        }
        DeploymentsController_getPodsStatus(deploymentId: $deploymentId) {
          error
          status
        }
      }
    `,
    { variables: { deploymentId: Number(deploymentId) } },
  );

  const [deletePod, deletePodResult] = useAuthedMutationWithNotification(gql`
    mutation DeploymentsController_deletePod($deploymentId: Int!, $podName: String) {
      DeploymentsController_deletePod(deploymentId: $deploymentId, podName: $podName) {
        error
        status
      }
    }
  `);

  const loadingStatus = podsQuery?.loading;
  const errorStatus = podsQuery?.error;
  const newStatus = podsQuery?.data?.DeploymentsController_getPodsStatus?.status;
  const newMetrics = podsQuery?.data?.DeploymentsController_getPodsMetrics?.metrics;

  const [oldStatus, setOldStatus] = useState(null);
  const [oldMetrics, setOldMetrics] = useState(null);

  useEffect(() => {
    newStatus && !isTunnelError(newStatus?.error) ? setOldStatus(newStatus) : null;
    newMetrics && !isTunnelError(newMetrics?.error) ? setOldMetrics(newMetrics) : null;
    return podsQuery.stopPolling;
  }, [newStatus, newMetrics]);

  useEffect(() => {
    Array.isArray(newStatus) ? onPodsFetched(newStatus.map((service: any) => ({ name: getServiceNameForUI(service), id: service.name }))) : null;
  }, [newStatus, onPodsFetched]);

  podsQuery.startPolling(5000);

  if (loadingStatus) return <Skeleton active={true} loading={true} />;
  if (!errorStatus && !newStatus) return <> No pods found </>;
  if (errorStatus || !newStatus || newStatus.error === 401) return <> Error: {JSON.stringify(errorStatus || newStatus?.error)} </>;

  let metricsObj = isTunnelError(newMetrics?.error) ? oldMetrics || <Skeleton active={true} loading={true} /> : newMetrics;

  const metricsKey = Object.keys(metricsObj)[0];
  const metrics: iOneMetric[] = metricsObj[metricsKey];

  let status = newStatus;
  if (isTunnelError(newStatus?.error)) {
    status = oldStatus
      ? oldStatus
      : (() => {
          console.error('Tunnel error', newStatus?.error);
          return <Skeleton active={true} loading={true} />;
        })();
  }

  const metricsByPodName = (podName: string): iOneMetric =>
    !metrics || metrics.length === 0 || !Array.isArray(metrics) ? null : metrics.find(m => m?.name === podName);

  if (!status) return null;
  if (status?.ErrorBody || Array.isArray(status) === false)
    return <Text type="danger"> {status?.ErrorBody?.message || status?.message || JSON.stringify(status)} </Text>;
  return (
    <Space direction="vertical">
      {status &&
        status.map((service: any, index: any) => {
          let checkError = getCheckError(service);
          // console.log(service.name, checkError);
          const podName = service.name;
          const nodeName = service?.nodeName || ``;
          const usage: iOneMetric = metricsByPodName(podName);
          const last = service.last_condition;

          const { lastTransitionTime, message, reason, status, type } = last;

          const content = () => (
            <Space direction="vertical">
              <Text strong> {getTitleHelp(checkError)} </Text>
              <Text italic> Node {nodeName} </Text>
              <p style={{ maxWidth: '300px' }}>
                {lastTransitionTime ? (
                  <ul>
                    {lastTransitionTime ? <li> Last transition time: {lastTransitionTime} </li> : ''}
                    {message ? <li> Message: {message} </li> : ''}
                    {reason ? <li> Reason: {reason} </li> : ''}
                    {status ? <li> Status: {status} </li> : ''}
                    {type ? <li> Type: {type} </li> : ''}
                  </ul>
                ) : (
                  ''
                )}
              </p>
            </Space>
          );

          const handleDeletePod = (podName: string) => {
            deletePod({ variables: { deploymentId: deploymentId, podName: podName } });
          };

          return (
            <Space>
              <TipLeft tip="Click here to delete this pod">
                <DeleteFilled style={{ color: 'red', cursor: 'pointer' }} onClick={() => handleDeletePod(service.name)} />
              </TipLeft>
              <Tag key={service.name} icon={serviceToIcon(service)}>
                <span style={{ minWidth: '90px' }}>
                  <Popover placement="left" content={content} title={`Phase: ${checkError}`}>
                    <Link to={`/app/${deploymentId}/logs/pods_${service.name}`}> {getServiceNameForUI(service)} </Link>
                  </Popover>
                </span>
              </Tag>
              {usage && <Text> CPU usage {Math.floor(usage?.cpu)}m </Text>}
              {usage && <Text> Memory usage {Math.floor(usage?.memory * 100) / 100}Mi </Text>}
            </Space>
          );
        })}
    </Space>
  );
};

const getCheckError = (service: any): string | undefined => {
  // let stateError = null;
  // if (service.status_containerStatuses.state && service.status_containerStatuses.state[0].reason)
  //   stateError = `${service.status_containerStatuses.state[0].reason} - ${service.status_containerStatuses.state[0].message}`;

  const wasTerminated = service?.status_containerStatuses?.state?.find((v: any) => v.stateType === 'terminated');
  const age = (new Date().getTime() - new Date(service.status_startTime).getTime()) / 1000;
  const status = service?.last_condition?.reason || service?.status_phase || service?.last_condition?.message;

  let checkError = status;
  checkError =
    wasTerminated && status === 'ContainersNotReady'
      ? 'CrashLoopBackOff'
      : wasTerminated && wasTerminated.reason === 'ContainerStatusUnknown'
      ? 'ContainerStatusUnknown'
      : age < 180 && status === 'ContainersNotReady'
      ? 'ContainerCreating'
      : service?.status_conditions?.length === 0 && status === 'Failed' && service?.last_condition?.lastTransitionTime === 0
      ? 'Evicted'
      : checkError;

  return service ? checkError : 'Unknown';
};

export const getTitleHelp = (service: any): string => {
  const checkError = getCheckError(service);
  if (!service) return 'No data';
  const titleMap = {
    Running: `${checkError} - It is working well`,
    'Init:CrashLoopBackOff': `${checkError} - It won't work, try to fix it. It will not work until you fix it. (check Init container`,
    CrashLoopBackOff: `${checkError} - It won't work, try to fix it. It will not work until you fix it.`,
    ContainerCreating: `${checkError} - Just wait some time, it is okay`,
    ContainersNotInitialized: `${checkError} - Just wait some time, it is okay`,
    Terminating: `${checkError} - Just wait some time, it is okay`,
    'Init:Error': `${checkError} - It is an error (check Init container)`,
    Error: `${checkError} - It is an error`,
    Pending: `${checkError} - Just wait some time, try to fix if this status do not changes more than 5 minutes`,
    ContainersNotReady: `${checkError} - Just wait some time, try to fix if this status do not changes more than 5 minutes`,
    Succeeded: `${checkError} - It is okay`,
    PodCompleted: `${checkError} - It is okay`,
    Unschedulable: `${checkError} - It is an error`,
    PodFailed: `${checkError} - It is an error`,
    ContainerStatusUnknown: `${checkError} - It is not critical if we have at least one green pod for this service`,
    Evicted: `${checkError} - Evicted/Failed`,
  };
  return titleMap[checkError] || (checkError === undefined ? 'No errors' : checkError);
};

export const serviceToIcon = (service: any) => {
  const checkError = getCheckError(service);
  const serviceToIconTitle = { title: getTitleHelp(service) };
  const iconMap = {
    Running: <CheckCircleOutlined {...serviceToIconTitle} style={{ color: '#00A58E' }} />,
    'Init:CrashLoopBackOff': <ThunderboltOutlined {...serviceToIconTitle} style={redColor} />,
    Evicted: <ThunderboltOutlined {...serviceToIconTitle} style={{ color: '#808080' }} />,
    ContainerStatusUnknown: <PoweroffOutlined {...serviceToIconTitle} style={{ color: '#808080' }} />,
    CrashLoopBackOff: <ThunderboltOutlined {...serviceToIconTitle} style={redColor} />,
    ContainerCreating: <RocketOutlined {...serviceToIconTitle} style={blueColor} />,
    ContainersNotInitialized: <RocketOutlined {...serviceToIconTitle} style={blueColor} />,
    Terminating: <DeleteOutlined {...serviceToIconTitle} style={blueColor} />,
    'Init:Error': <WarningOutlined {...serviceToIconTitle} style={redColor} />,
    Error: <WarningOutlined {...serviceToIconTitle} style={redColor} />,
    Pending: <SyncOutlined {...serviceToIconTitle} spin={true} style={blueColor} />,
    ContainersNotReady: <SyncOutlined {...serviceToIconTitle} spin={true} style={blueColor} />,
    Succeeded: <FieldTimeOutlined {...serviceToIconTitle} style={buttonColor} />,
    PodCompleted: <FieldTimeOutlined {...serviceToIconTitle} style={buttonColor} />,
    Unschedulable: <FireOutlined {...serviceToIconTitle} style={{ color: '#FF7300' }} />,
  };
  if (!service) return undefined;
  return iconMap[checkError] || <CloseCircleOutlined {...serviceToIconTitle} style={redColor} />;
};

export const getServiceNameForUI = (service: any): string => {
  return !service
    ? ''
    : ['ui-name', 'name', 'app'].find(key => service?.labels?.[key])
    ? service.labels[['ui-name', 'name', 'app'].find(key => service.labels[key])]
    : service?.name || 'no-name';
};
