// import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { ReactElement, ReactNode, ReactPortal, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { projectService } from 'services/project.service';
import { useUser } from 'utils/common';
import { CustomFieldTemplate } from 'form-components/ObjectFieldTemplate';
import { CustomWidgets } from 'form-components';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { Button, Drawer, Form, Input, notification, Select, Space, Tag } from 'antd';
import { buttonSize, buttonWidth } from 'utils/styles';
import { LoadingOutlined } from '@ant-design/icons';
import { useAuthedQuery } from '../../utils/qlAuth';
import gql from 'graphql-tag';
import { Option } from 'antd/es/mentions';

interface iNewProjectForm {
  openBtnUI: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
}

export const NewProjectForm = (props: iNewProjectForm) => {
  const showDrawer = () => setOpen(true);
  const onClose = () => setOpen(false);

  const user = useUser();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCreateProject = async (e: any) => {
    const res = await projectService.setNewProject({
      projectName: e.projectName,
      description: e.description, // optional
      tags: e.tags, // Array of numbers
      regionId: null,
    });
    if (!res.data) {
      setLoading(false);
      return notification.error({ message: 'Error', description: res.error });
    }
    history.push(`/app/${res.data.deploymentId}/configuration/new-service/add`);
    console.log(e);
  };

  let yourForm;

  // Not authorized to access this page
  if (!userHasPermission(user, UserPermissions.ProjectsMange)) {
    return null;
  }

  const drawerFooter = (
    <Space wrap>
      <Button
        disabled={loading}
        type="primary"
        onClick={() => {
          setLoading(true);
          form.submit();
        }}
        style={buttonWidth}
      >
        Next {`>`} Add a service {loading ? <LoadingOutlined /> : null}
      </Button>
      <Button disabled={loading} onClick={onClose} style={buttonSize}>
        Cancel
      </Button>
    </Space>
  );
  const [form] = Form.useForm();

  const qTags = useAuthedQuery(
    gql`
      query TagsController_tagsList {
        TagsController_tagsList {
          id
          name
          color
        }
      }
    `,
    {},
  );

  return (
    <Space direction="vertical">
      <Space onClick={showDrawer} onKeyUp={showDrawer} tabIndex={0} role="button">
        {props.openBtnUI ? props.openBtnUI : ''}
      </Space>
      <Drawer title="Add a New Application" open={open} onClose={onClose} closable={true} width={800} footer={drawerFooter}>
        <Form
          form={form}
          onFinish={handleCreateProject}
          layout="vertical"
          initialValues={{
            projectName: '',
            description: '',
          }}
          validateTrigger="onSubmit"
          validateMessages={{
            required: '${label} is required!',
          }}
        >
          <Form.Item
            label="Application Name"
            name="projectName"
            rules={[
              {
                required: true,
                message: 'Please input Application Name!',
              },
            ]}
          >
            <Input placeholder="Enter Name of the Project Here" />
          </Form.Item>
          <Form.Item
            label="Application Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please input Application Description!',
              },
            ]}
          >
            <Input.TextArea placeholder="Enter Project Description Here" />
          </Form.Item>
          {qTags.data?.TagsController_tagsList?.length && (
            <Form.Item name="tags" label="Application tags">
              <Select mode="multiple" placeholder="Tags">
                {qTags.data.TagsController_tagsList.map(elem => (
                  <Option value={elem.id} key={elem.id}>
                    <Tag color={elem.color}>{elem.name}</Tag>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Drawer>
    </Space>
  );
};
