import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Space, Timeline, Typography } from 'antd';
import { ReactElement } from 'react';
import { iDeployment } from 'shared/deployment';

const { Title, Text } = Typography;

export const OverViewInInit = (props: { deployment: iDeployment }) => {
  const { deployment } = props;

  const inInitStage_NoSync = !(deployment?.NamespaceModel?.lastSyncDelay < 1707802250);

  const TimelineSteps = [
    {
      dot: <CheckCircleOutlined />,
      children: (
        <Space direction="vertical">
          <Text>
            <Text strong> Application Name: </Text> {deployment?.name}
          </Text>
          <Text>
            <Text strong> Project Template: </Text> {deployment.ProjectModel.title}
          </Text>
          <Text>
            <Text strong> Application Cluster: </Text> {deployment.RegionModel.name}
          </Text>
        </Space>
      ),
    },
    { dot: <ClockCircleOutlined />, children: 'Namespace not created yet' },
    { dot: <ClockCircleOutlined />, children: 'No sync yet' },
  ];

  if (deployment?.NamespaceModel?.name)
    TimelineSteps[1] = {
      dot: <CheckCircleOutlined color="green" />,
      children: (
        <Text>
          <Text strong> Namespace: </Text> {deployment?.NamespaceModel?.name}
        </Text>
      ),
    };
  if (deployment?.NamespaceModel?.name)
    TimelineSteps[2] = {
      dot: <CheckCircleOutlined color="green" />,
      children: (
        <Text>
          <Text strong> Last sync time: </Text> {inInitStage_NoSync ? `No sync yet` : deployment?.NamespaceModel?.lastSyncDelay}
        </Text>
      ),
    };

  return <Timeline pending="Application will be ready soon" reverse={true} items={TimelineSteps} />;
};
