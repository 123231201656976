import { store } from './store';

export const mapDispatchToProps = dispatch => {
  return {
    actions: {
      setTitleAction: (title: string) => dispatch(setTitle(title)),
    },
  };
};

/**
 * Set title action into the redux store
 * @param title
 * @returns
 */
function setTitle(title: string) {
  //   console.log('setTitle title', title);
  return {
    type: 'SET_title',
    payload: title,
  };
}

/**
 * Set widgets action into the redux store
 * @param title
 * @returns
 */
function setWidgets(widgets: any) {
  return {
    type: 'SET_Widgets',
    payload: widgets,
  };
}
/**
 * Allow to set page title from any component
 * @param title string
 * @returns
 */
export const setPageTitle = (title: string) => store.dispatch(setTitle(title));

/**
 * Allow to set page title from any component
 * @param title string
 * @returns
 */
export const setPageWidgets = (widgets: any) => store.dispatch(setWidgets(widgets));
