import { Alert, Space } from 'antd';
import { autoFixer_emptyAsUndefined, CommonFormUI, CommonUI_JsonFormModalUI, CommonUI_select, CommonUI_text } from './CommonFormUI';
import { useContext } from 'react';
import { ProjectDeploymentSyncVarsContext } from '../ProjectDeploymentContext';

export interface iPersistentVolumeClaimMainOptionsUIProps {
  yamlObj: any;
  onChange: (key: string, newObj: any, action?: string) => void;
  lockName?: boolean;
}

export const form_schema_Labels = {
  schema: {
    type: 'object',
    additionalProperties: {
      type: 'string',
    },
  },
  ui: {},
};

export const PersistentVolumeClaimMainOptionsUI = (props: iPersistentVolumeClaimMainOptionsUIProps) => {
  let volumeHelpUI = null;
  const syncVarsContext = useContext(ProjectDeploymentSyncVarsContext);

  const form = [
    props.lockName !== true
      ? {
          name: 'Name',
          children: <CommonUI_text value={props.yamlObj?.metadata?.name} onChange={props.onChange} path={`.metadata`} propertyName={`name`} />,
        }
      : null,

    {
      name: 'Metadata labels',
      children: (
        <CommonUI_JsonFormModalUI
          formData={props.yamlObj?.metadata?.labels || {}}
          formConfigs={form_schema_Labels}
          SyncVarsContext={syncVarsContext}
          title={`Metadata labels`}
          onChange={(v: any) => {
            props.onChange(`.metadata.labels`, { ...v });
          }}
        />
      ),
    },
    {
      name: 'AccessModes',
      children: (
        <CommonUI_select
          value={props.yamlObj?.spec.accessModes}
          onChange={props.onChange}
          path={`.spec`}
          mode="multiple"
          propertyName={`accessModes`}
          options={[
            {
              label: 'ReadWriteOnce',
              value: 'ReadWriteOnce',
            },
            {
              label: 'ReadOnlyMany',
              value: 'ReadOnlyMany',
            },
            {
              label: 'ReadWriteMany',
              value: 'ReadWriteMany',
            },
            {
              label: 'ReadWriteOncePod',
              value: 'ReadWriteOncePod',
            },
          ]}
        />
      ),
    },
    {
      name: 'StorageClassName',
      children: (
        <CommonUI_text
          help={'The name of the StorageClass to use, keep empty value to use the default StorageClass'}
          value={props.yamlObj?.storageClassName}
          onChange={props.onChange}
          autoFixer={autoFixer_emptyAsUndefined}
          path={`.spec`}
          propertyName={`storageClassName`}
        />
      ),
    },
    {
      name: 'Storage',
      children: (
        <CommonUI_text
          value={props.yamlObj?.spec?.resources?.requests?.storage}
          onChange={props.onChange}
          path={`.spec.resources.requests`}
          propertyName={`storage`}
          help={'The amount of storage requested, for example: 1Gi'}
        />
      ),
    },
    // {
    //   name: 'volumeMode', // Filesystem
    //   children: <CommonUI_text value={props.yamlObj?.metadata?.name} onChange={props.onChange} path={`.metadata`} propertyName={`name`} />,
    // },
    // {
    //   name: 'selector',
    //   children: <CommonUI_text value={props.yamlObj?.metadata?.name} onChange={props.onChange} path={`.metadata`} propertyName={`name`} />,
    // },
  ].filter(Boolean);

  volumeHelpUI = (
    <Alert
      type="info"
      description={<>A PersistentVolumeClaim (PVC) is a request for storage</>}
      action={
        <a href={'https://kubernetes.io/docs/concepts/storage/persistent-volumes/'} target="_blank">
          Learn more
        </a>
      }
    />
  );

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <CommonFormUI form={form} />
      {volumeHelpUI}
    </Space>
  );
};
