//import ant design elements
import { Skeleton } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useGqlDeploymentById } from '../../../services/deployment.service';
import { DeploymentPvcList } from '../monitoring/DeploymentPvcList';
import { iDeployment } from 'shared/deployment';
import { DeploymentWidgetsRow } from '../DeploymentWidgetsRow';
import { TitleUIRow } from 'layout/TitleUI';

export default function DeploymentStoragePage() {
  const history = useHistory();
  // @ts-ignore
  const { id, tabName } = useParams();

  const dpQuery = useGqlDeploymentById(id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};
  const error = dpQuery.error;
  const loading = dpQuery.loading;

  if (!tabName) history.push(`/app/${id}/storage/pvc`);
  if (error) {
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }
  if (loading || !deployment) return <Skeleton active={true} loading={true} />;
  if (!deployment.isReady) {
    history.push(`/app/${deployment.id}/storage/namespace`);
    return <Skeleton active={true} loading={true} />;
  }

  return (
    <>
      <TitleUIRow title={`Application: ${deployment.name} - Persistent Volumes`} />
      <DeploymentWidgetsRow deployment={deployment} />
      <DeploymentPvcList deployment={deployment} />
    </>
  );
}
