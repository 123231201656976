import React, { useState } from 'react';
import { useApiQuery } from 'utils/common';
import { projectService } from 'services/project.service';
import { OneFile } from './OneFile';
import { iProject } from 'interface/project';
import { useAuthedMutation } from 'utils/qlAuth';
import { ProjectFilesController_removeFile } from 'queries/queries';
import Tree, { DataNode } from 'antd/lib/tree';
import { Row, Col, Popconfirm, Skeleton, Typography, Alert, Space } from 'antd';
import { DeleteOutlined, FileImageOutlined, FileOutlined, FileTextOutlined, FolderOpenOutlined, WarningOutlined } from '@ant-design/icons';
import { innerSidebar, redColor, spaceWidth, topMargined } from 'utils/styles';
import { iDeployment, iProjectModel } from 'shared/deployment';

export interface ProjectFilesProps {
  deployment: iDeployment;
}

const { Text } = Typography;

export const ProjectFiles = ({ deployment }: ProjectFilesProps) => {
  const project = deployment.ProjectModel;
  const [reloadTree, callReloadTree] = useState(null);
  const [filesTree, fError, fLoader] = useApiQuery(() => projectService.getFilesTree(project.id), [reloadTree]);
  const [fileName, setFileName] = useState(null);
  const [removeFileFunction, removeFileRes] = useAuthedMutation(ProjectFilesController_removeFile);

  if (!filesTree || fLoader) return <Skeleton active={true} loading={true} />;

  const sortFunc = (primary: { isFile: any; name: string }, secondary: { isFile: any; name: any }) =>
    primary.isFile !== secondary.isFile ? (primary.isFile ? 1 : -1) : primary.name.localeCompare(secondary.name);

  const mapFunc = (file): DataNode => {
    let children = file.isFile ? undefined : file.data.sort(sortFunc).map(mapFunc);
    let icon = !file.isFile ? (
      <FolderOpenOutlined />
    ) : /\.(yaml|yml)$/.test(file.name) ? (
      <FileTextOutlined />
    ) : /\.(jpg|jpeg|gif|png|svg|ico)$/.test(file.name) ? (
      <FileImageOutlined />
    ) : (
      <FileOutlined />
    );

    const popcornTitle = () => {
      const popcornDescription = (
        <Text>
          Do you want to Delete the file <Text strong> {file.name} </Text>?
        </Text>
      );

      const handleOnConfirmTime = async () => {
        await removeFileFunction({ variables: { projectId: project.id, fileName: file.path } });
        callReloadTree(new Date().getTime());
        return true;
      };

      return (
        <>
          <Popconfirm
            className="RemoveProjectFileIcon"
            title="Delete file?"
            placement="topLeft"
            icon={<WarningOutlined style={redColor} />}
            okText="Continue"
            cancelText="Cancel"
            description={popcornDescription}
            onConfirm={handleOnConfirmTime}
          >
            <DeleteOutlined />
          </Popconfirm>
          {file.name}
        </>
      );
    };
    return { title: popcornTitle(), key: file.path, checkable: file.isFile, selectable: file.isFile, icon, children };
  };

  const treeData: DataNode[] = filesTree.sort(sortFunc).map(mapFunc);

  const onSelect = (selectedKeys: React.Key[]) => setFileName(selectedKeys[0]);

  const treeContent = (
    <Col flex={'250px'} style={innerSidebar} data-qa={`ProjectFiles-Tree`} className="treeDarkA">
      <Tree
        showLine={true}
        showIcon={true}
        defaultExpandedKeys={['+']}
        onSelect={onSelect}
        multiple={false}
        treeData={treeData}
        className="treeDarkB"
      />
    </Col>
  );

  const fileContent = (
    <Col flex="auto">
      {fileName ? (
        <OneFile fileName={fileName} deployment={deployment} />
      ) : (
        <Space direction="vertical" style={spaceWidth}>
          <Text />
          <Alert showIcon type="info" message={`Select a file from the left sidebar to view its content.`} />
        </Space>
      )}
    </Col>
  );

  return (
    <Row gutter={[0, 0]} wrap={false} style={topMargined} data-qa={`ProjectFiles`}>
      {treeContent}
      {fileContent}
    </Row>
  );
};
