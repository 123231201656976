import { iRegionModel } from 'shared/deployment';
import { ClusterNodes } from './ClusterNodes';

interface iClusterNodesMonitoringProps {
  region: iRegionModel;
}

export function ClusterNodesMonitoring(props: iClusterNodesMonitoringProps) {
  return <ClusterNodes region={props.region} />;
}
