import { Fragment, useState } from 'react';
import { PROJECT_NAME } from 'interface/common';
import { TenantConfiguration } from 'components/Tenant/TenantConfiguration';
import { Button, Divider, Drawer, Space, Typography } from 'antd';
import { BellOutlined, BellTwoTone, BookOutlined, QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { TipBottom } from 'components/SharedComponents/Tooltip/Tooltip';
import { buttonColor } from 'utils/styles';

const { Title, Text } = Typography;

export const NhHelpDrawer = props => {
  const [drawerState, setDrawerState] = useState({ help: false, notifications: false, themes: false });

  const toggleDrawer = (drawerType: string, open: boolean) => setDrawerState(prevState => ({ ...prevState, [drawerType]: open }));

  const experimentData = () => {
    const [isHovered, setIsHovered] = useState(false);
    const hoverOn = () => setIsHovered(true);
    const hoverOff = () => setIsHovered(false);
    const buttonIcon = isHovered ? <BookOutlined style={buttonColor} /> : <BookOutlined />;
    return (
      <a href={`https://${window.location.host}/unifie-api/graphql/v1`} target="_blank">
        <TipBottom tip="Documentations" key="api">
          <Button type="text" icon={buttonIcon} onMouseEnter={hoverOn} onMouseLeave={hoverOff} />
        </TipBottom>
      </a>
    );
  };

  const buttonsData = type => {
    const [isHovered, setIsHovered] = useState(false);

    const buttonIcon = {
      help: { title: 'Help', icon: <QuestionCircleOutlined />, hoverIcon: <QuestionCircleOutlined style={buttonColor} /> },
      notifications: { title: 'Notifications', icon: <BellOutlined />, hoverIcon: <BellOutlined style={buttonColor} /> },
      themes: { title: 'Themes', icon: <SettingOutlined />, hoverIcon: <SettingOutlined style={buttonColor} /> },
    };

    const buttonTooltip = (type: string | number) => buttonIcon[type] || { title: '', icon: null };

    const { title, icon, hoverIcon } = buttonTooltip(type);

    const buttonIcons = () => (
      <TipBottom tip={title} key={type}>
        <Button type="text" icon={isHovered ? hoverIcon : icon} />
      </TipBottom>
    );

    const handleButtonClick = () => toggleDrawer(type, true);
    const hoverOn = () => setIsHovered(true);
    const hoverOff = () => setIsHovered(false);

    return (
      <Space role="button" tabIndex={0} key={`buttons${type}`} onClick={handleButtonClick} onMouseEnter={hoverOn} onMouseLeave={hoverOff}>
        {props.openBtnUI ? props.openBtnUI : buttonIcons()}
      </Space>
    );
  };

  const drawersData = (type: string) => {
    const helpText = (
      <Space key="S1" direction="horizontal">
        <Button type="primary" icon={<QuestionCircleOutlined />} />
        <Text> Look out for this icon to access help and related resources. </Text>
      </Space>
    );

    const guideText = (
      <Space key="S2" direction="vertical">
        <Text> Learn how to utilize {PROJECT_NAME} full potential using guided tutorials, and in-depth help guides. </Text>
        <Button type="primary" block>
          Start Learning
        </Button>
      </Space>
    );

    const supportText = (
      <Space key="S3" direction="vertical">
        <Text> Reach out to our support team for further questions and assistance </Text>
        <Button type="primary" block>
          Contact Us
        </Button>
      </Space>
    );

    const sections = [
      { key: 'section1', title: 'Need help?', content: helpText, divider: true },
      { key: 'section2', title: 'How to guide', content: guideText, divider: true },
      { key: 'section3', title: 'Help & Support', content: supportText, divider: false },
    ];

    const drawerHelp = () => (
      <Space direction="vertical">
        {sections.map(({ key, title, content, divider }) => (
          <Fragment key={key}>
            <Title level={4}> {title} </Title>
            {content}
            {divider && <Divider />}
          </Fragment>
        ))}
      </Space>
    );

    const drawerNotifications = () => (
      <Space direction="horizontal">
        <BellTwoTone twoToneColor="#00A58E" />
        <Text> Notifications Coming Soon... </Text>
      </Space>
    );

    const drawerContents = { help: drawerHelp(), notifications: drawerNotifications(), themes: <TenantConfiguration /> };

    return (
      <Drawer
        width={300}
        key={`drawers ${type}`}
        title={type.charAt(0).toUpperCase() + type.slice(1)}
        onClose={() => toggleDrawer(type, false)}
        open={drawerState[type]}
      >
        {drawerContents[type]}
      </Drawer>
    );
  };

  const appDrawer = () => (
    <Space direction="horizontal">
      {experimentData()}
      {['help', 'notifications', 'themes'].map(type => (
        <Space key={type}>
          {buttonsData(type)}
          {drawersData(type)}
        </Space>
      ))}
    </Space>
  );

  return appDrawer();
};
