export const ServiceSrcType_HelmChart = 'Yes-HelmChart';
export const ServiceSrcType_NoHelmChart = 'No-HelmChart';

export function isServiceNameValid(serviceName: string): boolean {
  return /^[a-zA-Z0-9-]+$/.test(serviceName) && serviceName !== `new` && serviceName !== `new-service` && serviceName !== ``;
}

export function isClusterNameValid(serviceName: string): boolean {
  return /^[a-zA-Z0-9-]+$/.test(serviceName) && serviceName !== `new` && serviceName !== `new-service` && serviceName !== ``;
}
