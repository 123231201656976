import { Space, Typography } from 'antd';
import { UserNoPermissions } from 'components/Permissions/Permissions';
import { Center, InfoApplication, BigOption, BigOptions, CreateCard } from 'components/SharedComponents/BigOptions/BigOptions';

import { userHasPermission, UserPermissions } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';

export interface iNewClusterPageProps {}
const { Title, Text, Link } = Typography;

export const NewClusterOptions = (props: iNewClusterPageProps) => {
  return (
    <Space direction="vertical">
      <BigOption
        name={`AWS`}
        tip={`Hosted on your own cloud`}
        path="/cluster/create/aws?hasCluster=false&useTerraform=true"
        secondary="Create new cluster in your own AWS EKS automatically"
      />
      <Center>
        <Space direction="vertical">
          <Text /> <Text />
          <Center>
            <Title level={4}> Or connect existed cluster </Title>
          </Center>
        </Space>
      </Center>
      <BigOptions
        options={[
          {
            name: `AWS`,
            tip: `Deploy application in AWS EKS cluster`,
            img: null,
            path: '/cluster/create/aws?hasCluster=true&useTerraform=false',
            secondary: 'Hosted on your own cloud',
          },
          {
            name: `GCP`,
            tip: `Deploy application in AWS EKS cluster`,
            img: null,
            path: '/cluster/create/gcp?hasCluster=true&useTerraform=false',
            secondary: 'Hosted on your own cloud',
          },
          {
            name: `Azure`,
            tip: `Deploy application in AWS EKS cluster`,
            img: null,
            path: '/cluster/create/azure?hasCluster=true&useTerraform=false',
            secondary: 'Hosted on your own cloud',
          },
          {
            name: `K3s`,
            tip: `Deploy application in AWS EKS cluster`,
            img: null,
            path: '/cluster/create/k3s?hasCluster=true&useTerraform=false',
            secondary: 'Hosted on your own server',
          },
          {
            name: `Other`,
            tip: `Deploy application in any other cloud`,
            img: null,
            path: '/cluster/create/other?hasCluster=true&useTerraform=false',
            secondary: 'Hosted on your own cloud',
          },
        ].filter(v => !!v)}
      />
    </Space>
  );
};

export const NewClusterPage = (props: iNewClusterPageProps) => {
  const user = useUser();

  if (!userHasPermission(user, UserPermissions.DeploymentCreate)) {
    return <UserNoPermissions permission={UserPermissions.DeploymentCreate} />;
  }

  return (
    <Center>
      <Space direction="vertical">
        <CreateCard title={`Let\`s create a new cluster`}> Please choose how you want to add a new cluster. </CreateCard>
        <NewClusterOptions />
      </Space>
    </Center>
  );
};
