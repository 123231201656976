import { useParams } from 'react-router-dom';
import { getUrlHashParams } from 'utils/common';
import { ClusterCreaionV2_awsTf } from './ClusterCreaionV2_awsTf';
import { ClusterCreaionV2_connect } from './ClusterCreaionV2_connect';

export const ClusterCreaionV2 = () => {
  const addOptions = getUrlHashParams();
  const params: any = useParams();
  const provider = params.provider;

  const useAwsTerraform = (addOptions?.useTerraform === 'true' && provider === `aws`) || false;

  if (useAwsTerraform) {
    return <ClusterCreaionV2_awsTf />;
  }

  return <ClusterCreaionV2_connect provider={provider} />;
};
