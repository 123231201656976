import { useCallback, useEffect } from 'react';
import { Button, Card, Flex, message } from 'antd';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { buttonColor, floatingRight, fullScreenView, primaryBorder } from 'utils/styles';
import { TipLeft } from '../Tooltip/Tooltip';

interface FullScreenProps {
  children?: React.ReactNode;
  isFullscreen?: boolean;
  setIsFullscreen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FullScreenButton: React.FC<FullScreenProps> = ({ isFullscreen, setIsFullscreen }) => {
  const handleFullScreenViewClick = () => {
    setIsFullscreen(!isFullscreen);
    message.info('Click ESC key to go back');
  };
  return (
    <TipLeft tip="Click here for Full Screen View">
      <Flex justify="flex-end" style={floatingRight}>
        <Button icon={<FullscreenOutlined style={buttonColor} />} onClick={handleFullScreenViewClick} />
      </Flex>
    </TipLeft>
  );
};

export const FullScreenEditor: React.FC<FullScreenProps> = ({ children, isFullscreen, setIsFullscreen }) => {
  const handleESC = useCallback(
    (event: KeyboardEvent) => (event.key === 'Escape' && isFullscreen ? setIsFullscreen(false) : null),
    [isFullscreen, setIsFullscreen],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleESC);
    return () => window.removeEventListener('keydown', handleESC);
  }, [handleESC]);

  const fullScreenContent = () => {
    const cardExtra = (
      <TipLeft tip="Click here to exit Full Screen View">
        <Button size="small" danger icon={<FullscreenExitOutlined />} onClick={() => setIsFullscreen(false)} />
      </TipLeft>
    );
    return (
      isFullscreen && (
        <Card size="small" extra={cardExtra} style={primaryBorder}>
          {children}
        </Card>
      )
    );
  };

  return (
    <div style={isFullscreen ? fullScreenView : undefined}>
      {fullScreenContent()}
      {children}
    </div>
  );
};

FullScreenEditor.defaultProps = { isFullscreen: false, setIsFullscreen: () => {} };
