import { useState } from 'react';
import { applyPostProcessingRules } from 'shared/postProcessingRules';
import { iPostProcessingRule } from 'shared/project.interface';
import { getK8sDocs, k8sDocsKindItem } from './k8sDocs';
import { RulesListComponent } from './RulesListComponent';
import { SpecsRenderResult } from 'shared/yaml';
import { Button, Collapse, Popover, Result, Space, notification } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { AddIcoStyle, dotTwo, floatingRight, minusKey, postContent, postPop, relatively, spaceWidth } from 'utils/styles';
import { SplitterComp } from 'components/SharedComponents/Splitter/SplitterComp';

export interface iYamlSpecCustomizationComponentProps {
  onChange: (rules: any[]) => void;
  specs: SpecsRenderResult;
  syncVars: any;
  rules: iPostProcessingRule[];
}

interface iYamlElementProps {
  spec: any;
  level: number;
  path?: string;
  keyName?: string;
  onNewRule: (rule: iPostProcessingRule) => void;
  mainKind: string;
  mainMetadataName: string;
}

interface iYamlKeyNameProps {
  name: string;
  path: string;
  onNewRule: (rule: iPostProcessingRule) => void;
  mainKind: string;
  mainMetadataName: string;
  showAddButton?: boolean;
}

const { Panel } = Collapse;

export const YamlSpecCustomizationComponent = (props: iYamlSpecCustomizationComponentProps) => {
  const { rules, syncVars, onChange } = props;

  const [rulesList, setRulesList] = useState(rules || []);
  const [curentSpecs, setResultSpecs] = useState(null);
  // const [loader, setLoader] = useState(true);

  const specs = props?.specs?.specs;
  const resultSpecs = curentSpecs !== null ? curentSpecs : applyPostProcessingRules(rulesList, specs, syncVars);

  const onNewRule = (rule: iPostProcessingRule) => {
    if (rulesList.find(r => r.path === rule.path)) {
      notification.error({ message: 'Error', description: 'Rule already exists' });
      return;
    }
    const newRulesList = [...rulesList, rule];
    setRulesList(newRulesList);
    onChange(newRulesList);
    setResultSpecs(applyPostProcessingRules(newRulesList, specs, syncVars));
  };

  const removeRule = (rule: iPostProcessingRule) => {
    const newRulesList = rulesList.filter(r => r.path !== rule.path);
    setRulesList(newRulesList);
    onChange(newRulesList);
    setResultSpecs(applyPostProcessingRules(newRulesList, specs, syncVars));
  };

  const editRule = (rule: iPostProcessingRule) => {
    const newRulesList = rulesList.map(r => {
      if (r.path === rule.path) return rule;
      return r;
    });
    setRulesList(newRulesList);
    onChange(newRulesList);
    setResultSpecs(applyPostProcessingRules(newRulesList, specs, syncVars));
  };

  if (!resultSpecs && !rulesList) {
    return (
      <Result
        status="404"
        title="Your yaml is not ready yet."
        subTitle="This tab allow to edit YAML specification before send it to Kubernetes cluster."
      />
    );
  }

  const panelData = () => (
    <Space style={spaceWidth}>
      <Collapse size="small" defaultActiveKey={[]}>
        {(resultSpecs || []).map((spec: any, index) => {
          const handleOnClick = event => {
            onNewRule({
              path: `${spec.kind}["${spec?.metadata?.name}"]`,
              action: 'removeFullRecord',
              mainKind: spec.kind,
              mainMetadataName: spec?.metadata?.name,
            });
            event.stopPropagation();
          };
          return (
            <Panel
              style={spaceWidth}
              key={index}
              header={
                <>
                  {spec?.kind} - {spec?.metadata?.name}
                  <Button danger size="small" style={floatingRight} onClick={handleOnClick}>
                    Remove
                  </Button>
                </>
              }
            >
              <YamlElement
                key={`YamlElement_${index}`}
                mainMetadataName={spec?.metadata?.name}
                mainKind={spec.kind}
                spec={spec}
                level={0}
                onNewRule={onNewRule}
              />
            </Panel>
          );
        })}
      </Collapse>
    </Space>
  );

  const valueData = () => (
    <Space style={{ ...spaceWidth, ...relatively, paddingLeft: 15 }}>
      <RulesListComponent editRule={editRule} removeRule={removeRule} rulesList={rulesList} syncVars={syncVars} />
    </Space>
  );

  return (
    <Space direction="vertical" style={spaceWidth}>
      <SplitterComp left={panelData()} right={valueData()} />
    </Space>
  );
};

const MinusKey = () => <span style={minusKey}>-</span>;
const Dot2 = () => <span style={dotTwo}>:</span>;

const YamlKeyName = (props: iYamlKeyNameProps) => {
  const { path, name, mainKind: kind, mainMetadataName: meta, showAddButton: add, onNewRule } = props;
  const pathname = `${path}["${name}"]`;

  const content = () => {
    const docs: k8sDocsKindItem = getK8sDocs(kind, pathname);

    const handleOnClickEdit = () => {
      onNewRule({ path: pathname, action: 'edit', mainKind: kind, mainMetadataName: meta, varValueType: 'string', varValueSrcType: 'hardcoded' });
    };

    const handleOnClickRemove = () => {
      onNewRule({ path: pathname, action: 'remove', mainKind: kind, mainMetadataName: meta });
    };

    const handleOnClickAdd = () => {
      onNewRule({
        path: pathname,
        action: 'add',
        mainKind: kind,
        mainMetadataName: meta,
        varValueType: 'string',
        varValueSrcType: 'hardcoded',
        addPropertyName: 'NewProperty',
      });
    };

    const buttonConfigs = [
      { key: 'edit', label: 'Edit', onClick: handleOnClickEdit, danger: false },
      { key: 'remove', label: 'Remove', onClick: handleOnClickRemove, danger: true },
      { key: 'add', label: 'Add', onClick: handleOnClickAdd, danger: false, condition: add },
    ];
    return (
      <>
        {buttonConfigs.map(
          ({ key, label, onClick, danger, condition }) =>
            (!condition || key !== 'add') && (
              <Button key={key} type="primary" danger={danger} onClick={onClick} style={postContent}>
                {label}
              </Button>
            ),
        )}
        {docs && docs.description ? <div> {docs.description} </div> : null}
      </>
    );
  };

  return (
    <Popover content={content()} title={pathname} trigger="hover">
      <span style={postPop}> {name} </span>
      <Dot2 />
      {add ? <PlusCircleOutlined style={AddIcoStyle} /> : null}
    </Popover>
  );
};

const YamlKeyValue = ({ value }: { value: string }) => <span style={{ color: '#8B4502', paddingLeft: '4px' }}> {value} </span>;

const YamlElement = (props: iYamlElementProps) => {
  const { level, path, spec, mainMetadataName, mainKind, onNewRule, keyName: nameKey } = props;

  const styles = { paddingLeft: `${8 * level}px`, marginLeft: '0px' };

  const res = Object.keys(props?.spec || {}).map((key: string, index: any) => {
    if (Array.isArray(spec[key])) {
      return (
        <div key={`Ye${index}`}>
          <YamlKeyName showAddButton={true} mainMetadataName={mainMetadataName} mainKind={mainKind} name={key} path={path} onNewRule={onNewRule} />
          {spec[key].map((item: any, index) => {
            if (['string', 'number', 'boolean'].includes(typeof item)) {
              const styles = { paddingLeft: `${8 * (level + 1)}px`, marginLeft: '0px' };
              return (
                <div style={styles} key={`YamlDiv_${index}`}>
                  <MinusKey />
                  <YamlKeyValue value={item} />
                </div>
              );
            }

            return (
              <YamlElement
                key={`YamlElement_${index}`}
                mainMetadataName={mainMetadataName}
                mainKind={mainKind}
                spec={item}
                level={level}
                path={`${path}["${key}"][${index}]`}
                keyName={'-'}
                onNewRule={onNewRule}
              />
            );
          })}
        </div>
      );
    }

    if (['string', 'number', 'boolean'].includes(typeof spec[key])) {
      return (
        <div key={`YamlElement_string${index}`}>
          <YamlKeyName mainMetadataName={mainMetadataName} mainKind={mainKind} name={key} path={path} onNewRule={onNewRule} />
          <YamlKeyValue value={spec[key]} />
        </div>
      );
    }

    if (typeof spec[key] === 'object') {
      return (
        <div key={`YamlElement_Object${index}`}>
          <YamlKeyName showAddButton={true} mainMetadataName={mainMetadataName} mainKind={mainKind} name={key} path={path} onNewRule={onNewRule} />
          <YamlElement
            key={`YamlElement_${index}`}
            mainMetadataName={mainMetadataName}
            mainKind={mainKind}
            spec={spec[key]}
            keyName={key}
            level={level + 1}
            path={`${path}["${key}"]`}
            onNewRule={onNewRule}
          />
        </div>
      );
    }
  });

  if (nameKey == '-') {
    return (
      <div style={styles}>
        <MinusKey />
        <div> {res} </div>
      </div>
    );
  }

  return (
    <div style={styles} key={path}>
      {res}
    </div>
  );
};
