import { apiQuery } from '../utils/common';

class VersionsService {
  publicVersions(projectId: number) {
    return apiQuery('GET', `/api/versions/${projectId}`);
  }

  async getInfoUpdateDeployment(versionId: any) {
    return apiQuery('GET', `/api/versions/${versionId}/versionParent`);
  }

  async getProjectVersionsList(projectId: number, where: string) {
    return apiQuery('GET', `/api/admin/VersionModel?projectId=${projectId}&${where}`);
  }
}

const versionsService = new VersionsService();
export { versionsService, VersionsService };
