import gql from 'graphql-tag';
import { useEffect } from 'react';
import { PROJECT_CODE_NAME, PROJECT_NAME } from 'interface/common';
import { useAuthedQuery } from 'utils/qlAuth';
import { CodeLine } from 'components/SharedComponents/CodeLine/CodeLine';
import { Space, Typography } from 'antd';

const { Text } = Typography;

export const InstallationFile = (props: { hasMetacontroller: boolean; regionId: number; action: 'install' | 'uninstall' | 'update' }) => {
  const { loading, error, data, stopPolling, startPolling } = useAuthedQuery(
    gql`
      query RegionsController_getRegionInstallKey($regionId: Int!) {
        RegionsController_getRegionInstallKey(regionId: $regionId)
      }
    `,
    { variables: { regionId: Number(props.regionId) }, pollInterval: 10000 },
  );
  startPolling(10000);

  useEffect(() => {
    return stopPolling;
  }, []);

  const metaUrl = `${window.location.origin.replace(':3000', ':4001')}/k8s/apply/${encodeURIComponent(data?.RegionsController_getRegionInstallKey)}`;
  const installPath = `${props.hasMetacontroller === false ? `${metaUrl}?addMt=4.10.3` : metaUrl}`;

  let command = `apply`;
  if (props.action === 'uninstall') command = `delete`;

  return (
    <Space direction="vertical">
      <Text strong>
        Run to {props.action} {PROJECT_NAME} agent to your cluster
      </Text>

      <CodeLine>{`kubectl ${command} -f ${installPath}`}</CodeLine>

      {props.action === 'install' && (
        <>
          <Text> It will add new namespace and install the {PROJECT_CODE_NAME} agent in your Cluster. It can be added to the exiting Cluster. </Text>
          <Text strong> But we are recommend to create a new empty Cluster for tests. </Text>
        </>
      )}
    </Space>
  );
};
