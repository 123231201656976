import { useContext } from 'react';
import { JsonFormModalUI } from './JsonFormModalUI';
import { ProjectDeploymentSyncVarsContext } from '../ProjectDeploymentContext';
import { CommonFormUI, CommonUI_DeploymentVarWidget, CommonUI_text } from './CommonFormUI';
import { form_schema_livenessProbe, form_schema_ui_livenessProbe } from './schema/ContainerLivenessProbe';
import { form_env_schema, form_env_schema_ui } from './schema/ContainerEnv';
import { form_ports_schema, form_ports_schema_ui } from './schema/ContainerPorts';
import { containerVolumeMountsDynamicSchema } from './schema/ContainerVolumeMounts';
import { Button, Modal, Select, Space, Tabs, Tag } from 'antd';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { selectWidth } from 'utils/styles';

export interface iContainersUIProps {
  yamlObj: any;
  onChange: (key: string, newObj: any, action: string) => void;
}

interface iNewContainersUIBtn {
  onChange: (arg0: string, arg1: { name: string; image: string; ports?: { containerPort: number }[] }, arg2: string) => void;
}

interface iOneContainersUIForm {
  deploymentYaml: { spec: { template: { spec: { volumes: string } } } };
  indexInArray: number;
  type: string;
  onChange: any;
  path: string;
  container: { name: any; image: any; livenessProbe: any; restartPolicy: any; imagePullPolicy: any; env: string; ports: any; volumeMounts: string };
}

const { Option } = Select;

export const ContainersUI = (props: iContainersUIProps) => {
  const tabsArr = [];

  let initContainers = props.yamlObj?.spec?.template?.spec?.initContainers;
  if (Array.isArray(initContainers) === false) initContainers = [];
  initContainers.forEach((current_container, i) => {
    const initContainerChildren = (
      <OneContainersUIForm
        type={`initContainers`}
        path={`.spec.template.spec.initContainers`}
        indexInArray={i}
        container={current_container}
        onChange={props.onChange}
        deploymentYaml={props.yamlObj}
      />
    );
    tabsArr.push({ label: `[init] ${current_container.name || 'container ' + i}`, key: `init-container-${i}`, children: initContainerChildren });
  });

  let containers = props.yamlObj?.spec?.template?.spec?.containers;
  if (Array.isArray(containers) === false) containers = [];
  containers.forEach((current_container, i) => {
    const containerChildren = (
      <OneContainersUIForm
        type={`containers`}
        path={`.spec.template.spec.containers`}
        indexInArray={i}
        container={current_container}
        onChange={props.onChange}
        deploymentYaml={props.yamlObj}
      />
    );
    tabsArr.push({ label: `${current_container.name || 'container ' + i}`, key: `container-${i}`, children: containerChildren });
  });

  const arrLabel = (
    <>
      New <PlusCircleOutlined />
    </>
  );

  tabsArr.push({ label: arrLabel, key: `container-new`, children: <NewContainersUIBtn onChange={props.onChange} /> });
  return <Tabs tabPosition={'top'} defaultActiveKey="1" items={tabsArr} />;
};

const NewContainersUIBtn = (props: iNewContainersUIBtn) => {
  const handleButtonClickNewContainersUIBtn = () =>
    props.onChange(`.spec.template.spec.containers`, { name: `new`, image: `karthequian/helloworld:latest`, ports: [{ containerPort: 80 }] }, `push`);
  return (
    <Space direction="vertical">
      <Button onClick={handleButtonClickNewContainersUIBtn}> Create new container </Button>
      <Button onClick={() => props.onChange(`.spec.template.spec.initContainers`, { name: `init`, image: `karthequian/helloworld:latest` }, `push`)}>
        Create new init container
      </Button>
    </Space>
  );
};

const OneContainersUIForm = (props: iOneContainersUIForm) => {
  const syncVarsContext = useContext(ProjectDeploymentSyncVarsContext);

  const path = `${props.path}[${props.indexInArray}]`;

  const nameChildren = () => <CommonUI_text value={props.container.name} onChange={props.onChange} path={path} propertyName={`name`} />;

  const imageChildren = () => (
    <CommonUI_DeploymentVarWidget value={props.container.image} onChange={props.onChange} path={path} propertyName={`image`} />
  );

  const livenessProbeChildren = () => {
    const handleButtonClickLivenessProbeChildren = () => {
      JsonFormModalUI({
        formData: { ports: props?.container?.livenessProbe || {} },
        formConfigs: { schema: form_schema_livenessProbe, ui: form_schema_ui_livenessProbe },
        SyncVarsContext: syncVarsContext,
        title: `Liveness probe`,
        onChange: v => props.onChange(`${props.path}[${props.indexInArray}].livenessProbe`, v),
      });
    };
    return (
      <Space>
        <Button onClick={handleButtonClickLivenessProbeChildren}>
          <EditOutlined />
        </Button>
        <Tag color={props.container.livenessProbe ? 'green' : undefined}> {props.container.livenessProbe ? 'Configured' : 'Not configured'} </Tag>
      </Space>
    );
  };

  const restartPolicyChildren = () => {
    const handleSelectChangeRestartPolicyChildren = v => props.onChange(`${props.path}[${props.indexInArray}].restartPolicy`, v);
    const restartOptions = [
      { value: 'Always', label: 'Always' },
      { value: 'OnFailure', label: 'On Failure' },
      { value: 'Never', label: 'Never' },
    ];
    return (
      <Select value={props.container.restartPolicy || 'Always'} onChange={handleSelectChangeRestartPolicyChildren} style={selectWidth}>
        {restartOptions.map(option => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    );
  };

  const imagePullPolicyChildren = () => {
    const handleSelectChangeImagePullPolicyChildren = v => props.onChange(`${props.path}[${props.indexInArray}].imagePullPolicy`, v);
    const imageOptions = [
      { value: 'Always', label: 'Always' },
      { value: 'IfNotPresent', label: 'If Not Present' },
      { value: 'Never', label: 'Never' },
    ];
    return (
      <Select value={props.container.imagePullPolicy || 'Always'} onChange={handleSelectChangeImagePullPolicyChildren} style={selectWidth}>
        {imageOptions.map(option => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    );
  };

  const environmentVariablesChildren = () => {
    const handleButtonClickEnvironmentVariablesChildren = () => {
      JsonFormModalUI({
        formData: props?.container?.env || [],
        formConfigs: { schema: form_env_schema, ui: form_env_schema_ui },
        SyncVarsContext: syncVarsContext,
        onChange: v => props.onChange(`${props.path}[${props.indexInArray}].env`, v),
      });
    };
    return (
      <Space>
        <Button onClick={handleButtonClickEnvironmentVariablesChildren}>
          <EditOutlined />
        </Button>
        {(props?.container?.env || []).length ? <Tag color="green"> {props?.container?.env?.length} env vars </Tag> : <Tag> Not configured </Tag>}
      </Space>
    );
  };

  const portsChildren = () => {
    const handleButtonClickPortsChildren = () => {
      JsonFormModalUI({
        title: `Volume mounts configuration`,
        formData: props?.container?.ports || [],
        formConfigs: { schema: form_ports_schema, ui: form_ports_schema_ui },
        SyncVarsContext: syncVarsContext,
        onChange: v => props.onChange(`${props.path}[${props.indexInArray}].ports`, v),
      });
    };
    return (
      <Space>
        <Button onClick={handleButtonClickPortsChildren}>
          <EditOutlined />
        </Button>
        <Tag color={props.container.ports ? 'green' : undefined}> {props.container.ports ? 'Configured' : 'Not configured'} </Tag>
      </Space>
    );
  };

  const volumeMountsChildren = () => {
    const errorModal = { title: `No volumes configured`, content: `Please configure volumes first on the Volumes tab.` };
    const handleButtonClickVolumeMountsChildren = () => {
      if (!props.deploymentYaml?.spec?.template?.spec?.volumes?.length) {
        Modal.error(errorModal);
        return;
      }
      JsonFormModalUI({
        formData: props?.container?.volumeMounts || [],
        formConfigs: containerVolumeMountsDynamicSchema(props.deploymentYaml, props.container),
        SyncVarsContext: syncVarsContext,
        onChange: v => props.onChange(`${props.path}[${props.indexInArray}].volumeMounts`, v),
      });
    };
    return (
      <Space>
        <Button onClick={handleButtonClickVolumeMountsChildren}>
          <EditOutlined />
        </Button>
        <Tag color={props.container.volumeMounts && props.container.volumeMounts.length > 0 ? 'green' : undefined}>
          {props.container.volumeMounts && props.container.volumeMounts.length > 0
            ? `${props.container.volumeMounts.length} volume`
            : 'Not configured'}
        </Tag>
      </Space>
    );
  };

  const formContent = () => {
    const formData = [
      { name: 'Name', children: nameChildren() },
      { name: 'Image', children: imageChildren() },
      { name: 'Liveness probe', children: livenessProbeChildren() },
      { name: 'Restart policy', children: restartPolicyChildren() },
      { name: 'Image pull policy', children: imagePullPolicyChildren() },
      { name: 'Environment variables', children: environmentVariablesChildren() },
      { name: 'Ports', children: portsChildren() },
      { name: 'Volume mounts', children: volumeMountsChildren() },
    ];
    return <CommonFormUI form={formData} />;
  };

  return (
    <>
      {formContent()}
      <Button type="dashed" danger onClick={() => props.onChange(props.path, props.indexInArray, `splice`)}>
        Delete
      </Button>
    </>
  );
};
