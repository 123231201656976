export const stats_opencost = [
  {
    id: 0,
    lg: 24,
    span: `24`,
    name: 'Price for last 30 days',
    vizState: {
      chartType: 'area',
      query: {
        limit: 500,
        dimensions: ['Deployments.name'],
        timeDimensions: [{ dimension: 'CostHistory.timeStart', granularity: 'day', dateRange: 'Last 30 days' }],
        measures: ['CostHistory.sum'],
        order: { 'CostHistory.sum': 'desc' },
        filters: [
          { member: 'CostHistory.timeInterval', operator: 'equals', values: ['hour'] },
          { member: 'CostHistory.costProvider', operator: 'equals', values: ['opencost'] },
          { member: 'CostHistory.deploymentId', operator: 'gt', values: ['0'] },
        ],
      },
    },
  },
];
