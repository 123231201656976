import { Link } from 'react-router-dom';
import { iDeployment } from 'shared/deployment';
import { LockOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

interface iDeploymentApplyWidget {
  deployment: iDeployment;
}

export const DeploymentApplyWidget = ({ deployment }: iDeploymentApplyWidget) => {
  const { useManualApply, id, hasChangesForApply } = deployment;
  return !useManualApply ? null : (
    <Link to={`/app/${id}/status/deploy`}>
      <Tag color={hasChangesForApply ? 'primary' : 'cyan'} icon={<LockOutlined />}>
        Check and deploy
      </Tag>
    </Link>
  );
};
