import { IEnvSettingSchema } from 'interface/common';
import { iDeployment } from 'shared/deployment';
import { Popover, Typography } from 'antd';
import { InfoCircleOutlined, WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface EnvValidatorArgs {
  name: string;
  value: string;
  deployment: iDeployment;
}

const validators: any = {};
/**
 * Validator function used in DeploymentEnvVariablesInfo
 * @param param0  EnvValidatorArgs
 * @returns
 */

validators.validator_func_NotEmpty = ({ name, value, deployment }: EnvValidatorArgs) => {
  return value && String(value).length > 0;
};

/**
 * Validator function used in DeploymentEnvVariablesInfo
 * @param param0  EnvValidatorArgs
 * @returns
 */

validators.validator_func_deprecated = ({ name, value, deployment }: EnvValidatorArgs) => {
  return false;
};

/**
 * Validator function used in DeploymentEnvVariablesInfo
 * @param param0  EnvValidatorArgs
 * @returns
 */

validators.validator_func_unsupported = ({ name, value, deployment }: EnvValidatorArgs) => {
  return false;
};

/**
 * Validator function used in DeploymentEnvVariablesInfo
 * @param param0  EnvValidatorArgs
 * @returns
 */

validators.validator_func_number = ({ name, value, deployment }: EnvValidatorArgs) => {
  return !/[^0-9]/.test(String(value));
};

/**
 * Validator function used in DeploymentEnvVariablesInfo
 * @param param0  EnvValidatorArgs
 * @returns
 */

validators.validator_func_Host = ({ name, value, deployment }: EnvValidatorArgs) => {
  return value && String(value).length > 0;
};

/**
 * Validator function used in DeploymentEnvVariablesInfo
 * @param param0  EnvValidatorArgs
 * @returns
 */

validators.validator_func_URL = ({ name, value, deployment }: EnvValidatorArgs) => {
  return value && String(value).length > 0;
};

/**
 * Validator function used in DeploymentEnvVariablesInfo
 * @param param0  EnvValidatorArgs
 * @returns
 */

validators.validator_func_boolean = ({ name, value, deployment }: EnvValidatorArgs) => {
  return ['true', 'false'].includes(value);
};

export interface iDeploymentEnvVariablesInfoProps {
  projectEnv: IEnvSettingSchema;
  deployment: iDeployment;
  name: string;
  value: string;
}

export function DeploymentEnvVariablesInfo(props: iDeploymentEnvVariablesInfoProps) {
  const { name, value, deployment, projectEnv } = props;

  const envDocs = projectEnv.env.find(e => e.name === name);

  if (!envDocs) {
    return (
      <Popover
        content={
          <div>
            <Text> No docs for this variable. </Text>
          </div>
        }
        title={`Docs for ${name}`}
      >
        <InfoCircleOutlined style={{ color: '#CCCCCC' }} />
      </Popover>
    );
  }

  let icon = <InfoCircleOutlined style={{ color: '#40A9FF' }} />;

  const validator = `validator_func_${envDocs.validator}`;
  if (envDocs.validator && validators[validator]) {
    if (!validators[validator]({ name, value, deployment })) icon = <WarningOutlined style={{ color: '#FF4D4F' }} />;
    else icon = <CheckCircleOutlined style={{ color: '#389E0D' }} />;
  }

  let content = envDocs.description;
  return (
    <Popover content={content} title={`Docs for ${name}`}>
      {icon}
    </Popover>
  );
}
