export interface iUserModel {
  id: number;
  name: string;
  avatar: string;
  password: string;
  createdAt: Date;
  deletedAt: Date;
  email: string;
  lastLogin: Date;
  blocked: Date;
  isBlocked: boolean;
  roles: string[];
  allowApi: boolean;
}

export interface iMyUserData {
  // settings: any;
  name: string;
  avatar: string;
  id: number;
  email: string;
  deletedAt?: string | Date;
  tenant: number;
  isConfirmed: boolean;
  createdAt: Date;
  updatedAt: Date;
  antThemeName: string;
  antThemeToken: any;
  githubLogin: string;
  roles: string[];
  allowApi: boolean;
}

export const isVariableSubDomainName = name =>
  !(!name || name.length < 3 || /[^a-z0-9\-]/.test(name) || /^-|-$/.test(name))
    ? !['localhost', 'nanoheal', 'undefined', 'null', 'admin', `auth`, `sso`, `docs`].includes(name)
    : false;
