import gql from 'graphql-tag';
import { useEffect } from 'react';
import { Console } from 'console-feed';
import { useAuthedQuery } from 'utils/qlAuth';
import { iRegionModel } from 'shared/deployment';
import { Alert, Skeleton, Space, Typography } from 'antd';
import { bottomMargin, logsStyle, spaceWidth } from 'utils/styles';

const { Text } = Typography;
interface iRegionMetacontrollerLogsLogsProps {
  region: iRegionModel;
}

export function RegionMetacontrollerLogsLogs(props: iRegionMetacontrollerLogsLogsProps) {
  const { loading, error, data, stopPolling, startPolling } = useAuthedQuery(
    gql`
      query DeploymentLogsController_metacontrollerRegionLogs($regionId: Int!) {
        DeploymentLogsController_metacontrollerRegionLogs(regionId: $regionId) {
          error
          logs {
            method
            data
            pod
          }
        }
      }
    `,
    { variables: { regionId: props.region.id }, pollInterval: 25000 },
  );
  startPolling(25000);

  useEffect(() => {
    return stopPolling;
  }, []);

  let podLogs = data?.DeploymentLogsController_metacontrollerRegionLogs.logs;
  const agentError = data?.DeploymentLogsController_metacontrollerRegionLogs.error;

  return (
    <Skeleton active={true} loading={loading && !error && !podLogs}>
      {(agentError || error || (!loading && !podLogs)) && (
        <Alert message={error?.message || agentError || error || `Something went wrong.`} type="error" style={bottomMargin} />
      )}
      {podLogs && podLogs.length ? (
        <Space direction="vertical" style={spaceWidth}>
          <Alert showIcon type="info" message="Here are the Sync logs." />
          <Text />
          <div style={logsStyle}>
            <Console logs={podLogs.flat()} variant="light" />
          </div>
        </Space>
      ) : null}
    </Skeleton>
  );
}
