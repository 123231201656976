/* IOneEnvSchema & Copy from packages/cloud-backend/src/ui-schema/env.form.ts */
export interface IOneEnvSchema {
  name: string;
  default: string;
  description?: string; // UI
  validator: 'no' | 'NotEmpty' | 'number' | 'unsupported' | 'deprecated' | 'boolean' | 'Host' | 'URL';
  required?: boolean;
  type?: string;
}

/* IEnvSettingSchema & Copy from packages/cloud-backend/src/ui-schema/env.form.ts */
export interface IEnvSettingSchema {
  env: IOneEnvSchema[];
}

export interface defaultAjaxResponse {
  error?: string;
  id?: number;
}

export interface CrudBodyFilters {
  current?: number;
  pageSize?: number;
}

export const PROJECT_NAME = 'Unifie';
export const PROJECT_CODE_NAME = 'unifie.cloud';
