import { useHistory } from 'react-router-dom';
import { useState, useRef, ReactElement, ReactNode, ReactPortal } from 'react';
import { ModalForm, ProFormCheckbox, ProFormDigit, ProFormSelect, ProFormText, ProFormTextArea } from '@ant-design/pro-form';
import { Button, message, Drawer, Modal, Form, Space, Input, Dropdown, Menu, Typography } from 'antd';
import { FooterToolbar } from '@ant-design/pro-layout';
import { CheckOutlined, EditOutlined, ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined, StarOutlined } from '@ant-design/icons';
import { StopOutlined } from '@ant-design/icons';
import { buttonWidth, flexDisplay } from 'utils/styles';
import ProDescriptions from '@ant-design/pro-descriptions';
import ProTable from '@ant-design/pro-table';
import type { ProColumns, ActionType } from '@ant-design/pro-table';

export type CRUDTableProps = {
  columns: any[];
  actions?: any[];
  canCreate?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
  crud: any;
  primaryKey: string;
};

interface iRenderEntity {
  [x: string]: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
}

const styles: any = {};

const { Title, Text } = Typography;
const { Item, List } = Form;
const { Password } = ProFormText;

export const inputMap = (attr, currentRow) => {
  const name = attr.name;
  const label = attr.label;

  const listData = () => (
    <List name={name} initialValue={Object.keys(currentRow[name]).map(k => ({ name: k, value: currentRow[name][k] }))}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, fieldKey, ...restField }) => (
            <Space key={key} align="baseline" style={{ ...flexDisplay, marginBottom: 8 }}>
              {['name', 'value'].map(field => {
                const itemRules = [{ required: true, message: `Missing ${field}` }];
                return (
                  <Item key={field} {...restField} name={[name, field]} fieldKey={[fieldKey, field]} rules={itemRules}>
                    <Input placeholder={field === 'name' ? 'Enter Variable Name Here' : 'Enter Value Here'} />
                  </Item>
                );
              })}
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))}
          <Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add variable
            </Button>
          </Item>
        </>
      )}
    </List>
  );
  return (
    <div key={name}>
      <Text> {label} </Text>
      {listData()}
    </div>
  );
};

export const CRUDTable: React.FC<CRUDTableProps> = (props: CRUDTableProps) => {
  const { crud: cruds, columns: col, primaryKey: primary, canUpdate: update, actions, canCreate: creates, canDelete: deletes } = props;

  /** Pop-up window for new window*/
  const [createModalVisible, handleModalVisible] = useState<boolean>(false);
  /** The pop-up window of the distribution update window */
  const [updateModalVisible, handleUpdateModalVisible] = useState<boolean>(false);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<any>();
  const [selectedRowsState, setSelectedRows] = useState<any[]>([]);

  const actionRef = useRef<ActionType>();
  const history = useHistory();

  /** Add @param fields */
  const handleAdd = async fields => {
    const hide = message.loading('Adding');
    try {
      await cruds.addObj({ ...fields });
      hide();
      message.success('Added successfully');
      return true;
    } catch (error) {
      hide();
      error && error.data && error.data.message
        ? message.error(error.data.message)
        : message.error(`Failed to add, please try again! ${`\n${JSON.stringify(error)}`}`);
      return false;
    }
  };

  /** @param fields */
  const handleUpdate = async (fields, rowId: number) => {
    const hide = message.loading('Configuring');
    try {
      await cruds.updateObj(rowId, fields);
      hide();
      message.success('Configuration is successful');
      return true;
    } catch (error) {
      hide();
      message.error('Configuration failed, please try again!');
      return false;
    }
  };

  /** @param selectedRows */
  const handleAction = async (action, selectedRows: any[]) => {
    if (!selectedRows) return true;
    const opt = { history };
    try {
      selectedRows.map(async row => {
        action.callback ? await action.callback(row, opt) : await cruds.actionObj(action, row.id, opt);
        message.loading('Action in progress..', 0);
      });
      return true;
    } catch (error) {
      message.error('Action failed, please try again');
      return false;
    }
  };

  /** @param selectedRows */
  const handleRemove = async (selectedRows: any[]) => {
    const hide = message.loading('deleting');
    if (!selectedRows) return true;
    try {
      selectedRows.map(({ id }) => cruds.removeObj(id));
      hide();
      message.success('Deleted successfully and will be refreshed soon');
      return true;
    } catch (error) {
      hide();
      message.error('Deletion failed, please try again');
      return false;
    }
  };

  const columns: ProColumns<any>[] = col.map(column => {
    if (!column.render && (column.valueType === 'ProFormCheckbox' || column.valueType === 'boolean')) {
      column.render = (entity: { [x: string]: any }) =>
        entity[column.dataIndex] ? <CheckOutlined style={{ color: '#1F6D00' }} /> : <StopOutlined style={{ color: '#8B5B00' }} />;
    }

    if (column.valueType === 'map' && !column.render) {
      column.normalize = (value: any[]) => (!value ? value : value.reduce((obj, element) => ({ ...obj, [element.name]: element.value }), {}));
      column.render = (entity: { [x: string]: any }) => {
        let resVal = entity[column.dataIndex];
        if (typeof resVal == 'string') {
          try {
            resVal = JSON.parse(resVal);
          } catch (e) {
            return resVal;
          }
        }
        if (Array.isArray(resVal)) {
          return (
            <ul>
              {resVal.map((key, index) => (
                <li key={index}> {key} </li>
              ))}
            </ul>
          );
        }
        if (typeof resVal != 'object') return JSON.stringify(resVal);
        return (
          <table>
            <tbody>
              {Object.keys(resVal).map(key => (
                <tr key={key}>
                  <td className={styles.tdIntable} title={key}>
                    {key}
                  </td>
                  <td className={styles[column.tableStyle || 'tdIntable']} title={resVal[key]}>
                    {resVal[key]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      };
    }

    if (column.valueType === 'link' && !column.render) {
      column.render = entity => {
        const url = entity[column.dataIndex]
          ? /^http/.test(entity[column.dataIndex])
            ? entity[column.dataIndex]
            : `http://${entity[column.dataIndex]}`
          : '';
        return entity[column.dataIndex] ? (
          <a href={url} className={styles.linkIntable} target="_blank">
            {entity[column.dataIndex]}
          </a>
        ) : (
          <Text italic>
            <StopOutlined style={{ color: '#8B5B00' }} /> empty
          </Text>
        );
      };
    }

    if (['text', 'textarea'].includes(column.valueType) && !column.render) {
      column.render = (entity: iRenderEntity) => (
        <div className={styles.textIntable} style={column.styles}>
          {entity[column.dataIndex]}
        </div>
      );
      column.renderAll = (entity: iRenderEntity) => <div> {entity[column.dataIndex]} </div>;
    }

    if (column.dataIndex === primary) {
      column.render = (dom: string | number | boolean | React.ReactElement<string> | Iterable<React.ReactNode> | React.ReactPortal, entity: any) => {
        let menuNotEmpty = update;
        const canUpdateData = () => {
          const handleEditClick = () => {
            setCurrentRow(entity);
            setShowDetail(false);
            handleUpdateModalVisible(true);
            handleModalVisible(false);
          };
          return update ? (
            <Menu.Item key="edit" icon={<EditOutlined />} onClick={handleEditClick}>
              Edit
            </Menu.Item>
          ) : null;
        };

        const actionsData = () => {
          const filteredData = () => action => action.isAllow ? action.isAllow(entity) : true;
          const mappedData = () => (action, index) => {
            menuNotEmpty = true;
            const btnProps: any = {
              className: action.className || undefined,
              style: action.style || undefined,
              icon: action.icon || <StarOutlined />,
            };
            const handleActionClick = async () => await handleAction(action, [entity]);
            return (
              <Menu.Item key={index} {...btnProps} onClick={handleActionClick}>
                {action.name}
              </Menu.Item>
            );
          };
          return actions ? actions.filter(filteredData()).map(mappedData()) : null;
        };

        const menu = (
          <Menu>
            {canUpdateData()}
            {actionsData()}
          </Menu>
        );

        let dropdownProps: any = {};
        if (menuNotEmpty) dropdownProps.overlay = menu;
        const handleButtonClick = () => {
          setCurrentRow(entity);
          setShowDetail(true);
          handleUpdateModalVisible(false);
          handleModalVisible(false);
        };
        return (
          <Space wrap>
            <Dropdown.Button {...dropdownProps} onClick={handleButtonClick}>
              {dom}
            </Dropdown.Button>
          </Space>
        );
      };
    }
    return column;
  });

  const editForm = currentRow => {
    if (!currentRow || !currentRow[primary]) return <Text> No Data </Text>;

    const filteredData = () => (column: any) => column.new != undefined;

    const mappedData = () => (column: any) => {
      let val = currentRow[column.dataIndex];
      const attrKey = `${currentRow.id}-${column.dataIndex}`;
      const attrRules = [{ required: column.new.required, message: `${column.title} is required` }];
      const attr: any = { initialValue: val, key: attrKey, label: column.title, name: column.dataIndex, width: 'lg', rules: attrRules };
      const inputMapInitialValue =
        Array.isArray(val) || typeof val !== 'object' || !val
          ? ((val = {}), (currentRow[column.dataIndex] = {}), [])
          : Object.keys(val).map(k => ({ name: k, value: val[k] }));

      return column.valueType === 'ProFormTextArea' || column.valueType === 'textarea' ? (
        <ProFormTextArea {...attr} />
      ) : ['ProFormText', 'text', 'json', 'link', 'password'].includes(column.valueType) ? (
        <ProFormText {...attr} />
      ) : column.valueType === 'password' ? (
        <ProFormText.Password {...attr} />
      ) : column.valueType === 'map' ? (
        inputMap({ ...attr, initialValue: inputMapInitialValue }, currentRow)
      ) : column.valueType === 'ProFormSelect' || column.valueType === 'select' ? (
        <ProFormSelect
          {...{
            ...attr,
            valueEnum: column.valueEnum || undefined,
            options: column.valueEnum ? Object.keys(column.valueEnum).map(k => ({ value: k, label: column.valueEnum[k] })) : undefined,
            request: column.request || undefined,
            showSearch: true,
            optionFilterProp: !column.filterOption ? 'textName' : undefined,
            filterOption: !column.filterOption
              ? (input: string, option: { children: string }) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              : undefined,
          }}
        />
      ) : column.valueType === 'ProFormDigit' || column.valueType === 'number' ? (
        <ProFormDigit {...attr} />
      ) : column.valueType === 'ProFormCheckbox' || column.valueType === 'boolean' ? (
        <>
          {(attr.options = ['true', 'false'])}
          <ProFormCheckbox {...attr} />
        </>
      ) : null;
    };

    const form = columns.filter(filteredData()).map(mappedData());

    const handleFormOnFinish = async (value: { [x: string]: any }) => {
      columns.map(({ normalize, dataIndex }: any) => (normalize ? (value[dataIndex] = normalize(value[dataIndex])) : null));
      await handleUpdate(value, currentRow[primary]);
      handleUpdateModalVisible(false);
      setCurrentRow({});
      actionRef.current ? actionRef.current.reload() : null;
    };

    return (
      <>
        <Title level={1}>
          {primary} - {currentRow[primary]}
        </Title>
        <Form onFinish={handleFormOnFinish}>
          {form}
          <Button type="primary" htmlType="submit" style={buttonWidth}>
            Save
          </Button>
        </Form>
      </>
    );
  };

  let newBtn = (
    <Button type="primary" key="primary" onClick={() => handleModalVisible(true)}>
      <PlusOutlined /> New
    </Button>
  );

  if (!creates) newBtn = <></>;

  const title = Object.keys(cruds.getFilters).map(key => (
    <span style={{ paddingLeft: '10px' }} key={key}>
      {key} - {cruds.getFilters[key]}
    </span>
  ));

  const proTableData = () => (
    <ProTable
      headerTitle={title}
      actionRef={actionRef}
      rowKey="id"
      search={{ labelWidth: 120 }}
      toolBarRender={() => [newBtn]}
      request={cruds.getObj}
      columns={columns}
      rowSelection={{ onChange: (_, selectedRows) => setSelectedRows(selectedRows) }}
    />
  );

  const footerToolbarData = () => {
    const footerToolbarExtra = (
      <div>
        chosen <Text style={{ fontWeight: 600 }}> {selectedRowsState.length} </Text> item
      </div>
    );

    const handleFooterToolbarClick = async () => {
      const handleOnOk = async () => {
        await handleRemove(selectedRowsState);
        setSelectedRows([]);
        actionRef.current?.reloadAndRest?.();
      };
      Modal.confirm({
        title: `Do you want to delete ${selectedRowsState.length} rows?`,
        icon: <ExclamationCircleOutlined />,
        content: `You will delete ${selectedRowsState.length} rows!`,
        onOk: handleOnOk,
      });
    };

    const footerToolbarFilter = (action: { isAllow: (arg0: string) => any; isSingle: any }) => {
      action.isAllow
        ? selectedRowsState.length === 1 && action.isAllow(selectedRowsState[0])
        : !action.isSingle || (action.isSingle && selectedRowsState.length === 1);
    };

    const footerToolbarMap = (action, index) => {
      const btnProps: any = { icon: action.icon || <StarOutlined />, className: action.className || undefined, style: action.style || undefined };
      return (
        <Button key={index} {...btnProps} onClick={async () => await handleAction(action, selectedRowsState)}>
          {action.name}
        </Button>
      );
    };

    return (
      selectedRowsState?.length > 0 && (
        <FooterToolbar extra={footerToolbarExtra}>
          {deletes ? <Button onClick={handleFooterToolbarClick}> Batch deletion </Button> : null}
          {actions ? actions.filter(footerToolbarFilter).map(footerToolbarMap) : null}
        </FooterToolbar>
      )
    );
  };

  const modalFormData = () => {
    const handleOnFinish = async value => {
      (await handleAdd(value)) && actionRef.current ? actionRef.current.reload() : null;
      handleModalVisible(false);
      setCurrentRow({});
    };

    const modalFormFilter = (column: { new: any }) => column.new != undefined;
    const modalFormMap = ({ ne, title, dataIndex, valueType, valueEnum, request }) => {
      const attrRules = [{ required: ne, message: `${title} is required` }];
      const attr: any = { key: dataIndex, label: title, name: dataIndex, width: 'md', rules: attrRules };
      return valueType === 'ProFormTextArea' || valueType === 'textarea' ? (
        <ProFormTextArea {...attr} />
      ) : ['ProFormText', 'text', 'json', 'link'].includes(valueType) ? (
        <ProFormText {...attr} />
      ) : valueType === 'password' ? (
        <Password {...attr} />
      ) : valueType === 'ProFormDigit' || valueType === 'number' ? (
        <ProFormDigit {...attr} />
      ) : valueType === 'ProFormSelect' || valueType === 'select' ? (
        <>
          {valueEnum && (attr.valueEnum = valueEnum)}
          {request && (attr.request = request)}
          <ProFormSelect {...attr} />
        </>
      ) : valueType === 'ProFormCheckbox' || valueType === 'boolean' ? (
        <>
          {(attr.options = ['true', 'false'])}
          <ProFormCheckbox {...attr} />
        </>
      ) : null;
    };

    return (
      <ModalForm title={'New item'} width="400px" open={createModalVisible} onVisibleChange={handleModalVisible} onFinish={handleOnFinish}>
        {col.filter(modalFormFilter).map(modalFormMap)}
      </ModalForm>
    );
  };

  const modalData = () => {
    const modalOnOk = () => {
      handleUpdateModalVisible(false);
      setCurrentRow({});
    };

    const modalOnCancel = () => {
      handleUpdateModalVisible(false);
      setCurrentRow({});
    };

    return (
      <Modal
        title={'Edit item'}
        width="400px"
        destroyOnClose={true}
        open={updateModalVisible}
        onOk={modalOnOk}
        onCancel={modalOnCancel}
        footer={null}
        closable={true}
      >
        {editForm(currentRow)}
      </Modal>
    );
  };

  const drawerData = () => {
    const handleDrawerOnClose = () => {
      setCurrentRow(undefined);
      setShowDetail(false);
    };

    const proData = () => {
      const drawerTitle = currentRow?.email || currentRow?.name;
      const drawerRequest = async () => {
        const data = {};
        Object.keys(currentRow).forEach(
          k => (data[k] = Array.isArray(currentRow[k]) || typeof currentRow[k] === 'object' ? JSON.stringify(currentRow[k]) : currentRow[k]),
        );
        return { data };
      };
      const drawerColumns = columns.map((c: any) => ({ ...c, render: c.renderAll ? c.renderAll : c.render }));
      return <ProDescriptions column={1} title={drawerTitle} request={drawerRequest} params={{ id: currentRow?.id }} columns={drawerColumns} />;
    };

    return (
      <Drawer width={600} open={showDetail} onClose={handleDrawerOnClose} closable={false}>
        {currentRow?.id && proData()}
      </Drawer>
    );
  };

  return (
    <>
      {proTableData()}
      {footerToolbarData()}
      {modalFormData()}
      {modalData()}
      {drawerData()}
    </>
  );
};
