import { useEffect } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps, setPageTitle } from 'services/actions';
import { IMainStoreProps, mapStateToProps } from 'services/reducer';
import { Typography } from 'antd';

const { Title } = Typography;

const mapConnector = connect(mapStateToProps, mapDispatchToProps);
export const TitleUI = mapConnector((props: any) => {
  const store: IMainStoreProps = props?.store;
  return <Title level={5}> {store?.page?.title} </Title>;
});

/** @returns @param props Set title for page, remove it - on unmount */
export const TitleUIRow = (props: { title: string }) => {
  useEffect(() => {
    setPageTitle(props.title);
    return () => {
      setPageTitle(null);
    };
  }, [props.title]);
  return <></>;
};
