import { Alert, Space, Tag, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { formatUserName } from 'utils/nameFormat';
import { TipRight } from 'components/SharedComponents/Tooltip/Tooltip';
import { bannerStyle, spaceWidth } from 'utils/styles';

const { Text, Paragraph } = Typography;

export default function ItemUpdateComponent(props: any) {
  const updateItem = () => {
    const alertMessage = () => (
      <TipRight tip={props.values.user}>
        <Text> {formatUserName(props.values.user)} </Text>
      </TipRight>
    );

    const alertAction = () => (
      <Space direction="vertical">
        {Object.keys(props.values).map(key =>
          !['action', 'createdAt', 'user'].includes(key) ? (
            <Paragraph key={key}>
              <Tag color="magenta" title="Property name">
                {key}
              </Tag>
              <ArrowRightOutlined /> &nbsp;
              <Tag color="green" title="New value">
                {props.values[key].new}
              </Tag>
            </Paragraph>
          ) : null,
        )}
      </Space>
    );

    return <Alert message={alertMessage()} action={alertAction()} style={bannerStyle} />;
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      {updateItem()}
    </Space>
  );
}
