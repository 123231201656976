import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TitleUIRow } from 'layout/TitleUI';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Alert, Button, Collapse, Skeleton, Space, Typography } from 'antd';
import { buttonBorder, spaceWidth } from 'utils/styles';
import { ProviderUI } from './ClusterCreaionV2_connect';
import { InstallationFile } from '../new-cluster/InstallationFile';
import { MetacontrollerOptions } from './metacontroller';
import { useRegionsController_getRegionById } from 'services/regions.service';
import { ClusterDeleteButton } from '../ClusterDangerZone';
import { PROJECT_CODE_NAME } from 'interface/common';

const { Title, Text } = Typography;

export const ApiAgentYamlInstallation = (props: any) => {
  const history = useHistory();
  const params: any = useParams();
  const regionId = params.regionId;

  const [probablyInstalled, setProbablyInstalled] = useState(null);
  const { loading, region, stopPolling, startPolling, refetch } = useRegionsController_getRegionById(regionId);

  const [hasMetacontroller, setHasMetacontroller] = useState<boolean>(false);

  useEffect(() => {
    return stopPolling;
  }, []);

  if (loading) {
    return <Skeleton active />;
  }

  if (region?.configureStatus === 'configured' && region?.integrationsStatus === 'configured') {
    // Cluster is ready - redirect to overview
    history.push(`/clusters/${region.id}/overview`);
    return <Skeleton active={true} loading={true} />;
  }

  if (region?.lastSyncTime !== 0 && region?.configureStatus === 'configured' && region?.integrationsStatus === 'not-configured') {
    history.push(`/cluster/create/${region.id}/integrations`);
    return <Skeleton active={true} loading={true} />;
  }

  if (region.useTerraform === true) {
    history.push(`/cluster/install/${region.id}/tf-aws`);
    return <Skeleton active={true} loading={true} />;
  }

  const onInstall = async () => {
    setProbablyInstalled(true);
    startPolling(5000);
    refetch();
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <TitleUIRow title={`Configure new cluster`} />

      <Collapse defaultActiveKey={['install']} bordered={false}>
        <Collapse.Panel header="Cluster creation instructions" key="ProviderUI" extra={<i>(In case if you did not done them on previous step)</i>}>
          <ProviderUI provider={region.cloudProvider} />
        </Collapse.Panel>
      </Collapse>

      <MetacontrollerOptions defaultValue={hasMetacontroller} onChange={setHasMetacontroller} />
      <InstallationFile hasMetacontroller={hasMetacontroller} regionId={regionId} action={'install'} />

      {probablyInstalled && (
        <Alert
          showIcon
          type="info"
          message={`As soon ${PROJECT_CODE_NAME} agent in will be installed in your Cluster without errors you will be able to continue the configuration of the Cluster.`}
          action={<Button onClick={() => refetch()}> Check installation </Button>}
        />
      )}

      <BottomButtons>
        <Space>
          <Button type="primary" onClick={onInstall} style={buttonBorder}>
            Check installation
          </Button>

          <ClusterDeleteButton region={region} />
        </Space>
      </BottomButtons>
    </Space>
  );
};
