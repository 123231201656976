import gql from 'graphql-tag';
import { iRegionModel } from 'shared/deployment';
import { useAuthedQuery, useAuthedMutationWithNotification } from 'utils/qlAuth';
import { useEffect, useState } from 'react';
import { regionsService } from '../../../services/regions.service';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { JobList } from '../jobs/JobList';
import { layout } from 'components/Deployments/Setting/common';
import { authService } from 'services/auth.service';
import { awsRegionNames } from '../settings/TerraformJobsConfig';
import { Button, Form, Input, Select, InputNumber, Skeleton, Typography, AutoComplete } from 'antd';
import { buttonBorder } from 'utils/styles';

export interface iTerraformClasterCreationProps {
  region: iRegionModel;
}

const { Item } = Form;
const { Option } = Select;
const { Password } = Input;
const { Title } = Typography;

const titles = { fontWeight: 'bold', marginTop: '10px' };
const texts = { marginTop: '10px', marginBottom: '10px' };

export const TerraformClasterCreation = (props: iTerraformClasterCreationProps) => {
  const [hasKeys, setHasKeys] = useState(null);
  const weHaveTfKeys = hasKeys === true || (hasKeys !== false && props?.region?.TF_AWS_ACCESS_KEY_ID && props?.region?.TF_AWS_SECRET_ACCESS_KEY);
  const region = props.region;

  const query = useAuthedQuery(
    gql`
      query JobsController_jobsList($regionId: Int!) {
        JobsController_jobsList(regionId: $regionId) {
          id
          status
          region
          createdAt
          updatedAt
          variables
          finishTime
          createdAt
          tfMode
          name
          lastError
        }
      }
    `,
    { skip: !Number(props?.region?.id), variables: { regionId: Number(props?.region?.id) }, pollInterval: 5000 },
  );
  query.startPolling(5000);

  const [runJobFunction, runJobRes] = useAuthedMutationWithNotification(gql`
    mutation JobsController_runJob($jobId: Int!) {
      JobsController_runJob(jobId: $jobId) {
        id
      }
    }
  `);

  useEffect(() => {
    return query.stopPolling;
  }, []);

  let showJobList = null;
  let create = null;
  let editJob = null;

  if (query.loading) {
    return <Skeleton active={true} loading={true} />;
  }

  if (query?.data?.JobsController_jobsList.length == 0) {
    create = 1;
  } else {
    if (query?.data?.JobsController_jobsList.length == 1 && query?.data?.JobsController_jobsList[0]['status'] == 'pre-init') {
      editJob = query?.data?.JobsController_jobsList[0];
    } else {
      showJobList = 1;
    }
  }

  const handleOnClick_RegionConfigurations = async value => {
    await regionsService.updateOwnRegion(region.id, value);
    setHasKeys(true);
  };

  const regionConfiguration = () => {
    const regionItems = [
      {
        name: 'regionName',
        label: `AWS region ${'\u00A0'.repeat(19)}`,
        initialValue: region.regionName || '',
        component: (
          <AutoComplete
            options={awsRegionNames.map(region => ({ value: region }))}
            filterOption={(input, option) => option?.value.toLowerCase().includes(input.toLowerCase())}
          >
            <Input placeholder="Select AWS region here" />
          </AutoComplete>
        ),
      },
      {
        name: 'TF_AWS_ACCESS_KEY_ID',
        label: `AWS access key ID ${'\u00A0'.repeat(8)}`,
        initialValue: region.TF_AWS_ACCESS_KEY_ID || '',
        component: <Password placeholder="Enter AWS access key ID here" />,
      },
      {
        name: 'TF_AWS_SECRET_ACCESS_KEY',
        label: `AWS secret access key ${'\u00A0'.repeat(2)}`,
        initialValue: region.TF_AWS_SECRET_ACCESS_KEY || '',
        component: <Password placeholder="Enter AWS secret access key here" />,
      },
    ];

    return !weHaveTfKeys ? (
      <Form onFinish={handleOnClick_RegionConfigurations} style={texts}>
        <Title level={5} style={titles}>
          Region configurations
        </Title>
        {regionItems.map(({ name, label, initialValue, component }) => (
          <Item key={name} required name={name} label={label} initialValue={initialValue}>
            {component}
          </Item>
        ))}
        <BottomButtons>
          <Button type="primary" htmlType="submit" style={buttonBorder}>
            Save AWS access configuration
          </Button>
        </BottomButtons>
      </Form>
    ) : null;
  };

  const jobListData = () => (showJobList ? <JobList query={query} refetch={query.refetch} region={region} clusterAreReady={false} /> : null);

  const clusterConfiguration = () => {
    const handleSubmit = async (values: any) => {
      try {
        if (!editJob?.id) {
          const res = await authService.getApolloClient().query({
            query: gql`
              mutation JobsController_newJob($regionId: Int!) {
                JobsController_newJob(regionId: $regionId) {
                  id
                  status
                  region
                  createdAt
                  updatedAt
                  variables
                }
              }
            `,
            variables: { regionId: Number(region.id) },
          });
          // editJob = await newJobFunction({ variables: { regionId: Number(region.id) } });
          editJob = res.data.JobsController_newJob;
        }
        await authService.getApolloClient().query({
          query: gql`
            mutation JobsController_updateJob($jobId: Int!, $variables: JSON!) {
              JobsController_updateJob(jobId: $jobId, variables: $variables) {
                id
                status
                region
                createdAt
                updatedAt
                variables
                variables
              }
            }
          `,
          variables: { jobId: editJob.id || 0, variables: values },
        });
        await runJobFunction({ variables: { jobId: editJob.id } });
      } catch (e) {
        console.log(`runJobFunction: ${e}`);
        debugger;
      }
    };

    const handleOnFinish = async value => {
      try {
        await handleSubmit(value);
      } catch (e) {
        console.log(`Error: ${e}`);
      }
    };

    const maxCapacityValue = editJob && editJob.variables && editJob.variables.max_capacity ? editJob.variables.max_capacity : 6;
    const minCapacityValue = editJob && editJob.variables && editJob.variables.min_capacity ? editJob.variables.min_capacity : 1;
    const desiredCapacityValue = editJob?.variables?.desired_capacity || 2;
    const instanceTypesValue = editJob && editJob.variables && editJob.variables.instance_types ? editJob.variables.instance_types : 'm4.xlarge';
    const clusterVersionValue = editJob && editJob.variables && editJob.variables.cluster_version ? editJob.variables.cluster_version : '1.30';
    const diskSizeValue = editJob && editJob.variables && editJob.variables.disk_size ? editJob.variables.disk_size : 80;

    const clusterItems = [
      { name: 'max_capacity', label: 'Max capacity', initialValue: maxCapacityValue, component: <InputNumber defaultValue={maxCapacityValue} /> },
      { name: 'min_capacity', label: 'Min capacity', initialValue: minCapacityValue, component: <InputNumber defaultValue={minCapacityValue} /> },
      {
        name: 'desired_capacity',
        label: 'Desired capacity',
        initialValue: desiredCapacityValue,
        component: <InputNumber defaultValue={desiredCapacityValue} />,
      },
      { name: 'instance_types', label: 'Instance types', initialValue: instanceTypesValue, component: <Input defaultValue={instanceTypesValue} /> },
      {
        name: 'cluster_version',
        label: 'Cluster version',
        initialValue: clusterVersionValue,
        component: (
          <Select defaultValue={clusterVersionValue}>
            <Option value="1.30"> 1.30 </Option>
            <Option value="1.29"> 1.29 </Option>
          </Select>
        ),
      },
      { name: 'disk_size', label: 'Disk size', initialValue: diskSizeValue, component: <InputNumber defaultValue={diskSizeValue} /> },
    ];

    return weHaveTfKeys && (create || editJob) && !showJobList ? (
      <Form onFinish={handleOnFinish}>
        <Title level={5} style={titles}>
          Cluster configurations
        </Title>
        {clusterItems.map(({ name, label, initialValue, component }) => (
          <Item key={name} {...layout} initialValue={initialValue} name={name} label={label}>
            {component}
          </Item>
        ))}
        <BottomButtons>
          <Button type="primary" htmlType="submit" style={buttonBorder}>
            {/** @todo - Add popconfirm to confirm the creation of the cluster - Add a text note about the cost of the cluster - Call JobsController_newClusterPlan mutation if user agrees to create the cluster */}
            Save & Continue
          </Button>
        </BottomButtons>
      </Form>
    ) : null;
  };

  return (
    <>
      {weHaveTfKeys && (
        <Button type="default" size="small" onClick={e => setHasKeys(false)} style={texts}>
          Edit AWS access configuration
        </Button>
      )}
      {regionConfiguration()}
      {jobListData()}
      {clusterConfiguration()}
    </>
  );
};
