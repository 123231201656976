import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';
import { SmileTwoTone } from '@ant-design/icons';

const resultExtra = (
  <Link to="/">
    <Button type="primary"> Next </Button>
  </Link>
);

export const PaymentOk = () => <Result title="Great, we have done it!" icon={<SmileTwoTone twoToneColor="#00A58E" />} extra={resultExtra} />;
