import _ from 'lodash';
import { RJSFSchema, UiSchema } from '@rjsf/utils';

export interface k8sDocsMapItem {
  [kind: string]: k8sDocsKindItem;
}

export interface k8sDocsKindItem {
  title: string;
  description: string;
  shema?: RJSFSchema;
  uiShema?: UiSchema;
  properties?: { [key: string]: k8sDocsKindItem };
}

const k8sDocsMap: k8sDocsMapItem = {
  deployment: {
    title: 'application',
    description: 'A Deployment provides declarative updates for Pods and ReplicaSets.',
    properties: {
      apiVersion: { title: 'apiVersion', description: 'The API version for the PodTemplateSpec', shema: { type: 'string', default: 'apps/v1' } },
      kind: { title: 'kind', description: 'The kind of object being referenced.', shema: { type: 'string', default: 'Deployment' } },
      metadata: {
        title: 'metadata',
        description: 'Standard object metadata.',
        properties: {
          name: { title: 'name', description: 'Name of the application', shema: { type: 'string' } },
          namespace: { title: 'namespace', description: 'Namespace of the application', shema: { type: 'string' } },
          labels: { title: 'labels', description: 'Labels of the application', shema: { type: 'object', additionalProperties: { type: 'string' } } },
        },
      },
    },
  },
};

export function getK8sDocs(kind: string, path: string): k8sDocsKindItem | null {
  const kindItem = k8sDocsMap[String(kind).toLowerCase()];
  return !kindItem
    ? null
    : path === ''
    ? kindItem
    : _.get(kindItem, `properties.${path.replace(/^\./, '').replaceAll('][', '].properties[')}`) || null;
}
