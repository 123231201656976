import React from 'react';
import { iDeployment } from 'shared/deployment';

export interface iProjectDeploymentContext {
  deployment: iDeployment | null;
}

const defaultDeploymentContextValue: iProjectDeploymentContext = { deployment: null };
export const ProjectDeploymentContext = React.createContext(defaultDeploymentContextValue);

export interface iProjectDeploymentSyncVarsContext {
  syncVars: any;
  /**
   * Is used because the syncVars object does not contain the service name
   * And we need to know the service name to know which service is in use.
   * Different services have different syncVars.
   */
  serviceName?: string | null;
}

const projectDeploymentSyncVarsContextValue: iProjectDeploymentSyncVarsContext = { syncVars: null, serviceName: null };
export const ProjectDeploymentSyncVarsContext = React.createContext(projectDeploymentSyncVarsContextValue);
