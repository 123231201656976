import { Alert, Space, Tabs, Typography } from 'antd';
import { iRegionModel } from 'shared/deployment';
import { ClusterAwsPrice } from './ClusterAwsPrice';
import { ClusterOpenCostPrice } from './ClusterOpenCostPrice';
import { Link } from 'react-router-dom';
import { spaceWidth } from 'utils/styles';

const { Text } = Typography;
const { TabPane } = Tabs;

export interface iClusterMonitoringProps {
  region: iRegionModel;
}

export const ClusterMonitoring = (props: iClusterMonitoringProps) => {
  let hasTabs = false;
  const region = props.region;

  if (region.usePluginOpencost === true) hasTabs = true;
  if (region.useCostExplorerApi === true) hasTabs = true;

  const alertDescription = (
    <Text>
      You need to enable
      <Text strong>
        <Link to={`/clusters/${region.id}/settings/kube-cost`}> OpenCost plugin </Link>
      </Text>
      or
      <Text strong>
        <Link to={`/clusters/${region.id}/settings/aws-price`}> AWS Cost Explorer API </Link>
      </Text>
      to see monitoring data.
    </Text>
  );

  if (!hasTabs) {
    return (
      <Space direction="vertical" style={spaceWidth}>
        <Alert type="warning" showIcon message="No monitoring available" description={alertDescription} />
      </Space>
    );
  }

  const monitoringData = () => {
    const tabMapping = [
      { condition: region.usePluginOpencost, tab: 'Open cost', key: 'ClusterOpenCostPrice', component: ClusterOpenCostPrice },
      { condition: region.useCostExplorerApi, tab: 'AWS cost', key: 'ClusterAwsPrice', component: ClusterAwsPrice },
    ];
    return (
      <Tabs defaultActiveKey="ClusterOpenCostPrice">
        {tabMapping.map(
          ({ condition, tab, key, component: Component }) =>
            condition && (
              <TabPane tab={tab} key={key}>
                <Component region={region} />
              </TabPane>
            ),
        )}
      </Tabs>
    );
  };

  return monitoringData();
};
