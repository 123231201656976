export const form_schema_ui_livenessProbe = {};
export const form_schema_livenessProbe = {
  type: 'object',
  properties: {
    /** @todo add support for other types of probes */
    /** @todo add more fields like here https://kubernetes.io/docs/tasks/configure-pod-container/configure-liveness-readiness-startup-probes/#define-a-liveness-command */
    // httpGet: { path: '/livez', port: 6080, scheme: 'HTTP' },
    initialDelaySeconds: { type: 'number', title: 'initialDelaySeconds', default: 60 },
    periodSeconds: { type: 'number', title: 'periodSeconds', default: 10 },
    timeoutSeconds: { type: 'number', title: 'timeoutSeconds', default: 1 },
    successThreshold: { type: 'number', title: 'successThreshold', default: 1 },
    failureThreshold: { type: 'number', title: 'failureThreshold', default: 3 },
  },
};
