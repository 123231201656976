import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { iDeployment } from 'shared/deployment';
import { useProjectDeployments } from 'services/deployment.service';
import { Button, Popover, Select, Space, Tag, Typography } from 'antd';
import { ArrowRightOutlined, ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { dropdownWidth, floatingRight } from 'utils/styles';

export interface iDeploymentSelectionComponentProps {
  projectId: number;
  onSelect: (deployment: iDeployment) => void;
  deployment: iDeployment;
}

const { Text } = Typography;

export const DeploymentSelectionComponent = (props: iDeploymentSelectionComponentProps) => {
  const res = useProjectDeployments(props.projectId, [props.projectId]);
  const [selectedValue, setSelectedValue] = useState(props.deployment?.id);
  const history = useHistory();

  useEffect(() => {
    const newVal = (res.answer || []).find((d: iDeployment) => Number(d.id) === Number(props?.deployment?.id));
    if (newVal) {
      props.onSelect(newVal);
      setSelectedValue(newVal.id);
    } else if (res?.answer?.length) {
      props.onSelect(res.answer[0]);
      setSelectedValue(res.answer[0].id);
    }
  }, [res.answer?.length, props.deployment?.id]);

  if (res?.loading) {
    return (
      <div data-qa="DeploymentSelectionComponent">
        <Tag icon={<SyncOutlined spin />} color={'default'}>
          Loading
        </Tag>
      </div>
    );
  }

  const popoverContent = (
    <Space direction="vertical">
      <Text> You have a project. Project is only template of application. </Text>
      <Text> You can deploy this project to kubernetes cluster. </Text>
    </Space>
  );

  if (!res.answer || res.answer.length === 0) {
    return (
      <div data-qa="DeploymentSelectionComponent">
        <Popover title="No deployments" content={popoverContent}>
          <Link to={`/new/${props.projectId}`}>
            <Button danger title="Create new application for this project">
              <ExclamationCircleOutlined /> Create application
            </Button>
          </Link>
        </Popover>
      </div>
    );
  }

  if (res.answer.length === 1) {
    return null;
  }

  const options = res.answer.map((d: iDeployment) => {
    const link = window.location.hash.replace('#/', '/').replace(/\/app\/[0-9]+\//, `/app/${d.id}/`);
    const optionsLabel = (
      <>
        {d.id}-{d.name}
        <Link style={floatingRight} to={link}>
          <Tag style={{ fontSize: `x-small`, padding: '0 6px', lineHeight: '16px', top: '-2px' }}>
            Application <ArrowRightOutlined />
          </Tag>
        </Link>
      </>
    );
    return { label: d?.RegionModel?.name || null, options: [{ value: d.id, label: optionsLabel }] };
  });

  return (
    <Select
      size="small"
      data-qa="DeploymentSelectionComponent"
      defaultValue={selectedValue}
      options={options}
      onSelect={e => {
        const link = window.location.hash.replace('#/', '/').replace(/\/app\/[0-9]+\//, `/app/${Number(e)}/`);
        history.push(link);
        props.onSelect(res.answer.find((d: iDeployment) => Number(d.id) === Number(e)));
      }}
      style={dropdownWidth}
    />
  );
};
