import _ from 'lodash';
import { EditOutlined } from '@ant-design/icons';
import { Space, Button, Tag, Modal, Input, Typography, Card } from 'antd';
import { iProjectDeploymentSyncVarsContext } from 'components/Projects/ProjectDeploymentContext';
import { iWCodeObjectArray } from './DockerWizard';
import { useState } from 'react';
import { buttonSize, fieldWidth, spaceWidth } from 'utils/styles';

interface IK8sResources {
  requests: { memory: string; cpu: string };
  limits: { memory: string; cpu: string };
}

interface iResourcesUI {
  syncVarsContext: iProjectDeploymentSyncVarsContext;
  path: string;
  yamlObj: iWCodeObjectArray;
  onChange: (path: string, resources: IK8sResources | undefined) => void;
}

export const form_schema_ui = {};

export const form_schema = {
  type: 'array',
  description: `Resources`,
  items: { type: 'object', properties: { name: { type: 'string', title: 'Name' }, value: { type: 'string', title: 'Value' } } },
};

const { Text } = Typography;

/**
 * https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/
 *  resources:
      requests:
        memory: "64Mi"
        cpu: "250m"
      limits:
        memory: "128Mi"
        cpu: "500m"
 * @param props
 * @returns */
export const ResourcesUI = (props: iResourcesUI) => {
  const resourcesV = _.get(props.yamlObj?.obj, props.path.replace(/^./, ''));
  const [showModal, setShowModal] = useState(false);
  const [resources, setResources] = useState<IK8sResources>(
    resourcesV || { requests: { memory: '64Mi', cpu: '250m' }, limits: { memory: '128Mi', cpu: '500m' } },
  );

  const resourceData = () => {
    const tagColor = resourcesV ? 'green' : 'red';
    const tagData = resourcesV
      ? `CPU ${resourcesV.requests.cpu} - ${resourcesV.limits.cpu} & Mem ${resourcesV.requests.memory} - ${resourcesV.limits.memory}`
      : 'Not configured';
    return <Tag color={tagColor}> {tagData} </Tag>;
  };

  const modalData = () => {
    const handleOnOk = () => (setShowModal(false), props.onChange(props.path, resources));

    const handleOnCancel = () => setShowModal(false);

    const resourceTypes = [
      { title: 'CPU resources', key: 'cpu', placeholder: ['250m', '500m'] },
      { title: 'Memory resources', key: 'memory', placeholder: ['64Mi', '128Mi'] },
    ];

    return (
      <Modal title="Manage resources" open={showModal} onOk={handleOnOk} onCancel={handleOnCancel}>
        <Space direction="vertical" style={spaceWidth}>
          {resourceTypes.map(({ title, key, placeholder }) => (
            <Card key={key} size="small" title={title} bordered={false}>
              <Space direction="vertical">
                {['requests', 'limits'].map(type => {
                  const inputLabel = `${type === 'requests' ? 'Requests' : 'Limits'} ${'\u00A0'.repeat(type === 'requests' ? 20 : 25)}`;
                  const inputValue = `${resources?.[type][key]}`;
                  const inputPlaceholder = placeholder[['requests', 'limits'].indexOf(type)];
                  const handleOnChange = e => setResources(prev => ({ ...prev, [type]: { ...prev[type], [key]: e.target.value } }));
                  return (
                    <Space key={type} direction="horizontal">
                      <Text> {inputLabel} </Text>
                      <Input type="number" value={inputValue} placeholder={inputPlaceholder} onChange={handleOnChange} style={fieldWidth} />
                    </Space>
                  );
                })}
              </Space>
            </Card>
          ))}
        </Space>
      </Modal>
    );
  };

  return (
    <>
      <Space direction="horizontal">
        <Button icon={<EditOutlined />} onClick={() => setShowModal(true)} style={buttonSize}>
          Manage resources
        </Button>
        {resourceData()}
      </Space>
      {modalData()}
    </>
  );
};
