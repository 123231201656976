import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TitleUIRow } from 'layout/TitleUI';
import { InstallationFile } from './InstallationFile';
import { HelpDrawerTpl } from 'components/Help/HelpDrawerTpl';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { PROJECT_NAME, PROJECT_CODE_NAME } from 'interface/common';
import { ClusterDeleteButton } from '../ClusterDangerZone';
import { TerraformClasterCreation } from './TerraformCreation';
import { regionsService, useRegionsController_getRegionById } from 'services/regions.service';
import { Alert, Button, Divider, Form, Image, Input, notification, Select, Skeleton, Space, Tabs, Tag, Typography } from 'antd';
import { buttonBorder, spaceWidth } from 'utils/styles';
import { getUrlHashParams } from 'utils/common';
import { CloudType_AWS, CloudType_Azure, CloudType_GCP, CloudType_k3s, CloudType_other } from '../new-cluster-v2/CloudTypes';
import { TipTopLeft } from 'components/SharedComponents/Tooltip/Tooltip';
import { K8sVersions } from '../new-cluster-v2/k8sVersions';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
const { Item } = Form;

export const generateCloudTooltip = (logoSrc: string, cloudName: string | number | boolean | ReactElement<string> | Iterable<ReactNode>) => (
  <TipTopLeft tip={<Image src={logoSrc} preview={false} height={40} />}> {cloudName} </TipTopLeft>
);

export const ClusterCreaionStep2 = () => {
  const history = useHistory();
  const addOptions = getUrlHashParams();
  const params: any = useParams();

  const { loading, region, stopPolling, startPolling, refetch } = useRegionsController_getRegionById(params.regionId);
  const [hasCluster, setHasCluster] = useState(addOptions?.hasCluster === 'true' || false);
  const [useTerraform, setUseTerraform] = useState(null);
  const [k8sVersion, setK8sVersion] = useState(null);
  const [cloudProvider, setCloudProvider] = useState(null);
  const [hasMetacontroller, setHasMetacontroller] = useState<boolean>(false);
  const [probablyInstalled, setProbablyInstalled] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    let _hasCluster = hasCluster;
    if (region?.useTerraform) {
      setUseTerraform(region?.useTerraform);
      if (_hasCluster === null && region?.useTerraform === true) {
        setHasCluster(false);
        _hasCluster = false;
      }
    }
    if (region?.cloudProvider) {
      setCloudProvider(region.cloudProvider);
      if (_hasCluster === null) {
        setHasCluster(true);
        _hasCluster = true;
      }
    }
    if (region?.kubernetesVersion) {
      setK8sVersion(region.kubernetesVersion);
      if (_hasCluster === null) {
        setHasCluster(true);
        _hasCluster = true;
      }
    }
    if (region?.id && (hasCluster || probablyInstalled)) startPolling(5000);
    else {
      stopPolling();
      return;
    }
    return () => stopPolling();
  }, [region, hasCluster, probablyInstalled]);

  const clusterSaveConfig = async values => await regionsService.updateOwnRegion(region.id, values);

  if (loading) return <Skeleton active={true} loading={true} />;

  if (region?.configureStatus === 'configured' && region?.integrationsStatus === 'configured') {
    history.push(`/clusters/${region.id}/overview`);
    return <Skeleton active={true} loading={true} />;
  }

  if (region?.configureStatus === 'one-sync-done') {
    history.push(`/cluster/new/${region.id}/step3`);
    return <Skeleton active={true} loading={true} />;
  }

  if (region?.lastSyncTime !== 0 && region?.configureStatus === 'configured' && region?.integrationsStatus === 'not-configured') {
    history.push(`/cluster/new/${region.id}/integrations`);
    return <Skeleton active={true} loading={true} />;
  }

  const probablyInstalledUI = () => {
    const handleOnClick = () => {
      refetch();
      setProbablyInstalled(true);
    };

    const alertDescription = `As soon ${PROJECT_CODE_NAME} agent in will be installed in your Cluster without errors you will be able to continue the configuration of the Cluster.`;
    const alertAction = <Button onClick={() => refetch()}> Check Installation Status </Button>;

    return (
      <>
        <BottomButtons>
          <Space>
            <Button type="primary" onClick={handleOnClick} style={buttonBorder}>
              Install this YAML file to my Cluster
            </Button>
            <ClusterDeleteButton region={region} />
          </Space>
        </BottomButtons>
        {probablyInstalled && <Alert showIcon type="info" description={alertDescription} action={alertAction} style={{ paddingBottom: '80px' }} />}
      </>
    );
  };

  const clusterCreate = () => {
    const handleOnFinish = async (values: any) => {
      if (addOptions.provider) values.cloudProvider = addOptions.provider;
      const res = await regionsService.newRegion(values);
      res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
      history.push(res.error ? '/' : `/clusters/${res.data.id}${addOptions?.hasCluster ? `?hasCluster=${addOptions.hasCluster}` : ``}`);
    };
    return (
      <Form form={form} initialValues={{}} layout="vertical" onFinish={handleOnFinish}>
        <Item name="name" label="Cluster name" rules={[{ required: true, message: 'Please input the cluster name!' }]}>
          <Input placeholder="Enter cluster name here" />
        </Item>
      </Form>
    );
  };

  const clusterExistence = () => {
    const helpLinks = [
      {
        url: 'https://www.unifie.cloud/post/how-add-your-own-cluster-to-unifie-dashboard',
        text: `How add your own cluster to ${PROJECT_NAME} dashboard`,
      },
      {
        url: 'https://www.unifie.cloud/post/try-unifie-with-k3s-in-5-min-on-a-local-machine-or-small-linux-server',
        text: `Try ${PROJECT_NAME} with K3S in 5 min on a local machine or small linux server`,
      },
    ];
    return (
      <Text strong>
        Do you have a Cluster?
        <HelpDrawerTpl>
          <Title level={5}> {PROJECT_NAME} </Title>
          <ul>
            <li> It provide great UI for managing clusters. </li>
            <li> If you want to deploy something, you need your own Kubernetes cluster. </li>
          </ul>
          <Divider />
          <Title level={5}> Additional information: </Title>
          <ul>
            {helpLinks.map(({ url, text }) => (
              <li key={url}>
                <Text strong>
                  <a href={url} target="_blank">
                    {text}
                  </a>
                </Text>
              </li>
            ))}
          </ul>
        </HelpDrawerTpl>
      </Text>
    );
  };

  const createCluster = () => {
    const clusterOptions = [
      { value: true, label: 'Yes, I have kubernetes cluster' },
      { value: false, label: 'No, I want to create a new cluster' },
    ];

    const handleOnChange = v => {
      setHasCluster(v);
      if (!v) {
        setProbablyInstalled(false);
      }
    };

    return (
      <Select placeholder="Please confirm how you want to create a cluster" value={hasCluster} onChange={handleOnChange} style={spaceWidth}>
        {clusterOptions.map(({ value, label }) => (
          <Option value={value} key={value ? 'Has-my-cluster' : 'Create-new-cluster'}>
            {label}
          </Option>
        ))}
      </Select>
    );
  };

  const hasClusterFalse = () => {
    const handleOnChange = v => {
      setUseTerraform(v);
      clusterSaveConfig({ useTerraform: v });
    };

    const InstallFile = <InstallationFile {...{ action: 'install', hasMetacontroller: !(!hasMetacontroller || hasCluster), regionId: region.id }} />;
    return (
      hasCluster === false && (
        <>
          <Text strong>How do you want to create a new cluster?</Text>
          <Select placeholder="Select how you want to continue" defaultValue={useTerraform} onChange={handleOnChange} style={spaceWidth}>
            <Option value={true}>
              <Tag color={`green`}> Simple way </Tag> Create a new cluster with terraform in AWS
            </Option>
            <Option value={false}>
              <Tag color={`red`}> For advanced users </Tag> No, I want to create a new cluster manually
            </Option>
          </Select>
          {/* If useTerraform === undefined then do not show next steps */}
          {useTerraform === true && <TerraformClasterCreation region={region} />}
          {useTerraform === false && (
            <Space direction="vertical">
              <Text>
                <Text strong> {PROJECT_NAME} </Text>
                provide great UI for managing clusters and if you want to deploy something, you need your own Kubernetes cluster.
              </Text>
              <Text />
              <Text />
              <Text strong> Select a Cloud platform for your cluster </Text>
              <Tabs defaultActiveKey={cloudProvider}>
                <TabPane key="aws" tab={generateCloudTooltip('branding/logoAWS.svg', 'AWS')}>
                  <CloudType_AWS />
                  {InstallFile}
                  {probablyInstalledUI()}
                </TabPane>
                <TabPane key="gcp" tab={generateCloudTooltip('branding/logoGCP.svg', 'GCP')}>
                  <CloudType_GCP />
                  {InstallFile}
                  {probablyInstalledUI()}
                </TabPane>
                <TabPane key="azure" tab={generateCloudTooltip('branding/logoAzure.svg', 'Azure')}>
                  <CloudType_Azure />
                  {InstallFile}
                  {probablyInstalledUI()}
                </TabPane>

                <TabPane key="k3s" tab={generateCloudTooltip('branding/logoK3S.svg', 'K3S')}>
                  <CloudType_k3s />
                  {InstallFile}
                  {probablyInstalledUI()}
                </TabPane>
                <TabPane key="other" tab={generateCloudTooltip('branding/logoExtra.svg', 'Other')}>
                  <CloudType_other />
                  {InstallFile}
                  {probablyInstalledUI()}
                </TabPane>
              </Tabs>
            </Space>
          )}
        </>
      )
    );
  };

  const hasClusterTrue = () => {
    const cloudProviders = [
      { label: 'Amazon Web Services', logo: 'branding/logoAWS.svg', value: 'aws' },
      { label: 'Google Cloud Platform', logo: 'branding/logoGCP.svg', value: 'gcp' },
      { label: 'Azure', logo: 'branding/logoAzure.svg', value: 'azure' },
      { label: 'K3s', logo: 'branding/logoK3S.svg', value: 'k3s' },
      { label: 'Other', logo: 'branding/logoExtra.svg', value: 'onprem' },
    ];

    const metacontrollerOptions = [
      { label: 'Yes, I have Metacontroller', value: true },
      { label: 'No, I do not have Metacontroller', value: false },
    ];

    const handleOnChangeSelectA = v => {
      setCloudProvider(v);
      clusterSaveConfig({ cloudProvider: v });
    };

    const handleOnChangeSelectB = v => {
      setK8sVersion(v);
      clusterSaveConfig({ kubernetesVersion: v });
    };

    return (
      hasCluster === true && (
        <>
          <Text strong> Cloud Service Provider: </Text>
          <Select style={spaceWidth} onChange={handleOnChangeSelectA} defaultValue={cloudProvider}>
            {cloudProviders.map(({ value, logo, label }) => (
              <Option value={value} key={value}>
                <Image src={logo} preview={false} height={20} />
                {'\u00A0'.repeat(3)}
                {label}
              </Option>
            ))}
          </Select>
          {cloudProvider && (
            <>
              <Text strong> Kubernate version: </Text>
              <K8sVersions defaultValue={k8sVersion} onChange={handleOnChangeSelectB} />
              <Text strong>
                Do you have metacontroller service in your Cluster?
                <HelpDrawerTpl>
                  <Title level={5}> {PROJECT_NAME} </Title>
                  <ul>
                    <li> It uses metacontroller to manage the cluster. </li>
                    <li> If you do not have metacontroller, {PROJECT_NAME} will install it </li>
                    <li> If you have metacontroller, {PROJECT_NAME} will use your version instead of install own version. </li>
                  </ul>
                  <Divider />
                  <Title level={5}> Additional information: </Title>
                  <Text strong>
                    <a href="https://metacontroller.github.io/metacontroller/intro.html" target="_blank">
                      What is a Metacontroller ?
                    </a>
                  </Text>
                  <ul>
                    <li> Metacontroller is an add-on for Kubernetes that makes it easy to write and deploy custom controllers. </li>
                    <li> Although the open-source project was started at Google, the add-on works the same in any Kubernetes Cluster. </li>
                  </ul>
                </HelpDrawerTpl>
              </Text>
              <Select placeholder="Select Your Choice Here" onChange={setHasMetacontroller} defaultValue={hasMetacontroller} style={spaceWidth}>
                {metacontrollerOptions.map(({ value, label }) => (
                  <Option value={value} key={value ? 'yes' : 'no'}>
                    {label}
                  </Option>
                ))}
              </Select>
            </>
          )}
          {cloudProvider && hasMetacontroller !== undefined && k8sVersion && (
            <>
              <InstallationFile {...{ action: 'install', hasMetacontroller, regionId: region.id }} />
              {probablyInstalledUI()}
            </>
          )}
        </>
      )
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <TitleUIRow title={`Configure a cluster`} />
      {/* {clusterCreate()} */}
      {clusterExistence()}
      {createCluster()}
      {hasClusterFalse()}
      {hasClusterTrue()}
    </Space>
  );
};
