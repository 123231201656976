import { Tabs, Typography, Skeleton } from 'antd';
import { deploymentService } from '../../../services/deployment.service';
import { useApiQuery } from '../../../utils/common';
import { CodeLine } from 'components/SharedComponents/CodeLine/CodeLine';
import { redColor } from 'utils/styles';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

/**
 * Install Commands for on-premise deployment type
 * @param param0
 * @returns
 */

export const InstallCommands = ({ deployment }: any) => {
  const [keys, errorStatus, loadingStatus] = useApiQuery(() => deploymentService.getOnPremiseKeys(deployment.id));

  if (errorStatus || !keys) return null;
  if (loadingStatus) return <Skeleton active={true} loading={true} />;

  const envVars = {
    AWS_ACCESS_KEY_ID: 'YOUR_AWS_ACCESS_KEY',
    AWS_SECRET_ACCESS_KEY: 'YOUR_AWS_SECRET_KEY',
  };

  const intro = (
    <Paragraph>
      <Title level={5}>Prepare the env vars</Title>
      We do not keep your access credentials, please add them to environment variables before start.
      <CodeLine>
        {Object.entries(envVars).map(([key, value]) => (
          <div key={key}>
            <span> export {key} =&quot; </span>
            <Text italic style={redColor}>
              {value}
            </Text>
            <span> &quot; </span>
          </div>
        ))}
      </CodeLine>
    </Paragraph>
  );

  const contentOneCommandInstallation = (
    <>
      <Title level={5}> Create or update Cluster </Title>
      <Paragraph>
        <CodeLine> {keys.INSTALL_SCRIPT} </CodeLine>
      </Paragraph>
    </>
  );

  const contentCreateOrUpdate = (
    <Paragraph>
      <CodeLine> {keys.APPLY_TF} </CodeLine>
    </Paragraph>
  );

  const contentDestroy = (
    <Paragraph>
      <Title level={5} style={redColor}>
        Delete the entire cluster. This action cannot be undone.
      </Title>
      <CodeLine> {keys.DESTROY_TF} </CodeLine>
    </Paragraph>
  );

  const contentUseKubectl = (
    <>
      <Title level={5}> Install or update software </Title>
      <Paragraph>
        <CodeLine> {keys.APPLY_API_DEPLOYMENT} </CodeLine>
      </Paragraph>
    </>
  );

  const contentUseDocker = (
    <>
      <Title level={5}> Install or update software (in Docker) </Title>
      <Paragraph>
        <CodeLine> {keys.APPLY_KUBE_IN_DOCKER} </CodeLine>
      </Paragraph>
    </>
  );

  const terraformMap = [
    { key: 'one-line', title: 'Create or update', content: contentCreateOrUpdate },
    { key: 'steps', title: 'Destroy', content: contentDestroy },
  ];

  const softwareMap = [
    { key: 'one-line', title: 'Use kubectl', content: contentUseKubectl },
    { key: 'steps', title: 'Use docker', content: contentUseDocker },
  ];

  const contentStepByStep = (
    <>
      <Title level={5}> Authorization </Title>
      <Paragraph>
        <CodeLine> {keys.DOCKER_LOGIN} </CodeLine>
      </Paragraph>
      <Title level={5}> Cluster infrastructure </Title>
      <Tabs defaultActiveKey={'one-line-tf'}>
        {terraformMap.map(tab => (
          <TabPane tab={tab.title} key={tab.key}>
            {intro}
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
      <Tabs defaultActiveKey={'one-line-software'}>
        {softwareMap.map(tab => (
          <TabPane tab={tab.title} key={tab.key}>
            {intro}
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
    </>
  );

  const commands = [
    { key: 'one-line', title: 'One command installation', content: contentOneCommandInstallation },
    { key: 'steps', title: 'Step by step', content: contentStepByStep },
  ];

  return (
    <Tabs defaultActiveKey={commands[0].key}>
      {commands.map(({ title, key, content }) => (
        <TabPane tab={title} key={key}>
          {content}
        </TabPane>
      ))}
    </Tabs>
  );
};
