import { iPostProcessingRule } from './project.interface';
import _ from 'lodash';

/**
 * This code parses a value to a boolean, which is useful for
 * converting strings to booleans, such as when reading from
 * the environment.
 */
export function parseBoolean(a: any): boolean {
  const TRUTHY_VALUES = [true, 'true', 1, '1', 'True', 'TRUE'];
  return TRUTHY_VALUES.some(function (t) {
    return t === a;
  });
}

/**
 * This code parses a value as a string and returns 'true' if the value is truthy and 'false' if it is not.
 */
export function parseBooleanAsString(a: any): string {
  const TRUTHY_VALUES = [true, 'true', 1, 'True', 'TRUE', '1', 'yes', 'Yes', 'YES', 'y', 'Y', 'on', 'On', 'ON', 'enable', 'Enable', 'ENABLE'];
  return TRUTHY_VALUES.some(function (t) {
    return t === a;
  })
    ? 'true'
    : 'false';
}

/**
 * apply PostProcessingRules for specs
 * @param rules
 * @param spec
 * @param syncVars
 * @returns
 */
export function applyPostProcessingRules(rules: iPostProcessingRule[], spec: any[], syncVars: any): any[] {
  if (!spec) {
    return [];
  }

  // If there are no rules, return the spec as-is
  if (!rules || rules.length === 0) {
    return spec;
  }

  const newSpec = JSON.parse(JSON.stringify(spec));
  return newSpec
    .filter((item: any) => {
      const resultsRules = (rules || [])
        .filter((rule: iPostProcessingRule) => {
          // Check only rule removeFullRecord for remove full item (other rule types will be applyed later)
          return rule.action === 'removeFullRecord';
        })
        .filter((rule: iPostProcessingRule) => {
          // Check rule. Not apply if it has wrong values
          return rule.mainKind && rule.mainMetadataName;
        })
        .find((rule: iPostProcessingRule) => {
          // Apply rules only for correct elements;
          // Check if element has the same main and name
          return rule.mainKind === item.kind && rule.mainMetadataName === item.metadata.name;
        });

      if (resultsRules !== undefined) {
        return false;
      }
      return true;
    })
    .map((item: any) => {
      (rules || [])
        .filter((rule: iPostProcessingRule) => {
          // Check rule. Not apply if it has wrong values
          return rule.action && rule.path && rule.mainKind && rule.mainMetadataName;
        })
        .filter((rule: iPostProcessingRule) => {
          // Apply rules only for correct elements;
          // Check if element has the same main and name
          return rule.mainKind === item.kind && rule.mainMetadataName === item.metadata.name;
        })
        .forEach((rule: iPostProcessingRule) => {
          const path = rule.path.replace(/^\./g, '');

          if (rule.action === 'remove') {
            // Remove element
            _.unset(item, path);
            return;
          }
          if (rule.action === 'edit' || rule.action === 'add') {
            let varValue = null;
            if (rule.varValueSrcType === 'hardcoded') {
              varValue = rule.value;
            } else {
              varValue = _.get(syncVars, rule.value);
            }

            // Type casting to rule.varValueType
            // Can be "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function"
            let currentType: string = typeof varValue;
            if (Array.isArray(varValue)) {
              currentType = 'array';
            }

            const parentValue = _.get(item, path);
            let parentValueType: string = typeof parentValue;
            if (Array.isArray(parentValue)) {
              parentValueType = 'array';
            }
            // If we need to add/edit element in object or array
            if (currentType !== rule.varValueType) {
              if (['bigint', 'number'].includes(rule.varValueType)) {
                // Cast to number
                varValue = parseInt(varValue);
              } else if (rule.varValueType === 'boolean') {
                // Cast to boolean
                varValue = parseBooleanAsString(varValue);
              } else if (['array'].includes(rule.varValueType)) {
                // Cast to array
                try {
                  if (currentType === 'string') {
                    // Cast to array from string
                    varValue = JSON.parse(varValue);
                  } else if (currentType === 'object') {
                    // Cast to array from object
                    varValue = Object.keys(varValue).map(function (key) {
                      return { key: key, value: varValue[key] };
                    });
                  } else {
                    varValue = [];
                    console.error(`error can not cast  ${currentType} ${path}: ${String(varValue)} to array`, {
                      rule: rule,
                    });
                  }
                } catch (error) {
                  console.error(`error can not cast  ${currentType} ${path}: ${String(varValue)} to array`, {
                    rule: rule,
                    error: JSON.stringify(error),
                  });
                  varValue = [];
                }
              }

              if (['object'].includes(rule.varValueType)) {
                // Cast to object
                try {
                  if (currentType === 'string') {
                    varValue = JSON.parse(varValue);
                  } else {
                    varValue = [];
                    console.error(`error can not cast  ${currentType} ${path}: ${String(varValue)} to object`, {
                      rule: rule,
                    });
                  }
                } catch (error) {
                  console.error(`error cast object ${path}: ${String(varValue)} to object`, {
                    rule: rule,
                    error: JSON.stringify(error),
                  });
                  varValue = {};
                }
              }

              if (['string', 'symbol'].includes(rule.varValueType)) {
                // Cast to string
                if (['object', 'array'].includes(currentType)) {
                  varValue = JSON.stringify(varValue);
                } else {
                  varValue = String(varValue);
                }
              }
              if (['undefined'].includes(rule.varValueType)) {
                // Cast to string
                varValue = ``;
              }

              // // Remove element
              // _.set(item, path, varValue);
              // return;
            }

            if (parentValueType == 'array' && rule.action === 'add') {
              // If we need to add element to array
              const currentValue = _.get(item, path);
              currentValue.push(varValue);
              _.set(item, path, currentValue);
            } else {
              if (rule.action === 'add') {
                const currentValue = _.get(item, path);
                currentValue[rule.addPropertyName] = varValue;

                _.set(item, path, currentValue);
              } else {
                _.set(item, path, varValue);
              }
            }
          }
        });

      return item;
    });
}
