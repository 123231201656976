import { Typography } from 'antd';

const { Text } = Typography;

const fieldsData = (
  <Text>
    Metadata is the standard object's metadata. &nbsp;
    <a target="_blank" href={'https://kubernetes.io/docs/concepts/overview/working-with-objects/#required-fields'}>
      see more...
    </a>
  </Text>
);

const namesData = (
  <Text>
    Name must be unique within a namespace. &nbsp;
    <a target="_blank" href={'https://kubernetes.io/docs/concepts/overview/working-with-objects/names/'}>
      see more...
    </a>
  </Text>
);

const namespaceData = (
  <Text>
    Namespace in which the object should be created. &nbsp;
    <a target="_blank" href={'https://kubernetes.io/docs/concepts/overview/working-with-objects/namespaces/'}>
      see more...
    </a>
  </Text>
);

export const kind_Secret = {
  properties: {
    apiVersion: { type: `string`, default: `apps/v1`, required: true },
    metadata: {
      type: `object`,
      required: true,
      help: fieldsData,
      properties: {
        labels: { type: 'object', additionalProperties: { type: 'string' } },
        name: { type: `string`, required: true, help: namesData },
        namespace: { type: `string`, required: true, help: namespaceData },
      },
    },
  },
};
