import { Button, Modal, Radio, RadioChangeEvent, Slider, Space, Tag, Typography } from 'antd';
import { iProjectDeploymentSyncVarsContext } from 'components/Projects/ProjectDeploymentContext';
import { iDeployment } from 'shared/deployment';
import { iWCodeObjectArray } from '../wizard/DockerWizard';
import { useState } from 'react';
import { CommonFormUI, CommonUI_DeploymentVarWidget } from '../CommonFormUI';
import { patchYamlCode } from '../utils/yaml-tools';
import { EditOutlined } from '@ant-design/icons';
import { buttonSize, dockerWidth } from 'utils/styles';

type ScalerType = 'KEDA_CPU' | 'default';

interface IScalingSettingsUI {
  deployment: iDeployment;
  deploymentObj: iWCodeObjectArray;
  kedaScaledObject: iWCodeObjectArray;
  syncVarsContext: iProjectDeploymentSyncVarsContext;
  onSave: (type: ScalerType, replicas: Number | undefined, kedaScaledObject: any) => void;
}

const { Text } = Typography;
const { Group } = Radio;

export const ScalingSettingsUI = (props: IScalingSettingsUI) => {
  const useKedaScaler = !!props.kedaScaledObject;
  const [scalerType, setScalerTypeValue] = useState<ScalerType>(useKedaScaler ? `KEDA_CPU` : `default`);
  const [replicas, setReplicas] = useState<Number | undefined>(props.deploymentObj?.obj?.spec?.replicas);
  const [showModal, setShowModal] = useState(false);
  const [kedaScaledObject, setKedaScaledObject] = useState<iWCodeObjectArray | null>(props.kedaScaledObject);

  const kedaCPU_default = {
    apiVersion: 'keda.sh/v1alpha1',
    kind: 'ScaledObject',
    metadata: { name: `${props.deploymentObj.obj?.metadata?.name}-scaledobject` },
    spec: {
      scaleTargetRef: { name: props.deploymentObj.obj?.metadata?.name, kind: props.deploymentObj.obj?.kind, apiVersion: `apps/v1` },
      fallback: { failureThreshold: 1, replicas: 1 },
      minReplicaCount: 1,
      maxReplicaCount: 5,
      triggers: [{ type: `cpu`, metricType: `Utilization`, metadata: { value: 2000 } }],
    },
  };

  const kedaScalerSpecs: any = kedaScaledObject || { obj: kedaCPU_default, role: 'ScaledObject' };

  const onChangeScalerType = (e: RadioChangeEvent) => setScalerTypeValue(e.target.value);

  const onChangeKedaSettings = (path: string, value: any) => {
    let res = patchYamlCode<iWCodeObjectArray>(kedaScalerSpecs, path, value);
    setKedaScaledObject(res);
  };

  const onSaved = () => {
    if (scalerType === `KEDA_CPU`) props.onSave(scalerType, undefined, kedaScalerSpecs.obj);
    if (scalerType === `default`) props.onSave(scalerType, replicas || undefined, null);
  };

  const fixedOption = () => {
    return (
      scalerType === `default` && (
        <>
          <Text> Number of replicas </Text>
          <Slider
            min={1}
            max={10}
            defaultValue={1}
            tooltip={{ open: false }}
            onChange={value => setReplicas(value)}
            value={replicas ? Number(replicas) : 1}
            marks={{ 1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6', 7: '7', 8: '8', 9: '9', 10: '10' }}
          />
        </>
      )
    );
  };

  const autoscalingOption = () => {
    const commonValidator = val => (val < 1 ? 'Must be more than 0' : null);
    const formContent = [
      {
        icon: <EditOutlined />,
        name: 'Target CPU usage',
        text: 'Autoscale to target 80% CPU uasge on average per instance',
        children: (
          <CommonUI_DeploymentVarWidget
            dstType={'number'}
            value={kedaScalerSpecs.obj?.spec?.triggers?.[0]?.metadata?.value || 2000}
            onChange={onChangeKedaSettings}
            path={`.spec.triggers[0].metadata`}
            propertyName={`value`}
            validator={commonValidator}
          />
        ),
      },
      {
        icon: <EditOutlined />,
        name: 'Min replica count',
        text: 'Maintain at least replicas for consistent service availability',
        children: (
          <CommonUI_DeploymentVarWidget
            dstType={'number'}
            value={kedaScalerSpecs.obj?.spec?.minReplicaCount}
            onChange={onChangeKedaSettings}
            path={`.spec`}
            propertyName={`minReplicaCount`}
            validator={commonValidator}
          />
        ),
      },
      {
        icon: <EditOutlined />,
        name: 'Max replica count',
        text: 'Scale up to replicas to manage peak traffic effectively',
        children: (
          <CommonUI_DeploymentVarWidget
            dstType={'number'}
            value={kedaScalerSpecs.obj?.spec?.maxReplicaCount}
            onChange={onChangeKedaSettings}
            path={`.spec`}
            propertyName={`maxReplicaCount`}
            validator={commonValidator}
          />
        ),
      },
      {
        icon: <EditOutlined />,
        name: 'Fallback replicas count',
        text: 'Keep this for rapid demand adaptation and recovery',
        children: (
          <CommonUI_DeploymentVarWidget
            dstType={'number'}
            value={kedaScalerSpecs.obj?.spec?.fallback?.replicas}
            onChange={onChangeKedaSettings}
            path={`.spec.fallback`}
            propertyName={`replicas`}
            validator={commonValidator}
          />
        ),
      },
      {
        icon: <EditOutlined />,
        name: 'Failure threshold',
        text: 'Set failure threshold to trigger scaling and recovery actions',
        children: (
          <CommonUI_DeploymentVarWidget
            dstType={'number'}
            value={kedaScalerSpecs.obj?.spec?.fallback?.failureThreshold}
            onChange={onChangeKedaSettings}
            path={`.spec.fallback`}
            propertyName={`failureThreshold`}
            validator={commonValidator}
          />
        ),
      },
    ];
    return scalerType === `KEDA_CPU` && <CommonFormUI form={formContent} />;
  };

  const options = () => (
    <Group onChange={onChangeScalerType} defaultValue={scalerType}>
      <Space direction="vertical">
        <Text />
        <Radio key={`default`} value={`default`}>
          <Text>
            <Text strong> Fixed: </Text>
            Fixed number of instances in a region of load level
          </Text>
        </Radio>
        <Radio key={`KEDA_CPU`} value={`KEDA_CPU`}>
          <Text>
            <Text strong> Autoscaling: </Text>
            Autoscaling adjusted number of instaces in the region depending on the load
          </Text>
        </Radio>
        <Text /> <Text />
      </Space>
    </Group>
  );

  return (
    <Space direction="horizontal">
      <Button icon={<EditOutlined />} onClick={() => setShowModal(true)} style={buttonSize}>
        Autoscaling settings
      </Button>
      <Modal
        title="Configure scaling"
        open={showModal}
        onOk={() => (setShowModal(false), onSaved())}
        onCancel={() => setShowModal(false)}
        width={'42.5vw'}
        style={{ top: 10 }}
      >
        <Space direction="vertical">
          {options()}
          {fixedOption()}
          {autoscalingOption()}
        </Space>
      </Modal>
      {scalerType === `KEDA_CPU` && <Tag color="green"> Keda CPU autoscaller </Tag>}
      {scalerType === `default` && <Tag color="green"> {props.deploymentObj?.obj?.spec?.replicas} replicas </Tag>}
    </Space>
  );
};
