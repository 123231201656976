import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { iUseRegionsController_getRegionById, useRegionsController_getRegionById } from 'services/regions.service';
import { useGqlDeploymentById } from 'services/deployment.service';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { iDeployment, iRegionModel } from 'shared/deployment';
import { iMyUserData } from 'shared/user';
import { Menu } from 'antd';
import { AppstoreAddOutlined, AppstoreOutlined, BarChartOutlined, BarsOutlined, BranchesOutlined, BugOutlined } from '@ant-design/icons';
import { CheckCircleOutlined, CloudServerOutlined, CloudUploadOutlined, ClusterOutlined, CodeOutlined, DashboardOutlined } from '@ant-design/icons';
import { DeploymentUnitOutlined, PauseCircleOutlined, PieChartOutlined, PlusSquareTwoTone, ReadOutlined, SettingOutlined } from '@ant-design/icons';
import { ToolOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons';
import { greenColor, orangeColor, redColor, siderStyle } from 'utils/styles';
import { useAuthedQuery } from 'utils/qlAuth';
import { capitalizeFirstLetter, useUser } from 'utils/common';

const {
  ClusterMange,
  UsersMange,
  AuditLogs,
  ProjectsMange,
  DeploymentMange,
  DeploymentWebSSH,
  DeploymentLogs,
  DeploymentHistory,
  DeploymentSpecs,
  Tariffs,
} = UserPermissions;

export function SideNavBarLevel1() {
  const user = useUser();
  const path = window.location.hash != '' ? window.location.hash.replace('#/', '') : window.location.pathname.replace('/', '');
  const currentPath = path.split('/');
  const selectedItem = [`/${path}`];
  const history = useHistory();
  const [current, setCurrent] = useState(selectedItem);

  const menuitem = () => selectedItem.push(...currentPath.map((_, index) => `/${currentPath.slice(0, index + 1).join('/')}`));

  menuitem();
  useEffect(() => {
    menuitem();
    setCurrent(selectedItem);
  }, [path]);

  const navMenu = [
    { label: 'Applications', key: `/`, icon: <CloudServerOutlined /> },
    { label: 'Clusters', key: `/clusters`, icon: <ClusterOutlined />, permission: ClusterMange, condition: true },
    { label: 'Activity', key: `/audit-logs`, icon: <ReadOutlined />, permission: AuditLogs, condition: true },
    { label: 'Team', key: `/users`, icon: <UserOutlined />, permission: UsersMange, condition: true },
    // { label: 'Templates', key: `/projects`, icon: <DeploymentUnitOutlined />, permission: ProjectsMange, condition: true },
    // { label: 'Charts', key: `/charts`, icon: <PieChartOutlined />, condition: true },
    // { label: 'Integrations', key: `/integrations`, icon: <ExperimentOutlined />, permission: ClusterMange, condition: true },
    // { label: 'Tariffs', key: `/tariffs`, icon: <WalletOutlined />, permission: Tariffs, condition: isUnifie() || isDevLocalhost() },
  ]
    .filter(({ permission, condition }) => {
      const permit = userHasPermission(user, permission);
      return condition === true ? permit : condition === undefined || (condition && permit);
    })
    .map(({ permission, condition, ...rest }) => rest);

  const handleMenuClick = ({ key }) => (setCurrent(key), history.push(key));
  const menuItems = navMenu.filter(v => v).map(item => ({ ...item, label: item.key ? <Link to={item.key}> {item.label} </Link> : item.label }));

  return <Menu mode="horizontal" selectedKeys={current} onClick={handleMenuClick} items={menuItems} style={siderStyle} />;
}

interface iMenuItems {
  label: string | JSX.Element;
  key: string;
  icon?: JSX.Element;
  children?: iMenuItems[];
  disabled?: boolean;
}

function getCurrentMenu(currentPath, deployment, qCluster: iUseRegionsController_getRegionById, projectServiceList, user): iMenuItems[] {
  const { specsErrors, specsWarns } = deployment || {};

  const permissions = [DeploymentMange, DeploymentWebSSH, DeploymentLogs, DeploymentHistory, DeploymentSpecs, ProjectsMange];
  const [permitDeploymentMange, permitDeploymentWebSSH, permitDeploymentLogs, permitDeploymentHistory, permitDeploymentSpecs, permitProjectsMange] =
    permissions.map(permission => userHasPermission(user, permission));

  if (currentPath[0] === 'new') {
    return [
      { label: 'Basic details', key: `/new`, icon: <CloudServerOutlined />, children: null },
      { label: 'Application target', key: `/new`, icon: <PieChartOutlined />, children: null, disabled: true },

      { label: 'Customise', key: `/new`, icon: <DeploymentUnitOutlined />, children: null, disabled: true },
      { label: 'Deploy', key: `/new`, icon: <DeploymentUnitOutlined />, children: null, disabled: true },
    ];
  }

  if (currentPath[0] === 'app') {
    const items = [];

    const baseKey = `/app/${currentPath[1]}`;
    const icon = specsErrors ? <WarningOutlined style={redColor} /> : specsWarns ? <WarningOutlined style={orangeColor} /> : null;

    if (deployment?.isReady) {
      // Show only if application is ready to deploy
      items.push({ label: 'Overview', key: `${baseKey}/status/overview`, icon: <DashboardOutlined /> });

      // if (permitDeploymentMange) {
      //   items.push({ label: 'Service details', key: `${baseKey}/services/services-requests`, icon: <CloudServerOutlined /> });
      // }
      if (permitDeploymentLogs || permitDeploymentSpecs) {
        items.push({ label: <> Diagnostic {icon} </>, key: `${baseKey}/logs`, icon: <BugOutlined /> });
      }

      items.push({
        label: 'Settings',
        key: `${baseKey}/configuration/settings/danger-zone`,
        icon: <SettingOutlined />,
        children: null,
      });
    } else {
      items.push({ label: 'Overview', key: `${baseKey}/configuration/settings/general`, icon: <CloudUploadOutlined />, children: null });
    }

    const projectServices = projectServiceList || [];

    const cofigPath = `${baseKey}/configuration`;

    // List of services in the deployment (application)
    const serviceChildren = [
      ...(permitProjectsMange || permitDeploymentMange
        ? projectServices.map(({ name }) => {
            const path = `${cofigPath}/services/${name}`;
            return {
              label: <Link to={path}> {capitalizeFirstLetter(name)} </Link>,
              key: path,
              icon: deployment?.services?.[name]?.enabled ? <CheckCircleOutlined style={greenColor} /> : <PauseCircleOutlined style={redColor} />,
              children: null,
            };
          })
        : []),
    ];
    if (projectServices.length > 10) {
      // Add services list to the second menu level
      items.push({
        label: 'Services',
        key: `${cofigPath}/new-service`,
        icon: <AppstoreAddOutlined />,
        children: [
          { label: 'Add new service', icon: <PlusSquareTwoTone twoToneColor="#00A58E" />, key: `${cofigPath}/new-service/add`, children: null },
          { type: 'divider' },
          ...serviceChildren,
        ],
      });
    } else {
      // Add services list to the first menu level
      if (serviceChildren.length) {
        items.push.apply(items, serviceChildren);
      }
      items.push({
        label: 'Add new service',
        icon: <PlusSquareTwoTone twoToneColor="#00A58E" />,
        key: `${cofigPath}/new-service/add`,
        children: null,
      });
    }

    items.push({ label: 'Activity', key: `${baseKey}/activity`, icon: <BarsOutlined />, children: null });
    return items;
  }

  if (currentPath[0] === 'clusters') {
    const baseKey = `/clusters/${currentPath[1]}`;
    const navMenu = [
      { label: 'Overview', icon: <CloudServerOutlined /> },
      { label: 'Settings', icon: <ToolOutlined /> },
      { label: 'Nodes', icon: <AppstoreOutlined /> },
      { label: 'PVC', icon: <AppstoreOutlined /> },
      { label: 'Monitoring', icon: <BarChartOutlined /> },
      { label: 'Integrations', icon: <AppstoreOutlined /> },
      { label: 'Agent status', icon: <AppstoreOutlined /> },
      { label: 'Sync logs', icon: <AppstoreOutlined /> },
      { label: 'Audit logs', icon: <ReadOutlined /> },
      { label: 'Events', icon: <ReadOutlined /> },
      { label: 'Danger zone', icon: <BarChartOutlined /> },
    ];

    if (qCluster?.region?.useTerraform) {
      navMenu.push({ label: 'Jobs', icon: <AppstoreOutlined /> });
    }

    return navMenu.map(({ label, icon }) => ({
      label,
      key: `${baseKey}/${label.replace(/ /g, '-').toLowerCase()}`,
      icon,
      children: null,
    }));
  }

  return [];
}

/** @returns Main menu for the application and Can change menu items based on current URL */
export function SideNavBarLevel2() {
  const user = useUser();
  const path = window.location.hash != '' ? window.location.hash.replace('#/', '') : window.location.pathname.replace('/', '');
  const currentPath = path.split('/');
  const selectedItem = [`/${path}`];
  const history = useHistory();
  const [current, setCurrent] = useState(selectedItem);

  // Get data about the cluster if we really on the cluster page
  const qCluster = useRegionsController_getRegionById(
    currentPath[0] === 'clusters' && /^[0-9]+$/.test(currentPath[1]) ? Number(currentPath[1]) : null,
  );

  selectedItem.push(...currentPath.map((_, index) => `/${currentPath.slice(0, index + 1).join('/')}`));

  const deployment: iDeployment = useGqlDeploymentById(Number(currentPath[0] === 'app' && currentPath[1] ? currentPath[1] : null))?.data
    ?.DeploymentsController_getDeployment;

  // In case if we in application creation or configuration page - will add services to the menu
  const query = useAuthedQuery(
    gql`
      query ProjectController_getServiceList($projectId: Int!) {
        ProjectController_getServiceList(projectId: $projectId) {
          name
          uiType
        }
      }
    `,
    { skip: !Number(deployment?.projectId), variables: { projectId: Number(deployment?.projectId) } },
  );

  const menuItems = (getCurrentMenu(currentPath, deployment, qCluster, query?.data?.ProjectController_getServiceList, user) || [])
    .filter(v => v)
    .map(item => ({ ...item, label: item.key ? <Link to={item.key}> {item.label} </Link> : item.label }));
  const menuPop = ({ parentNode }) => parentNode;
  const handleMenuClick = ({ key }) => (setCurrent(key), history.push(key));

  return menuItems.length === 0 ? null : (
    <Menu mode="vertical" getPopupContainer={menuPop} selectedKeys={current} onClick={handleMenuClick} items={menuItems} style={siderStyle} />
  );
}

export function hasSideNavBarLevel2(_user: iMyUserData): boolean {
  const currentPath = (window.location.hash != '' ? window.location.hash.replace('#/', '') : window.location.pathname.replace('/', ''))
    .split('?')[0]
    .split('/');

  if (currentPath[0] === 'cluster' && [`create`, `install`].includes(currentPath[1])) {
    // Hide side nav bar for cluster creation and installation
    return false;
  }
  if (currentPath[0] === 'clusters' && /^[0-9]+$/.test(currentPath[1])) {
    // /clusters/XXX/***
    return true;
  }

  const excludedPath = ['', 'users', 'clusters', 'audit-logs', 'new-application', 'new-from-template', 'new-intro', 'cluster/new', 'first-step'];

  if (excludedPath.includes(currentPath[0])) {
    return false;
  }

  return true;
}
