import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { useEffect, useState } from 'react';
import { useUser } from 'utils/common';
import { useAuthedMutation } from 'utils/qlAuth';
import { CustomWidgets } from 'form-components';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { UsersController_setThemeName, UsersController_setThemeConfig } from 'queries/queries';
import { Button, Divider, notification, Row, Select, Skeleton, Space, Typography } from 'antd';
import { buttonBorder, spaceWidth } from 'utils/styles';
import { BgColorsOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { OptGroup, Option } = Select;

const uiWidget = (widgetType: any) => ({ 'ui:widget': widgetType });

const uiWidgetMap = {
  colorBgBase: 'color',
  colorTextBase: 'color',
  colorPrimary: 'color',
  colorLink: 'color',
  colorInfo: 'color',
  colorSuccess: 'color',
  colorWarning: 'color',
  colorError: 'color',
  fontSize: 'range',
  borderRadius: 'range',
};

const antdThemeTokenUISchema = Object.keys(uiWidgetMap).reduce((schema, key) => {
  schema[key] = uiWidget(uiWidgetMap[key]);
  return schema;
}, {});

const antdThemeTokenSchema: any = {
  type: 'object',
  title: 'Customization for UI Theme',
  properties: {
    colorBgBase: { type: 'string', title: 'Background Color' },
    colorTextBase: { type: 'string', title: 'Text Color' },
    colorPrimary: { type: 'string', title: 'Primary Color' },
    colorLink: { type: 'string', title: 'Link Color' },
    colorInfo: { type: 'string', title: 'Info Color' },
    colorSuccess: { type: 'string', title: 'Success Color' },
    colorWarning: { type: 'string', title: 'Warning Color' },
    colorError: { type: 'string', title: 'Error Color' },
    fontSize: { type: 'number', title: 'Font Size', minimum: 12, maximum: 18, default: 12 },
    borderRadius: { type: 'number', title: 'Border Radius', minimum: 0, maximum: 10, default: 6 },
  },
};

const labelTheme = ['Light', 'Light Compact', 'Dark', 'Dark Compact', 'Custom Light', 'Custom Light Compact', 'Custom Dark', 'Custom Dark Compact'];

const valueTheme = ['default', 'default-compact', 'dark', 'dark-compact', 'custom', 'custom-compact', 'custom-dark', 'custom-dark-compact'];

const themeOptions = labelTheme.map((label, index) => ({ value: valueTheme[index], label: <Text key={index}> {label} </Text> }));

const renderThemeGroups = groups =>
  groups.map(group => (
    <OptGroup
      key={group.label}
      label={
        <Text strong type="secondary">
          {group.label}
        </Text>
      }
    >
      {group.options.map(option => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </OptGroup>
  ));

const renderThemeGroupsOption = () =>
  renderThemeGroups([
    { label: 'Light mode', options: themeOptions.slice(0, 2) },
    { label: 'Dark mode', options: themeOptions.slice(2, 4) },
    { label: 'Custom Light mode', options: themeOptions.slice(4, 6) },
    { label: 'Custom Dark mode', options: themeOptions.slice(6) },
  ]);

export const TenantConfiguration = () => {
  const [formState, setFormState] = useState({});
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [saveUserTheme] = useAuthedMutation(UsersController_setThemeName);
  const [saveUserThemeToken] = useAuthedMutation(UsersController_setThemeConfig);

  const user = useUser();

  useEffect(() => {
    user && (setFormState(user.antThemeToken), setSelectedTheme(user.antThemeName));
  }, [user]);

  const handleSubmit = async e => {
    window.location.reload();
    setFormState(e.formData);
    await saveUserThemeToken({ variables: { config: { antThemeToken: e.formData } } });
    notification.success({ key: 'Saved', message: 'New UI Theme saved', description: 'New customized UI Theme has been applied...' });
  };

  if (!user) return <Skeleton active={true} loading={true} />;

  const handleThemeSelect = async (v: string) => {
    setSelectedTheme(v);
    await saveUserTheme({ variables: { themeName: v } });
    notification.success({ key: 'Saved', message: 'New UI Theme saved', description: 'New customized UI Theme has been applied' });
    /^(default|default-compact|dark|dark-compact)$/.test(v) ? window.location.reload() : null;
  };

  const themeStatus = () => (
    <Space direction="vertical">
      <Text> Select the new UI Theme below </Text>
      <Text strong>
        <Text type="success">
          <BgColorsOutlined />
        </Text>
        &nbsp; Current UI Theme:
      </Text>
      <Select defaultValue={user.antThemeName || 'default'} style={spaceWidth} onSelect={handleThemeSelect}>
        {renderThemeGroupsOption()}
      </Select>
    </Space>
  );

  const themeForm = () => (
    <Row>
      <Divider />
      <Form
        widgets={CustomWidgets}
        formData={formState}
        schema={antdThemeTokenSchema}
        uiSchema={antdThemeTokenUISchema}
        validator={validator}
        onSubmit={handleSubmit}
        onError={e => console.log('Form submit error: ', e)}
      >
        <BottomButtons>
          <Button htmlType="submit" type="primary" style={buttonBorder}>
            Save
          </Button>
        </BottomButtons>
      </Form>
    </Row>
  );

  const appThemes = () => (
    <Space direction="vertical">
      {themeStatus()}
      {selectedTheme && selectedTheme.indexOf('custom') === 0 && themeForm()}
    </Space>
  );

  return appThemes();
};
