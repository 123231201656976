import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { ReactElement, ReactNode, ReactPortal, useState } from 'react';
import { projectService } from 'services/project.service';
import { CustomWidgets } from 'form-components';
import { iDeployment, iProjectModel } from 'shared/deployment';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { CodeLine } from 'components/SharedComponents/CodeLine/CodeLine';
import { Button, Divider, Modal, Popconfirm, Progress, Space, Spin, Typography, notification } from 'antd';
import { buttonBorder, modalStyle, redColor, spaceWidth } from 'utils/styles';
import { DeleteServiceBtn } from './MainTab';

export interface iProjectOneServiceSettingsFormProps {
  project: iProjectModel;
  serviceName: string;
  tabType: string;
  service: iCloudProjectServiceYamlSpecs;
  deployment: iDeployment;
}

interface iVType {
  type: string | number | boolean | ReactElement<string> | Iterable<ReactNode>;
  cmd: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
  message: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
}

const { Text } = Typography;

async function helmResult(resPromis: Promise<any>, title: string) {
  Modal.destroyAll();
  Modal.success({
    title: 'Loading...',
    content: <Progress percent={100} status="active" showInfo={false} />,
    icon: <Spin size="large" />,
    closable: false,
    onOk: () => resPromis,
  });

  try {
    const res = await resPromis;
    const { cmd, error, logs, step1, step2 } = res?.data || {};

    const modalContent = () => {
      const cmdData = cmd ? (
        <Text strong>
          {cmd} <Divider />
        </Text>
      ) : (
        ''
      );

      const errorData = error ? (
        <span style={{ color: '#BC4040' }}>
          {error} <Divider />
        </span>
      ) : null;

      const { stdout: step1Out, stderr: step1Err } = step1 || {};
      const { stdout: step2Out, stderr: step2Err } = step2 || {};

      const logsData =
        logs &&
        logs.map((v: iVType) => {
          const type = (
            <Text strong style={{ color: v.type === 'error' ? '#FF7777' : '#009688' }}>
              {v.type}
            </Text>
          );
          return (
            <Space direction="vertical">
              {v?.cmd} {type}:{v.message} <Divider />
            </Space>
          );
        });

      return (
        <div style={modalStyle}>
          {cmdData}
          {errorData}
          {step1Out}
          {step1Err}
          {step2Out}
          {step2Err}
          {logsData}
        </div>
      );
    };

    Modal.destroyAll();
    res.error
      ? notification.error({ message: `Error: ${res.error || error}` })
      : Modal.success({ width: '800px', title: title, content: modalContent() });
    return res;
  } catch (error) {
    Modal.destroyAll();
    notification.error({ message: `Error: ${error}` });
  }
}

export const ProjectOneServiceSettingsHelmTab = (props: iProjectOneServiceSettingsFormProps) => {
  const { project: projectId, serviceName, tabType, service } = props;
  const [helmConfig, setHelmConfig] = useState(null);
  const serviceOrConfigs = helmConfig === null ? service : helmConfig;
  const { serviceHelmAdd: serviceAdd, serviceHelmPull: servicePull, serviceHelmUpdate: serviceUpdate } = projectService;
  const {
    helmRepositoryChartName: repoChartName,
    helmRepositoryName: repoName,
    helmRepositoryVersion: repoVersion,
    helmRepositoryURL: repoURL,
  } = serviceOrConfigs;

  const handleSubmit = async e => {
    const data = e.formData;
    data.helmChartName = data.helmRepositoryChartName;
    const res = await projectService.setProjectServices(projectId.id, { tabType: tabType, name: data.name, data: data });
    res.error ? notification.error({ message: `Error: ${res.error}` }) : notification.success({ message: 'Ready' });
  };

  const tabConfigurations = () => {
    const addText = 'helm repo add';
    const pullText = 'helm pull';
    const updateText = 'helm update';

    const helmAction = async (action: 'add' | 'pull' | 'update', message: string) => {
      await handleSubmit({ formData: serviceOrConfigs });
      const serviceAction = { add: serviceAdd, pull: servicePull, update: serviceUpdate }[action];
      await helmResult(serviceAction(projectId.id, serviceName), message);
    };

    const hanldeOnConfirmHelmAdd = () => helmAction('add', addText);
    const hanldeOnConfirmHelmPull = () => helmAction('pull', pullText);
    const hanldeOnConfirmHelmUpdate = () => helmAction('update', updateText);

    const commonVersion = repoVersion && `--version ${repoVersion}`;

    const addPop = () => (
      <Space direction="vertical">
        <Text> Run: </Text>
        <CodeLine>
          {addText} {repoName} {repoURL} {commonVersion}
        </CodeLine>
      </Space>
    );

    const pullPop = () => (
      <Text>
        <ul>
          <li>
            {pullText} {repoName} {repoChartName}
          </li>
          <li style={redColor}> This is not a reversible action, and it removes current helm chart files. </li>
        </ul>
      </Text>
    );

    const updatePop = () => {
      const helmText = ['helm repo update', 'helm dependency update', 'helm dependency build'];
      return (
        <Space direction="vertical">
          <Text> Run: </Text>
          <ul>
            {helmText.map((command, index) => (
              <li key={index}> {command} </li>
            ))}
          </ul>
        </Space>
      );
    };

    const helmInstall = () => (
      <Text>
        <Text strong> helm install </Text> {repoChartName} {repoName}/{repoChartName} {commonVersion}
      </Text>
    );

    const helmCommands = [
      { type: 'add', text: addText, description: addPop(), onConfirm: hanldeOnConfirmHelmAdd },
      { type: 'pull', text: pullText, description: pullPop(), onConfirm: hanldeOnConfirmHelmPull, color: { danger: true } },
      { type: 'update', text: updateText, description: updatePop(), onConfirm: hanldeOnConfirmHelmUpdate },
    ];

    const helmButtons = () =>
      helmCommands.map(({ type, text, description, onConfirm, color }) => {
        const addCommands = (
          <>
            {repoName} {repoURL} {commonVersion}
          </>
        );
        const pullCommands = (
          <>
            {repoName} {repoChartName}
          </>
        );
        return (
          <Space size="large" direction="horizontal" key={type}>
            <Popconfirm
              title={`Do you want to run "${text}" command?`}
              okText={type === 'pull' ? 'Remove current files and pull it again' : 'Run'}
              cancelText="Cancel"
              description={description}
              onConfirm={onConfirm}
              placement="right"
            >
              <Button {...color}> Start </Button>
            </Popconfirm>
            <Text>
              <Text strong> {text} </Text>
              {type === 'add' ? addCommands : type === 'pull' ? pullCommands : ''}
            </Text>
          </Space>
        );
      });

    return (
      <Space direction="vertical" style={spaceWidth}>
        {helmInstall()}
        {helmButtons()}
      </Space>
    );
  };

  const formData = () => {
    const formSchema: any = {
      type: 'object',
      properties: {
        helmRepositoryName: { type: 'string', title: 'Helm chart repository name' },
        helmRepositoryURL: { type: 'string', title: 'Helm chart repository URL' },
        helmRepositoryVersion: { type: 'string', title: 'Helm chart version' },
        helmRepositoryChartName: { type: 'string', title: 'Helm chart name' },
        // helmChartName: {
        //   hidden: true,
        //   type: 'string',
        //   title: `Helm chart path (often the same as 'helm chart name')`,
        //   description: `Path in project reposytory to the helm chart folder (please put all your helm charts to the folder "helm" in your project root directory)`,
        // },
      },
    };

    const formUI = {
      helmRepositoryName: { 'ui:emptyValue': '', 'ui:placeholder': 'Enter Helm chart repository name here' },
      helmRepositoryURL: { 'ui:emptyValue': '', 'ui:placeholder': 'Enter Helm chart repository URL here' },
      helmRepositoryVersion: { 'ui:emptyValue': '', 'ui:placeholder': 'Enter Helm chart version here' },
      helmRepositoryChartName: { 'ui:emptyValue': '', 'ui:placeholder': 'Enter Helm chart name here' },
      helmChartName: { 'ui:emptyValue': '', 'ui:placeholder': 'Enter Helm chart name here' },
    };

    const handleOnChange = async e => {
      e.formData.helmChartName = e.formData.helmRepositoryChartName;
      setHelmConfig(e.formData);
    };

    const handleOnError = e => console.log(`Error in form submit: ${e}`);

    return (
      <Form
        widgets={CustomWidgets}
        formData={serviceOrConfigs}
        schema={formSchema}
        uiSchema={formUI}
        validator={validator}
        onChange={handleOnChange}
        onSubmit={handleSubmit}
        onError={handleOnError}
      >
        <BottomButtons extra={<DeleteServiceBtn serviceName={serviceName} projectId={projectId.id} deploymentId={props.deployment.id} />}>
          <Button type="primary" htmlType="submit" style={buttonBorder}>
            Save
          </Button>
        </BottomButtons>
      </Form>
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      {formData()}
      {tabConfigurations()}
    </Space>
  );
};
