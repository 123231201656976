import Markdown from 'react-markdown';
import { iDeployment } from 'shared/deployment';
import { Card } from 'antd';
import { bottomMargin } from 'utils/styles';

interface iDeploymentNotesProps {
  deployment: iDeployment;
}
export const DeploymentNotes = (props: iDeploymentNotesProps) => {
  if (!props.deployment || !props.deployment.notes) return null;

  const notes: any = props.deployment.notes;
  return (
    <Card title="Notes" style={bottomMargin}>
      <Markdown>{notes}</Markdown>
    </Card>
  );
};
