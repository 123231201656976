import { Typography, Button, Space, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { TipRight } from '../Tooltip/Tooltip';

export const CodeLine = ({ children }) => {
  const { Paragraph } = Typography;

  const handleCopy = () => navigator.clipboard.writeText(children).then(() => message.success('Snippet copied successfully...'));

  return (
    <Space direction="horizontal">
      <TipRight tip="Click to copy this snippet">
        <Button type="link" icon={<CopyOutlined />} onClick={handleCopy} />
      </TipRight>
      <Paragraph>
        <pre>{children}</pre>
      </Paragraph>
    </Space>
  );
};
