import { iDeployment } from 'shared/deployment';
import { DeploymentMetacontrollerLogs } from './DeploymentMetacontrollerLogs';
import { Alert, Space } from 'antd';
import { spaceWidth } from 'utils/styles';

export interface iMetacontrollerLogsProps {
  deployment: iDeployment;
}

export default function MetacontrollerLogs(props: iMetacontrollerLogsProps) {
  const deployment = props.deployment;

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Alert showIcon type="info" message={`Metacontroller Sync logs.`} />
      <DeploymentMetacontrollerLogs deployment={deployment} />
    </Space>
  );
}
