import { renderGolangTemplate } from 'shared/templates';
import { JsonFormModalUI } from '../JsonFormModalUI';
import { kind_Deployment } from './Deployment';
import { kind_Secret } from './Secret';
import { Alert } from 'antd';

export interface iOneKindType {
  schema: any;
  name: string;
}

interface iKJindLib {
  [key: string]: iOneKindType;
}

/**
 * Teplate for new kube items, just an examples
 * Here we can use variables like {{ .name }} and so on (see renderGolangTemplate function and `minimalExampleOptions`)
 */
const KindLibExamples = {
  persistentvolumeclaim: `apiVersion: v1
kind: PersistentVolumeClaim
metadata:
  name: "{{ .name }}"
spec:
  accessModes:
    - ReadWriteOnce
  resources:
    requests:
      storage: 1Gi`,
  configmap: `apiVersion: v1
data:
  config.ini: '# Example config file'
kind: ConfigMap
metadata:
  labels:
    name: "{{ .name}}"
  name: "{{ .name}}"`,
  secret: `apiVersion: v1
kind: Secret
metadata:
  name: {{ .name }}
type: Opaque
stringData:
  mypassword: "super-secret-string"`,
  service: `apiVersion: v1
kind: Service
metadata:
  labels:
    app: {{ .name }}
  name: {{ .name }}
spec:
  ports:
    - name: http
      port: {{ .port }}
      protocol: TCP
      targetPort: {{ .targetPort }}
  selector:
    app: {{ .name }}
  sessionAffinity: None
  type: {{ .type }}`,
  deployment: `apiVersion: apps/v1
kind: Deployment
metadata:
  name: "{{ .name }}"
spec:
  selector:
    matchLabels:
      app: "{{ .name }}"
  replicas: 1
  template:
    metadata:
      labels:
        app: "{{ .name }}"
    spec:
      containers:
      - name: "{{ .name }}"
        image: "{{ .image }}"
        ports:
        - containerPort: 80`,

  statefulset: `apiVersion: apps/v1
kind: StatefulSet
metadata:
  labels:
    app: "{{ .name }}"
  name: "{{ .name }}"
spec:
  replicas: 1
  selector:
    matchLabels:
      app: "{{ .name }}"
  serviceName: "{{ .name }}-headless"
  template:
    metadata:
      labels:
        app: "{{ .name }}"
    spec:
      containers:
      - name: "{{ .name }}"
        image: "{{ .image }}"
        imagePullPolicy: Always
        ports:
        - containerPort: 80
          name: http
          protocol: TCP
      restartPolicy: Always
`,
  daemonset: `apiVersion: apps/v1
kind: DaemonSet
metadata:
labels:
  app: "{{ .name }}"
name: "{{ .name }}"
spec:
replicas: 1
selector:
  matchLabels:
    app: "{{ .name }}"
template:
  metadata:
    labels:
      app: "{{ .name }}"
  spec:
    containers:
    - name: "{{ .name }}"
      image: "{{ .image }}"
      imagePullPolicy: Always
      ports:
      - containerPort: 80
        name: http
        protocol: TCP
    restartPolicy: Always
`,
  cronjob: `apiVersion: batch/v1
kind: CronJob
metadata:
  name: "{{ .image }}"
spec:
  schedule: '*/5 * * * *'
  concurrencyPolicy: Forbid
  startingDeadlineSeconds: 600
  suspend: false
  successfulJobsHistoryLimit: 1
  failedJobsHistoryLimit: 1
  jobTemplate:
    spec:
      backoffLimit: 0
      template:
        metadata:
          labels:
            app: "{{ .image }}"
        spec:
          restartPolicy: Never
          containers:
          - name: dashboard-job
            image: "{{ .image }}"
            imagePullPolicy: IfNotPresent
            ports:
            - containerPort: 80`,
  scaledobject: `apiVersion: keda.sh/v1alpha1
kind: ScaledObject
metadata:
  name: "{{ .name }}"
spec:
  scaleTargetRef:
    name: "{{ .scaleTargetRefName }}"
    kind: Deployment
    apiVersion: apps/v1
  fallback:
    failureThreshold: 4
    replicas: 1
  minReplicaCount: 1
  maxReplicaCount: 2
  triggers:
  - type: cpu
    metricType: Utilization
    metadata:
      value: '2000'`,
};

/**
 * Structure of all possible kube objects types
 */
export const KindLib: iKJindLib = {
  deployment: { schema: kind_Deployment, name: 'Deployment' },
  statefulset: { schema: kind_Deployment, name: 'StatefulSet' },
  daemonset: { schema: kind_Deployment, name: 'DaemonSet' },
  cronjob: { schema: kind_Deployment, name: 'CronJob' },
  secret: { schema: kind_Secret, name: 'Secret' },
  service: { schema: kind_Secret, name: 'Service' },
  configmap: { schema: kind_Secret, name: 'ConfigMap' },
  scaledobject: { schema: kind_Secret, name: 'Keda ScaledObject' },
  persistentvolumeclaim: { schema: kind_Secret, name: 'PersistentVolumeClaim' },
};

/**
 * Minimal options for all kube objects types
 */
const commonExampleOptionsSchema = { schema: { type: 'object', properties: { name: { type: 'string', title: 'Name' } } }, ui: {} };

const minimalExampleOptions_for_deployment = {
  schema: {
    type: 'object',
    properties: {
      name: { type: 'string', title: 'Name', default: `my-deployment` },
      image: { type: 'string', title: 'Docker Image', default: `karthequian/helloworld:latest` },
    },
  },
  ui: {},
};

/**
 * Minimal options for some kube objects types
 * For cases when we need to show more than only name option field (for example deployment)
 */
const minimalExampleOptions = {
  statefulset: minimalExampleOptions_for_deployment,
  deployment: minimalExampleOptions_for_deployment,
  service: {
    schema: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          title: 'Name',
          default: `my-deployment`,
          description: `The name of the service. You can use the same name as the deployment name.`,
        },
        port: { type: 'number', title: 'Port', description: `Port on the service to direct traffic to.`, default: 80 },
        targetPort: { type: 'number', title: 'Target port', description: `Port on the container to direct traffic to.`, default: 80 },
        type: {
          type: 'string',
          title: 'Type',
          default: `ClusterIP`,
          enum: [`ClusterIP`, `NodePort`, `LoadBalancer`, `ExternalName`],
          description: `Type of the service.`,
        },
      },
    },
    ui: {},
  },
  scaledobject: {
    schema: {
      type: 'object',
      properties: {
        name: { type: 'string', title: 'Name', default: `my-deployment` },
        scaleTargetRefName: {
          type: 'string',
          title: 'Target ref name',
          default: `my-deployment`,
          description: `The name of the deployment to scale.`,
        },
      },
    },
    ui: {},
  },
};

/**
 * Show modal with form to fill the template variables
 * @param kind - kube object type
 * @returns - yaml string or empty string if user canceled
 */
export const getExampleYaml = async (kind: string): Promise<{ example: string; status: boolean }> => {
  return new Promise(resolve => {
    let conf = commonExampleOptionsSchema;
    if (minimalExampleOptions[kind]) conf = minimalExampleOptions[kind];
    JsonFormModalUI({
      title: `New ${KindLib[kind].name}`,
      help: <Alert type="info" showIcon message={`Fill the form, You can change it later.`} />,
      formData: {},
      formConfigs: conf,
      onChange: (v: any) => resolve({ example: renderGolangTemplate(KindLibExamples[kind], v), status: true }),
      onCancel: () => resolve({ example: '', status: false }),
    });
  });
};
