import { RegistryWidgetsType } from '@rjsf/utils';
import { YamlWidget } from './yaml';
import { JSONWidget } from './json';
import { DeploymentVarWidget } from './deploymentVarWidget';

export const CustomWidgets: RegistryWidgetsType = {
  YamlWidget: YamlWidget,
  JSONWidget: JSONWidget,
  DeploymentVarWidget: DeploymentVarWidget,
};
