import { EditOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { Space, Button, Tag } from 'antd';
import { iProjectDeploymentSyncVarsContext } from 'components/Projects/ProjectDeploymentContext';
import { JsonFormModalUI } from '../JsonFormModalUI';
import { form_env_schema, form_env_schema_ui } from '../schema/ContainerEnv';
import { iWCodeObjectArray } from './DockerWizard';
import { buttonSize } from 'utils/styles';

interface iEnvVarsUI {
  syncVarsContext: iProjectDeploymentSyncVarsContext;
  path: string;
  yamlObj: iWCodeObjectArray;
  onChange: (path: string, newObj: any) => void;
}

export const EnvVarsUI = (props: iEnvVarsUI) => {
  const val = _.get(props.yamlObj?.obj, props.path.replace(/^./, ''));

  const handleOnClick = () => {
    JsonFormModalUI({
      formData: val || [],
      formConfigs: { schema: form_env_schema, ui: form_env_schema_ui },
      SyncVarsContext: props.syncVarsContext,
      onChange: (v: any) => props.onChange(props.path, v),
    });
  };

  return (
    <Space direction="horizontal">
      <Button icon={<EditOutlined />} onClick={handleOnClick} style={buttonSize}>
        Add default env vars
      </Button>
      <Tag color={val?.length ? 'green' : 'red'}> {val?.length ? `${val.length} env vars` : 'Not configured'} </Tag>
    </Space>
  );
};
