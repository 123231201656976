import { useState } from 'react';
import { Button, Drawer, Space } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { TipTop } from 'components/SharedComponents/Tooltip/Tooltip';

interface iHelpDrawerTpl {
  title?: string;
  children: React.ReactNode;
  btnText?: string | React.ReactNode;
}

export const HelpDrawerTpl = (props: iHelpDrawerTpl) => {
  const [drawerState, setDrawerState] = useState(false);

  const commonClick = (state: boolean) => (event: React.MouseEvent | React.KeyboardEvent) => {
    setDrawerState(state);
    event.stopPropagation();
    event.preventDefault();
  };

  const helpButton = () => (
    <TipTop tip="Help">
      <Button size="small" type="text" icon={<QuestionCircleTwoTone twoToneColor="#00A58E" />} />
    </TipTop>
  );

  const appDrawer = () => (
    <>
      <Space role="button" title={props.title} tabIndex={0} onClick={commonClick(true)} onKeyUp={commonClick(true)}>
        {props.btnText ? props.btnText : helpButton()}
      </Space>

      <Drawer width={300} title={props.title} open={drawerState} onClose={commonClick(false)}>
        <Space direction="vertical"> {props.children} </Space>
      </Drawer>
    </>
  );

  return appDrawer();
};
